import React, { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from '../../../contexts/firebase';
import { MessagesContext } from '../../../contexts/messages';
import styled from 'styled-components/macro';
import CarouselItem from './CarouselItem';
import Spinner from '../../shared/Spinner';
import HTML5Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

const Doc = styled.div`
  display: flex;
  align-items: center;
`;

const List = styled(Doc)`
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const CarouselList = ({ filter = '' }) => {
  const { database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const [fetched, setFetched] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [filteredCarousels, setFilteredCarousels] = useState([]);

  /**
   * Subscribe to the Doc list
   */
  useEffect(() => {
    setFetched(false);

    const unsub = database
      .collection('/carousel')
      .orderBy('order', 'asc')
      .onSnapshot(
        docs => {
          const carousel = docs.docs
            .map(d => ({
              ...d.data(),
              id: d.id,
              ref: d.ref,
            }))
            .sort((a, b) => a.order - b.order);

          setAllItems(carousel);
          setFetched(true);
        },
        e => {
          console.error(e);
          addMessage('Unable to fetch carousel list', 'error');
          setFetched(true);
        },
      );

    return () => unsub && typeof unsub === 'function' && unsub();
  }, [addMessage, database]);

  /**
   * Filter the carousel list based on the search field
   */
  useEffect(() => {
    if (!filter && !allItems.length) {
      return;
    }

    if (!filter) {
      return setFilteredCarousels(allItems);
    }

    const regex = new RegExp(filter, 'i');

    const filtered = allItems.filter(
      g => regex.test(g.displayName) || regex.test(g.email),
    );

    setFilteredCarousels(filtered);
  }, [filter, allItems]);

  return (
    <List>
      {!fetched && <Spinner />}

      {fetched && (!filteredCarousels || !filteredCarousels.length) && (
        <div>No results found.</div>
      )}

      {filteredCarousels && (
        <DndProvider backend={HTML5Backend}>
          {filteredCarousels.map((carousel, index) => (
            <CarouselItem
              key={index}
              order={index}
              carousel={carousel}
              allItems={allItems}
            />
          ))}
        </DndProvider>
      )}
    </List>
  );
};

export default CarouselList;
