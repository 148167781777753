import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import LensIcon from '@material-ui/icons/Lens';
import { useHistory } from 'react-router';
import { pubScroll } from '../../constants/routes.js';
import { TABLET_WIDTH } from '../../constants/dimensions.js';
import Hamburger from './Hamburger.jsx';
import { FeaturesContext } from '../../contexts/features';
import { COLORS } from '../../constants/colors.js';

export const StyledMenu = withStyles({
  paper: {
    maxWidth: 400,
    backgroundColor: '#e2e2ef',

    '& ul': {
      '& li::before': {
        content: '-',
      },
    },
  },
})(props => {
  return (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  );
});

export const StyledMenuItem = withStyles({
  root: {
    alignItems: 'baseline',
    whiteSpace: 'normal',
    color: '#3b3b8f',
    fontWeight: 'bold',
    fontSize: 18,
  },
})(({ children, ...props }) => {
  return (
    <MenuItem dense={true} {...props}>
      <LensIcon
        style={{ fontSize: '0.6rem', marginRight: 8 }}
        htmlColor="#992131"
        fontSize="small"
      />
      {children}
    </MenuItem>
  );
});

export const handleBasLogin = (features, history) => {
  if (features.active('demoLogin')) {
    history.push({
      pathname: pubScroll.LOGIN_DEMO,
      search: history.location.search,
    });
  } else if (/^next\.|test\./.test(window.location.host)) {
    window.location.href =
      'https://www.bastest.com/web_projects/MainHomePage/Login.aspx?site=311210472';
  } else {
    window.location.href =
      'https://www.myenroll.com/web_projects/MainHomePage/Login.aspx?site=311210472';
  }
};

const StyledButton = withStyles({
  root: {
    textTransform: 'none',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 20,
    color: COLORS.RED,
    [`@media (max-width: ${TABLET_WIDTH}px)`]: {
      fontSize: 16,
      display: 'none',
    },
  },
})(props => {
  return <Button aria-haspopup="true" color="inherit" {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0)',
    boxShadow: 'none',
  },
  toolbar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    flexWrap: 'wrap',
    backgroundColor: 'white',
    padding: '0 64px',
    maxWidth: 1440,
    borderBottom: `1px inset ${COLORS.RED}`,
    borderTop: 'none',
    // boxShadow:
    //   '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    [`@media (max-width: ${TABLET_WIDTH}px)`]: {
      padding: '0 24px',
    },
  },
  menuButton: {
    [`@media (min-width: ${TABLET_WIDTH + 1}px)`]: {
      display: 'none',
    },
  },
}));

const NavBar = () => {
  const classes = useStyles();
  const history = useHistory();
  const features = useContext(FeaturesContext);

  const [anchorEls, setAnchorEls] = useState({ about: null });

  const handleClick = (menuName, event) => {
    setAnchorEls({ ...anchorEls, [menuName]: event.currentTarget });
  };

  const handleClose = menuName => {
    setAnchorEls({ ...setAnchorEls, [menuName]: null });
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Hamburger classIcon={classes.menuButton} />

          <StyledButton
            aria-controls="about-reta"
            onClick={e => handleClick('about', e)}
          >
            About Reta
          </StyledButton>
          <StyledButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="inherit"
            onClick={() => {
              history.push({
                pathname: pubScroll.ROOT,
                hash: 'small-employer',
                search: history.location.search,
              });
            }}
          >
            Reta Health Plans
          </StyledButton>
          <StyledButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="inherit"
            onClick={() => {
              history.push({
                pathname: pubScroll.ROOT,
                hash: 'request-proposal',
                search: history.location.search,
              });
            }}
          >
            Request a Proposal
          </StyledButton>
          <StyledButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="inherit"
            onClick={() => {
              history.push({
                pathname: pubScroll.ROOT,
                hash: 'explore',
                search: history.location.search,
              });
            }}
          >
            Explore More
          </StyledButton>
          <StyledButton
            aria-controls="reta-login"
            aria-haspopup="true"
            color="inherit"
            onClick={e => {
              handleClick('login', e);
            }}
          >
            Reta Login
          </StyledButton>
          <StyledButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="inherit"
            onClick={() => {
              history.push({
                pathname: pubScroll.ROOT,
                hash: 'home',
                search: history.location.search,
              });
            }}
          >
            Home
          </StyledButton>

          {/* About Reta */}
          <StyledMenu
            id="about-reta"
            anchorEl={anchorEls.about}
            keepMounted
            open={Boolean(anchorEls.about)}
            onClose={() => handleClose('about')}
          >
            <div>
              <StyledMenuItem
                onClick={() => {
                  history.push({
                    pathname: pubScroll.ROOT,
                    hash: 'welcome',
                    search: history.location.search,
                  });
                  handleClose('about');
                }}
              >
                Our Story
              </StyledMenuItem>
              <StyledMenuItem
                onClick={() => {
                  history.push({
                    pathname: pubScroll.ROOT,
                    hash: 'reta-advantage',
                    search: history.location.search,
                  });
                  handleClose('about');
                }}
              >
                The Reta Advantage
              </StyledMenuItem>
              <StyledMenuItem
                onClick={() => {
                  history.push({
                    pathname: pubScroll.ROOT,
                    hash: 'member-orgs',
                    search: history.location.search,
                  });
                  handleClose('about');
                }}
              >
                Member Organizations
              </StyledMenuItem>
              <StyledMenuItem
                onClick={() => {
                  history.push({
                    pathname: pubScroll.ROOT,
                    hash: 'leadership',
                    search: history.location.search,
                  });
                  handleClose('about');
                }}
              >
                Reta Leadership
              </StyledMenuItem>
            </div>
          </StyledMenu>

          {/* Reta Login */}
          <StyledMenu
            id="reta-login"
            anchorEl={anchorEls.login}
            keepMounted
            open={Boolean(anchorEls.login)}
            onClose={() => handleClose('login')}
          >
            <div>
              <StyledMenuItem
                onClick={() => {
                  handleBasLogin(features, history);
                  handleClose('login');
                }}
              >
                Login for Reta Members
              </StyledMenuItem>
              <StyledMenuItem
                onClick={() => {
                  history.push({
                    pathname: pubScroll.LOGIN,
                  });
                  handleClose('login');
                }}
              >
                Login for Trustors, Committee Members and Trustees
              </StyledMenuItem>
            </div>
          </StyledMenu>
        </Toolbar>
      </AppBar>
    </div>
  );
};
export default NavBar;
