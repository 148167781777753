import React, { useState, useRef, useEffect, useContext } from 'react';
import { POSTER_WIDTH, POSTER_HEIGHT } from '../../constants/dimensions';
import imgArrowLeft from '../../assets/img/arrowLeft.svg';
import imgArrowRight from '../../assets/img/arrowRight.svg';
import Slider from 'react-slick';
import { VideoModal } from './VideoModal';
import { Poster } from './Poster';
import { Paragraph } from './Paragraph';
import { Header } from './Header';
import { COLORS } from '../../constants/colors';
import styled from 'styled-components/macro';
import { FirebaseContext } from '../../contexts/firebase';
import { MessagesContext } from '../../contexts/messages';
import { FeaturesContext } from '../../contexts/features';
import Promise from 'bluebird';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${props =>
    props.scrollingLayout ? 'inherit' : COLORS.CAROUSEL_BG};
  box-sizing: border-box;
  padding-bottom: 74px;
  overflow-y: auto;
`;

const PosterArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 28px;
  background-color: ${props =>
    props.scrollingLayout ? 'inherit' : COLORS.CAROUSEL_BG};
`;

const Arrow = styled.div`
  display: flex;
  flex: 1 1 22px;
  justify-content: center;
  align-items: center;
  height: ${POSTER_HEIGHT}px;
  cursor: pointer;
`;

const Img = styled.img`
  width: auto;
  height: auto;
`;

const CarouselArea = styled.div`
  width: ${POSTER_WIDTH}px;
  flex: 0 0 ${POSTER_WIDTH}px;
  min-width: 0;
  min-height: 0;
  overflow: hidden;

  & .slick-track {
    display: flex;
  }
`;

const Carousel = () => {
  const [showVideo, setShowVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState(false);
  const [items, setItems] = useState([]);
  const slider = useRef();
  const { database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);
  const features = useContext(FeaturesContext);

  useEffect(() => {
    if (!database) return;

    return database
      .collection('carousel')
      .where('active', '==', true)
      .orderBy('order', 'asc')
      .onSnapshot(
        async query => {
          const videoItems = await Promise.map(
            query.docs,
            async doc => {
              // resolve the trustor name
              const data = await new Promise(async resolve => {
                const data = doc.data();
                let trustorName = data.trustor;

                // look up the trustor
                if (data.trustor.id) {
                  const trustor = await data.trustor.get();
                  trustorName = trustor.data().displayName;
                }

                return resolve({
                  title: [data.name, data.title, trustorName],
                  video: data.videoUrl,
                  thumbnail: data.thumbnailUrl,
                });
              });

              return data;
            },
            { concurrency: 3 },
          );

          console.log('Video Items', videoItems);
          setItems(videoItems);
        },
        err => {
          console.error(err);
          addMessage('Unable to get carousel items.', 'error');
        },
      );
  }, [database, addMessage]);

  return (
    <Wrapper
      className="wrapper"
      scrollingLayout={features.active('scrollingLayout')}
    >
      {/* Header */}
      <Header className="header">Real People. Real Reta.</Header>

      {/* Description */}
      <Paragraph className="description">
        There are good reasons why Reta continues to add new members as they
        bring increasing value to those they serve. But this story is best told
        by the Real People of Reta.
      </Paragraph>

      {/* Poster Area */}
      {items && items.length > 0 && (
        <PosterArea scrollingLayout={features.active('scrollingLayout')}>
          {/* Prev Arrow */}
          <Arrow>
            <Img
              src={imgArrowLeft}
              onClick={() => slider.current.slickPrev()}
            />
          </Arrow>

          {/* Carousel */}

          <CarouselArea>
            <Slider
              infinite={true}
              autoplay={true}
              autoplaySpeed={10000}
              easing={true}
              lazyLoad={true}
              pauseOnHover={true}
              useCSS={true}
              arrows={false}
              speed={1000}
              ref={slider}
            >
              {items.map((item, i) => (
                <Poster
                  key={i}
                  item={item}
                  onClickPlay={url => {
                    setShowVideo(true);
                    setVideoUrl(url);
                  }}
                />
              ))}
            </Slider>
          </CarouselArea>

          {/* Next Arrow */}
          <Arrow>
            <Img
              src={imgArrowRight}
              onClick={() => slider.current.slickNext()}
            />
          </Arrow>
        </PosterArea>
      )}

      {/* Video Modal */}
      {showVideo && (
        <VideoModal
          videoUrl={videoUrl}
          onHideModal={() => setShowVideo(false)}
        />
      )}
    </Wrapper>
  );
};

export default Carousel;
