import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { TABLET_WIDTH } from '../../../constants/dimensions';
import { VideoModal } from '../../carousel/VideoModal';
import PlayButton from './PlayButton';

const VideoWrapper = styled.div`
  position: relative;
  float: ${props => props.float};

  @media (max-width: ${TABLET_WIDTH}px) {
    margin-bottom: 12px;
  }

  & img {
    width: 600px;

    @media (max-width: ${TABLET_WIDTH}px) {
      width: 100%;
      float: clear;
      padding: 0;
    }
  }

  & .slug {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    color: black;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    padding-bottom: 7px;
  }

  & .play {
    position: absolute;
    width: 100%;
    bottom: 45px;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
  }
`;

const Video = ({ poster, videoUrl, float = 'left', children, ...props }) => {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <VideoWrapper float={float} {...props}>
      <div>
        <img src={poster} alt="poster" />
        <div className="play">
          <PlayButton
            onClickPlay={() => {
              setShowVideo(true);
            }}
          />
        </div>
        <div className="slug">{children}</div>
      </div>

      {/* Video Modal */}
      {showVideo && (
        <VideoModal
          videoUrl={videoUrl}
          onHideModal={() => setShowVideo(false)}
        />
      )}
    </VideoWrapper>
  );
};

export default Video;
