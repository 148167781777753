import React, { useContext, useState, useEffect } from 'react';
import Canvas from '../../../admin/shared/Canvas';
import Header from '../../../shared/Header2';
import styled from 'styled-components/macro';
import TextInput from '../../../shared/TextInput';
import Spinner from '../../../shared/Spinner';
import Button from '../../../shared/Button';
import { vault } from '../../../../constants/routes';
import { FirebaseContext } from '../../../../contexts/firebase';
import { MessagesContext } from '../../../../contexts/messages';

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  margin-bottom: 42px;

  &.header {
    margin-bottom: 16px;
  }

  &.column {
    flex-direction: column;
  }

  &.center {
    justify-content: center;
    align-items: center;
  }

  &.field {
    justify-content: center;
    margin-bottom: 16px;
  }

  &.buttons {
    justify-content: space-between;
  }

  & > div {
    line-height: 1.6;
    margin-bottom: 24px;
  }
`;

const ResourceDelete = ({ match, history }) => {
  const { categoryId, resourceId } = match.params;

  const { database, storage } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const [resource, setResource] = useState();
  const [deleting, setDeleting] = useState(false);
  const [valid, setValid] = useState(false);

  /**
   * Look up the resource
   */
  useEffect(() => {
    (async () => {
      let doc;
      try {
        doc = await database
          .doc(`/vault_categories/${categoryId}/resources/${resourceId}`)
          .get();
      } catch (e) {
        console.error(e);
        addMessage('Unable to collect the resource.', 'error');
        return;
      }

      if (!doc.exists) {
        addMessage('Unable to locate resource.', 'warn');
        return;
      }

      setResource(doc.data());
    })();
  }, [addMessage, categoryId, database, resourceId]);

  /**
   * Handle events
   */
  const handleValidation = text => {
    setValid(text === resource.displayName);
  };

  const handleDelete = async () => {
    setDeleting(true);

    /**
     * Delete the database record
     */
    try {
      await database
        .doc(`/vault_categories/${categoryId}/resources/${resourceId}`)
        .delete();
    } catch (e) {
      console.error(e);
      addMessage(`Unable to delete the "${resource.displayName}".`, 'error');
      return;
    }

    /**
     * Delete the storage
     */
    const { items } = await storage
      .child(`vault_categories/${categoryId}/resources/${resourceId}`)
      .list();

    if (items.length) {
      try {
        await Promise.all(
          items.map(async item => {
            console.debug('Deleting resource item:', item);
            await item.delete();
          }),
        );
      } catch (e) {
        console.error(e);
        addMessage(
          `Unable to delete the storage for "${resource.displayName}".`,
          'error',
        );
        return;
      }
    } else {
      console.debug(
        'No storage items found in:',
        `vault_categories/${categoryId}/resources/${resourceId}/`,
      );
    }

    addMessage(`Successfully deleted "${resource.displayName}".`);
    setDeleting(false);
    history.replace(vault.admin.CATEGORIES + '/' + categoryId);
  };

  return (
    <Canvas>
      <Row className="header">
        <Header>Delete Resource</Header>
      </Row>

      {resource && (
        <React.Fragment>
          <Row className="column">
            <div>
              To continue deleting the resource, type in the resource name "
              <b>{resource.displayName}</b>" below:
            </div>
          </Row>

          <Row className="field">
            <div style={{ width: '75%' }}>
              <TextInput
                placeholder="Enter the resource name to delete"
                onDebounced={handleValidation}
              />
            </div>
          </Row>

          {!deleting && (
            <Row className="buttons">
              <Button invert onClick={() => history.goBack()}>
                Cancel
              </Button>
              <Button disabled={!valid} onClick={handleDelete}>
                Delete Resource
              </Button>
            </Row>
          )}

          {deleting && <Spinner />}
        </React.Fragment>
      )}
    </Canvas>
  );
};

export default ResourceDelete;
