import React, { useContext } from 'react';
import { FirebaseContext } from '../../contexts/firebase';
import {
  pubScroll,
  member,
  vault as vaultRoutes,
  admin,
} from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import imgUser from '../../assets/img/user.svg';
import imgUserAdmin from '../../assets/img/user-admin.svg';
import imgSignOut from '../../assets/img/signOut.svg';
import imgAdmin from '../../assets/img/admin.svg';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  background-color: #495b5e;
  padding: 6px;
  box-sizing: border-box;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 6px 12px;
  box-sizing: border-box;
  text-decoration: none;
  color: white;
`;

const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 12px;
`;

const SignOut = styled.img`
  cursor: pointer;
  margin-left: 12px;
  box-sizing: border-box;
`;

const Right = styled.div`
  display: flex;
  flex: 1 1 50px;
  justify-content: flex-end;
  align-items: center;
`;

const Header = ({ history, vault = false }) => {
  const { firebase, user, profile } = useContext(FirebaseContext);

  const signOut = async () => {
    // redirect to the sign out page
    history.push(pubScroll.LOGOUT);

    // unsubscribe from profile changes
    profile.unsub();

    // sign out
    await firebase.auth().signOut();
  };

  return (
    <Wrapper>
      {profile.isActive && (
        <StyledLink to={member.PROFILE}>
          <img alt="user" src={profile.isAdmin ? imgUserAdmin : imgUser} />
          <Name>{profile.displayName || user.email}</Name>
        </StyledLink>
      )}

      <Right>
        {profile.isAdmin && (
          <Link to={vault ? vaultRoutes.admin.HOME : admin.HOME}>
            <img src={imgAdmin} alt="Admin" />
          </Link>
        )}

        <SignOut alt="Sign Out" src={imgSignOut} onClick={signOut} />
      </Right>
    </Wrapper>
  );
};

export default withRouter(Header);
