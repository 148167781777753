import imgPillar from './RETA.org.PILLAR.IV.BUTTON.png';
import Paragraph from '../../../shared/newShared/Paragraph';
import Pillar from '../Pillar';
import imgHuber from './RETA.org.LAURA.HUBER.png';
import imgMcGowan from './RETA.org.KATHRYNE.MCGOWAN.png';
import Video from '../Video';
import Header from '../../../shared/Header';
import useSetTitle from '../../../../hooks/useSetTitle';

const Pillar2 = () => {
  useSetTitle('Pillar IV');

  return (
    <Pillar imgSrc={imgPillar} imgAlt="Pillar IV">
      <Paragraph>
        Of all the Pillars that support the Reta promise, there is one that is
        the glue that ties them all together. Pillar IV is about the technology
        platforms that allow us to operate and communicate. And how Reta uses
        the latest technologies to simplify benefit administration and
        personalize benefit communication to help members better <b>choose</b>{' '}
        and <b>use</b> their benefits.{' '}
      </Paragraph>

      <Paragraph>
        The good news is, you don’t even have to think about it. It all happens
        automatically. And you can access these systems and customer support
        around the clock….making it convenient for members, administrators and
        Trustors alike.{' '}
      </Paragraph>

      <Video
        poster={imgHuber}
        float="right"
        style={{ paddingLeft: 25 }}
        videoUrl="https://assets.retatrust.org/2021/210421.1/Huber+P4.mp4"
      >
        Laura Huber — Finance Officer, Diocese of Bismarck ND
      </Video>

      <Paragraph>
        Reta’s benefit administration system through BAS and Reta’s personalized
        communication platform we call the <i>Reta Benefits Center</i> or RBC…
        work together seamlessly to provide a robust … yet user-friendly way for
        members to choose and use their benefits.
      </Paragraph>

      <Paragraph>
        What you don’t see is the Reta team and our partners who work behind the
        scenes… and sometimes around the clock… with a keen eye to assure that
        all things are running as they should. Simply. Effectively. Efficiently.
        While they tend to those things… that need tending to.
      </Paragraph>

      <Header>
        Adapting and developing tools and technology to support our Trustors
      </Header>

      <Paragraph>
        It’s our obligation to understand the complex needs of our individual
        Trustors that continue to gather under the Reta umbrella. And in so
        doing, that leads to the need to adapt and develop tools and technology
        to collectively support those individual Trustor operations.{' '}
      </Paragraph>

      <Paragraph>
        During the pandemic and with churches, schools and workplaces closed
        down, Trustors and their employees relied heavily on Reta’s
        administration and communication technology tools for choosing and using
        their benefits. Now more than ever before.
      </Paragraph>

      <Paragraph>
        To support this demand, Reta made a series of enhancements to our
        standard communications toolset and BAS administration services. We
        added embedded links in all emails to allow each member to access their
        personalized RBC portal with a single click. This resulted in 6,500
        unique RBC users responding to almost 100,000 personalized emails sent
        through the <i>Reta Mass Email Manager</i>.
      </Paragraph>

      <Paragraph>
        Trustors have access to the Reta email system that can be used
        throughout the year. And when choosing benefits is behind us with the
        end of open enrollment, the RBC automatically transforms itself to
        become a personalized guide to help members in using their benefits.
        24/7/365.
      </Paragraph>

      <Paragraph>
        A recent enhancement to the RBC is the new <i>My WorkPlace</i> page
        where Trustors can include HR and diocesan information including Paid
        Time Off programs, EAP programs, employee orientation videos, incentive
        savings plan info and more. Call it an extension of your workplace HR
        and a place to post information that will be easily accessible for your
        employees.
      </Paragraph>

      <Header>Pillar IV summary and closing</Header>

      <Video
        poster={imgMcGowan}
        float="right"
        style={{ paddingLeft: 25 }}
        videoUrl="https://assets.retatrust.org/2021/210421.1/McGowan+P4.mp4"
      >
        Kathryne McGowan — CEO, Reta Trust
      </Video>

      <Paragraph>
        It’s no secret that benefit programs are very complex and difficult to
        administer and communicate if you  are trying to manage them on your
        own. And that’s why Reta’s administration and communication systems and
        tools are custom tailored to each Trustor and for each of your employees
        so we can make it easier for all to concentrate on choosing and using
        their Reta benefits.{' '}
      </Paragraph>
    </Pillar>
  );
};

export default Pillar2;
