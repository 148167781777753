import React, { useContext } from 'react';
import Dropdown from '../../shared/Dropdown';
import { TrustorsContext } from '../../../contexts/trustors';

const TrustorDropDown = ({
  label = 'Trustor:',
  defaultValue,
  onChange = () => {},
}) => {
  const { trustors, byId } = useContext(TrustorsContext);

  const handleChange = (id, setSpinner) => {
    return onChange(byId[id], setSpinner);
  };

  return (
    <Dropdown
      label={label}
      options={[{ name: 'None', value: 'NONE' }].concat(
        trustors.map(t => ({ name: t.displayName, value: t.id })),
      )}
      onChange={handleChange}
      defaultValue={defaultValue}
    />
  );
};

export default TrustorDropDown;
