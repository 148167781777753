import React, { useState, useEffect } from 'react';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styled from 'styled-components/macro';
import draftToHtml from 'draftjs-to-html';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 600px;
  font-family: Roboto;

  & .DraftEditor-root {
    background-color: white;
    border: 1px solid #f1f1f1;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow-y: auto;

    & .public-DraftEditorPlaceholder-inner {
      padding: 10px;
      box-sizing: border-box;
    }

    & .DraftEditor-editorContainer {
      padding: 10px;
      box-sizing: border-box;
    }

    & .public-DraftEditor-content {
      box-sizing: border-box;
    }

    & .public-DraftStyleDefault-ltr {
      display: inline;
    }

    & .public-DraftStyleDefault-depth1 {
      padding-left: 40px;
    }

    & .public-DraftStyleDefault-depth2 {
      padding-left: 60px;
    }

    & .public-DraftStyleDefault-depth3 {
      padding-left: 80px;
    }

    & .public-DraftStyleDefault-depth4 {
      padding-left: 100px;
    }
  }
`;

const RichTextEditor = ({
  name = 'bio',
  style,
  defaultValue,
  readOnly = false,
  placeholder,
  onChange = () => {},
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  /**
   * Load the default value
   */
  useEffect(() => {
    if (defaultValue) {
      setEditorState(
        EditorState.createWithContent(convertFromRaw(defaultValue)),
      );
    }
  }, [defaultValue]);

  useEffect(() => {
    const toolbar = document.querySelector(
      `.${name}-wrapper > .rdw-editor-toolbar`,
    );
    const main = document.querySelector(`.${name}-wrapper .DraftEditor-root`);
    const parent = toolbar.parentNode.parentNode;

    const resize = () => {
      const mainHeight =
        parent.getBoundingClientRect().height -
        toolbar.getBoundingClientRect().height;

      main.style.height = mainHeight + 'px';
      main.style.boxSizing = 'border-box';
    };

    resize();

    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, [name]);

  /**
   * Call `onChange` when the editor state updates
   */
  useEffect(() => {
    const content = editorState.getCurrentContent();
    const raw = convertToRaw(content);
    const html = draftToHtml(raw);
    console.log({ html, raw });
    onChange(raw, html);
    // eslint-disable-next-line
  }, [editorState]);

  return (
    <Container style={style}>
      <Editor
        wrapperClassName={`${name}-wrapper`}
        editorClassName={`${name}-editor`}
        editorState={editorState}
        onEditorStateChange={setEditorState}
      />
    </Container>
  );
};

export default RichTextEditor;
