import React from 'react';
import { Text } from './Text';

export const Title = props => {
  return (
    <div
      style={{
        textAlign: 'center',
        fontSize: 16,
        ...props.style,
      }}
    >
      <Text
        style={{
          textTransform: 'uppercase',
          fontSize: 9,
          fontWeight: 700,
          letterSpacing: '0.25em',
          lineHeight: 1.43,
        }}
      >
        {props.children}
      </Text>
    </div>
  );
};
