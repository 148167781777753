import React, { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../../contexts/firebase';
import { MessagesContext } from '../../contexts/messages';
import { TrustorsContext } from '../../contexts/trustors';
import { PreferencesContext } from '../../contexts/preferences';
import styled from 'styled-components/macro';
import Spinner from '../shared/Spinner';
import _Header from '../shared/Header2';
import { BioStyle } from '../admin/users/Bio';
import { ProfileImg } from '../admin/users/UserUpdate';
import imgGlass from '../../assets/img/stainedGlass.png';
import imgLogo from '../../assets/img/logoBio.png';
import { useParams } from 'react-router-dom';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const NameRow = styled.div`
  font-family: 'Titillium Web';
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 42px;
  flex-wrap: wrap-reverse;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 34px;
  padding-top: 175px;
  box-sizing: border-box;
  max-width: 800px;
`;

const Header = styled(_Header)`
  font-weight: bold;
  margin-bottom: 0.4em;
`;

const Trustor = styled.div`
  width: 100%;
  font-size: 20px;
  font-style: italic;
  color: #951a2f;
  margin-bottom: 0.8em;
`;

const Title = styled.div`
  font-weight: bold;
`;

const Glass = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 36px;
  background-image: url(${imgGlass});
  background-repeat: none;
  background-size: cover;
`;

const Logo = styled.img`
  position: absolute;
  top: 50px;
  left: 34px;
`;

const ViewBio = () => {
  const { database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);
  const { byId } = useContext(TrustorsContext);
  const { setShowLandingLogo, setShowCarousel } = useContext(
    PreferencesContext,
  );

  const { bioId } = useParams();

  const [loading, setLoading] = useState(true);
  const [bio, setBio] = useState();
  const [pubInfo, setPubInfo] = useState();
  const [trustorName, setTrustorName] = useState();

  useEffect(() => {
    setShowCarousel(false);
    setShowLandingLogo(false);
  }, [setShowLandingLogo, setShowCarousel]);
  /**
   * Fetch the bio
   */
  useEffect(() => {
    if (!bioId || !database) {
      return;
    }

    const unsub = database.doc(`/bios/${bioId}`).onSnapshot(
      doc => {
        setBio({ ...doc.data(), id: doc.id, ref: doc.ref });
      },
      e => {
        console.error(e);
        addMessage('Unable to get bio.', 'error');
      },
    );

    return () => {
      unsub && typeof unsub === 'function' && unsub();
    };
  }, [addMessage, database, bioId]);

  /** Fetch the public user info */
  useEffect(() => {
    if (!bio || !bio.userPublicInfo) return;

    return bio.userPublicInfo.onSnapshot(
      query => {
        const data = query.data();
        setPubInfo(data);

        // get the trustor display name by the id
        if (data.trustor && data.trustor.id) {
          setTrustorName(byId[data.trustor.id].displayName);
        }
      },
      e => {
        console.error(e);
        addMessage('Unable to get user public info.', 'warn');
      },
    );
  }, [bio, addMessage, byId]);

  /** After fetching everything, turn off the loading message */
  useEffect(() => {
    if (!bio || !pubInfo) return;

    setLoading(false);
  }, [bio, pubInfo]);

  console.debug('Bio data:', bio);
  console.debug('User public data:', pubInfo);

  return (
    <Container>
      <Glass />
      <Logo src={imgLogo} />

      {loading && (
        <Row>
          <Spinner size={16} style={{ marginRight: 16 }} />
          Fetching biography...
        </Row>
      )}

      {!loading && (
        <Wrapper>
          <NameRow>
            <div>
              {pubInfo.displayName && <Header>{pubInfo.displayName}</Header>}
              {trustorName && (
                <Trustor>
                  {pubInfo.title && <span>{pubInfo.title},&nbsp;</span>}
                  <span>{trustorName}</span>
                </Trustor>
              )}
              {pubInfo.retaTitle && <Title>{pubInfo.retaTitle}</Title>}
            </div>
            {pubInfo.photo && <ProfileImg src={pubInfo.photo.url} alt="" />}
          </NameRow>

          {bio.html && (
            <BioStyle dangerouslySetInnerHTML={{ __html: bio.html }} />
          )}
        </Wrapper>
      )}
    </Container>
  );
};

export default ViewBio;
