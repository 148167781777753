import React, { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from '../../../contexts/firebase';
import { MessagesContext } from '../../../contexts/messages';
import styled from 'styled-components/macro';
import CategoryItem from './CategoryItem';
import Spinner from '../../shared/Spinner';
import HTML5Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

const Doc = styled.div`
  display: flex;
  align-items: center;
`;

const List = styled(Doc)`
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const CategoryList = ({ filter }) => {
  const { database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const [fetched, setFetched] = useState(false);
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);

  /**
   * Subscribe to the Doc list
   */
  useEffect(() => {
    setFetched(false);

    const unsub = database.collection('/categories').onSnapshot(
      docs => {
        const categories = docs.docs
          .map(d => ({
            ...d.data(),
            id: d.id,
            ref: d.ref,
          }))
          .sort((a, b) => a.order - b.order);

        setCategories(categories);
        setFetched(true);
      },
      e => {
        console.error(e);
        addMessage('Unable to fetch categories list', 'error');
        setFetched(true);
      },
    );

    return () => unsub && typeof unsub === 'function' && unsub();
  }, [addMessage, database]);

  /**
   * Filter the categories list based on the search field
   */
  useEffect(() => {
    if (!filter && !categories.length) {
      return;
    }

    if (!filter) {
      return setFilteredCategories(categories);
    }

    const regex = new RegExp(filter, 'i');

    const filtered = categories.filter(
      g => regex.test(g.displayName) || regex.test(g.email),
    );

    setFilteredCategories(filtered);
  }, [filter, categories]);

  return (
    <List>
      {!fetched && <Spinner />}

      {fetched && (!filteredCategories || !filteredCategories.length) && (
        <div>No results found.</div>
      )}

      {filteredCategories && (
        <DndProvider backend={HTML5Backend}>
          {filteredCategories.map((category, index) => (
            <CategoryItem
              key={index}
              category={category}
              order={index}
              categories={categories}
            />
          ))}
        </DndProvider>
      )}
    </List>
  );
};

CategoryList.defaultProps = {
  filter: '',
};

export default CategoryList;
