import styled from 'styled-components/macro';
import Color from 'color2';

const bgColor = '#88a4a4';
const bgColorInvert = '#e2ebe9';
const bgHover = new Color(bgColor).lighten(0.1);
const bgHoverInvert = new Color(bgColorInvert).lighten(0.1);
const color = 'white';
const colorInvert = '#495b5e';
const borderLight = '#e2ebe9';
const borderLightInvert = '#E2EBE9';
const borderDark = '#495b5e';
const borderDarkInvert = '#88A4A4';

const Button = styled.button`
  font-family: Titillium;
  font-weight: bold;
  font-size: 16px;
  min-width: 100px;
  min-height: 30px;
  line-height: 1.61em;
  padding: 0 1rem;
  color: ${props => (props.invert ? colorInvert : color)};
  background-color: ${props => (props.invert ? bgColorInvert : bgColor)};
  border-radius: 3px;
  border-top: 1px solid
    ${props => (props.invert ? borderLightInvert : borderLight)};
  border-left: 1px solid
    ${props => (props.invert ? borderLightInvert : borderLight)};
  border-bottom: 1px solid
    ${props => (props.invert ? borderDarkInvert : borderDark)};
  border-right: 1px solid
    ${props => (props.invert ? borderDarkInvert : borderDark)};
  box-sizing: border-box;
  outline: none;

  &:hover {
    background-color: ${props => (props.invert ? bgHoverInvert : bgHover)};
  }

  &:disabled {
    background-color: ${new Color('#88a4a4').alpha(0.5)};
    cursor: not-allowed;
  }
`;

export default Button;
