import { useEffect, useState } from 'react';
import { Header } from '../shared/Header';
import { Paragraph } from '../shared/newShared/Paragraph';
import styled from 'styled-components/macro';
import retaAdvantage from '../../assets/img/retaAdvantage.png';
import { Toolbar } from '@material-ui/core';
import { useHistory } from 'react-router';
import ImageMapper from 'react-image-mapper';

export const Content = styled.div`
  background-color: white;
  flex: 1 1 100px;
  align-items: stretch;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 0 34px;
`;

export const Strap = styled.div`
  font-size: 19px;
  font-style: italic;
  padding: 5px 0px 5px 0px;
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const RetaAdvantage = () => {
  const history = useHistory();
  const [width, setWidth] = useState(760);

  const goTo = to => {
    history.push({
      pathname: to,
      hash: 'main',
      search: history.location.search,
    });

    const el = document.querySelector('#main');

    if (el) {
      el.scrollIntoView();
    }
  };

  /**
   * Resie the image map based on the window size
   */
  useEffect(() => {
    const listener = () => {
      console.log(window.innerWidth, window.innerWidth < 1170);
      if (window.innerWidth < 1170 && window.innerWidth >= 500) {
        setWidth(500);
      } else if (window.innerWidth < 500) {
        setWidth(325);
      } else {
        setWidth(760);
      }
    };
    listener();

    window.addEventListener('resize', listener);

    return () => window.removeEventListener('resize', listener);
  }, []);

  useEffect(() => {
    console.log('W', width);
  }, [width]);

  return (
    <Content id="reta-advantage">
      <Toolbar />

      <Strap>Our Pillars of Commitment</Strap>

      <Header>The Reta Advantage</Header>

      <Paragraph style={{ marginBottom: '0px' }}>
        Our founding commitments are represented by the six Pillars that support
        the foundation of who we are, what we do and how we evolve to best serve
        our Trustors. These Pillars also separate Reta from the rest. Click on
        each pillar below to learn more.
      </Paragraph>

      <ImgContainer>
        <ImageMapper
          src={retaAdvantage}
          onClick={area => {
            goTo(`/pillars/${area._id}`);
          }}
          fillColor="rgba(255,255,255,0.25)"
          strokeColor="rgba(255,255,255,0)"
          lineWidth={0}
          width={width}
          imgWidth={760}
          map={{
            name: 'image_map',
            areas: [
              {
                _id: '1',
                shape: 'rect',
                coords: [158, 71, 306, 216],
              },
              {
                _id: '2',
                shape: 'rect',
                coords: [453, 72, 600, 217],
              },
              {
                _id: '3',
                shape: 'rect',
                coords: [598, 300, 745, 445],
              },
              {
                _id: '4',
                shape: 'rect',
                coords: [453, 537, 598, 682],
              },
              {
                _id: '5',
                shape: 'rect',
                coords: [162, 536, 308, 683],
              },
              {
                _id: '6',
                shape: 'rect',
                coords: [18, 298, 165, 445],
              },
            ],
          }}
        />
      </ImgContainer>
    </Content>
  );
};

export default RetaAdvantage;
