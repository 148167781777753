import styled from 'styled-components/macro';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 64px;

  & > .center {
    max-width: 950px;
    width: 100%;
    position: relative;
  }

  & .button {
    position: relative;
    width: 100%;
    top: 5px;
    text-align: center;
    // transform: scale(0.7);

    & img {
      width: 275px;
    }
  }
`;

const Pillar = ({ imgSrc, imgAlt, children }) => {
  return (
    <Wrapper>
      <div className="center">
        <div className="button">
          <img src={imgSrc} alt={imgAlt} />
        </div>

        {children}
      </div>
    </Wrapper>
  );
};

export default Pillar;
