import React from 'react';
import { TextNew } from './TextNew';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;
  line-height: 1.61;

  & a {
    color: #992131;
    font-weight: bold;
    text-decoration: underline;
  }

  & ul {
    & li {
      list-style: none;

      &::before {
        content: '•';
        color: #992131;
        font-size: 2rem;
        display: inline-block;
        width: 16px;
        position: relative;
        top: 5px;
      }
    }
  }
`;

export const Paragraph = ({ children, props }) => {
  return (
    <Wrapper {...props}>
      <TextNew>{children}</TextNew>
    </Wrapper>
  );
};

export default Paragraph;
