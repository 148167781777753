/**
 * This is a refactor or ./Header.jsx
 */
import { COLORS } from '../../constants/colors';
import styled from 'styled-components/macro';

const Header = styled.div`
  color: ${COLORS.RED};
  font-family: Titillium Web;
  font-size: 34px;
  letter-spacing: 0.03em;
  box-sizing: border-box;
`;

export default Header;
