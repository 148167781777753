import React, { useState, useEffect, useContext } from 'react';
import Header from '../../shared/Header2';
import Canvas from '../shared/Canvas';
import Row from '../shared/Row';
import TextInput from '../../shared/TextInput';
import TextArea from '../../shared/TextArea';
import Button from '../../shared/Button';
import { FirebaseContext } from '../../../contexts/firebase';
import Spinner from '../../shared/Spinner';
import { admin } from '../../../constants/routes';
import { MessagesContext } from '../../../contexts/messages';
import Label from '../../shared/Label';
import CheckboxItem from '../../shared/CheckboxItem';
import CheckRow from '../shared/CheckRow';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import queryString from 'query-string';
import CustomEditor from './CustomEditor';
import moment from 'moment';
import equal from 'fast-deep-equal';

const CenterRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const BulletinAdd = () => {
  const { database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const history = useHistory();
  const { search } = useLocation();

  const [title, setTitle] = useState();
  const [slug, setSlug] = useState();
  const [body, setBody] = useState();
  const [startDate, setStartDate] = useState(
    moment(moment().format('YYYY-MM-DD')).toDate(),
  );
  const [validStartDate, setValidStartDate] = useState(true);
  const [endDate, setEndDate] = useState();
  const [validEndDate, setValidEndDate] = useState(true);
  const [onlyTrustors, setOnlyTrustors] = useState([]);
  const [notTrustors, setNotTrustors] = useState([]);
  const [notes, setNotes] = useState();
  const [archived, setArchived] = useState(false);
  const [order, setOrder] = useState(0);

  const [valid, setValid] = useState(false);
  const [adding, setAdding] = useState(false);

  useEffect(() => {
    const qs = queryString.parse(window.location.search);

    if (qs.order) {
      setOrder(parseInt(qs.order));
      return;
    }

    (async () => {
      const bulletins = await database.collection('/bulletins').get();
      setOrder(bulletins.size + 1);
    })();
  }, [database, search]);

  /**
   * Validate the form
   */
  useEffect(() => {
    const isValid =
      title &&
      title.length > 0 &&
      body?.html &&
      body?.html?.length > 0 &&
      startDate &&
      validStartDate &&
      validEndDate;
    setValid(isValid);
  }, [title, body, startDate, validStartDate, validEndDate]);

  /**
   * Add the bulletin
   */
  const handlAdd = async () => {
    setAdding(true);

    // Assemble the payload
    const data = { title, body, startDate, order, archived };

    if (slug) {
      data.slug = slug;
    }

    if (endDate) {
      data.endDate = endDate;
    }

    if (onlyTrustors.length || notTrustors.length) {
      data.trustors = {};
      if (onlyTrustors.length) {
        data.trustors.only = onlyTrustors;
      }
      if (notTrustors.length) {
        data.trustors.not = notTrustors;
      }
    }

    if (notes) {
      data.notes = notes;
    }

    console.debug('Adding bulletin item', data);

    try {
      const docRef = await database.collection('/bulletins').add(data);
      setAdding(false);
      history.push(admin.BULLETINS + '/' + docRef.id);
      addMessage(`Successfully added bulletin "${title}"`);
    } catch (e) {
      console.error(e);
      addMessage(`Unable to add bulletin "${title}"`, 'error');
      setAdding(false);
    }
  };

  const handleChange = ({ raw, html }) => {
    console.log('RAW', raw);
    setBody({ raw: JSON.stringify(raw), html });
  };

  return (
    <Canvas style={{ alignItems: 'flex-start' }}>
      <Row style={{ marginBottom: 45 }}>
        <Header>Add Bulletin</Header>
      </Row>

      {/* Title */}
      <TextInput
        label="* Title:"
        placeholder="Required Field."
        onDebounced={setTitle}
      />

      {/* Slug */}
      <TextInput label="Slug:" placeholder="Optional." onDebounced={setSlug} />

      {/* Body */}
      <Label>* Body</Label>
      <CustomEditor style={{ height: 400 }} handleChange={handleChange} />

      <Row>
        {/* Start Date */}
        <TextInput
          style={{ width: 'auto' }}
          label="* Start Date"
          type="date"
          value={moment(startDate).format('YYYY-MM-DD')}
          placeholder="Required Field. Example: 1/19/21"
          validationMessage="The date entered is invalid.  Expecting MM/DD/YYYY."
          onValidate={setValidStartDate}
          onDebounced={v => {
            if (!v) {
              return;
            }
            const newDate = moment(v);
            if (!newDate.isSame(startDate)) {
              setStartDate(newDate.toDate());
            }
          }}
        />

        {/* End Date */}
        <TextInput
          style={{ width: 'auto' }}
          label="End Date"
          type="date"
          placeholder="Example: 2/28/21"
          validationMessage="The date entered is invalid.  Expecting MM/DD/YYYY."
          onValidate={setValidEndDate}
          onDebounced={v => {
            if (!v) {
              return;
            }
            const newDate = moment(v);
            if (!newDate.isSame(endDate)) {
              setEndDate(newDate.toDate());
            }
          }}
        />
      </Row>

      {/* Trustors */}
      <Row>
        <TextArea
          label="Only for these trustors:"
          style={{ width: '45%', marginBottom: 42 }}
          onDebounced={v => {
            if (!v) {
              return;
            }

            const val = v.split('\n');

            if (equal(onlyTrustors, val)) {
              return;
            }

            setOnlyTrustors(val);
          }}
        />

        <TextArea
          label="Not for these trustors:"
          style={{ width: '45%', marginBottom: 42 }}
          onDebounced={v => {
            if (!v) {
              return;
            }

            const val = v.split('\n');

            if (equal(notTrustors, val)) {
              return;
            }

            setNotTrustors(val);
          }}
        />
      </Row>

      {/* Notes */}
      <TextArea
        label="Notes:"
        style={{ marginBottom: 42 }}
        onDebounced={setNotes}
      />

      {/* Options */}
      <Row style={{ marginBottom: 16 }}>
        <Label>Options:</Label>
      </Row>
      <CheckRow>
        <CheckboxItem
          label="Archived"
          checked={archived}
          onChange={checked => setArchived(checked)}
        />
      </CheckRow>

      {!adding && (
        <Row>
          <Button invert onClick={() => history.push(admin.BULLETINS)}>
            Cancel
          </Button>
          <Button disabled={!valid} onClick={handlAdd}>
            Add Bulletin
          </Button>
        </Row>
      )}

      {adding && (
        <CenterRow>
          <Spinner />
        </CenterRow>
      )}
    </Canvas>
  );
};

export default BulletinAdd;
