import React, { useState, useEffect, useContext } from 'react';
import Header from '../../shared/Header2';
import Canvas from '../shared/Canvas';
import Row from '../shared/Row';
import TextInput from '../../shared/TextInput';
import TextArea from '../../shared/TextArea';
import Button from '../../shared/Button';
import { FirebaseContext } from '../../../contexts/firebase';
import Spinner from '../../shared/Spinner';
import { admin } from '../../../constants/routes';
import { MessagesContext } from '../../../contexts/messages';
import Label from '../../shared/Label';
import CheckboxItem from '../../shared/CheckboxItem';
import CheckRow from '../shared/CheckRow';
import SelectFile from '../../shared/SelectFile';
import { Group } from '../resources/ResourceAdd';
import { useHistory } from 'react-router-dom';
import TrustorDropDown from '../users/TrustorList';
import styled from 'styled-components/macro';

const WideRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const CenterRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Percentage = styled.div`
  color: #951a2f;
  font-family: Titillium Web;
  letter-spacing: 0.03em;
`;

const CarouselAdd = () => {
  const { firebase, database, storage } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const history = useHistory();

  const [name, setName] = useState();
  const [title, setTitle] = useState();
  const [trustor, setTrustor] = useState();
  const [videoFile, setVideoFile] = useState();
  const [videoUrl, setVideoUrl] = useState();
  const [videoPercentage, setVideoPercentage] = useState(0);
  const [thumbnail, setThumbnail] = useState();
  const [thumbnailPercentage, setThumbnailPercentage] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [order, setOrder] = useState();

  const [valid, setValid] = useState(false);
  const [adding, setAdding] = useState(false);
  const [notes, setNotes] = useState();
  const [validUrl, setValidUrl] = useState(false);

  useEffect(() => {
    (async () => {
      const carousel = await database.collection('/carousel').get();
      setOrder(carousel.size + 1);
    })();
  }, [database]);
  /**
   * Validate the form
   */
  useEffect(() => {
    const isValid =
      name &&
      name.length > 0 &&
      title &&
      title.length > 0 &&
      (videoFile !== undefined ||
        (videoUrl && videoUrl.length > 0 && validUrl)) &&
      thumbnail !== undefined &&
      trustor !== undefined;
    setValid(isValid);
  }, [name, title, videoFile, videoUrl, thumbnail, trustor, validUrl]);

  /**
   * Add the category
   */
  const handlAdd = async () => {
    setAdding(true);

    const data = { active: isActive, name, title, trustor, order };

    if (notes) {
      data.notes = notes;
    }

    if (trustor.ref) {
      data.trustor = trustor.ref;
    }

    console.debug('Adding carousel item', data);

    try {
      const docRef = await database.collection('/carousel').add(data);
      let url = videoUrl;
      if (videoFile) {
        url = await uploadVideo(docRef.id, videoFile);
      }
      const thumbnailUrl = await uploadThumbnail(docRef.id, thumbnail);

      // update db with video files
      await docRef.update({ videoUrl: url, thumbnailUrl });

      setAdding(false);
      history.push(admin.CAROUSELS);
      addMessage(`Successfully added carousel item "${name}"`);
    } catch (e) {
      console.error(e);
      addMessage(`Unable to add carousel "${name}"`, 'error');
      setAdding(false);
    }
  };

  /**
   * Upload the video thumbnail
   *
   * @param {*} carouselId
   * @param {*} file
   */
  const uploadThumbnail = async (carouselId, file) => {
    const gsPath = `/carousel/${carouselId}/thumbnail.png`;
    await new Promise((resolve, reject) => {
      const uploadTask = storage.child(gsPath).put(file, {
        customMetadata: {
          resize: '269>',
          firestorePath: JSON.stringify([`/carousel/${carouselId}`]),
          firestoreField: 'thumbnailUrl',
        },
      });

      const unsub = uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        // next callback
        snapshot => {
          const percent =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setThumbnailPercentage(percent);
        },
        // error callback
        error => {
          console.error('Upload Error:', error);
          addMessage('There was an error uploading the file.', 'error');
          reject();
        },
        // complete
        () => {
          unsub();
          resolve();
        },
      );
    });

    const origUrl = await storage.child(gsPath).getDownloadURL();
    return origUrl;
  };

  const uploadVideo = async (carouselId, file) => {
    const gsPath = `/carousel/${carouselId}/${file.name}`;

    await new Promise((resolve, reject) => {
      const uploadTask = storage.child(gsPath).put(file, {
        customMetadata: {
          firestorePath: `/carousel/${carouselId}`,
          firestoreField: 'videoUrl',
        },
      });

      const unsub = uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        // next callback
        snapshot => {
          const percent =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setVideoPercentage(percent);
        },
        // error callback
        error => {
          console.error('Upload Error:', error);
          addMessage('There was an error uploading the file.', 'error');
          reject();
        },
        // complete
        () => {
          unsub();
          resolve();
        },
      );
    });

    const origUrl = await storage.child(gsPath).getDownloadURL();
    return origUrl;
  };

  return (
    <Canvas style={{ alignItems: 'flex-start' }}>
      <Row style={{ marginBottom: 45 }}>
        <Header>Add Video Carousel Item</Header>
      </Row>

      {/* Name */}
      <TextInput
        label="* Name:"
        placeholder="Required Field. Example: John Smith"
        onDebounced={setName}
      />

      {/* Title */}
      <TextInput
        label="* Title:"
        placeholder="Required Field. Example: Director of Something"
        onDebounced={setTitle}
      />

      {/* Trustor */}
      <Group>
        <Label className="label">* Trustor</Label>

        <TrustorDropDown onChange={trustor => setTrustor(trustor)} />

        <div className="or">
          <div>or</div>
        </div>

        <TextInput
          label="Custom Info:"
          placeholder="Custom Trustor Information"
          onDebounced={setTrustor}
        />
      </Group>

      {/* Thumbnail */}
      <WideRow>
        <SelectFile label="* Thumbnail" onSelectedFile={setThumbnail} />
        {thumbnailPercentage > 0 && (
          <Percentage>{Math.round(thumbnailPercentage)}%</Percentage>
        )}
      </WideRow>

      {/* Video */}
      <Group>
        <Label className="label">* Video File or URL</Label>

        <WideRow>
          <SelectFile label="Video to Upload:" onSelectedFile={setVideoFile} />
          {videoPercentage > 0 && (
            <Percentage>{Math.round(videoPercentage)}%</Percentage>
          )}
        </WideRow>

        <div className="or">
          <div>or</div>
        </div>

        <TextInput
          label="URL:"
          type="url"
          validationMessage="A valid URL is required.  Exampe: https://example.com"
          placeholder="Exampe: https://example.com"
          onValidate={setValidUrl}
          onDebounced={setVideoUrl}
        />
      </Group>

      <TextArea
        label="Notes:"
        style={{ marginBottom: 42 }}
        onDebounced={setNotes}
      />

      {/* Options */}
      <Row style={{ marginBottom: 16 }}>
        <Label>Options:</Label>
      </Row>
      <CheckRow>
        <CheckboxItem
          label="Active"
          checked={isActive}
          onChange={checked => setIsActive(checked)}
        />
      </CheckRow>

      {!adding && (
        <Row>
          <Button invert onClick={() => history.push(admin.CATEGORIES)}>
            Cancel
          </Button>
          <Button disabled={!valid} onClick={handlAdd}>
            Add Carousel
          </Button>
        </Row>
      )}

      {adding && (
        <CenterRow>
          <Spinner />
        </CenterRow>
      )}
    </Canvas>
  );
};

export default CarouselAdd;
