import React, { useState, useContext, useReducer, useEffect } from 'react';
import Header from '../../shared/Header2';
import Editor from '../../shared/Editor';
import styled from 'styled-components/macro';
import Button from '../../shared/Button';
import Spinner from '../../shared/Spinner';
import CheckboxItem from '../../shared/CheckboxItem';
import { FirebaseContext } from '../../../contexts/firebase';
import { MessagesContext } from '../../../contexts/messages';

const Container = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 24px;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 16px;
`;

export const BioStyle = styled.div`
  & h1 {
    color: #495b5e;
    font-family: Titillium Web;
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    box-sizing: border-box;
    line-height: 1.6;
    margin: 32px 0;

    &:first-child {
      margin-top: 0;
    }
  }

  & h2 {
    font-size: 18px;
    line-height: 1.6;
    color: #951a2f;
    font-family: Titillium Web;
    box-sizing: border-box;
    margin-top: 22px;
  }

  & h3 {
    font-family: Roboto;
    font-weight: bold;
    font-size: 17px;
    line-height: 1.6;
    color: #495b5e;
    margin-bottom: 6px;
    box-sizing: border-box;
  }

  & p,
  .unstyled-body {
    font-size: 16px;
    line-height: 1.6;
    margin: 0.8em 0;
    box-sizing: border-box;
  }

  & ul {
    list-style: none;
    list-style-position: outside;
    margin-bottom: 0.8em;

    & li {
      padding-left: 20px;
      text-indent: -20px;
      margin-bottom: 12px;
      line-height: 1.6em;

      &::before {
        content: '+';
        font-weight: bold;
        color: #951a2f;
        padding: 0 6px;
        line-height: 1.6em;
      }
    }

    & .public-DraftStyleDefault-depth1::before,
    & .public-DraftStyleDefault-depth2::before,
    & .public-DraftStyleDefault-depth3::before,
    & .public-DraftStyleDefault-depth4::before {
      content: '-';
    }

    & ul {
      padding-left: 20px;
      margin-bottom: 0;

      & li {
        &::before {
          content: '-';
        }
      }
    }
  }

  & ol {
    list-style: none;
    counter-reset: li;
    margin-bottom: 0.8em;

    & li::before {
      content: counter(li) '.';
      color: #951a2f;
      display: inline-block;
      padding: 0 6px;
      line-height: 1.6em;
    }

    & li {
      counter-increment: li;
    }
  }
`;

const Bio = ({ userId, photo }) => {
  const { database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const [dbBio, setDbBio] = useState({});
  const [bio, dispatchBio] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'LOAD_BIO':
          return {
            ...state,
            raw: action.payload.raw ? JSON.parse(action.payload.raw) : null,
            cached: action.payload.raw ? JSON.parse(action.payload.raw) : null,
            html: action.payload.html,
            modified: false,
          };

        case 'UPDATE_BIO':
          return {
            ...state,
            raw: action.payload.raw,
            html: action.payload.html,
            modified: true,
          };

        case 'SAVING':
          return {
            ...state,
            saving: true,
          };

        case 'SAVED':
          return {
            ...state,
            cached: { ...state.raw },
            modified: false,
            saving: false,
          };

        default:
          return { ...state };
      }
    },
    // initial state
    {
      raw: null,
      cached: null,
      html: null,
      modified: false,
      saving: false,
    },
  );

  /**
   * Subscribe to the bio from the database
   */
  useEffect(() => {
    const unsub = database.doc(`/bios/${userId}`).onSnapshot(
      doc => {
        if (doc.exists) {
          const data = doc.data();
          setDbBio(data);
        } else {
          console.warn(
            `Unable to locate bio for user "${userId}".  It may not have been created yet.`,
          );
        }
      },
      e => {
        console.warn('Unable to subscribe to bio:', userId);
      },
    );

    return () => unsub && typeof unsub === 'function' && unsub();
  }, [database, userId]);

  /**
   * Load the bio to the editor
   */
  useEffect(() => {
    if (!Object.keys(dbBio).length) {
      return;
    }

    dispatchBio({ type: 'LOAD_BIO', payload: dbBio });
  }, [dbBio]);

  /**
   * Update bio store when editor changes
   */
  const handleUpdateBio = async (raw, html) => {
    dispatchBio({ type: 'UPDATE_BIO', payload: { raw, html } });
  };

  /**
   * Save the bio to the database
   */
  const saveBio = async () => {
    if (bio.raw) {
      dispatchBio({ type: 'SAVING' });

      const data = {
        ...dbBio,
        userPublicInfo: database.doc(`/users/${userId}/public/info`),
        raw: JSON.stringify(bio.raw),
        html: bio.html,
      };

      if (photo) {
        data.photo = photo;
      }

      console.debug('Saving Bio:', data);
      try {
        await database.doc(`/bios/${userId}`).set(data);
        addMessage('Bio saved successfully.');
      } catch (e) {
        console.error(e);
        addMessage('Unable to save bio', 'error');
      }

      dispatchBio({ type: 'SAVED' });
    }
  };

  /**
   * Reset local editor changes
   */
  const cancelBio = async () => {
    dispatchBio({ type: 'LOAD_BIO', payload: dbBio });
  };

  const updateBio = async (path, value, setSpinner) => {
    setSpinner(true);

    try {
      const ref = database.doc(`/bios/${userId}`);
      await ref.set({ ...dbBio, [path]: value });
      await database.doc(`/users/${userId}`).update({ bio: ref });
      addMessage(`Successfully updated bio.`);
    } catch (e) {
      console.error(e);
      addMessage(`Unable to update bio.`, 'error');
    }

    setSpinner(false);
  };

  return (
    <Container>
      <Header style={{ marginBottom: 24 }}>Biography</Header>

      <CheckboxItem
        label="Show in Resources"
        checked={dbBio.showBio}
        onChange={(checked, setSpinner) =>
          updateBio('showBio', checked, setSpinner)
        }
      />

      <BioStyle>
        <Editor
          placeholder="Enter bio information..."
          defaultValue={bio.cached}
          onChange={handleUpdateBio}
        />
      </BioStyle>

      <Row style={{ justifyContent: 'space-between', marginTop: 16 }}>
        <Button invert onClick={cancelBio}>
          Cancel
        </Button>
        {!bio.saving && (
          <Button onClick={saveBio} disabled={!bio.modified}>
            Save Bio
          </Button>
        )}
        {bio.saving && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              minWidth: 100,
            }}
          >
            <Spinner size={15} />
          </div>
        )}
      </Row>
    </Container>
  );
};

export default Bio;
