import imgBack from '../../assets/img/backButton.png';
import styled from 'styled-components/macro';
import { TABLET_WIDTH } from '../../constants/dimensions';
import { useHistory } from 'react-router';

const BackWrapper = styled.div`
  position: absolute;
  right: 6px;
  top: 6px;
  cursor: pointer;
  z-index: 1000;

  @media (max-width: ${TABLET_WIDTH}px) {
    display: none;
  }
`;

const Back = ({ to, hash = '' }) => {
  const history = useHistory();

  console.log({ to, hash });
  return (
    <BackWrapper
      className="back-btn"
      onClick={() => (to ? history.push(to) : history.goBack())}
    >
      <img style={{ width: 60, height: 'auto' }} src={imgBack} alt="back" />
    </BackWrapper>
  );
};

export default Back;
