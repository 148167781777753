import React, { useState, useEffect } from 'react';
import imgCheckbox from '../../assets/img/checkbox.svg';
import imgChecked from '../../assets/img/checkbox-checked.svg';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  width: auto;
  height: auto;
  cursor: pointer;
`;

const Checkbox = ({ checked, onChange, ...props }) => {
  const [check, setCheck] = useState(false);

  useEffect(() => {
    setCheck(checked);
  }, [checked]);

  return (
    <Wrapper
      {...props}
      onClick={() => {
        const nextValue = !check;
        onChange(nextValue);
        setCheck(nextValue);
      }}
    >
      <img src={check ? imgChecked : imgCheckbox} alt="X" />
    </Wrapper>
  );
};

Checkbox.defaultProps = {
  onChange: val => console.debug('Change:', val),
  defaultValue: false,
  checked: false,
};

export default Checkbox;
