import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import 'github-markdown-css/github-markdown.css';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  width: 100vw;
  justify-content: center;
  background-color: cadetblue;

  & > div {
    width: 100%;
    max-width: 980px;
    background-color: white;
    padding: 64px;
    box-sizing: border-box;
    box-shadow: 0px 12px 20px 7px #0000004a;

    & h2 {
      border-bottom: none;
      margin-top: 48px;
    }

    & ul {
      list-style: disc;
    }

    & img {
      box-shadow: 0px 7px 15px 6px #00000038;
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }
`;

const Documents = () => {
  const { docName } = useParams();
  const [markdown, setMarkdown] = useState();

  useEffect(() => {
    (async () => {
      const res = await fetch(`/assets/docs/${docName}.md`);
      setMarkdown(await res.text());
    })();
  }, [docName]);

  return (
    <Wrapper>
      <div className="markdown-body">
        <ReactMarkdown
          plugins={[gfm]}
          children={markdown}
          transformImageUri={uri => uri.replace(/^\./, '/assets/docs')}
        />
      </div>
    </Wrapper>
  );
};

export default Documents;
