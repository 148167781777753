import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components/macro';
import TrustorItem from './TrustorItem';
import Spinner from '../../shared/Spinner';
import { TrustorsContext } from '../../../contexts/trustors';

const Group = styled.div`
  display: flex;
  align-items: center;
`;

const List = styled(Group)`
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const TrustorList = ({ filter }) => {
  const { trustors, fetching } = useContext(TrustorsContext);

  const [filteredTrustors, setFilteredTrustors] = useState([]);

  /**
   * Filter the trustors list based on the search field
   */
  useEffect(() => {
    if (!filter && !trustors.length) {
      return;
    }

    if (!filter) {
      return setFilteredTrustors(trustors);
    }

    const regex = new RegExp(filter, 'i');

    const filtered = trustors.filter(
      g => regex.test(g.displayName) || regex.test(g.email),
    );

    setFilteredTrustors(filtered);
  }, [filter, trustors]);

  return (
    <List>
      {fetching && <Spinner />}

      {!fetching && (!filteredTrustors || !filteredTrustors.length) && (
        <div>No results found.</div>
      )}

      {!fetching &&
        filteredTrustors &&
        filteredTrustors.map((trustor, index) => (
          <TrustorItem key={index} trustor={trustor} />
        ))}
    </List>
  );
};

TrustorList.defaultProps = {
  filter: '',
};

export default TrustorList;
