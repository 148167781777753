import React, { useContext, useState, useEffect } from 'react';
import Canvas from '../shared/Canvas';
import Header from '../../shared/Header2';
import styled from 'styled-components/macro';
import TextInput from '../../shared/TextInput';
import { UsersContext } from '../../../contexts/users';
import Spinner from '../../shared/Spinner';
import Button from '../../shared/Button';
import { admin } from '../../../constants/routes';
import { FirebaseContext } from '../../../contexts/firebase';
import { PreferencesContext } from '../../../contexts/preferences';
import { MessagesContext } from '../../../contexts/messages';

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  margin-bottom: 42px;

  &.header {
    margin-bottom: 16px;
  }

  &.column {
    flex-direction: column;
  }

  &.center {
    justify-content: center;
    align-items: center;
  }

  &.field {
    justify-content: center;
    margin-bottom: 16px;
  }

  &.buttons {
    justify-content: space-between;
  }

  & > div {
    line-height: 1.6;
    margin-bottom: 24px;
  }
`;

const UserDelete = ({ match, history }) => {
  const { userId } = match.params;

  const fbase = useContext(FirebaseContext);
  const { users, byId, fetching, refreshUsers } = useContext(UsersContext);
  const { apiHost } = useContext(PreferencesContext);
  const { addMessage } = useContext(MessagesContext);

  const [user, setUser] = useState();
  const [deleting, setDeleting] = useState(false);
  const [valid, setValid] = useState(false);

  /**
   * Look up the user in the user context by the id
   */
  useEffect(() => {
    if (!users || !users.length || !Object.keys(byId).length) {
      return;
    }

    if (!byId[userId]) {
      addMessage('Unable to locate user.', 'warn');
      return;
    }

    setUser(byId[userId]);
  }, [addMessage, byId, history, userId, users]);

  /**
   * Handle events
   */
  const handleValidation = text => {
    if (user.email) {
      return setValid(text === user.email);
    }

    if (user.phoneNumber) {
      return setValid(text === user.phoneNumber);
    }
  };

  const handleDelete = async () => {
    setDeleting(true);

    const idToken = await fbase.user.getIdToken();
    let res;
    try {
      res = await fetch(`${apiHost}/admin/users/${userId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `IdToken ${idToken}`,
        },
      });
    } catch (e) {
      console.error(e);
      addMessage('Unable to connect to the API.', 'error');
      setDeleting(false);
      return;
    }

    if (res.status >= 400) {
      const text = await res.text();
      console.error(text);
      addMessage('Unable to delete user.', 'error');
      setDeleting(false);
      return;
      // return history.push(`${admin.USERS}/${userId}`);
    }

    refreshUsers();

    setDeleting(false);
    history.replace(admin.USERS);
  };

  return (
    <Canvas>
      <Row className="header">
        <Header>Delete User</Header>
      </Row>

      {fetching && (
        <Row className="center">
          <Spinner size={15} />
          <div style={{ marginLeft: 16 }}>Fetching user information...</div>
        </Row>
      )}

      {!fetching && user && (
        <React.Fragment>
          <Row className="column">
            <div>
              This will remove all references to "
              <b>{user.displayName || user.email || user.phoneNumber}</b>". It
              is often better to <b>deactivate</b> the user, instead of removing
              them.
            </div>
            {user.email && (
              <div>
                To continue deleting the user, type in the email "
                <b>{user.email}</b>" below:
              </div>
            )}
            {user.phoneNumber && (
              <div>
                To continue deleting the user, type in the phone number "
                <b>{user.phoneNumber}</b>" below:
              </div>
            )}
          </Row>

          <Row className="field">
            <div style={{ width: '75%' }}>
              {user.email && (
                <TextInput
                  placeholder="Enter the user email to delete"
                  onDebounced={handleValidation}
                />
              )}
              {user.phoneNumber && (
                <TextInput
                  placeholder="Enter the user phone number to delete"
                  onDebounced={handleValidation}
                />
              )}
            </div>
          </Row>

          {!deleting && (
            <Row className="buttons">
              <Button invert onClick={() => history.goBack()}>
                Cancel
              </Button>
              <Button disabled={!valid} onClick={handleDelete}>
                Delete User
              </Button>
            </Row>
          )}

          {deleting && <Spinner />}
        </React.Fragment>
      )}
    </Canvas>
  );
};

export default UserDelete;
