import React, { useContext } from 'react';
import imgCross from '../../assets/img/cross.svg';
import { Flexbox } from '../shared/Flexbox';
import { COLORS } from '../../constants/colors';
import { Header } from '../shared/Header';
import { TrustorsContext } from '../../contexts/trustors';
import styled from 'styled-components/macro';

const Columns = styled.div`
  column-count: 2;
  column-gap: 24px;
`;

const Trustor = ({ trustor }) => {
  return (
    <Flexbox
      style={{
        alignItems: 'center',
        marginBottom: 16,
      }}
    >
      <img
        alt="Cross"
        src={imgCross}
        style={{
          width: 'auto',
          height: 'auto',
        }}
      />

      <div
        style={{
          color: COLORS.BLUE,
          fontSize: 18,
          paddingLeft: 8,
          boxSizing: 'border-box',
        }}
      >
        {trustor.displayName}, {trustor.state}
      </div>
    </Flexbox>
  );
};

export const TrustorList = () => {
  const { trustors } = useContext(TrustorsContext);

  return (
    <div
      style={{
        width: '100%',
        boxSizing: 'border-box',
      }}
    >
      <Header
        style={{
          marginBottom: 38,
        }}
      >
        Member Organizations
      </Header>

      <Columns>
        {trustors.map((trustor, i) => (
          <Trustor key={i} trustor={trustor} />
        ))}
      </Columns>
    </div>
  );
};

export default TrustorList;
