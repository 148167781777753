import React, { useEffect, useContext, useState } from 'react';
import { FirebaseContext } from '../../contexts/firebase';
import { MessagesContext } from '../../contexts/messages';
import { Container, Category, CategoryName, Docs } from './CategoryItem';
import { Container as Resource, StyledLink } from './ResourceItem';
import imgCross from '../../assets/img/cross.svg';
import Promise from 'bluebird';

const BiosList = () => {
  const { database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);
  const [bios, setBios] = useState([]);

  /**
   * Get a list of users with bio's
   */
  useEffect(() => {
    const unsub = database
      .collection('bios')
      .where('showBio', '==', true)
      .onSnapshot(
        async query => {
          if (query.empty) {
            setBios([]);
            return;
          }

          // enrich with the user data
          const enriched = await Promise.map(query.docs, async doc => {
            const bioData = doc.data();
            let pubInfo = {};
            if (bioData.userPublicInfo) {
              const doc = await bioData.userPublicInfo.get();
              pubInfo = doc.data();
            }

            return {
              ...bioData,
              ...pubInfo,
              id: doc.id,
              ref: doc.ref,
            };
          });

          // sort based on `displayName`
          const sorted = enriched.sort((a, b) => {
            if (!a.displayName || !b.displayName) {
              return 0;
            }

            const A = a.displayName.split(' ').pop().toUpperCase();
            const B = b.displayName.split(' ').pop().toUpperCase();

            return A < B ? -1 : A > B ? 1 : 0;
          });

          setBios(sorted);
        },
        err => {
          console.error(err);
          addMessage('Unable to get categories for resources list.', 'error');
        },
      );

    return () => {
      unsub && typeof unsub === 'function' && unsub();
    };
  }, [addMessage, database]);

  if (!bios.length) {
    return <div></div>;
  }

  return (
    <Container>
      <Category>
        <div>
          <img src={imgCross} alt="+" />
        </div>
        <CategoryName>Leadership and Trustees</CategoryName>
      </Category>

      <Docs>
        {bios.map((bio, idx) => (
          <Resource key={idx}>
            <StyledLink to={`/member/view/bios/${bio.id}`}>
              {bio.displayName}
            </StyledLink>
          </Resource>
        ))}
      </Docs>
    </Container>
  );
};

export default BiosList;
