import React from 'react';
import imgPlayBtn from '../../assets/img/playButton.svg';
import { Flexbox } from '../shared/Flexbox';
import { Text } from './Text';

export const PlayButton = ({ className, onPlay, onClickPlay, ...props }) => {
  return (
    <Flexbox
      style={{
        width: '100%',
        justifyContent: 'center',
        marginTop: 10,
        cursor: 'pointer',
      }}
      className={className}
    >
      <Flexbox
        style={{
          alignItems: 'center',
        }}
        onClick={() => onClickPlay(props.url)}
      >
        <img alt="Play" src={imgPlayBtn} />
        <Text
          style={{
            paddingLeft: 12,
          }}
        >
          Play Video
        </Text>
      </Flexbox>
    </Flexbox>
  );
};

export default PlayButton;
