import Promise from 'bluebird';
import React, { useContext, useState, useEffect } from 'react';
import Canvas from '../shared/Canvas';
import Header from '../../shared/Header2';
import styled from 'styled-components/macro';
import TextInput from '../../shared/TextInput';
import Spinner from '../../shared/Spinner';
import Button from '../../shared/Button';
import { admin } from '../../../constants/routes';
import { FirebaseContext } from '../../../contexts/firebase';
import { MessagesContext } from '../../../contexts/messages';

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  margin-bottom: 42px;

  &.header {
    margin-bottom: 16px;
  }

  &.column {
    flex-direction: column;
  }

  &.center {
    justify-content: center;
    align-items: center;
  }

  &.field {
    justify-content: center;
    margin-bottom: 16px;
  }

  &.buttons {
    justify-content: space-between;
  }

  & > div {
    line-height: 1.6;
    margin-bottom: 24px;
  }
`;

const TrustorDelete = ({ match, history }) => {
  const { trustorId } = match.params;

  const { database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const [trustor, setTrustor] = useState();
  const [deleting, setDeleting] = useState(false);
  const [valid, setValid] = useState(false);

  /**
   * Look up the trustor
   */
  useEffect(() => {
    (async () => {
      let doc;
      try {
        doc = await database.doc(`/trustors/${trustorId}`).get();
      } catch (e) {
        console.error(e);
        addMessage('There was an error retrieving the trustor.', 'error');
        return;
      }

      if (!doc.exists) {
        addMessage('Unable to locate trustor.', 'warn');
        return;
      }

      setTrustor(doc.data());
    })();
  }, [addMessage, database, trustorId]);

  /**
   * Handle events
   */
  const handleValidation = text => {
    setValid(text === trustor.displayName);
  };

  const handleDelete = async () => {
    setDeleting(true);
    const trustorRef = database.doc(`/trustors/${trustorId}`);

    // Delete the trustor from all categories
    const categories = await database
      .collection('categories')
      .where('trustors', 'array-contains', trustorRef)
      .get();

    await Promise.map(
      categories.docs,
      async category => {
        const data = category.data();
        const trustors = data.trustors.filter(g => {
          return g.id !== trustorId;
        });
        await category.ref.update({ trustors });
        console.debug('Removed trustor from category:', data.displayName);
      },
      { concurrency: 3 },
    );

    // Delete the trustor from all users
    const users = await database
      .collection('users')
      .where('trustors', 'array-contains', trustorRef)
      .get();

    await Promise.map(
      users.docs,
      async user => {
        const data = user.data();
        const trustors = data.trustors.filter(g => {
          return g.id !== trustorId;
        });
        await user.ref.update({ trustors });
        console.debug('Removed trustor from user:', data.displayName);
      },
      { concurrency: 3 },
    );

    // Delete the trustor
    try {
      await database.doc(`/trustors/${trustorId}`).delete();
      addMessage(`Successfully deleted "${trustor.displayName}".`);
      setDeleting(false);
    } catch (e) {
      console.error(e);
      addMessage(`Unable to delete the "${trustor.displayName}".`, 'error');
      return;
    }

    history.replace(admin.TRUSTORS);
  };

  return (
    <Canvas>
      <Row className="header">
        <Header>Delete Trustor</Header>
      </Row>

      {trustor && (
        <React.Fragment>
          <Row className="column">
            <div>
              To continue deleting the trustor, type in the trustor name "
              <b>{trustor.displayName}</b>" below:
            </div>
          </Row>

          <Row className="field">
            <div style={{ width: '75%' }}>
              <TextInput
                placeholder="Enter the trustor name to delete"
                onDebounced={handleValidation}
              />
            </div>
          </Row>

          {!deleting && (
            <Row className="buttons">
              <Button invert onClick={() => history.goBack()}>
                Cancel
              </Button>
              <Button disabled={!valid} onClick={handleDelete}>
                Delete Trustor
              </Button>
            </Row>
          )}

          {deleting && <Spinner />}
        </React.Fragment>
      )}
    </Canvas>
  );
};

export default TrustorDelete;
