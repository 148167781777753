import styled from 'styled-components/macro';
import { Paragraph } from '../shared/newShared/Paragraph';
import { Header } from '../shared/Header';
import welcomeAbout from '../../assets/img/welcomeAbout.png';
import { Toolbar } from '@material-ui/core';
import pdfAbout from './Reta_org_OUR_STORY_Sept2022_WEBSITE.pdf';

const Content = styled.div`
  background-color: white;
  flex: 1 1 100px;
  align-items: stretch;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 34px;
  padding-bottom: 0;
  padding-top: 6px;

  @media (max-width: 600px) {
    display: block;
    overflow-y: inherit;
  }
`;

const Img = styled.img`
  width: auto;
  height: auto;
`;

const WelcomeAboutImages = styled.div`
  display: grid;
  place-items: center;
`;

const MainScrolling = () => {
  return (
    <>
      <Content id="welcome">
        <Toolbar />

        <Header>Welcome to Reta</Header>

        <Paragraph>
          Since Reta’s humble beginnings five decades ago, the Roman Catholic
          Directives stand as our core guiding principles in everything we do.
          In how we design healthcare benefits. In auditing our claims for ERD
          compliance. With Reta Trust investments that are aligned with Catholic
          principles. In how we operate our business day to day.
        </Paragraph>

        <Paragraph>
          And now we are pleased to announce that we have developed a new
          healthcare program specially designed for smaller Catholic
          organizations with less than 100 employees that up until now had
          limited options for comprehensive and affordable Catholic healthcare.
        </Paragraph>

        <WelcomeAboutImages>
          <a href={pdfAbout} target="_blank" rel="noreferrer">
            <Img alt="Click To View" src={welcomeAbout} />
          </a>
        </WelcomeAboutImages>
      </Content>
    </>
  );
};

export default MainScrolling;
