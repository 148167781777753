import React, { Fragment } from 'react';
import styled from 'styled-components/macro';
import { ThemeProvider } from 'styled-components/macro';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import defaultTheme from './themes';
import GlobalStyle from './GlobalStyle';
import { FirebaseProvider } from './contexts/firebase';
import { PreferencesProvider } from './contexts/preferences';
import fbaseProps from './constants/firebase';
import { MessagesProvider } from './contexts/messages';
import TrustorsProvider from './contexts/trustors';
import FeaturesProvider from './contexts/features';
import Routes from './Routes';
import CssBaseline from '@material-ui/core/CssBaseline';

const Canvas = styled.div`
  height: 100vh;
  width: 100%;
  color: ${({ theme }) => theme.global.color};
  background-color: ${({ theme }) => theme.global.backgroundColor};
  overflow: auto;
`;

const customTheme = createTheme({
  palette: {
    type: 'light',
  },
});

const App = () => (
  <Fragment>
    <GlobalStyle />

    <FirebaseProvider {...fbaseProps}>
      <FeaturesProvider>
        <PreferencesProvider>
          <MuiThemeProvider theme={customTheme}>
            <ThemeProvider theme={defaultTheme}>
              <MessagesProvider>
                <TrustorsProvider>
                  <Canvas className="canvas">
                    <CssBaseline />
                    <Routes />
                  </Canvas>
                </TrustorsProvider>
              </MessagesProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </PreferencesProvider>
      </FeaturesProvider>
    </FirebaseProvider>
  </Fragment>
);

export default App;
