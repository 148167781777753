import React from 'react';
import imgLogo from '../../assets/img/logoBio.png';
import styled from 'styled-components/macro';
import { admin } from '../../constants/routes';
import Routes from './Routes';
import { MOBILE_WIDTH } from '../../constants/dimensions';
import GroupsProvider from '../../contexts/groups';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 42px 70px;
  box-sizing: border-box;

  @media (max-width: ${MOBILE_WIDTH}px) {
    padding: 24px 16px;
  }
`;

export const LogoWrapper = styled.div`
  margin-bottom: 60px;
  width: 100%;
  cursor: pointer;

  @media (max-width: ${MOBILE_WIDTH}px) {
    display: none;
  }
`;

export const Img = styled.img`
  width: auto;
  height: auto;
`;

const AdminHome = ({ history }) => {
  return (
    <Wrapper>
      <GroupsProvider>
        <LogoWrapper onClick={() => history.push(admin.HOME)}>
          <Img src={imgLogo} />
        </LogoWrapper>

        <Routes />
      </GroupsProvider>
    </Wrapper>
  );
};

export default AdminHome;
