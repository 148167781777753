import fb from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import React, { useEffect, useState, useReducer } from 'react';

export const FirebaseContext = React.createContext({
  isAuthenticated: false,
  firebase: {},
  database: null,
  storage: null,
  functions: null,
  user: null,
  initialized: false,
});

export const FirebaseProvider = ({
  children,
  apiKey,
  authDomain,
  databaseURL,
  projectId,
  storageBucket,
  messagingSenderId,
}) => {
  const [user, setUser] = useState(null);

  // https://reactjs.org/docs/context.html#caveats
  const [value, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'UPDATE':
          return { ...state, ...action.payload };
        default:
          return { ...state };
      }
    },
    {
      isAuthenticated: false,
      firebase: {},
      database: null,
      storage: null,
      user: null,
      initialized: false,
      functions: null,
      auth: null,
    },
  );

  /**
   * On component mount...
   */
  useEffect(() => {
    if (fb.apps.length === 0) {
      fb.initializeApp({
        apiKey,
        authDomain,
        databaseURL,
        projectId,
        storageBucket,
        messagingSenderId,
      });

      const database = fb.firestore();
      const storage = fb.storage().ref();
      const functions = fb.functions();
      const auth = fb.auth();

      // setup the emulators
      if (/true|1/.test(process.env.REACT_APP_EMULATOR)) {
        console.warn('Using Emulators!');
        functions.useFunctionsEmulator('http://localhost:5001');
        auth.useEmulator('http://localhost:9099/');
        database.useEmulator('localhost', 8080);
      }

      dispatch({
        type: 'UPDATE',
        payload: { firebase: fb, database, storage, functions, auth },
      });

      // On Authentication state change...
      auth.onAuthStateChanged(user => {
        console.debug('Auth State Changed:', user);
        if (user) {
          // Authenticated
          dispatch({
            type: 'UPDATE',
            payload: { isAuthenticated: true, user },
          });
          setUser(user);
        } else {
          // Unauthenticated
          dispatch({
            type: 'UPDATE',
            payload: { isAuthenticated: false, user: null, profile: null },
          });
        }

        dispatch({ type: 'UPDATE', payload: { initialized: true } });
      });
    }
    // eslint-disable-next-line
  }, []);

  /**
   * Get the user profile
   */
  useEffect(() => {
    console.debug('Get Profile');
    if (!user) {
      return;
    }

    (async () => {
      const docRef = value.database.doc(`/users/${user.uid}`);
      const doc = await docRef.get();

      // create a default profile if it doesn't exist
      if (!doc.exists) {
        await doc.ref.set({
          isActive: false,
          isAdmin: false,
          groups: [],
        });
      }

      const unsub = doc.ref.onSnapshot(
        doc => {
          const profile = doc.data();
          console.debug('Profile', profile, unsub);
          dispatch({
            type: 'UPDATE',
            payload: { profile: { ...profile, unsub } },
          });
        },
        e => {
          console.error('Unable to subscribe to profile changes.');
          console.error(e);
        },
      );
    })();
  }, [user, value.database]);

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};
