import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components/macro';
import Header from '../shared/Header2';
import Search from '../shared/Search';
import { FirebaseContext } from '../../contexts/firebase';
import AdminCategoryItem from '../member/AdminCategoryItem';
import { MessagesContext } from '../../contexts/messages';
import CheckboxItem from '../shared/CheckboxItem';
import CategoryItem from './CategoryItem';
import HTML5Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { vault } from '../../constants/routes';
import { Link } from 'react-router-dom';

const Container = styled.div`
  position: relative;
  padding: 30px 22px;
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
`;

const StyledHeader = styled(Header)`
  margin-bottom: 28px;
`;

const Toggle = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

const Resources = () => {
  const { profile, database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const [categories, setCategories] = useState([]);
  const [filter, setFilter] = useState();
  const [showAdmin, setShowAdmin] = useState(profile.vault?.isAdmin);

  /**
   * Get the categories for each group the user is assigned to.
   */
  useEffect(() => {
    return database
      .collection('vault_categories')
      .where('visible', '==', true)
      .onSnapshot(
        query => {
          if (query.empty) {
            return;
          }
          setCategories(
            query.docs
              .map(d => ({ ...d.data(), id: d.id, ref: d.ref }))
              .sort((a, b) => a.order - b.order),
          );
        },
        err => {
          console.error(err);
          addMessage('Unable to get categories for resources list.', 'error');
        },
      );
  }, [addMessage, database]);

  return (
    <Container>
      <Link to={vault.HOME}>
        <StyledHeader>Vault Resources</StyledHeader>
      </Link>

      <Search placeholder="Filter Resources" onChange={setFilter} />

      {/* Admin Category List */}
      {showAdmin && (
        <DndProvider backend={HTML5Backend}>
          {categories.map((cat, idx) => (
            <AdminCategoryItem
              isVault
              key={cat.id}
              category={cat}
              filter={filter}
              order={idx}
              allCategories={categories}
            />
          ))}
        </DndProvider>
      )}

      {/* Non Admin View */}
      {!showAdmin &&
        categories.map((cat, idx) => (
          <CategoryItem key={cat.id} category={cat} filter={filter} />
        ))}

      {/* Show the admin toggle if isAdmin */}
      {profile.vault.isAdmin && (
        <Toggle>
          <CheckboxItem
            style={{ marginBottom: 0 }}
            label="Show Admin View"
            checked={showAdmin}
            onChange={checked => setShowAdmin(checked)}
          />
        </Toggle>
      )}
    </Container>
  );
};

export default Resources;
