import styled from 'styled-components/macro';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  line-height: 1.6;
  box-sizing: border-box;
`;

export default Row;
