import React, { useState, useEffect } from 'react';
import { admin } from '../../../constants/routes';
import Delete from '../../shared/Delete';
import Item from '../shared/Item';
import DisplayName from '../shared/DisplayName';
import GroupString from '../groups/GroupString';
import styled from 'styled-components/macro';
import { useDrag, useDrop } from 'react-dnd';
import arrayMove from 'array-move';
import Promise from 'bluebird';

const DropZone = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 12px;
  box-sizing: border-box;
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1 100px;
`;

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 100px;
  overflow: hidden;

  & > .delete {
    flex: 0 0 32px;
  }
`;

const CategoryItem = ({ category, order, categories }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [classNames, setClassNames] = useState('');

  /**
   * Set the drag event
   */
  const [{ opacity }, dragRef] = useDrag({
    item: { type: `CATEGORY_ITEM`, category, order },
    collect: monitor => {
      setIsDragging(monitor.isDragging());

      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
      };
    },
  });

  /**
   * Setup the drop event
   */
  const handleDrop = async item => {
    await Promise.map(
      arrayMove(categories, item.order, order),
      async (d, id) => {
        await d.ref.update({ order: id });
      },
    );
  };

  const [{ isOver }, dropRef] = useDrop({
    accept: `CATEGORY_ITEM`,
    drop: handleDrop,
    collect: monitor => {
      return {
        isOver: monitor.isOver(),
      };
    },
  });

  /**
   * Assemble the class names
   */
  useEffect(() => {
    let classes = '';
    if (isOver) {
      classes += 'over ';
    }

    if (isDragging) {
      classes += 'dragging ';
    }

    setClassNames(classes);
  }, [isDragging, isOver]);

  return (
    <Item className={classNames} ref={dragRef} style={{ opacity }}>
      <DropZone ref={dropRef} className={classNames}>
        <LeftWrapper>
          <DisplayName to={admin.CATEGORIES + '/' + category.id}>
            {category.displayName || 'No Display Name'}
          </DisplayName>
        </LeftWrapper>
        <RightWrapper>
          <GroupString groups={category.groups} style={{ paddingRight: 0 }} />
          <div className="delete">
            <Delete
              to={admin.CATEGORIES + '/' + category.id + '/delete'}
              style={{ marginLeft: 12 }}
            />
          </div>
        </RightWrapper>
      </DropZone>
    </Item>
  );
};

export default CategoryItem;
