import styled from 'styled-components/macro';
import { TABLET_WIDTH } from '../../../constants/dimensions';

const CheckRow = styled.div`
  display: inline-block;
  width: 100%;
  padding: 0 42px;
  box-sizing: border-box;
  column-count: 2;
  column-gap: 24px;
  margin-bottom: 25px;

  @media (max-width: ${TABLET_WIDTH}px) {
    column-count: 1;
  }
`;

export default CheckRow;
