import React, { useState } from 'react';
import Checkbox from './Checkbox';
import styled from 'styled-components/macro';
import Spinner from './Spinner';

const Row = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  box-sizing: border-box;
`;

const IconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 23px;
  height: 18px;
`;

const Label = styled.div`
  font-family: Roboto;
  font-size: 14px;
  color: #495b5e;
  margin-bottom: 8px;
`;

const CheckboxItem = ({ label, onChange, style, ...props }) => {
  const [spinner, setSpinner] = useState(false);

  const handleChange = value => onChange(value, setSpinner);

  return (
    <Row style={style}>
      <IconWrapper>
        {spinner ? (
          <Spinner size={10} />
        ) : (
          <Checkbox {...props} onChange={handleChange} />
        )}
      </IconWrapper>
      <Label style={{ fontWeight: 'normal', marginBottom: 0 }}>{label}</Label>
    </Row>
  );
};

export default CheckboxItem;
