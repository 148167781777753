import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components/macro';
import UserItem from './UserItem';
import Spinner from '../../shared/Spinner';
import { UsersContext } from '../../../contexts/users';

const Group = styled.div`
  display: flex;
  align-items: center;
`;

const List = styled(Group)`
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const UserList = ({ filter }) => {
  const { users, fetching } = useContext(UsersContext);

  const [filteredUsers, setFilteredUsers] = useState([]);

  /**
   * Filter the user list based on the search field
   */
  useEffect(() => {
    if (!filter && !users.length) {
      return;
    }

    if (!filter) {
      return setFilteredUsers(users);
    }

    const regex = new RegExp(filter, 'i');

    const filtered = users.filter(
      u => regex.test(u.displayName) || regex.test(u.email),
    );

    setFilteredUsers(filtered);
  }, [filter, users]);

  return (
    <List>
      {fetching && <Spinner />}
      {!fetching && (!filteredUsers || !filteredUsers.length) && (
        <div>No results found.</div>
      )}
      {filteredUsers &&
        filteredUsers.map((user, index) => (
          <UserItem key={index} user={user} />
        ))}
    </List>
  );
};

UserList.defaultProps = {
  filter: '',
};

export default UserList;
