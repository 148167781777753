import moment from 'moment';

const validate = (type, text, cb) => {
  if (!text || !text.length) {
    return cb(true);
  }

  switch (type) {
    case 'email':
      return cb(/[^@]+@[^.]+\..+/.test(text));
    case 'phone':
      return cb(/\+\d?[0-9()\-+\s]{11,}/.test(text));
    case 'url':
      try {
        new URL(text);
        return cb(true);
      } catch (e) {
        return cb(false);
      }
    case 'date':
      return cb(moment(text).isValid());
    case 'verification-code':
      return cb(/^\d{6,}/.test(text));
    default:
      return cb(true);
  }
};

export default validate;
