import React, { useContext, useState, useEffect } from 'react';
import { admin } from '../../../constants/routes';
import Delete from '../../shared/Delete';
import Item from '../shared/Item';
import DisplayName from '../shared/DisplayName';
import Checkbox from '../../shared/Checkbox';
import imgAdd from '../../../assets/img/add-item.svg';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd';
import arrayMove from 'array-move';
import Promise from 'bluebird';
import Date from '../shared/Date';
import { FirebaseContext } from '../../../contexts/firebase';

const DropZone = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 12px;
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 2 1 200px;
`;

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 100px;
`;

const DateLabel = styled.span`
  font-size: 0.75em;
`;

const Text = styled.span`
  font-size: 0.75em;
  padding-left: 1em;
  font-style: italic;
`;

const BulletinItem = ({
  bulletin,
  allBulletins,
  order,
  onArchive = () => {},
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [classNames, setClassNames] = useState('');
  const { database } = useContext(FirebaseContext);

  /**
   * Set the drag event
   */
  const [{ opacity }, dragRef] = useDrag({
    item: { type: `BULLETIN_ITEM`, bulletin, order },
    collect: monitor => {
      setIsDragging(monitor.isDragging());

      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
      };
    },
  });

  /**
   * Setup the drop event
   */
  const handleDrop = async item => {
    await Promise.map(
      arrayMove(allBulletins, item.order, order),
      async (d, id) => {
        await d.ref.update({ order: id });
      },
    );
  };

  const [{ isOver }, dropRef] = useDrop({
    accept: `BULLETIN_ITEM`,
    drop: handleDrop,
    collect: monitor => {
      return {
        isOver: monitor.isOver(),
      };
    },
  });

  /**
   * Assemble the class names
   */
  useEffect(() => {
    let classes = '';
    if (isOver) {
      classes += 'over ';
    }

    if (isDragging) {
      classes += 'dragging ';
    }

    setClassNames(classes);
  }, [isDragging, isOver]);

  const duplicate = async (id, order) => {
    const ref = await database.collection('/bulletins').doc(id).get();
    const data = ref.data();

    const dupe = {
      ...data,
      title: data.title + ' Copy',
    };

    // create the copy
    const newRef = await database.collection('/bulletins').add(dupe);

    // splice in the new item
    let updateBulletins = [].concat(allBulletins);
    updateBulletins.splice(data.order + 1, 0, { ...dupe, ref: newRef });

    // update the order for each item
    await Promise.map(
      updateBulletins,
      async (d, id) => {
        // console.log(d.title, id);
        await d.ref.update({ order: id });
      },
      { concurrency: 10 },
    );
  };

  return (
    <Item className={classNames} ref={dragRef} style={{ opacity }}>
      <DropZone ref={dropRef} className={classNames}>
        <LeftWrapper>
          <Checkbox
            style={{ marginRight: 12 }}
            checked={bulletin.archived}
            onChange={onArchive}
          />

          <DisplayName
            style={{ width: 'auto' }}
            to={`${admin.BULLETINS}/${bulletin.id}`}
          >
            {bulletin.title || 'No Display Name'}
          </DisplayName>

          <Text>{bulletin.notes}</Text>
        </LeftWrapper>

        <RightWrapper>
          <DateLabel>Starts:</DateLabel>
          <Date date={bulletin.startDate} />

          {bulletin.endDate && (
            <>
              <DateLabel>Ends:</DateLabel>
              <Date date={bulletin.endDate} />
            </>
          )}

          <div
            style={{ paddingRight: 8, position: 'relative' }}
            onClick={() => duplicate(bulletin.id, order)}
          >
            <i
              style={{ fontSize: '0.9em', top: -2, position: 'relative' }}
              class="far fa-clone"
            ></i>
          </div>

          <Link to={`${admin.BULLETINS}/add?order=${order || 1}`}>
            <img src={imgAdd} alt="+" />
          </Link>

          <Delete
            to={`${admin.BULLETINS}/${bulletin.id}/delete`}
            style={{ marginLeft: 12 }}
          />
        </RightWrapper>
      </DropZone>
    </Item>
  );
};
export default BulletinItem;
