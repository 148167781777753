import { forwardRef } from 'react';
import styled from 'styled-components/macro';
import Header from '../shared/Header';
import { TABLET_WIDTH } from '../../constants/dimensions';
import { COLORS } from '../../constants/colors.js';

const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: #9ab3b7;
  min-height: 250px;
  z-index: 10000;
  display: flex;
  justify-content: center;
  padding: 32px;
  font-size: 18px;
  border-top: 1px solid ${COLORS.RED};
  border-bottom: none;
  color: white;

  & .row {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;

    & > .col {
      flex: 1 1 100px;
    }

    & > div:first-child {
      padding-right: 12px;

      @media (max-width: ${TABLET_WIDTH}px) {
        padding-right: 0;
        margin-bottom: 12px;
      }
    }

    & > div:last-child {
      padding-left: 12px;

      @media (max-width: ${TABLET_WIDTH}px) {
        padding-left: 0;
      }
    }

    @media (max-width: ${TABLET_WIDTH}px) {
      display: block;
    }
  }

  & a {
    color: ${COLORS.YELLOW};
    text-decoration: none;
    font-weight: bold;
  }

  & .address {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 700px;
    color: white;
    font-size: 18px;
    font-weight: bold;

    @media (max-width: ${TABLET_WIDTH}px) {
      display: block;
    }
  }
`;

const Pipe = styled.div`
  color: ${COLORS.YELLOW};
  &::before {
    content: '|';
  }

  @media (max-width: ${TABLET_WIDTH}px) {
    display: none;
  }
`;

const Footer = forwardRef((props, ref) => {
  return (
    <Container id="explore" ref={ref}>
      <div>
        <Header textStyle={{ color: COLORS.RED }}>Explore More</Header>

        <div className="row">
          <div className="col">
            If you are a current Reta member looking for support, click{' '}
            <a href="mailto:service@retaenroll.org ">here</a> to send an email
            or call the Reta Member Help Desk at 877-303-7382.
          </div>
          <div className="col">
            If you represent a Catholic organization and are interested to learn
            more about Reta Trust, click{' '}
            <a href="mailto:learnmore@retatrust.org">here</a>.
          </div>
        </div>

        <div
          className="row"
          style={{ justifyContent: 'center', paddingTop: 24 }}
        >
          <div className="address">
            <div>The Reta Trust</div>
            <Pipe />
            <div>2121 N California Blvd</div>
            <Pipe />
            <div>Suite 350</div>
            <Pipe />
            <div>Walnut Creek CA 94596</div>
          </div>
        </div>
      </div>
    </Container>
  );
});

export default Footer;
