import React from 'react';
import Header from '../shared/Header';
import { Paragraph } from '../shared/Paragraph';
import { pubScroll } from '../../constants/routes';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { COLORS } from '../../constants/colors';
import { Wrap } from '../../views/ScrollingLanding';
import { Toolbar } from '@material-ui/core';

const StyledLink = styled(Link)`
  color: ${COLORS.RED};
  font-weight: bold;
`;

const Logout = () => {
  return (
    <Wrap>
      <Toolbar />

      <div style={{ fontSize: 16, maxWidth: 700 }}>
        <Header style={{ marginBottom: 42, marginTop: 32 }}>
          Successully Signed Out
        </Header>

        <Paragraph>
          To access Reta Leadership content, please follow the instructions{' '}
          <StyledLink to={pubScroll.LOGIN}>here</StyledLink>.
        </Paragraph>
      </div>
    </Wrap>
  );
};

export default Logout;
