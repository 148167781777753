import React from 'react';
import { COLORS } from '../../constants/colors';
import { Text } from './Text';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 12px;
`;
export const Header = props => {
  return (
    <Wrapper {...props}>
      <Text
        style={{
          color: COLORS.RED,
          fontFamily: 'Titillium Web',
          fontSize: 34,
          letterSpacing: 'inherit',
          ...props.textStyle,
        }}
      >
        {props.children}
      </Text>
    </Wrapper>
  );
};

export default Header;
