import React, { useState } from 'react';
import Header from '../../shared/Header2';
import Search from '../../shared/Search';
import Add from '../../shared/Add';
import { admin } from '../../../constants/routes';
import ResourceList from './ResourceList';
import Canvas from '../shared/Canvas';
import Row from '../shared/Row';
import Group from '../shared/Group';

const Resources = ({ categoryId }) => {
  const [filter, setFilter] = useState(null);

  return (
    <Canvas>
      <Row style={{ marginBottom: 45 }}>
        <Group>
          <Header>Resources</Header>
          <Add
            style={{ marginLeft: 27 }}
            to={`${admin.CATEGORIES}/${categoryId}/resources/add`}
          />
        </Group>

        <Search onChange={val => setFilter(val)} />
      </Row>

      <ResourceList filter={filter} categoryId={categoryId} />
    </Canvas>
  );
};

export default Resources;
