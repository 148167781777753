import React, { useState, useEffect } from 'react';
import imgCross from '../../assets/img/cross.svg';
import styled from 'styled-components/macro';
import { member } from '../../constants/routes';
import ResourceItem, { StyledLink } from './ResourceItem';

export const Container = styled.div`
  margin-bottom: 24px;
`;

export const Category = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 6px;
  min-height: 32px;
  padding: 6px;
  box-sizing: border-box;
`;

export const CategoryName = styled.div`
  font-family: Roboto;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.6;
  color: #495b5e;
  padding-left: 13px;
  box-sizing: border-box;
`;

export const Docs = styled.div`
  padding-left: 20px;
  box-sizing: border-box;
`;

export const Archive = styled.div`
  font-style: italic;
  padding: 6px;
  opacity: 0.62;
`;

const CategoryItem = ({ category, filter }) => {
  const [docSnapshots, setDocSnapshots] = useState([]);
  const [docs, setDocs] = useState([]);
  const [regex, setRegEx] = useState(new RegExp('.', 'g'));

  /**
   * Build a new regexp when the filter value changes
   */
  useEffect(() => {
    if (!filter || !filter.length) {
      setRegEx(new RegExp('.', 'g'));
    }
    setRegEx(new RegExp(filter, 'gi'));
  }, [filter]);

  /**
   * Get the active resources
   */
  useEffect(() => {
    const unsub = category.ref
      .collection('documents')
      .where('archive', '==', false)
      .onSnapshot(docs => {
        if (docs.empty) {
          return;
        }
        setDocSnapshots(docs.docs);
      });
    return () => {
      unsub && unsub();
    };
  }, [category]);

  /**
   * Get the resource information
   */
  useEffect(() => {
    setDocs(
      docSnapshots
        .map(doc => {
          const data = doc.data();
          return { ...data, id: doc.id, ref: doc.ref };
        })
        .filter(d => regex.test(d.displayName))
        .sort((a, b) => a.order - b.order),
    );
  }, [docSnapshots, regex]);

  if (!docs.length) {
    return <div></div>;
  }

  return (
    <Container>
      {/* Category */}
      <Category>
        <div>
          <img src={imgCross} alt="+" />
        </div>
        <CategoryName>{category.displayName}</CategoryName>
      </Category>

      {/* Resources */}
      <Docs>
        {docs.map((doc, idx) => (
          <React.Fragment key={doc.id}>
            <ResourceItem
              to={`${member.MEMBER_HOME}/view/${category.id}/${doc.id}`}
              resource={doc}
            />
          </React.Fragment>
        ))}

        {/* Archive */}
        {category.showArchive && (
          <Archive>
            <StyledLink
              to={`${member.MEMBER_HOME}/view/${category.id}/archive`}
            >
              Archive...
            </StyledLink>
          </Archive>
        )}
      </Docs>
    </Container>
  );
};

export default CategoryItem;
