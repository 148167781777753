import React, { useState } from 'react';
import Header from '../../shared/Header2';
import Search from '../../shared/Search';
import Add from '../../shared/Add';
import { admin } from '../../../constants/routes';
import BulletinList from './BulletinList';
import Canvas from '../shared/Canvas';
import Row from '../shared/Row';
import Group from '../shared/Group';

const Bulletins = () => {
  const [filter, setFilter] = useState(null);

  return (
    <Canvas>
      <Row style={{ marginBottom: 45 }}>
        <Group>
          <Header>Bulletins</Header>
          <Add style={{ marginLeft: 27 }} to={admin.BULLETIN_ADD} />
        </Group>
        <Search onChange={val => setFilter(val)} />
      </Row>

      <BulletinList filter={filter} />
    </Canvas>
  );
};

export default Bulletins;
