import React, { useState, useContext, useEffect } from 'react';
import Canvas from '../../../admin/shared/Canvas';
import TextInput from '../../../shared/TextInput';
import Label from '../../../shared/Label';
import CheckboxItem from '../../../shared/CheckboxItem';
import { FirebaseContext } from '../../../../contexts/firebase';
import { MessagesContext } from '../../../../contexts/messages';
import Header from '../../../shared/Header2';
import CheckRow from '../../../admin/shared/CheckRow';
import Spinner from '../../../shared/Spinner';
import TextArea from '../../../shared/TextArea';
import SelectFile from '../../../shared/SelectFile';
import { PreferencesContext } from '../../../../contexts/preferences';
import styled from 'styled-components/macro';
import imgExternal from '../../../../assets/img/external.svg';
import Delete from '../../../admin/shared/Delete';
import { Group } from './ResourceAdd';
import moment from 'moment';

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  margin-bottom: 24px;
`;

export const StyledLabel = styled(Label)`
  margin: 0;
`;

export const StyledA = styled.a`
  font-family: Roboto;
  font-size: 16px;
  color: #951a2f;
  text-decoration: none;

  &:link {
    font-weight: bold;
  }
`;

const ResourceUpdate = ({ match }) => {
  const { categoryId, resourceId } = match.params;

  const { user, firebase, database, storage } = useContext(FirebaseContext);
  const { apiHost } = useContext(PreferencesContext);
  const { addMessage } = useContext(MessagesContext);

  const [initialized, setInitialized] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [resource, setResource] = useState({});
  const [validName, setValidName] = useState({});
  const [validDate, setValidDate] = useState({});
  const [url, setUrl] = useState();
  const [validUrl, setValidUrl] = useState({});

  /**
   * Subscribe to the resource
   */
  useEffect(() => {
    setFetching(true);
    const unsub = database
      .doc(`/vault_categories/${categoryId}/resources/${resourceId}`)
      .onSnapshot(
        doc => {
          let data = doc.data();

          let date;
          if ('toDate' in data.date) {
            date = moment(data.date.toDate()).format('YYYY-MM-DD');
          } else if (typeof data.date === 'string') {
            date = moment(data.date).format('YYYY-MM-DD');
          }

          data = {
            ...data,
            date,
          };

          console.debug('resource:', data);
          setResource(data);
          setFetching(false);
          setInitialized(true);
        },
        e => {
          console.error(e);
          addMessage('Unable to get resource.', 'error');
          setFetching(false);
          setInitialized(true);
        },
      );

    return () => unsub && typeof unsub === 'function' && unsub();
  }, [addMessage, categoryId, database, resourceId]);

  /**
   * Update the information in the user profile:w
   *
   * @param {string} path Firebase field to update
   * @param {*} value Value for the firebase field
   * @param {function} setSpinner enable or disable the spinner
   */
  const update = async (path, value, setSpinner) => {
    setSpinner(true);

    console.debug('---->', path, typeof value, value, value.length);
    // if blank, remove the field
    if (
      typeof value !== 'boolean' &&
      !value instanceof Date &&
      (!value || !value.length)
    ) {
      value = firebase.firestore.FieldValue.delete();
    }

    try {
      console.debug('Updating:', { [path]: value });
      await database
        .doc(`/vault_categories/${categoryId}/resources/${resourceId}`)
        .update({ [path]: value });
      addMessage(`Successfully updated resource "${resource.displayName}".`);
    } catch (e) {
      addMessage(
        `Unable to update resource "${resource.displayName}".`,
        'error',
      );
    }

    setSpinner(false);
  };

  /**
   * Remove the google storage path field
   */
  const removeGSPath = async () => {
    await storage.child(resource.gsPath).delete();

    // remove the field
    await database
      .doc(`/vault_categories/${categoryId}/resources/${resourceId}`)
      .update({ gsPath: firebase.firestore.FieldValue.delete() });
  };

  /**
   * Handle file upload
   */
  const handleUpload = async (file, setSpinner) => {
    setSpinner(true);

    const gsPath = `/vault_categories/${categoryId}/resources/${resourceId}/${file.name}`;

    const docRef = database.doc(
      `/vault_categories/${categoryId}/resources/${resourceId}`,
    );

    // remove any existing forced url
    await database
      .doc(`/vault_categories/${categoryId}/resources/${resourceId}`)
      .update({ url: firebase.firestore.FieldValue.delete() });

    // remove existing resource
    if (resource.gsPath) {
      try {
        await storage.child(resource.gsPath).delete();
      } catch (e) {
        console.error(e);
        return addMessage(`Unable to remove existing record.`, 'error');
      }
    }

    // upload new resource
    try {
      await storage.child(gsPath).put(file, {
        customMetadata: {
          resourceName: resource.displayName,
          resourceDate: resource.date,
          resourcePath: docRef.path,
        },
      });
    } catch (e) {
      console.error(e);
      return addMessage(`Unable to upload file "${file.name}".`, 'error');
    }

    // update db with new path and remove any `url` field
    try {
      await docRef.update({
        gsPath,
        url: firebase.firestore.FieldValue.delete(),
      });
    } catch (e) {
      console.error(e);
      return addMessage('Unable to update existing resource record.', 'error');
    }

    addMessage(`Successfully uploaded file "${file.name}".`);

    setSpinner(false);
  };

  /**
   * Fetch SignedURL for the resource
   */
  useEffect(() => {
    if (!resource || !resource.gsPath) {
      console.debug('No `gsPath` for the current resource', resource);
      return;
    }

    const fetchSignedUrls = async paths => {
      const idToken = await user.getIdToken();
      let res;
      try {
        res = await fetch(`${apiHost}/signedUrls`, {
          method: 'POST',
          headers: {
            Authorization: `IdToken ${idToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ paths: [resource.gsPath] }),
        });
      } catch (e) {
        console.error(e);
        addMessage('Unable to connect to API.', 'error');
        return;
      }

      const json = await res.json();
      setUrl(json[resource.gsPath].url);
    };

    fetchSignedUrls([resource.gsPath]);
  }, [addMessage, apiHost, resource, user]);

  return (
    <Canvas style={{ alignItems: 'flex-start' }}>
      <Row style={{ marginBottom: 45 }}>
        <Header>Update Resource Information</Header>
        <Delete to={`${match.url}/delete`} />
      </Row>

      {fetching && (
        <Spinner size={20} message="Fetching resource information..." />
      )}

      {initialized && !fetching && resource && (
        <React.Fragment>
          {/* Resource Link */}
          <Row style={{ justifyContent: 'flex-start' }}>
            <StyledLabel>Resource:</StyledLabel>
            <div style={{ paddingLeft: 16 }}>
              <StyledA
                href={resource.url || url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {resource.displayName}
                {(resource.url || url) && (
                  <img src={imgExternal} alt="^" style={{ paddingLeft: 16 }} />
                )}
              </StyledA>
            </div>
          </Row>

          {/* Display Name */}
          <TextInput
            label="* Display Name:"
            placeholder="Example: Calendar"
            value={resource.displayName}
            onValidate={setValidName}
            onConfirm={(v, setSpinner) =>
              validName && update('displayName', v, setSpinner)
            }
            style={{ marginBottom: 8 }}
          />

          {/* Update File or URL */}
          <Group>
            <Label className="label" style={{}}>
              * File or URL
            </Label>

            <SelectFile label="Update File:" onConfirm={handleUpload} />

            <div className="or">
              <div>or</div>
            </div>

            <TextInput
              label="Force URL:"
              type="url"
              placeholder="Exampe: https://example.com"
              value={resource.url}
              onValidate={setValidUrl}
              onConfirm={async (v, setSpinner) => {
                if (!validUrl) {
                  return;
                }
                await update('url', v, setSpinner);
                await removeGSPath();
              }}
            />
          </Group>

          {/* Date */}
          <TextInput
            style={{ width: 'fit-content' }}
            label="* Date:"
            type="date"
            value={resource.date}
            placeholder="Required Field. Example: 4/16/19"
            validationMessage="The date entered is invalid.  Expecting M/D/YYYY."
            onValidate={setValidDate}
            onConfirm={(v, setSpinner) =>
              validDate && update('date', moment(v).toDate(), setSpinner)
            }
          />

          <TextArea
            label="Notes:"
            style={{ marginBottom: 42 }}
            onConfirm={(v, setSpinner) => update('notes', v, setSpinner)}
            placeholder="(optional)"
            value={resource.notes}
          />

          {/* Options */}
          <Row style={{ marginBottom: 16 }}>
            <Label>Options:</Label>
          </Row>

          <CheckRow>
            <CheckboxItem
              label="Archive"
              checked={resource.archive}
              onChange={(checked, setSpinner) =>
                update('archive', checked, setSpinner)
              }
            />
          </CheckRow>
        </React.Fragment>
      )}
    </Canvas>
  );
};

export default ResourceUpdate;
