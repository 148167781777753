import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.6;
  color: #951a2f;
  padding: 6px;
  box-sizing: border-box;
  min-height: 32px;
`;

export const StyledLink = styled(Link)`
  color: #951a2f;

  &:link {
    text-decoration: none;
  }
`;

const ResourceItem = ({ resource, ...props }) => {
  return (
    <Container>
      <StyledLink {...props}>{resource.displayName}</StyledLink>
    </Container>
  );
};

export default ResourceItem;
