import React, { useState, useContext, useEffect } from 'react';
import { FirebaseContext } from '../../contexts/firebase';
import Spinner from '../shared/Spinner';
import Fullscreen from '../shared/Fullscreen';
import ButtonField from '../shared/ButtonField';
import { Paragraph } from '../shared/Paragraph';
import styled from 'styled-components/macro';
import Header from '../shared/Header';
import useLocalStorage from '../../hooks/useLocalStorage';
import { pubScroll } from '../../constants/routes';
import { useHistory } from 'react-router';
import { Wrap } from '../../views/ScrollingLanding';
import { Toolbar } from '@material-ui/core';

const Wrapper = styled.div`
  max-width: 480px;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const PostLogin = () => {
  const { firebase } = useContext(FirebaseContext);
  const [signInEmail, setSignInEmail, delSignInEmail] =
    useLocalStorage('signInEmail');
  const [showVerifyEmail, setShowVerifyEmail] = useState(false);
  const [invalidUrl, setInvalidUrl] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState();
  const history = useHistory();

  /**
   * Process the link
   */
  useEffect(() => {
    const processLink = async () => {
      if (!firebase || !firebase.auth) {
        return;
      }

      // User opened the link on a different device.
      // To prevent session fixation attacks, ask the user to provide the
      // associated email again.
      if (!signInEmail) {
        setShowVerifyEmail(true);

        return;
      }

      // check that this is a sign in link
      if (!firebase.auth().isSignInWithEmailLink(window.location.href)) {
        console.error(
          `"${window.location.href}" is not a sign in link.  Skipping.`,
        );
        setInvalidUrl(true);
        return;
      }

      console.debug('SIGNING IN:', signInEmail, window.location.href);

      let result;
      try {
        result = await firebase
          .auth()
          .signInWithEmailLink(signInEmail, window.location.href);
      } catch (e) {
        console.debug('UNABLE TO SIGN IN TO FIREBASE', e);
        switch (e.code) {
          case 'auth/invalid-action-code':
            console.error(
              `The email provided (${signInEmail}) does not match the email used to start the sign in process.`,
            );
            return;

          case 'auth/invalid-email':
            setInvalidEmail(signInEmail);
            delSignInEmail();
            return;

          default:
            console.error('Something went wrong signing in:', e);
            return;
        }
      }

      console.debug('SIGNED IN:', result);
      const { additionalUserInfo } = result;

      // delete sign in email from localstorage
      delSignInEmail();

      //  New User
      if (additionalUserInfo.isNewUser) {
        console.debug('NEW USER');
      }

      // go to `home`
      history.push('/member');
    };

    processLink();
    // eslint-disable-next-line
  }, [firebase, signInEmail]);

  return (
    <Wrap>
      <Toolbar />

      <Fullscreen>
        <Wrapper>
          <Header style={{ marginBottom: 42 }}>Processing Login...</Header>

          {!showVerifyEmail && !invalidUrl && !invalidEmail && (
            <Row>
              <Spinner />
            </Row>
          )}

          {showVerifyEmail && !invalidUrl && !invalidEmail && (
            <React.Fragment>
              <Paragraph>
                This sign in link has been opened in a different browser or on a
                different device other then where the login process started.
              </Paragraph>

              <Paragraph>
                In order to continue, please verify the email address associated
                with your account.
              </Paragraph>
              <ButtonField
                type="email"
                buttonLabel="Verify Email"
                placeholder="Re-enter email"
                style={{
                  input: {
                    backgroundColor: '#e4e9f1',
                    border: '1px solid #526366',
                    minHeight: 48,
                  },
                  button: { backgroundColor: '#2a407f', minHeight: 48 },
                }}
                onClick={val => setSignInEmail(val)}
              />
            </React.Fragment>
          )}

          {invalidUrl && (
            <React.Fragment>
              <Paragraph>
                The URL provided is not a valid sign in link. Please login
                again.
              </Paragraph>

              <a href={pubScroll.LOGIN}>Proceed to Login</a>
            </React.Fragment>
          )}

          {invalidEmail && (
            <React.Fragment>
              <Paragraph>
                The email provided, <b>{invalidEmail}</b>, does not match the
                email used to start the sign in process. Please login again.
              </Paragraph>

              <a href={pubScroll.LOGIN}>Proceed to Login</a>
            </React.Fragment>
          )}
        </Wrapper>
      </Fullscreen>
    </Wrap>
  );
};

export default PostLogin;
