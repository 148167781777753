import React, { useState, useEffect } from 'react';
import { admin } from '../../../constants/routes';
import Delete from '../../shared/Delete';
import Item from '../shared/Item';
import DisplayName from '../shared/DisplayName';
import Checkbox from '../../shared/Checkbox';
import imgAdd from '../../../assets/img/add-item.svg';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd';
import arrayMove from 'array-move';
import Promise from 'bluebird';

const DropZone = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 12px;
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 2 1 200px;
`;

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 100px;
`;

const NewsletterItem = ({ newsletter, allNewsletters, order }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [classNames, setClassNames] = useState('');

  /**
   * Set the drag event
   */
  const [{ opacity }, dragRef] = useDrag({
    item: { type: `NEWSLETTER_ITEM`, newsletter, order },
    collect: monitor => {
      setIsDragging(monitor.isDragging());

      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
      };
    },
  });

  /**
   * Setup the drop event
   */
  const handleDrop = async item => {
    await Promise.map(
      arrayMove(allNewsletters, item.order, order),
      async (d, id) => {
        await d.ref.update({ order: id });
      },
    );
  };

  const [{ isOver }, dropRef] = useDrop({
    accept: `NEWSLETTER_ITEM`,
    drop: handleDrop,
    collect: monitor => {
      return {
        isOver: monitor.isOver(),
      };
    },
  });

  /**
   * Assemble the class names
   */
  useEffect(() => {
    let classes = '';
    if (isOver) {
      classes += 'over ';
    }

    if (isDragging) {
      classes += 'dragging ';
    }

    setClassNames(classes);
  }, [isDragging, isOver]);

  return (
    <Item className={classNames} ref={dragRef} style={{ opacity }}>
      <DropZone ref={dropRef} className={classNames}>
        <LeftWrapper>
          <Checkbox style={{ marginRight: 12 }} checked={newsletter.active} />
          <DisplayName
            style={{ width: 'auto' }}
            to={`${admin.NEWSLETTERS}/${newsletter.id}`}
          >
            {newsletter.displayName || 'No Display Name'}
          </DisplayName>
        </LeftWrapper>
        <RightWrapper>
          <Link to={`${admin.NEWSLETTERS}/add?order=${order || 1}`}>
            <img src={imgAdd} alt="+" />
          </Link>
          <Delete
            to={`${admin.NEWSLETTERS}/${newsletter.id}/delete`}
            style={{ marginLeft: 12 }}
          />
        </RightWrapper>
      </DropZone>
    </Item>
  );
};
export default NewsletterItem;
