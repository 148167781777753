import React from 'react';
import { Header } from '../shared/Header';
import { Paragraph } from '../shared/newShared/Paragraph';
import { COLORS } from '../../constants/colors';
import { Link } from 'react-router-dom';
import { pubScroll as routes } from '../../constants/routes';
import { Toolbar } from '@material-ui/core';
import { Content, Strap } from './RetaAdvantage';

export let Leadership = () => {
  return (
    <Content id="leadership">
      <Toolbar />

      <Strap>Proven Commitment</Strap>

      <Header>Reta Leadership</Header>

      <Paragraph>
        Individual member organizations elect a Board of Trustees that governs
        all aspects of Reta’s policies, operations and budget for the
        self-funded Trust.
      </Paragraph>

      <Paragraph>
        Through prudent management and leadership, Reta has consistently
        provided a fully-funded plan status. And beyond that, we have been able
        to return nearly $100 million in premium credits to our member
        organizations since 2006.
      </Paragraph>

      <div style={{ textAlign: 'center' }}>
        <Link
          to={routes.TEAM + '#content'}
          style={{
            color: COLORS.RED,
            textDecoration: 'underline',
            fontSize: 18,
            fontWeight: 'bold',
            letterSpacing: '0.02em',
          }}
        >
          Meet the Reta Leadership Team.
        </Link>
      </div>

      <div style={{ paddingBottom: 32 }} />
    </Content>
  );
};
