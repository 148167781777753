import { useEffect } from 'react';

const useSetTitle = title => {
  useEffect(() => {
    const prevTitle = window.document.title;

    window.document.title = `Reta Trust: ${title}`;

    return () => (window.document.title = prevTitle);
  }, [title]);
};

export default useSetTitle;
