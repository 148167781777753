import React, { useState, useEffect } from 'react';
import Item from '../shared/Item';
import styled from 'styled-components/macro';
import { useDrag, useDrop } from 'react-dnd';
import arrayMove from 'array-move';
import Promise from 'bluebird';
import Contact from '../../leadership/Contact';

const DropZone = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 12px;
  box-sizing: border-box;
`;

const ContactsItem = ({
  contact,
  contacts,
  order,
  orderField,
  dndType = 'CONTACT_ITEM',
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [classNames, setClassNames] = useState('');
  const [location, setLocation] = useState('');

  useEffect(() => {
    let out = '';

    if (contact.retaTitle && contact.title) {
      out = `${contact.title}`;
    }

    setLocation(out);
  }, [contact]);

  /**
   * Set the drag event
   */
  const [{ opacity }, dragRef] = useDrag({
    item: { type: dndType, contact, order },
    collect: monitor => {
      setIsDragging(monitor.isDragging());

      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
      };
    },
  });

  /**
   * Setup the drop event
   */
  const handleDrop = async item => {
    await Promise.map(arrayMove(contacts, item.order, order), async (d, id) => {
      await d.ref.update({ [orderField]: id });
    });
  };

  const [{ isOver }, dropRef] = useDrop({
    accept: dndType,
    drop: handleDrop,
    collect: monitor => {
      return {
        isOver: monitor.isOver(),
      };
    },
  });

  /**
   * Assemble the class names
   */
  useEffect(() => {
    let classes = '';
    if (isOver) {
      classes += 'over ';
    }

    if (isDragging) {
      classes += 'dragging ';
    }

    setClassNames(classes);
  }, [isDragging, isOver]);

  return (
    <Item className={classNames} ref={dragRef} style={{ opacity }}>
      <DropZone ref={dropRef} className={classNames}>
        <Contact
          name={contact.displayName}
          title={contact.retaTitle || contact.title}
          location={location}
        />
      </DropZone>
    </Item>
  );
};

export default ContactsItem;
