import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Message } from '../components/messages/Message';
import shortid from 'shortid';

export const MessagesContext = React.createContext({
  addMessage: () => console.log('addMessage'),
  removeMessage: () => console.log('removeMessage'),
});

const MessageArea = styled.div`
  position: fixed;
  bottom: 6px;
  right: 6px;
`;

const useMessages = expires => {
  const [messages, setMessages] = useState([]);

  const removeMessage = id => {
    console.debug('Removing message:', id);
    setMessages(prevMessages => {
      const updated = prevMessages.filter(m => m.id !== id);
      console.log(updated);
      return updated;
    });
  };

  const addMessage = (msg, type = 'info') => {
    if (typeof msg === 'string') {
      msg = [msg];
    }
    const id = shortid.generate();
    setMessages(prevMessages => prevMessages.concat([{ type, msg, id }]));

    // expire the message
    setTimeout(() => removeMessage(id), expires);
    return id;
  };

  return [messages, addMessage, removeMessage];
};

export const MessagesProvider = ({ children, expires = 7000 }) => {
  const [messages, addMessage, removeMessage] = useMessages(expires);

  // https://reactjs.org/docs/context.html#caveats
  const [value] = useState({
    addMessage,
    removeMessage,
  });

  // useEffect(() => {
  //   addMessage('Howdy');
  //   addMessage('Howdy', 'error');
  //   addMessage('Howdy');
  //   addMessage(
  //     [
  //       'blah',
  //       'Minim reprehenderit eu aliquip magna occaecat nisi qui. Enim ullamco elit magna sunt dolor eu tempor aute. Mollit nostrud reprehenderit ea laboris fugiat aute ea. Quis nulla esse sint exercitation duis cillum.',
  //     ],
  //     'warn',
  //   );
  //   addMessage('Howdy');
  //   addMessage('Howdy', 'error');
  // }, []);

  return (
    <MessagesContext.Provider value={value}>
      {children}

      <MessageArea>
        {messages.map((item, key) => (
          <Message key={key} type={item.type} id={item.id} expires={expires}>
            {item.msg.map((m, id) => (
              <p key={id}>{m}</p>
            ))}
          </Message>
        ))}
      </MessageArea>
    </MessagesContext.Provider>
  );
};

export default MessagesProvider;
