import React, { useState, useEffect, useContext } from 'react';
import { admin } from '../../../constants/routes';
import Delete from '../../shared/Delete';
import Item from '../shared/Item';
import DisplayName from '../shared/DisplayName';
import styled from 'styled-components/macro';
import { useDrag, useDrop } from 'react-dnd';
import arrayMove from 'array-move';
import Promise from 'bluebird';
import { TrustorsContext } from '../../../contexts/trustors';
import clsx from 'clsx';

const DropZone = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 12px;
  box-sizing: border-box;
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1 100px;
`;

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 100px;
  overflow: hidden;

  & > .delete {
    flex: 0 0 32px;
  }
`;

const Trustor = styled.div`
  text-align: right;
  font-weight: 400;
  font-style: italic;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 14px;
  box-sizing: border-box;
`;

const CarouselItem = ({ carousel, order, allItems }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [trustor, setTrustor] = useState();

  const { byId } = useContext(TrustorsContext);

  /**
   * Set the drag event
   */
  const [{ opacity }, dragRef] = useDrag({
    item: { type: `CAROUSEL_ITEM`, carousel, order },
    collect: monitor => {
      setIsDragging(monitor.isDragging());

      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
      };
    },
  });

  /**
   * Setup the drop event
   */
  const handleDrop = async item => {
    await Promise.map(arrayMove(allItems, item.order, order), async (d, id) => {
      await d.ref.update({ order: id });
    });
  };

  const [{ isOver }, dropRef] = useDrop({
    accept: `CAROUSEL_ITEM`,
    drop: handleDrop,
    collect: monitor => {
      return {
        isOver: monitor.isOver(),
      };
    },
  });

  const classes = clsx({
    over: isOver,
    dragging: isDragging,
    inactive: !carousel.active,
  });

  /**
   * Get the trustor info
   */
  useEffect(() => {
    if (typeof carousel.trustor === 'string') {
      setTrustor(carousel.trustor);
    }
    if (carousel.trustor.id) {
      setTrustor(byId[carousel.trustor.id]?.displayName);
    }
  }, [carousel, byId]);

  return (
    <Item className={classes} ref={dragRef} style={{ opacity }}>
      <DropZone ref={dropRef} className={classes}>
        <LeftWrapper>
          <DisplayName to={admin.CAROUSELS + '/' + carousel.id}>
            {carousel.name || 'No Display Name'}
          </DisplayName>
        </LeftWrapper>
        <RightWrapper>
          <Trustor>{trustor}</Trustor>
          <div className="delete">
            <Delete
              to={admin.CAROUSELS + '/' + carousel.id + '/delete'}
              style={{ marginLeft: 12 }}
            />
          </div>
        </RightWrapper>
      </DropZone>
    </Item>
  );
};

export default CarouselItem;
