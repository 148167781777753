import React from 'react';
import { admin } from '../../../constants/routes';
import Delete from '../../shared/Delete';
import Item from '../shared/Item';
import DisplayName from '../shared/DisplayName';
import styled from 'styled-components/macro';

const StyledDisplayName = styled(DisplayName)`
  flex: 1 1 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledItem = styled(Item)`
  align-items: baseline;
  padding: 12px;
`;

const State = styled.div`
  flex: 1 1 50px;
  text-align: right;
  font-size: 0.63em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 12px;
  box-sizing: border-box;
`;

const TrustorItem = ({ trustor }) => {
  return (
    <StyledItem>
      <StyledDisplayName to={admin.TRUSTORS + '/' + trustor.id}>
        {trustor.displayName || 'No Display Name'}
        <span style={{ paddingLeft: 16, fontSize: '0.8em' }}>
          ({trustor.shortName})
        </span>
      </StyledDisplayName>
      <State>{trustor.state}</State>
      <Delete
        to={admin.TRUSTORS + '/' + trustor.id + '/delete'}
        style={{ marginLeft: 12 }}
      />
    </StyledItem>
  );
};

export default TrustorItem;
