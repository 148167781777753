import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components/macro';

const TRANSITION = 4;
const INTERVAL = 10;

const fadeIn = keyframes`
  from {
    opacity: 0.0;
  }

  to {
    opacity: 0.5;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 0.5;
  }

  to {
    opacity: 0.0;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  & > div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0;

    &.fade-in {
      animation: ${fadeIn} ${TRANSITION}s ease-in-out;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }

    &.fade-out {
      animation: ${fadeOut} ${TRANSITION}s ease-in-out;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }
`;

// http://bost.ocks.org/mike/shuffle/
function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

const Slideshow = ({ images = [] }) => {
  const [slides, setSlides] = useState(images);

  useEffect(() => {
    setSlides(shuffle(images).filter(i => i.url));
  }, [images]);

  useEffect(() => {
    if (!slides.length) {
      return;
    }

    let cur = 0;
    const cancelId = setInterval(() => {
      // fade out the current element
      document.getElementById(`slide-${cur}`).setAttribute('class', 'fade-out');
      // fade in the next element
      document
        .getElementById(`slide-${cur + 1}`)
        .setAttribute('class', 'fade-in');

      if (cur < slides.length - 2) {
        cur++;
      } else {
        cur = 0;
      }
    }, INTERVAL * 1000);

    return () => clearInterval(cancelId);
  }, [slides]);

  return (
    <Wrapper>
      {slides.map((i, idx) => {
        return (
          <div
            key={idx}
            id={`slide-${idx}`}
            className={idx === 0 ? 'fade-in' : ''}
            style={{ backgroundImage: `url(${i.url})` }}
          />
        );
      })}
    </Wrapper>
  );
};

export default Slideshow;
