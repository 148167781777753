import React, { useEffect, useContext, useReducer } from 'react';
import { FirebaseContext } from './firebase';
import { MessagesContext } from './messages';

export const GroupsContext = React.createContext([]);

export const GroupsProvider = ({ children }) => {
  const { database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const [state, dispatch] = useReducer(
    // reducing function
    (state, action) => {
      console.debug('Action:', action);
      console.debug('Current State:', state);
      let nextState;

      switch (action.type) {
        case 'UPDATE_GROUPS':
          nextState = {
            ...state,
            groups: action.payload.groups,
            byId: action.payload.groups.reduce((acc, g) => {
              acc[g.id] = g;
              return acc;
            }, {}),
          };
          break;

        case 'FETCHING':
          nextState = { ...state, fetching: true };
          break;

        case 'FETCHED':
          nextState = { ...state, fetching: false };
          break;

        default:
          nextState = { ...state };
          break;
      }

      console.debug('Next State:', state);
      return nextState;
    },
    // initial state
    { groups: [], byId: {}, fetching: false },
  );

  /**
   * Subscribe to the Group list
   */
  useEffect(() => {
    dispatch({ type: 'FETCHING' });

    const unsub = database.collection('/groups').onSnapshot(
      docs => {
        const groups = docs.docs.map(d => ({
          ...d.data(),
          id: d.id,
          ref: d.ref,
        }));
        dispatch({ type: 'UPDATE_GROUPS', payload: { groups } });
        dispatch({ type: 'FETCHED' });
      },
      e => {
        console.error(e);
        addMessage('Unable to fetch group list', 'error');
        dispatch({ type: 'FETCHED' });
      },
    );

    return () => unsub && typeof unsub === 'function' && unsub();
  }, [addMessage, database]);

  return (
    <GroupsContext.Provider value={state}>{children}</GroupsContext.Provider>
  );
};

export default GroupsProvider;
