import styled from 'styled-components/macro';

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: Roboto;
  color: #495b5e;
  border: 1px solid #88a4a4;
  box-sizing: border-box;
  margin-top: -1px;

  &:nth-child(even) {
    background-color: #e2ebe9;
  }

  &:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  &:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &:hover {
    background-color: #d9f5ef;
  }

  &.over {
    background-color: #84e8e7;
  }

  &.inactive {
    color: white !important;
    background-color: #c5ccca !important;
    font-style: italic !important;
  }
`;

export default Item;
