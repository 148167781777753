import React from 'react';
import { Header } from '../shared/Header';
import { Toolbar } from '@material-ui/core';
import { Content } from './RetaAdvantage';
import imgBrochure from './brochure.png';
import { Paragraph } from '../shared/Paragraph';

export let SmallEmployer = () => {
  return (
    <Content id="small-employer">
      <Toolbar />

      <Header>Reta Plans for Larger and Smaller Catholic Employers</Header>

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          paddingTop: 32,
        }}
      >
        <div>
          <a
            href="https://www.flipsnack.com/BDF6AEEEFB5/about-reta-brochure-2022/full-view.html"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img style={{ width: '100%' }} src={imgBrochure} alt="brochure" />
          </a>

          <Paragraph
            style={{
              textAlign: 'center',
              fontStyle: 'italic',
              marginTop: 16,
            }}
          >
            Click on image to view the brochure.
          </Paragraph>
        </div>
      </div>

      <div style={{ paddingBottom: 32 }} />
    </Content>
  );
};
