import styled from 'styled-components/macro';

const Label = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  color: #495b5e;
  margin-bottom: 8px;
`;

export default Label;
