import React, { useEffect, useState } from 'react';
import TextInput from './TextInput';
import imgSearch from '../../assets/img/search.svg';
import styled from 'styled-components/macro';
import useDebounce from '../../hooks/useDebounce';

const Wrapper = styled.div`
  position: relative;
`;

const SearchInput = styled(TextInput)`
  padding-right: 30px;
`;

const Icon = styled.div`
  position: absolute;
  height: 30px;
  right: 6px;
  top: 6px;

  &.focus {
    right: 5px;
  }
`;

const Search = ({
  style,
  defaultValue,
  placeholder = 'Search',
  onChange,
  debounce = 250,
  noValidationMsg = true,
}) => {
  const [text, setText] = useState(defaultValue);
  const [focused, setFocused] = useState(false);
  const debouncedText = useDebounce(text, debounce);

  useEffect(() => {
    onChange(debouncedText);
  }, [debouncedText, onChange]);

  return (
    <Wrapper style={{ style }}>
      <SearchInput
        style={{ marginBottom: 0 }}
        placeholder={placeholder}
        onDebounced={val => {
          setText(val);
        }}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        noValidationMsg={noValidationMsg}
      />
      <Icon className={focused ? 'focus' : ''}>
        <img src={imgSearch} alt="search" />
      </Icon>
    </Wrapper>
  );
};

Search.defaultProps = {
  defaultValue: '',
  onChange: val => console.log('onChange:', val),
};

export default Search;
