import React, { useState, useContext, useEffect } from 'react';
import Canvas from '../shared/Canvas';
import TextInput from '../../shared/TextInput';
import Label from '../../shared/Label';
import CheckboxItem from '../../shared/CheckboxItem';
import { FirebaseContext } from '../../../contexts/firebase';
import { MessagesContext } from '../../../contexts/messages';
import Header from '../../shared/Header2';
import CheckRow from '../shared/CheckRow';
import Spinner from '../../shared/Spinner';
import TextArea from '../../shared/TextArea';
import Delete from '../shared/Delete';
import { useParams, useLocation } from 'react-router-dom';
import CustomEditor from './CustomEditor';
import Row from '../shared/Row';
import moment from 'moment';
import TrustorSelector from '../shared/TrustorSelector';

const BulletinUpdate = () => {
  const location = useLocation();
  const { firebase, database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);
  const { bulletinId } = useParams();

  const [initialized, setInitialized] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [bulletin, setBulletin] = useState({});

  /**
   * Subscribe to the bulletin
   */
  useEffect(() => {
    setFetching(true);
    const unsub = database.doc(`/bulletins/${bulletinId}`).onSnapshot(
      doc => {
        setFetching(false);
        setInitialized(true);

        if (!doc.exists) {
          addMessage('Unable to get bulletin.', 'error');
          return;
        }

        setBulletin(doc.data());
      },
      e => {
        console.error(e);
        addMessage('Unable to get bulletin.', 'error');
        setFetching(false);
        setInitialized(true);
      },
    );

    return () => unsub && typeof unsub === 'function' && unsub();
  }, [addMessage, database, bulletinId]);

  /**
   * Update the information in the user profile:w
   *
   * @param {string} path Firebase field to update
   * @param {*} value Value for the firebase field
   * @param {function} setSpinner enable or disable the spinner
   */
  const update = async (path, value, setSpinner) => {
    console.dir(path);
    console.dir(value);
    setSpinner && setSpinner(true);

    // if blank, remove the field
    if (
      typeof value !== 'boolean' &&
      typeof value !== 'object' &&
      Object.keys(value).length > 0 &&
      (!value || !value.length)
    ) {
      value = firebase.firestore.FieldValue.delete();
    }

    try {
      await database.doc(`/bulletins/${bulletinId}`).update({ [path]: value });
      addMessage(`Successfully updated "${bulletin.title}".`);
    } catch (e) {
      addMessage(`Unable to update "${bulletin.title}".`, 'error');
    }

    setSpinner && setSpinner(false);
  };

  const bulletinExists = Object.keys(bulletin).length > 0;

  const handleSave = async ({ raw, html }, setSpinner) => {
    if (raw) {
      await update('body', { raw: JSON.stringify(raw), html }, setSpinner);
    }
  };

  const handleTrustorOnly = shortName => {
    let newList = bulletin.trustors?.only || [];
    if (newList.includes(shortName)) {
      newList = newList.filter(t => t !== shortName);
    } else {
      newList.push(shortName);
    }
    update('trustors.only', newList.sort());
  };

  const handleTrustorNot = shortName => {
    let newList = bulletin.trustors?.not || [];
    if (newList.includes(shortName)) {
      newList = newList.filter(t => t !== shortName);
    } else {
      newList.push(shortName);
    }
    update('trustors.not', newList.sort());
  };

  return (
    <Canvas style={{ alignItems: 'flex-start' }}>
      <Row style={{ marginBottom: 45 }}>
        <Header>Update Bulletin Information</Header>

        <Delete to={`${location.pathname}/delete`} />
      </Row>

      {fetching && (
        <Spinner size={20} message="Fetching bulletin information..." />
      )}

      {initialized && !fetching && !bulletinExists && (
        <div>Unable to locate bulletin.</div>
      )}

      {initialized && !fetching && bulletinExists && (
        <React.Fragment>
          {/* Title */}
          <TextInput
            label="* Title:"
            placeholder="Required Field."
            value={bulletin.title}
            onConfirm={(v, setSpinner) => update('title', v, setSpinner)}
          />

          {/* Slug */}
          <TextInput
            label="Slug:"
            placeholder="Optional."
            value={bulletin.slug}
            onConfirm={(v, setSpinner) => update('slug', v, setSpinner)}
          />

          {/* Body */}
          <Label>* Body</Label>
          <CustomEditor
            style={{ height: 400 }}
            raw={bulletin.body?.raw}
            html={bulletin.body?.html}
            handleChange={() => {}}
            handleCancel={() => {}}
            handleSave={handleSave}
          />

          <Row>
            {/* Start Date */}
            <TextInput
              style={{ width: 'auto' }}
              label="* Start Date"
              type="date"
              value={
                bulletin.startDate &&
                moment(bulletin.startDate.toDate()).format('YYYY-MM-DD')
              }
              placeholder="Required Field. Example: 1/19/21"
              validationMessage="The date entered is invalid.  Expecting MM/DD/YYYY."
              onConfirm={(v, setSpinner) =>
                update('startDate', moment(v).toDate(), setSpinner)
              }
            />

            {/* End Date */}
            <TextInput
              style={{ width: 'auto' }}
              label="End Date"
              type="date"
              value={
                bulletin.endDate &&
                moment(bulletin.endDate.toDate()).format('YYYY-MM-DD')
              }
              placeholder="Example: 2/28/21"
              validationMessage="The date entered is invalid.  Expecting MM/DD/YYYY."
              onConfirm={(v, setSpinner) =>
                update('endDate', moment(v).toDate(), setSpinner)
              }
            />
          </Row>

          {/* Trustors */}
          <Row>
            <div style={{ width: '45%' }}>
              <TrustorSelector
                label="Only for these trustors:"
                selected={bulletin.trustors?.only}
                onChange={handleTrustorOnly}
              />

              <TextArea
                style={{ width: '100%', marginBottom: 42 }}
                value={bulletin.trustors?.only?.reduce((acc, t) => {
                  acc += t.replace(/,/g, '\n');
                  return acc + '\n';
                }, '')}
                onConfirm={(v, setSpinner) =>
                  update('trustors.only', v.trim().split('\n'), setSpinner)
                }
              />
            </div>

            <div style={{ width: '45%' }}>
              <TrustorSelector
                label="Not for these trustors:"
                selected={bulletin.trustors?.not}
                onChange={handleTrustorNot}
              />

              <TextArea
                style={{ width: '100%', marginBottom: 42 }}
                value={bulletin.trustors?.not?.reduce((acc, t) => {
                  acc += t.replace(/,/g, '\n');
                  return acc + '\n';
                }, '')}
                onConfirm={(v, setSpinner) =>
                  update('trustors.not', v.trim().split('\n'), setSpinner)
                }
              />
            </div>
          </Row>

          {/* Notes */}
          <TextArea
            label="Notes:"
            style={{ marginBottom: 42 }}
            placeholder="(optional)"
            value={bulletin.notes}
            onConfirm={(v, setSpinner) => update('notes', v, setSpinner)}
          />

          {/* Options */}
          <Row style={{ marginBottom: 16 }}>
            <Label>Options:</Label>
          </Row>

          <CheckRow>
            <CheckboxItem
              label="Archived"
              checked={bulletin.archived}
              onChange={(checked, setSpinner) =>
                update('archived', checked, setSpinner)
              }
            />
          </CheckRow>
        </React.Fragment>
      )}
    </Canvas>
  );
};

export default BulletinUpdate;
