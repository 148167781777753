import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components/macro';
import { FirebaseContext } from '../../../contexts/firebase';
import moment from 'moment';

const Wrapper = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-style: italic;
  font-weight: lighter;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #495b5e;
  padding: 0 14px;
  box-sizing: border-box;

  &:visited {
    color: inherit;
  }
`;

const Part = styled.span`
  &::after {
    content: '/';
    padding: 0 4px;
  }

  &:last-child {
    &::after {
      content: '';
      padding: 0;
    }
  }
`;

const Date = ({ date, ...props }) => {
  const { firebase } = useContext(FirebaseContext);

  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [year, setYear] = useState('');

  useEffect(() => {
    if (!date) {
      return;
    }

    let _date;
    if (date.constructor === firebase.firestore.Timestamp) {
      _date = moment(date.toDate());
    } else if (date.constructor === Date) {
      _date = moment(date);
    } else if (typeof date === 'string') {
      _date = moment(date);
    }

    if (!_date) {
      return;
    }

    setMonth(_date.format('M'));
    setDay(_date.format('D'));
    setYear(_date.format('YYYY'));
  }, [date, firebase.firestore.Timestamp]);

  return (
    <Wrapper {...props}>
      <Part>{month}</Part>
      <Part>{day}</Part>
      <Part>{year}</Part>
    </Wrapper>
  );
};

export default Date;
