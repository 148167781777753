import { Text } from '../../carousel/Text';
import imgPlayBtn from '../../../assets/img/playButton.svg';

const PlayButton = ({
  size = 35,
  onPlay,
  onClickPlay = () => {},
  ...props
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onClick={() => onClickPlay(props.url)}
    >
      <img
        alt="Play"
        src={imgPlayBtn}
        style={{
          width: size,
          height: size,
        }}
      />
      <Text
        style={{
          paddingLeft: 12,
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        Play Video
      </Text>
    </div>
  );
};

export default PlayButton;
