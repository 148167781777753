import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { useContext, useEffect, useRef, useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import { handleBasLogin, StyledMenuItem } from './AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { useHistory } from 'react-router';
import { pubScroll } from '../../constants/routes.js';
import { FeaturesContext } from '../../contexts/features';

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: '#e2e2ef',
    zIndex: 10002,
  },
}));

const Hamburger = ({ classIcon }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const classes = useStyles();
  const history = useHistory();
  const features = useContext(FeaturesContext);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    // if (anchorRef.current && anchorRef.current.contains(event.target)) {
    //   return;
    // }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <IconButton
        ref={anchorRef}
        edge="start"
        className={classIcon}
        color="inherit"
        aria-label="menu"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <MenuIcon />
      </IconButton>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  <StyledMenuItem
                    onClick={() => {
                      history.push({
                        pathname: pubScroll.ROOT,
                        hash: 'home',
                        search: history.location.search,
                      });
                      handleClose();
                    }}
                  >
                    Home
                  </StyledMenuItem>
                  <Divider />

                  <StyledMenuItem
                    onClick={() => {
                      history.push({
                        pathname: pubScroll.ROOT,
                        hash: 'welcome',
                        search: history.location.search,
                      });
                      handleClose();
                    }}
                  >
                    Our Story
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={() => {
                      history.push({
                        pathname: pubScroll.ROOT,
                        hash: 'reta-advantage',
                        search: history.location.search,
                      });
                      handleClose();
                    }}
                  >
                    The Reta Advantage
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={() => {
                      history.push({
                        pathname: pubScroll.ROOT,
                        hash: 'member-orgs',
                        search: history.location.search,
                      });
                      handleClose();
                    }}
                  >
                    Member Organizations
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={() => {
                      history.push({
                        pathname: pubScroll.ROOT,
                        hash: 'leadership',
                        search: history.location.search,
                      });
                      handleClose();
                    }}
                  >
                    Reta Leadership
                  </StyledMenuItem>
                  <Divider />

                  <StyledMenuItem
                    onClick={() => {
                      history.push({
                        pathname: pubScroll.ROOT,
                        hash: 'small-employer',
                        search: history.location.search,
                      });
                      handleClose();
                    }}
                  >
                    Our Small Employer Plan
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={() => {
                      history.push({
                        pathname: pubScroll.ROOT,
                        hash: 'request-proposal',
                        search: history.location.search,
                      });
                      handleClose();
                    }}
                  >
                    Request a Proposal
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={() => {
                      history.push({
                        pathname: pubScroll.ROOT,
                        hash: 'explore',
                        search: history.location.search,
                      });
                      handleClose();
                    }}
                  >
                    Explore More
                  </StyledMenuItem>
                  <Divider />

                  <StyledMenuItem
                    onClick={() => {
                      handleBasLogin(features, history);
                      handleClose();
                    }}
                  >
                    I am logging in as a Reta Member
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={() => {
                      history.push({
                        pathname: pubScroll.LOGIN,
                      });
                      handleClose();
                    }}
                  >
                    I am logging in as a Trustor, a Committee Member, Trustee or
                    Administrator
                  </StyledMenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default Hamburger;
