import React, { useContext, useState, useEffect } from 'react';
import Canvas from '../shared/Canvas';
import Header from '../../shared/Header2';
import styled from 'styled-components/macro';
import TextInput from '../../shared/TextInput';
import Spinner from '../../shared/Spinner';
import Button from '../../shared/Button';
import { admin } from '../../../constants/routes';
import { FirebaseContext } from '../../../contexts/firebase';
import { MessagesContext } from '../../../contexts/messages';
import { PreferencesContext } from '../../../contexts/preferences';

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  margin-bottom: 42px;

  &.header {
    margin-bottom: 16px;
  }

  &.column {
    flex-direction: column;
  }

  &.center {
    justify-content: center;
    align-items: center;
  }

  &.field {
    justify-content: center;
    margin-bottom: 16px;
  }

  &.buttons {
    justify-content: space-between;
  }

  & > div {
    line-height: 1.6;
    margin-bottom: 24px;
  }
`;

const ResourceDelete = ({ match, history }) => {
  const { categoryId, resourceId } = match.params;

  const { database, user } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);
  const { apiHost } = useContext(PreferencesContext);

  const [category, setCategory] = useState();
  const [deleting, setDeleting] = useState(false);
  const [valid, setValid] = useState(false);

  /**
   * Look up the category
   */
  useEffect(() => {
    (async () => {
      let doc;
      try {
        doc = await database.doc(`/categories/${categoryId}`).get();
      } catch (e) {
        console.error(e);
        addMessage('Unable to connect.', 'error');
        return;
      }

      if (!doc.exists) {
        addMessage('Unable to locate category.', 'warn');
        return;
      }

      setCategory(doc.data());
    })();
  }, [addMessage, categoryId, database, resourceId]);

  /**
   * Handle events
   */
  const handleValidation = text => {
    setValid(text === category.displayName);
  };

  const handleDelete = async () => {
    setDeleting(true);

    /**
     * Delete the database record
     *
     * Note: there is no method for deleting/ querying subcollections from the
     * Web client. Need to use an admin function.
     */
    const idToken = await user.getIdToken();
    try {
      const res = await fetch(`${apiHost}/admin/categories/${categoryId}`, {
        method: 'DELETE',
        headers: { Authorization: `IdToken ${idToken}` },
      });

      if (res.status >= 400) {
        const text = await res.text();
        console.error(text);
        addMessage(`Unable to delete ${category.displayName}`, 'error');
      }
    } catch (e) {
      console.error(e);
      addMessage('Unable to connect to API', 'error');
    }

    addMessage(`Successfully deleted "${category.displayName}".`);
    setDeleting(false);
    history.replace(admin.CATEGORIES);
  };

  return (
    <Canvas>
      <Row className="header">
        <Header>Delete Category</Header>
      </Row>

      {category && (
        <React.Fragment>
          <Row className="column">
            <div>
              To continue deleting the category, type in the category name "
              <b>{category.displayName}</b>" below:
            </div>
          </Row>

          <Row className="field">
            <div style={{ width: '75%' }}>
              <TextInput
                placeholder="Enter the category name to delete"
                onDebounced={handleValidation}
              />
            </div>
          </Row>

          {!deleting && (
            <Row className="buttons">
              <Button invert onClick={() => history.goBack()}>
                Cancel
              </Button>
              <Button disabled={!valid} onClick={handleDelete}>
                Delete Category
              </Button>
            </Row>
          )}

          {deleting && <Spinner />}
        </React.Fragment>
      )}
    </Canvas>
  );
};

export default ResourceDelete;
