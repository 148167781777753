import React, { useContext, useEffect } from 'react';
import { Switch, Route, withRouter } from 'react-router';
import NotAuthorized from './NotAuthorized';
import NoMatch from '../shared/NoMatch';
import { FirebaseContext } from '../../contexts/firebase';
import { member } from '../../constants/routes';
import AdminHome from '../admin/Home';
import View from './View';
import ViewBio from './ViewBio';
import CategoryArchive from './CategoryArchive';
import MemberHome from './MemberHome';
import ProfileUpdate from './ProfileUpdate';

export const AdminRoute = withRouter(({ history, ...props }) => {
  const { profile } = useContext(FirebaseContext);
  useEffect(() => {
    if (!profile) {
      return;
    }

    if (!profile.isActive || !profile.isAdmin) {
      console.warn('Not active or admin.');
      history.push(member.MEMBER_HOME);
    }
  }, [history, profile]);

  return <Route {...props} />;
});

const Routes = ({ match }) => {
  return (
    <Switch>
      <Route exact path={member.MEMBER_HOME} component={MemberHome} />
      <Route
        exact
        path={`${member.MEMBER_HOME}/view/bios/:bioId`}
        component={ViewBio}
      />
      <Route
        exact
        path={`${member.MEMBER_HOME}/view/:categoryId/archive`}
        component={CategoryArchive}
      />
      <Route exact path={member.MEMBER_VIEW} component={View} />
      <Route path={`${match.path}/not-authorized`} component={NotAuthorized} />
      <Route
        exact
        path={member.MEMBER_HOME + '/profile'}
        component={ProfileUpdate}
      />

      <AdminRoute path={`${match.path}/admin`} component={AdminHome} />

      <Route path="*" component={NoMatch} />
    </Switch>
  );
};

export default withRouter(Routes);
