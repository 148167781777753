import React, { useEffect, useState, useContext } from 'react';
import Header from '../shared/Header2';
import Canvas from '../admin/shared/Canvas';
import Row from '../admin/shared/Row';
import { Wrapper, LogoWrapper, Img } from '../admin/Home';
import imgLogo from '../../assets/img/logoBio.png';
import { admin } from '../../constants/routes';
import { FirebaseContext } from '../../contexts/firebase';
import { MessagesContext } from '../../contexts/messages';
import Spinner from '../shared/Spinner';
import styled from 'styled-components/macro';
import Search from '../shared/Search';
import Item from './CategoryArchiveItem';

const Resource = styled.div`
  display: flex;
  align-items: center;
`;

const List = styled(Resource)`
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
`;

const CategoryArchive = ({ match, history }) => {
  const { categoryId } = match.params;

  const { database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const [fetching, setFetching] = useState(false);
  const [resources, setResources] = useState([]);
  const [filter, setFilter] = useState();
  const [filtered, setFiltered] = useState([]);
  const [category, setCategory] = useState([]);

  /**
   * Subscribe to the Doc List
   */
  useEffect(() => {
    let unsub;
    (async () => {
      setFetching(true);

      const catRef = await database.doc(`/categories/${categoryId}`);
      const doc = await catRef.get();
      setCategory(doc.data());

      const col = catRef.collection('documents').get();

      // bail out if it's an empty collection
      if (col.empty) {
        setFetching(false);
        return;
      }

      unsub = database
        .doc(`/categories/${categoryId}`)
        .collection('documents')
        .where('archive', '==', true)
        .onSnapshot(
          docs => {
            const resources = docs.docs
              .map(d => ({
                ...d.data(),
                id: d.id,
                ref: d.ref,
              }))
              .sort((a, b) => a.order - b.order);
            setResources(resources);
            setFetching(false);
          },
          e => {
            console.error(e);
            addMessage('Unable to fetch resource list', 'error');
            setFetching(false);
          },
        );
    })();

    return () => unsub && typeof unsub === 'function' && unsub();
  }, [addMessage, categoryId, database]);

  /**
   * Filter the resource list based on the search field
   */
  useEffect(() => {
    if (!filter && !resources.length) {
      return;
    }

    if (!filter) {
      return setFiltered(resources);
    }

    const regex = new RegExp(filter, 'i');

    const filtered = resources
      .filter(d => regex.test(d.displayName) || regex.test(d.email))
      .sort((a, b) => a.order - b.order);

    setFiltered(filtered);
  }, [filter, resources]);

  return (
    <Wrapper>
      <LogoWrapper onClick={() => history.push(admin.HOME)}>
        <Img src={imgLogo} />
      </LogoWrapper>

      <Canvas>
        <Row>
          <Header>{category.displayName} Archive</Header>
          <Search onChange={setFilter} />
        </Row>

        <List>
          {fetching && <Spinner />}

          {!fetching && (!filtered || !filtered.length) && (
            <div>No results found.</div>
          )}

          {!fetching &&
            filtered &&
            filtered.map((resource, index) => (
              <Item key={index} resource={resource} />
            ))}
        </List>
      </Canvas>
    </Wrapper>
  );
};

export default CategoryArchive;
