import React, { useContext, useEffect } from 'react';
import { FirebaseContext } from '../../contexts/firebase';
import { pubScroll, member } from '../../constants/routes';
import Spinner from '../shared/Spinner';
import Fullscreen from '../shared/Fullscreen';
import {
  Route,
  Link,
  useLocation,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components/macro';
import { MOBILE_WIDTH, TABLET_WIDTH } from '../../constants/dimensions';
import Profile from './Profile';
import AdminMenu from '../admin/AdminMenu';
import Routes from './Routes';
import Resources from './Resources';
import { COLORS } from '../../constants/colors';

export const Canvas = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  @media (max-width: ${MOBILE_WIDTH}px) {
    display: block;
  }
`;

export const Sidebar = styled.div`
  position: relative;
  display: flex;
  flex: 0 0 365px;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  background-color: blue;
  box-shadow: 0px 0px 24px 0 rgba(20, 0, 0, 0.3);

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    display: block;
  }

  @media screen and (min-width: ${MOBILE_WIDTH +
    1}px) and (max-width: ${TABLET_WIDTH}px) {
    flex: 0 1 365px;
  }
`;

export const SideContent = styled.div`
  background-color: #d8e6e8;
  overflow-y: auto;
  flex: 1 1 500px;
  width: 100%;

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    height: 300px;
  }
`;

export const Content = styled.div`
  flex: 2 2 1000px;
  overflow: auto;

  @media (max-width: ${MOBILE_WIDTH}px) {
    width: 100%;
  }
`;

export const BackWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  color: ${COLORS.RED};
  font-size: 0.8em;

  & > a:visited {
    color: ${COLORS.RED};
  }

  &,
  i {
    padding-left: 15px;
  }
`;

export const BackToReta = () => (
  <BackWrapper>
    <Link to={pubScroll.HOME}>
      Back to retatrust.org
      <i className="fas fa-external-link-alt"></i>
    </Link>
  </BackWrapper>
);

const Home = () => {
  const { isAuthenticated, initialized, profile } = useContext(FirebaseContext);
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    if (!initialized) {
      return;
    }

    if (!isAuthenticated) {
      history.push({
        pathname: pubScroll.LOGIN,
        search: `?next=${encodeURIComponent(
          location.pathname + location.search,
        )}`,
      });
    }
  }, [
    history,
    initialized,
    isAuthenticated,
    location.pathname,
    location.search,
  ]);

  /**
   * If not an active user, redirect to the unauthorized message
   */
  useEffect(() => {
    if (profile && !profile.isActive) {
      return history.push(member.NOT_AUTHORIZED);
    }
  }, [history, profile]);

  if (!isAuthenticated || !profile) {
    return (
      <Fullscreen>
        <Spinner />
      </Fullscreen>
    );
  }

  return (
    <Canvas>
      <Sidebar>
        <SideContent>{profile.isActive && <Resources />}</SideContent>

        {profile.isActive && profile.isAdmin && (
          <Route path={`${match.path}/admin`} component={AdminMenu} />
        )}

        <Profile />
      </Sidebar>
      <Content>
        <Routes />
      </Content>
      <BackToReta />
    </Canvas>
  );
};

export default Home;
