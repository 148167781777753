import React, { useState, useEffect } from 'react';
import useDebounce from '../../hooks/useDebounce';
import styled from 'styled-components/macro';
import Color from 'color2';
import Label from './Label';
import { Container, BtnContainer, Img } from './TextInput';
import imgOk from '../../assets/img/ok.svg';
import imgCancel from '../../assets/img/cancel.svg';
import Spinner from './Spinner';

export const StyledTextArea = styled.textarea`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 1 100px;
  justify-content: stretch;
  align-items: stretch;
  color: #495b5e;
  background-color: #e2ebe9;
  border: 1px solid #88a4a4;
  border-radius: 2px;
  padding: 0 10px;
  box-sizing: border-box;
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.61;
  outline: none;

  &.invalid {
    border-color: #ff8a00;
    color: #da7608;
  }

  &::placeholder {
    font-style: italic;
    opacity: 1;
  }

  /* zoom effect on focus */
  &:focus {
    border-color: ${new Color('#88a4a4').lighten(0.03)};
    background-color: ${new Color('#e2ebe9').lighten(0.03)};
    box-shadow: 0 8px 10px 0 rgba(20, 0, 0, 0.15);
    width: calc(100% + 2px);
    top: -1px;
    left: -1px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
`;

const StyledWrapper = styled(Wrapper)``;

const StyledContainer = styled(Container)`
  align-items: flex-end;
  margin-bottom: 24px;
`;

const StyledBtnContainer = styled(BtnContainer)`
  top: 0;
`;

const TextArea = ({
  label,
  children,
  style,
  value,
  onDebounced = text => console.debug('Debounced', text),
  onConfirm,
  onFocus = () => {},
  onBlur = () => {},
  ...props
}) => {
  const [text, setText] = useState(children);
  const debouncedText = useDebounce(text, 750);
  const [showSpinner, setSpinner] = useState(false);
  const [cachedText, setCachedText] = useState();
  const [edit, setEdit] = useState(false);
  const [confirm, setConfirm] = useState(false);

  /**
   * Set the controlled `text` when the `value` props changes.
   */
  useEffect(() => {
    setText(value !== undefined ? value : '');
  }, [value]);

  /**
   * Call the onDebounced fn when debounced text updates
   */
  useEffect(() => {
    onDebounced(debouncedText);
  }, [onDebounced, debouncedText]);

  /**
   * If we receive an onConfirm function, enable the confirmation behavior.
   */
  useEffect(() => {
    if (onConfirm && typeof onConfirm === 'function') {
      setConfirm(true);
    }
  }, [onConfirm]);

  /**
   * Event handlers
   */
  const handleFocus = () => {
    confirm && setEdit(true);
    if (!cachedText) {
      setCachedText(text);
    }
    onFocus();
  };

  const handleBlur = () => {
    if (confirm && text === cachedText) {
      setEdit(false);
    }
    onBlur();
  };

  const handleCancel = () => {
    setText(cachedText);
    setEdit(false);
  };

  const handleOk = async () => {
    await onConfirm(text, setSpinner);
    setCachedText(null);
    setEdit(false);
  };

  return (
    <StyledContainer style={style}>
      <StyledWrapper>
        {label && <Label>{label}</Label>}
        <StyledTextArea
          rows="3"
          {...props}
          value={text}
          onChange={e => setText(e.target.value)}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </StyledWrapper>
      {edit && (
        <StyledBtnContainer>
          {showSpinner && <Spinner size={15} />}
          {!showSpinner && (
            <React.Fragment>
              <Img src={imgCancel} onClick={handleCancel} />
              <Img src={imgOk} onClick={handleOk} />
            </React.Fragment>
          )}
        </StyledBtnContainer>
      )}
    </StyledContainer>
  );
};

export default TextArea;
