import React, { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../../contexts/firebase';
import { MessagesContext } from '../../contexts/messages';
import { PreferencesContext } from '../../contexts/preferences';
import styled from 'styled-components/macro';
import Spinner from '../shared/Spinner';
import Header from '../shared/Header2';
import { useParams } from 'react-router-dom';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IFrame = styled.iframe`
  flex: 1 1 100px;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  /* hack for drop shadow effect:
       https://stackoverflow.com/questions/25474276/css-inset-shadow-by-parenting-div-over-iframe */
  & > .shadow {
    flex: 1 1 200px;

    &:before {
      content: '';
      position: absolute;
      z-index: 2;
      box-shadow: inset 0px 0px 32px 0 rgba(20, 0, 0, 0.3);
      top: 92px;
      right: 0;
      bottom: 0;
      left: 0;
      pointer-events: none;
    }
  }
`;

const StyledHeader = styled(Header)`
  width: 100%;
  flex: 0 0 92px;
  padding: 12px 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;

const View = ({ isVault = false }) => {
  const { categoryId, resourceId } = useParams();
  const { user, profile, database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);
  const { apiHost } = useContext(PreferencesContext);

  const [hasPermission, setHasPermission] = useState(false);
  const [url, setUrl] = useState();
  const [category, setCategory] = useState({});
  const [resource, setResource] = useState({});
  const [loading, setLoading] = useState(true);

  /**
   * See if the signed in user can view the resource
   */
  useEffect(() => {
    (async () => {
      if (isVault && profile.vault?.isActive) {
        setHasPermission(true);
        const doc = await database.doc(`/vault_categories/${categoryId}`).get();
        setCategory(doc.data());
      } else {
        // get the groups for the category
        const cat = await database.doc(`/categories/${categoryId}`).get();
        const data = cat.data();

        // if there is an intersection of groups the user has and groups belonging
        // to the category, show the resource.
        const intersection = profile.groups.filter(g =>
          data.groups.map(d => d.id).includes(g.id),
        );

        if (intersection.length || profile.isAdmin) {
          setHasPermission(true);
          const doc = await database.doc(`/categories/${categoryId}`).get();
          setCategory(doc.data());
        }
      }
    })();
  }, [
    categoryId,
    database,
    profile.groups,
    profile.isAdmin,
    profile.vault,
    isVault,
  ]);

  /**
   * Get the resource
   */
  useEffect(() => {
    (async () => {
      setLoading(true);
      if (!category || !resourceId) {
        return;
      }

      // get the signed url
      const docPath = isVault
        ? `/vault_categories/${categoryId}/resources/${resourceId}`
        : `/categories/${categoryId}/documents/${resourceId}`;
      console.debug(docPath);
      const resource = await database.doc(docPath).get();
      if (!resource.exists) {
        addMessage('Unable to locate resources.', 'warn');
        setLoading(false);
        return;
      }

      const data = resource.data();
      const { gsPath, url } = data;

      setResource(data);

      console.debug('Resource Info', data);

      // If the url is set, use that first...
      if (url) {
        setUrl(url);
        setLoading(false);
      }

      // ...otherwise fetch the signed url for the Google Storage path
      else if (gsPath) {
        const fetchSignedUrls = async paths => {
          const idToken = await user.getIdToken();
          let res;
          try {
            res = await fetch(`${apiHost}/signedUrls`, {
              method: 'POST',
              headers: {
                Authorization: `IdToken ${idToken}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ paths }),
            });
          } catch (e) {
            console.error(e);
            addMessage('Unable to connect to API.', 'error');
            return;
          }

          if (res.status >= 400) {
            addMessage('Unable to retrieve the resource.', 'warn');
            return;
          }

          const json = await res.json();
          return json;
        };

        try {
          const signedUrls = await fetchSignedUrls([gsPath]);
          setUrl(signedUrls[gsPath].url);
        } catch (e) {
          addMessage('Unable to get a secure URL.', 'error');
        }
        setLoading(false);
      }
    })();
  }, [
    addMessage,
    apiHost,
    category,
    categoryId,
    database,
    resourceId,
    user,
    isVault,
  ]);

  return (
    <Container>
      {loading && (
        <Row>
          <Spinner size={16} style={{ marginRight: 16 }} />
          Fetching resource...
        </Row>
      )}

      {!loading && hasPermission && (
        <Wrapper>
          <StyledHeader>
            <div>
              {category.displayName} - {resource.displayName}
            </div>
          </StyledHeader>

          <div className="shadow">
            <IFrame src={url} />
          </div>
        </Wrapper>
      )}

      {!loading && !hasPermission && (
        <div>You don't have permission to view this resource.</div>
      )}
    </Container>
  );
};

export default View;
