import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import imgAdd from '../../assets/img/add.svg';

const Wrapper = styled(Link)`
  width: auto;
  height: auto;
  cursor: pointer;
`;

const Add = props => {
  return (
    <Wrapper {...props}>
      <img src={imgAdd} alt="+" />
    </Wrapper>
  );
};

export default Add;
