import React from 'react';
import { Flexbox } from '../shared/Flexbox';
import { POSTER_WIDTH } from '../../constants/dimensions';
import { Text } from './Text';

export const Paragraph = ({ children, ...props }) => {
  return (
    <Flexbox
      style={{
        width: '100%',
        justifyContent: 'center',
      }}
      {...props}
    >
      <div
        style={{
          width: POSTER_WIDTH,
        }}
      >
        <Text
          style={{
            fontSize: 14,
            lineHeight: '2em',
          }}
        >
          {children}
        </Text>
      </div>
    </Flexbox>
  );
};
