import React, { useContext } from 'react';
import imgPillar from './RETA.org.PILLAR.I.BUTTON.png';
import Paragraph from '../../../shared/newShared/Paragraph';
import Pillar from '../Pillar';
import imgSeitz from './RETA.org.BISHOP.SEITZ.png';
import imgGoode from './RETA.org.BARBARA.GOODE.png';
import imgMcGowan from './RETA.org.KATHRYNE.MCGOWAN.png';
import imgLin from './RETA.org.FELIX.LIN.png';
import Video from '../Video';
import Header from '../../../shared/Header';
import useSetTitle from '../../../../hooks/useSetTitle';
import { TrustorsContext } from '../../../../contexts/trustors';

const Pillar1 = () => {
  useSetTitle('Pillar I');
  const { count } = useContext(TrustorsContext);

  return (
    <Pillar imgSrc={imgPillar} imgAlt="Pillar I">
      <Paragraph>
        The first Pillar is at the top of our list for good reason. And that’s
        because the teachings of the Catholic Church are core guiding principles
        in everything we do. And that’s a promise that we take seriously. In
        fact, the Reta Trust pioneered the first offering of Catholic healthcare
        benefits for Catholic employers. And nearly a half century later, we
        continue to provide uninterrupted Catholic healthcare benefit coverage
        for {count} dioceses and Catholic organizations across America.
      </Paragraph>

      <Video
        poster={imgSeitz}
        float="right"
        style={{ paddingLeft: 25 }}
        videoUrl="https://assets.retatrust.org/2021/210412.1/Bishop+Seitz.mp4"
      >
        Bishop Mark J. Seitz - Catholic Dioceses of El Paso TX
      </Video>

      <Paragraph>
        Up until now, we have been humble about telling our story. The story of
        Reta’s promise to stay true to our Catholic religious convictions in
        everything we do. But now, the time is right to speak out as we believe
        this is an important message of certainty and comfort during these
        uncertain and uncomfortable times.
      </Paragraph>

      <Paragraph>
        It’s a little-known fact that Reta was the first to create Catholic
        healthcare plans for clergy and employees of Catholic organizations.
        It’s what we do. It’s all we do. And we’ve been doing it longer than
        anyone. Our founding commitments that formed our identity nearly a half
        century ago, still define us today and for all tomorrows to come.
      </Paragraph>

      <Paragraph>
        We refer to those founding commitments as the six Pillars that support
        the foundation of who we are, what we do and how we evolve to best serve
        our Reta members by standing next to Reta Trustors… during these
        turbulent times… and beyond
      </Paragraph>

      <Header>From our humble beginnings nearly five decades ago</Header>

      <Paragraph>
        Now we will take a trip back in time to 1976 when Jimmy Carter was
        elected the 39th president of the US, One Flew Over the Cuckoo’s Nest
        won the Academy award for best picture, and the Religion Employee Trust
        Association was organized in California.
      </Paragraph>

      <Video
        poster={imgGoode}
        videoUrl="https://assets.retatrust.org/2021/210421.1/Goode+P1.mp4"
        float="right"
        style={{ paddingLeft: 25 }}
      >
        Barbara Goode — Lead Legal Counsel for Reta
      </Video>

      <Paragraph>
        Better known as Reta, the Trust has continued to evolve over five
        decades to remain responsive to our Trustors during changing times… and
        with their changing needs. From Reta’s origin, pooling priest healthcare
        claims for the dioceses of San Francisco, Oakland, San Jose, and Los
        Angeles, to standing next to you, and moving forward in today’s world.
      </Paragraph>

      <Paragraph>
        So, on the Reta continuum, we started with a few dioceses in California.
        We pooled the risk of their clergy population. And we expanded
        eligibility to Catholic lay employees which coincided with federal
        legislation. And that allowed Reta to apply consistent exclusions
        nationwide, so that the healthcare benefits we offered then, and now,
        align with the Roman Catholic Ethical and Religious Directives.
      </Paragraph>

      <Header>Reta… A complete Catholic health plan</Header>

      <Paragraph>
        The Reta team goes to work every day with a clear charter that the
        teachings and directives of the Catholic Church are core guiding
        principles in everything we do. To us that means that Reta provides
        Catholic healthcare plans shaped by the ERDs and the principles and
        morals of the Church.
      </Paragraph>

      <Video
        poster={imgLin}
        videoUrl="https://assets.retatrust.org/2021/210421.1/Lin+P1.mp4"
        float="right"
        style={{ paddingLeft: 25 }}
      >
        Felix Lin — President of Institutional Services, Beacon Pointe
      </Video>

      <Paragraph>
        We believe it’s important that the Bishop, leadership and members of
        each Reta diocesan Trustor feel confident that their healthcare plans
        comply with the ERDs and the teachings of the Roman Catholic Church. So,
        to make sure we are complying in every way, Reta has checks and balance
        systems in place that include annual audits, redundant internal
        oversight procedures, and the review, input and direction from Reta’s
        Board and Committees.
      </Paragraph>

      <Paragraph>
        All of these things together make Reta a complete Catholic healthcare
        plan. The ERDs alone are the biggest part of the picture. But there is
        more that we do to assure that we are in concert with the Roman Catholic
        principles. And that includes everything from Reta’s portfolio
        investments, claims adjudication and our everyday governance and
        operations.
      </Paragraph>

      <Paragraph>
        Our compliance record just over the past five years is rather
        impressive. And even though our audit score is sometimes slightly under
        100% compliance, at the end of day, we reprocess the outlying claims to
        get to a perfect score.
      </Paragraph>

      <Header>Pillar I summary and closing</Header>

      <Paragraph>
        In this, our first chapter of the Reta series, we reflect on Reta’s
        founding history as the first Catholic healthcare plan provider… and how
        we have evolved over five decades by remaining responsive to the
        evolving needs of our Trustors.
      </Paragraph>

      <Video
        poster={imgMcGowan}
        videoUrl="https://assets.retatrust.org/2021/210421.1/McGowan+P1.mp4"
        float="right"
        style={{ paddingLeft: 25 }}
      >
        Kathryne McGowan — CEO, Reta Trust
      </Video>

      <Paragraph>
        The core message, though, is that the teachings and directives of the
        Catholic Church are Core Guiding Principles in Everything We Do. This is
        the central Pillar that has,and will continue to support the Reta
        promise.
      </Paragraph>

      <Paragraph>
        We would like to close this first chapter of our series by reflecting on
        the memory of Bob Serventi, a former chairman of the Reta Board. He was
        a dedicated servant to the Reta Trust who passed away unexpectedly in
        2016 after a brief illness. Before his passing he was asked to comment
        on Reta’s history.
      </Paragraph>

      <Paragraph>
        He left us with these words as recounted by Reta’s CEO Kathryne McGowan.
      </Paragraph>
    </Pillar>
  );
};

export default Pillar1;
