import React, { useState, useContext, useEffect } from 'react';
import Canvas from '../shared/Canvas';
import TextInput from '../../shared/TextInput';
import Label from '../../shared/Label';
import CheckboxItem from '../../shared/CheckboxItem';
import { FirebaseContext } from '../../../contexts/firebase';
import { MessagesContext } from '../../../contexts/messages';
import Header from '../../shared/Header2';
import CheckRow from '../shared/CheckRow';
import Spinner from '../../shared/Spinner';
import TextArea from '../../shared/TextArea';
import Delete from '../shared/Delete';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { Group } from '../resources/ResourceAdd';
import UploadFile from '../../shared/UploadFile';
import imgExternal from '../../../assets/img/external.svg';
import { StyledA, StyledLabel } from '../resources/ResourceUpdate';

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  margin-bottom: 24px;
`;

const NewsletterUpdate = ({ match }) => {
  const { firebase, database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);
  const { newsletterId } = useParams();

  const [initialized, setInitialized] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [newsletter, setNewsletter] = useState({});
  const [validUrl, setValidUrl] = useState(false);

  /**
   * Subscribe to the newsletter
   */
  useEffect(() => {
    setFetching(true);
    const unsub = database.doc(`/newsletters/${newsletterId}`).onSnapshot(
      doc => {
        setFetching(false);
        setInitialized(true);

        if (!doc.exists) {
          addMessage('Unable to get newsletter.', 'error');
          return;
        }

        setNewsletter(doc.data());
      },
      e => {
        console.error(e);
        addMessage('Unable to get newsletter.', 'error');
        setFetching(false);
        setInitialized(true);
      },
    );

    return () => unsub && typeof unsub === 'function' && unsub();
  }, [addMessage, database, newsletterId]);

  /**
   * Update the information in the user profile:w
   *
   * @param {string} path Firebase field to update
   * @param {*} value Value for the firebase field
   * @param {function} setSpinner enable or disable the spinner
   */
  const update = async (path, value, setSpinner) => {
    setSpinner && setSpinner(true);

    // if blank, remove the field
    if (typeof value !== 'boolean' && (!value || !value.length)) {
      value = firebase.firestore.FieldValue.delete();
    }

    try {
      await database
        .doc(`/newsletters/${newsletterId}`)
        .update({ [path]: value });
      addMessage(`Successfully updated "${newsletter.displayName}".`);
    } catch (e) {
      addMessage(`Unable to update "${newsletter.displayName}".`, 'error');
    }

    setSpinner && setSpinner(false);
  };

  const newsletterExists = Object.keys(newsletter).length > 0;

  return (
    <Canvas>
      <Row style={{ marginBottom: 45 }}>
        <Header>Update Newsletter Information</Header>

        <Delete to={`${match.url}/delete`} />
      </Row>

      {fetching && (
        <Spinner size={20} message="Fetching newsletter information..." />
      )}

      {initialized && !fetching && !newsletterExists && (
        <div>Unable to locate newsletter.</div>
      )}

      {initialized && !fetching && newsletterExists && (
        <React.Fragment>
          {/* Resource Link */}
          <Row style={{ justifyContent: 'flex-start' }}>
            <StyledLabel>Newsletter:</StyledLabel>
            <div style={{ paddingLeft: 16 }}>
              <StyledA
                href={newsletter.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {newsletter.displayName}
                {newsletter.url && (
                  <img src={imgExternal} alt="^" style={{ paddingLeft: 16 }} />
                )}
              </StyledA>
            </div>
          </Row>

          {/* Display Name */}
          <TextInput
            label="Display Name:"
            placeholder="Example: Calendar"
            value={newsletter.displayName}
            onConfirm={(v, setSpinner) => update('displayName', v, setSpinner)}
          />

          {/* Update File or URL */}
          <Group>
            <Label className="label">* File or URL</Label>

            <UploadFile
              label="File to Upload:"
              dest={{
                gsPath: `/newsletters/${newsletterId}/{basename}.{ext}`,
                firestorePath: `/newsletters/${newsletterId}`,
                firestoreField: 'url',
              }}
              onUpload={async url => {
                console.log(url);
                await update('url', url);
              }}
            />

            <div className="or">
              <div>or</div>
            </div>

            <TextInput
              label="URL:"
              type="url"
              placeholder="Exampe: https://example.com"
              value={newsletter.url}
              onValidate={setValidUrl}
              onConfirm={async (v, setSpinner) => {
                if (!validUrl) {
                  return;
                }
                await update('url', v, setSpinner);
              }}
            />
          </Group>

          <TextArea
            label="Notes:"
            style={{ marginBottom: 42 }}
            onConfirm={(v, setSpinner) => update('notes', v, setSpinner)}
            placeholder="(optional)"
            value={newsletter.notes}
          />

          {/* Options */}
          <Row style={{ marginBottom: 16 }}>
            <Label>Options:</Label>
          </Row>

          <CheckRow>
            <CheckboxItem
              label="Active"
              checked={newsletter.active}
              onChange={(checked, setSpinner) =>
                update('active', checked, setSpinner)
              }
            />
          </CheckRow>
        </React.Fragment>
      )}
    </Canvas>
  );
};

export default NewsletterUpdate;
