import React from 'react';
import imgCross from '../../assets/img/cross-admin.svg';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { MOBILE_WIDTH } from '../../constants/dimensions';

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  width: 100%;
  min-height: 25px;
  padding: 4px 8px;
  box-sizing: border-box;

  @media (max-width: ${MOBILE_WIDTH}px) {
    margin-bottom: 0.5rem;
  }

  & > .docs {
    display: none;
    color: #6cf991;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);

    & > .docs {
      display: block;
    }
  }
`;

const Link = styled(NavLink)`
  font-family: Roboto;
  font-size: 1rem;
  font-weight: bold;
  color: #88a4a4;
  text-decoration: none;
  /* letter-spacing: 0.05rem; */
`;

const Name = styled.span`
  padding-left: 14px;
`;

const AdminItem = ({ to, docs, children }) => {
  return (
    <Item>
      <Link to={to}>
        <img src={imgCross} alt="cross" />
        <Name>{children}</Name>
      </Link>

      {docs && (
        <Link className="docs" to={`docs/${docs}`}>
          <i className="far fa-question-circle"></i>
        </Link>
      )}
    </Item>
  );
};

export default AdminItem;
