import React from 'react';
import { admin } from '../../../constants/routes';
import Delete from '../../shared/Delete';
import Item from '../shared/Item';
import DisplayName from '../shared/DisplayName';
import styled from 'styled-components/macro';

const StyledItem = styled(Item)`
  padding: 12px;
`;

const GroupItem = ({ group }) => {
  return (
    <StyledItem>
      <DisplayName to={admin.GROUPS + '/' + group.id}>
        {group.displayName || 'No Display Name'}
      </DisplayName>
      <Delete
        to={admin.GROUPS + '/' + group.id + '/delete'}
        style={{ marginLeft: 12 }}
      />
    </StyledItem>
  );
};

export default GroupItem;
