import React, { useState, useEffect } from 'react';
import useDebounce from '../../hooks/useDebounce';
import styled from 'styled-components/macro';
import Color from 'color2';
import validate from './validate';
import Label from './Label';
import ValidationMsg from './ValidationMsg';
import imgOk from '../../assets/img/ok.svg';
import imgCancel from '../../assets/img/cancel.svg';
import Spinner from './Spinner';
import moment from 'moment';

const Wrapper = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  margin-bottom: 8px;
  box-sizing: border-box;
`;

export const StyledInput = styled.input`
  position: relative;
  width: 100%;
  display: flex;
  flex: 1 1 100px;
  justify-content: stretch;
  align-items: center;
  height: 30px;
  color: #495b5e;
  background-color: #e2ebe9;
  border: 1px solid #88a4a4;
  border-radius: 2px;
  padding: 0 10px;
  box-sizing: border-box;
  font-family: Roboto;
  font-size: 14px;
  outline: none;

  &.invalid {
    border-color: #ff8a00;
    color: #da7608;
  }

  &:disabled {
    font-style: italic;
    background-color: white;
    border: none;
  }

  &::placeholder {
    font-style: italic;
    opacity: 1;
  }

  /* zoom effect on focus */
  &:focus {
    border-color: ${new Color('#88a4a4').lighten(0.03)};
    background-color: ${new Color('#e2ebe9').lighten(0.03)};
    box-shadow: 0 8px 10px 0 rgba(20, 0, 0, 0.15);
    height: 32px;
    width: calc(100% + 2px);
    top: -1px;
    left: -1px;

    &.invalid {
      border-color: #ff8a00;
      color: #da7608;
    }
  }
`;

export const BtnContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 30px;
  top: 4px;
`;

export const Img = styled.img`
  position: relative;
  width: 22px;
  height: 22px;
  margin-left: 12px;
  box-sizing: border-box;
  cursor: pointer;
`;

const Input = ({
  label,
  type,
  validationMessage,
  noValidationMsg = false,
  className,
  style,
  onValidate,
  onDebounced,
  onConfirm,
  value,
  debounce,
  onFocus,
  onBlur,
  inputStyle,
  ...props
}) => {
  const [text, setText] = useState(value || '');
  const debouncedText = useDebounce(text, debounce);
  const [valid, setValid] = useState(true);
  const [focus, setFocus] = useState(false);
  const [edit, setEdit] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [cachedText, setCachedText] = useState();
  const [confirm, setConfirm] = useState(false);

  /**
   * Validate the debounced text when updated
   */
  useEffect(() => {
    validate(type, debouncedText, isValid => {
      setValid(isValid);
      onValidate(isValid);
    });
  }, [debouncedText, onValidate, type]);

  /**
   * Call the onDebounced fn when debounced text updates
   */
  useEffect(() => {
    if (valid) {
      onDebounced(debouncedText);
    }
  }, [onDebounced, debouncedText, valid]);

  /**
   * Set the controlled `text` when the `value` props changes.
   */
  useEffect(() => {
    let val = value !== undefined ? value : '';

    if (value instanceof Date) {
      val = moment(value).format('YYYY-MM-DD');
    }

    setText(val);
  }, [value]);

  /**
   * If we receive an onConfirm function, enable the confirmation behavior.
   */
  useEffect(() => {
    if (onConfirm && typeof onConfirm === 'function') {
      setConfirm(true);
    }
  }, [onConfirm]);

  /**
   * Event handlers
   */
  const handleFocus = () => {
    setFocus(true);
    confirm && setEdit(true);
    if (!cachedText) {
      setCachedText(text);
    }
    onFocus();
  };

  const handleBlur = () => {
    setFocus(false);
    if (confirm && text === cachedText) {
      setEdit(false);
    }
    onBlur();
  };

  const handleCancel = () => {
    setText(cachedText);
    setEdit(false);
  };

  const handleOk = async () => {
    await onConfirm(text, setSpinner);
    setCachedText(null);
    setEdit(false);
  };

  /**
   * Component
   */
  return (
    <Container style={style}>
      <Wrapper style={style}>
        {label && <Label>{label}</Label>}

        <StyledInput
          {...props}
          type={type === 'verification-code' ? 'number' : type}
          className={className + (valid ? '' : ' invalid')}
          value={text}
          onChange={e => setText(e.target.value)}
          onFocus={handleFocus}
          onBlur={handleBlur}
          style={inputStyle}
        />

        <ValidationMsg
          className={focus ? 'focus' : ''}
          noValidationMsg={noValidationMsg}
        >
          {valid ? (
            noValidationMsg ? null : (
              <span>&nbsp;</span>
            )
          ) : (
            validationMessage
          )}
        </ValidationMsg>
      </Wrapper>

      {edit && (
        <BtnContainer>
          {showSpinner && <Spinner size={15} />}
          {!showSpinner && (
            <React.Fragment>
              <Img src={imgCancel} onClick={handleCancel} />
              <Img src={imgOk} onClick={handleOk} />
            </React.Fragment>
          )}
        </BtnContainer>
      )}
    </Container>
  );
};

Input.defaultProps = {
  debounce: 750,
  onValidate: isValid => console.debug('valid?', isValid),
  onDebounced: val => console.debug('debounced:', val),
  onFocus: () => {},
  onBlur: () => {},
};

export default Input;
