import React, { useContext, useState, useEffect } from 'react';
import Canvas from '../shared/Canvas';
import Header from '../../shared/Header2';
import styled from 'styled-components/macro';
import TextInput from '../../shared/TextInput';
import Spinner from '../../shared/Spinner';
import Button from '../../shared/Button';
import { admin } from '../../../constants/routes';
import { FirebaseContext } from '../../../contexts/firebase';
import { MessagesContext } from '../../../contexts/messages';

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  margin-bottom: 42px;

  &.header {
    margin-bottom: 16px;
  }

  &.column {
    flex-direction: column;
  }

  &.center {
    justify-content: center;
    align-items: center;
  }

  &.field {
    justify-content: center;
    margin-bottom: 16px;
  }

  &.buttons {
    justify-content: space-between;
  }

  & > div {
    line-height: 1.6;
    margin-bottom: 24px;
  }
`;

const NewsletterDelete = ({ match, history }) => {
  const { newsletterId } = match.params;

  const { database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const [newsletter, setNewsletter] = useState();
  const [deleting, setDeleting] = useState(false);
  const [valid, setValid] = useState(false);

  /**
   * Look up the newsletter
   */
  useEffect(() => {
    (async () => {
      let doc;
      try {
        doc = await database.doc(`/newsletters/${newsletterId}`).get();
      } catch (e) {
        console.error(e);
        addMessage('Unable to connect.', 'error');
        return;
      }

      if (!doc.exists) {
        addMessage('Unable to locate newsletter.', 'warn');
        return;
      }

      setNewsletter(doc.data());
    })();
  }, [addMessage, newsletterId, database]);

  /**
   * Handle events
   */
  const handleValidation = text => {
    setValid(text === newsletter.displayName);
  };

  const handleDelete = async () => {
    setDeleting(true);

    try {
      const doc = await database.doc(`/newsletters/${newsletterId}`).get();

      if (!doc.exists) {
        addMessage(
          `Unable to locate newsletter item "${newsletter.displayName}".`,
          'error',
        );
        setDeleting(false);
        return;
      }

      try {
        const result = await doc.ref.delete();
        console.debug('Delete Result:', result);
        addMessage(
          `Successfully deleted newsletter item "${newsletter.displayName}".`,
        );
        return history.replace(admin.NEWSLETTERS);
      } catch (e) {
        console.error(e);
        addMessage(
          `Unable to delete newsletter item "${newsletter.displayName}"`,
          'error',
        );
      }
    } catch (e) {
      console.error(e);
      addMessage(
        `Unable to get newsletter item "${newsletter.displayName}"`,
        'error',
      );
    }

    setDeleting(false);
  };

  return (
    <Canvas>
      <Row className="header">
        <Header>Delete Newsletter</Header>
      </Row>

      {newsletter && (
        <React.Fragment>
          <Row className="column">
            <div>
              To continue deleting the newsletter, type in the newsletter name "
              <b>{newsletter.displayName}</b>" below:
            </div>
          </Row>

          <Row className="field">
            <div style={{ width: '75%' }}>
              <TextInput
                placeholder="Enter the newsletter name to delete"
                onDebounced={handleValidation}
              />
            </div>
          </Row>

          {!deleting && (
            <Row className="buttons">
              <Button invert onClick={() => history.goBack()}>
                Cancel
              </Button>
              <Button disabled={!valid} onClick={handleDelete}>
                Delete Newsletter
              </Button>
            </Row>
          )}

          {deleting && <Spinner />}
        </React.Fragment>
      )}
    </Canvas>
  );
};

export default NewsletterDelete;
