import React, { useState, useEffect, useContext } from 'react';
import Header from '../../shared/Header2';
import Canvas from '../shared/Canvas';
import Row from '../shared/Row';
import TextInput from '../../shared/TextInput';
import TextArea from '../../shared/TextArea';
import Button from '../../shared/Button';
import { FirebaseContext } from '../../../contexts/firebase';
import Spinner from '../../shared/Spinner';
import { admin } from '../../../constants/routes';
import { MessagesContext } from '../../../contexts/messages';
import Label from '../../shared/Label';
import CheckboxItem from '../../shared/CheckboxItem';
import CheckRow from '../shared/CheckRow';
import SelectFile from '../../shared/SelectFile';
import moment from 'moment';
import styled from 'styled-components/macro';
import Promise from 'bluebird';

export const Group = styled.div`
  width: 100%;
  border: 1px solid #88a4a4;
  padding: 18px;
  box-sizing: border-box;
  position: relative;
  border-radius: 4px;
  margin-bottom: 18px;

  & > .label {
    background-color: white;
    width: fit-content;
    position: relative;
    top: -26px;
    padding: 0 12px;
    box-sizing: border-box;
  }

  & > .or {
    border-top: 1px solid #88a4a469;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 6px;

    & > div {
      background-color: white;
      position: relative;
      top: -9px;
      padding: 0 12px;
      box-sizing: border-box;
    }
  }
`;

const ResourceAdd = ({ match, history }) => {
  const { database, storage } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const [displayName, setDisplayName] = useState('');
  const [valid, setValid] = useState(false);
  const [adding, setAdding] = useState(false);
  const [notes, setNotes] = useState('');
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [validDate, setValidDate] = useState(true);
  const [file, setFile] = useState();
  const [validFile, setValidFile] = useState(false);
  const [validUrl, setValidUrl] = useState(false);
  const [url, setUrl] = useState();
  const [options, setOptions] = useState({
    archive: false,
    order: 0,
  });

  /**
   * Validate the form
   */
  useEffect(() => {
    console.debug('-----');
    console.debug('Valid: displayName', displayName, displayName.length > 0);
    console.debug('Valid: date', date, validDate);
    console.debug('Valid: file', file, validFile);
    console.debug('Valid: url', url, url, validUrl);
    const isValid =
      displayName.length > 0 && validDate && (validFile || validUrl);
    setValid(isValid);
  }, [date, displayName, file, url, validDate, validFile, validUrl]);

  /**
   * Validate the file input
   */
  useEffect(() => {
    if (file && file.constructor === File) {
      setValidFile(true);
    } else {
      setValidFile(false);
    }
  }, [file]);

  /**
   * Set the order from the url, if present
   */
  useEffect(() => {
    setOptions(prev => ({
      ...prev,
      order: match.params.orderId ? parseInt(match.params.orderId) : 0,
    }));
  }, [match.params.orderId]);

  /**
   * Add the resource
   */
  const handleAdd = async () => {
    setAdding(true);

    const allResources = await database
      .doc(`/categories/${match.params.categoryId}`)
      .collection(`documents`)
      .orderBy('order', 'asc')
      .get();

    // reorder the existing resources
    await Promise.map(allResources.docs, async (d, id) => {
      if (id >= options.order) {
        await d.ref.update({ order: id + 1 });
      }
    });

    // assemble the data for the record
    const data = { displayName, date: new Date(date), ...options };
    if (notes) {
      data.notes = notes;
    }
    if (url) {
      data.url = url;
    }

    console.debug('Adding:', data);

    // Add the record to the database
    let docRef;
    try {
      docRef = await database
        .doc(`/categories/${match.params.categoryId}`)
        .collection(`documents`)
        .add(data);
    } catch (e) {
      console.error(e);
      addMessage(
        `Unable to add resources "${displayName}" to the database.`,
        'error',
      );
      setAdding(false);
    }

    // Upload the file, if given
    if (validFile) {
      try {
        // Upload the file
        const gsPath = `categories/${match.params.categoryId}/resources/${docRef.id}/${file.name}`;
        await storage.child(gsPath).put(file, {
          customMetadata: {
            resourceName: displayName,
            resourceDate: data.date,
            resourcePath: docRef.path,
          },
        });

        // add the path to the record
        docRef.update({ gsPath });
      } catch (e) {
        console.error(e);
        addMessage(`Unable to upload resource "${displayName}".`, 'error');
        setAdding(false);
      }
    }

    setAdding(false);
    history.push(
      `${admin.CATEGORIES}/${match.params.categoryId}/resources/${docRef.id}`,
    );
    addMessage(`Successfully added resource "${displayName}"`);
  };

  return (
    <Canvas style={{ alignItems: 'flex-start' }}>
      <Row style={{ marginBottom: 45 }}>
        <Header>Add a New Resource</Header>
      </Row>

      <TextInput
        label="* Display Name:"
        placeholder="Required Field. Example: Adoptions of Reta Plans Policy"
        onDebounced={setDisplayName}
        style={{ marginBottom: 8 }}
      />

      <Group>
        <Label className="label" style={{}}>
          * File or URL
        </Label>

        <SelectFile label="Resource to Upload:" onSelectedFile={setFile} />

        <div className="or">
          <div>or</div>
        </div>

        <TextInput
          label="URL:"
          type="url"
          validationMessage="A valid URL is required.  Exampe: https://example.com"
          placeholder="Exampe: https://example.com"
          onValidate={setValidUrl}
          onDebounced={setUrl}
        />
      </Group>

      <TextInput
        style={{ width: 'auto' }}
        label="* Date:"
        type="date"
        value={date}
        placeholder="Required Field. Example: 4/16/19"
        validationMessage="The date entered is invalid.  Expecting MM/DD/YYYY."
        onValidate={setValidDate}
        onDebounced={setDate}
      />

      <TextArea
        label="Notes:"
        style={{ marginBottom: 42 }}
        onDebounced={setNotes}
      />

      {/* Options */}
      <Row style={{ marginBottom: 16 }}>
        <Label>Options:</Label>
      </Row>
      <CheckRow>
        <CheckboxItem
          label="Archive"
          checked={options.archive}
          onChange={checked => setOptions({ ...options, archive: checked })}
        />
      </CheckRow>

      {!adding && (
        <Row>
          <Button
            invert
            onClick={() =>
              history.push(`${admin.CATEGORIES}/${match.params.categoryId}`)
            }
          >
            Cancel
          </Button>
          <Button disabled={!valid} onClick={handleAdd}>
            Add Resource
          </Button>
        </Row>
      )}
      {adding && (
        <Row style={{ justifyContent: 'center' }}>
          <Spinner />
        </Row>
      )}
    </Canvas>
  );
};

export default ResourceAdd;
