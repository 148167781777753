import React, { useState, useContext, useEffect } from 'react';
import Canvas from '../shared/Canvas';
import TextInput from '../../shared/TextInput';
import { FirebaseContext } from '../../../contexts/firebase';
import { MessagesContext } from '../../../contexts/messages';
import Header from '../../shared/Header2';
import Spinner from '../../shared/Spinner';
import TextArea from '../../shared/TextArea';
import Delete from '../shared/Delete';
import styled from 'styled-components/macro';
import { PreferencesContext } from '../../../contexts/preferences';
import maps from '@google/maps';
import Promise from 'bluebird';
import { admin } from '../../../constants/routes';

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  margin-bottom: 24px;
`;

const TrustorUpdate = ({ match }) => {
  const { trustorId } = match.params;

  const { firebase, database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);
  const { googleMapsApiKey } = useContext(PreferencesContext);

  const [initialized, setInitialized] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [trustor, setTrustor] = useState({});
  const [users, setUsers] = useState([]);

  /**
   * Subscribe to the trustors
   */
  useEffect(() => {
    setFetching(true);
    const unsub = database.doc(`/trustors/${trustorId}`).onSnapshot(
      doc => {
        setFetching(false);
        setInitialized(true);

        if (!doc.exists) {
          addMessage('Unable to get trustor.', 'error');
          return;
        }

        setTrustor({ ...doc.data(), ref: doc.ref });
      },
      e => {
        console.error(e);
        addMessage('Unable to get trustor.', 'error');
        setFetching(false);
        setInitialized(true);
      },
    );

    return () => unsub && typeof unsub === 'function' && unsub();
  }, [addMessage, database, trustorId]);

  useEffect(() => {
    if (!trustor.ref) {
      return;
    }
    (async () => {
      console.log('USERS', trustor);
      const users = await database
        .collection(`users`)
        .where('trustor', '==', trustor.ref)
        .get();

      const usersData = await Promise.map(
        users.docs,
        doc => {
          return { ...doc.data(), id: doc.id };
        },
        { concurrency: 5 },
      );

      setUsers(usersData);
    })();
  }, [database, trustor]);

  /**
   * Update the trustor information
   *
   * @param {string} path Firebase field to update
   * @param {*} value Value for the firebase field
   * @param {function} setSpinner enable or disable the spinner
   */
  const update = async (path, value, setSpinner) => {
    setSpinner(true);

    // if blank, remove the field
    if (typeof value !== 'boolean' && (!value || !value.length)) {
      value = firebase.firestore.FieldValue.delete();
    }

    try {
      await database.doc(`/trustors/${trustorId}`).update({ [path]: value });
      addMessage(`Successfully updated "${trustor.displayName}".`);
    } catch (e) {
      addMessage(`Unable to update "${trustor.displayName}".`, 'error');
    }

    setSpinner(false);
  };

  /**
   * Update the geolocation when the form updates
   */
  const updateLocation = overrides => {
    const data = {
      ...trustor,
      ...overrides,
    };

    let sendAddress = '';

    if (data.address) {
      sendAddress += data.address + ', ';
      if (data.city || data.state || data.zipCode) {
        sendAddress += ', ';
      }
    }

    if (data.city) {
      sendAddress += data.city;
      if (data.state || data.zipCode) {
        sendAddress += ', ';
      }
    }

    if (data.state) {
      sendAddress += data.state;
      if (data.zipCode) {
        sendAddress += ', ';
      }
    }

    if (data.zipCode) {
      sendAddress += data.zipCode;
    }

    if (sendAddress.length <= 0) {
      return;
    }

    console.debug('Getting Geocode for Address:', sendAddress);

    maps
      .createClient({
        key: googleMapsApiKey,
      })
      .geocode(
        {
          address: sendAddress,
        },
        async (err, response) => {
          if (err) {
            console.error(err);
            return;
          }
          console.debug('Geocode Results:', response.json.results[0]);
          const { lat, lng } = response.json.results[0].geometry.location;
          try {
            await database.doc(`/trustors/${trustorId}`).update({ lat, lng });
            addMessage(
              `Successfully updated geocode for "${trustor.displayName}".`,
            );
          } catch (e) {
            addMessage(
              `Unable to update geocode for "${trustor.displayName}".`,
              'error',
            );
          }
        },
      );
  };

  const trustorExists = Object.keys(trustor).length > 0;

  return (
    <Canvas style={{ alignItems: 'flex-start' }}>
      <Row style={{ marginBottom: 45 }}>
        <Header>Update Trustor Information</Header>
        <Delete to={`${match.url}/delete`} />
      </Row>

      {(fetching || !trustor) && (
        <Spinner size={20} message="Fetching trustor information..." />
      )}

      {initialized && !fetching && !trustorExists && (
        <div>Unable to locate trustor.</div>
      )}

      {initialized && !fetching && trustorExists && (
        <React.Fragment>
          <Row style={{ marginBottom: 0 }}>
            {/* Display Name */}
            <TextInput
              label="Display Name:"
              placeholder="Example: Diocese of ..."
              value={trustor.displayName}
              onConfirm={(v, setSpinner) =>
                update('displayName', v, setSpinner)
              }
            />
            {/* Short Name */}
            <TextInput
              style={{ paddingLeft: 12 }}
              label="Short Name:"
              placeholder="Example: adsj"
              value={trustor.shortName}
              onConfirm={(v, setSpinner) => update('shortName', v, setSpinner)}
              help="blh"
            />
          </Row>

          <TextInput
            label="Address:"
            placeholder="Example: 1123 State St."
            value={trustor.address}
            onConfirm={(v, setSpinner) =>
              update('address', v, setSpinner) && updateLocation({ address: v })
            }
          />

          <Row style={{ marginBottom: 0 }}>
            <TextInput
              style={{ flex: '1 1 200px', width: 'auto', marginRight: 16 }}
              label="City:"
              placeholder="Example: San Francisco"
              value={trustor.city}
              onConfirm={(v, setSpinner) =>
                update('city', v, setSpinner) && updateLocation({ city: v })
              }
            />

            <TextInput
              style={{ flex: '1 1 50px', width: 'auto' }}
              label="* State:"
              placeholder="Required. Ex: CA or California"
              value={trustor.state}
              onConfirm={(v, setSpinner) =>
                update('state', v, setSpinner) && updateLocation({ state: v })
              }
            />
          </Row>

          <TextInput
            style={{ flex: '1 1 100px', width: 'auto' }}
            label="Zip Code:"
            placeholder="Example: 94101"
            value={trustor.zipCode}
            onConfirm={(v, setSpinner) =>
              update('zipCode', v, setSpinner) && updateLocation({ zipCode: v })
            }
          />

          <TextArea
            label="Notes:"
            style={{ marginBottom: 42 }}
            onConfirm={(v, setSpinner) => update('notes', v, setSpinner)}
            placeholder="Optional trustor notes"
            value={trustor.notes}
          />

          {users.length > 0 && (
            <div>
              <Header>Users</Header>

              <p style={{ marginTop: 32 }}>
                <ul>
                  {users.map(user => (
                    <li>
                      <a href={`${admin.USERS}/${user.id}`}>
                        {user.displayName}
                      </a>
                    </li>
                  ))}
                </ul>
              </p>
            </div>
          )}
        </React.Fragment>
      )}
    </Canvas>
  );
};

export default TrustorUpdate;
