import React, { useState } from 'react';
import styled from 'styled-components/macro';
import TextInput from './TextInput';
import Button from './Button';
import ValidationMsg from './ValidationMsg';

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  position: relative;
`;

const StyledButton = styled(Button)`
  margin-left: 15px;
  position: relative;
  top: ${props => (props.focus ? -1 : 0)}px;
  flex: 1 0 120px;
`;

const ButtonField = ({
  buttonLabel,
  onClick,
  placeholder,
  defaultValue,
  type,
  style = { input: {}, button: {} },
  validationMessage,
}) => {
  const [text, setText] = useState(defaultValue);
  const [invalid, setInvalid] = useState(false);
  const [focus, setFocus] = useState(false);

  return (
    <div>
      <Row>
        <TextInput
          type={type}
          placeholder={placeholder}
          value={defaultValue}
          onDebounced={txt => setText(txt)}
          onValidate={isValid => setInvalid(!isValid)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          inputStyle={style.input}
        />

        <StyledButton
          focus={focus}
          onClick={() => onClick(text)}
          disabled={invalid || !text || !text.length}
          style={style.button}
        >
          {buttonLabel}
        </StyledButton>
      </Row>

      {invalid && (
        <Row>
          <ValidationMsg>{validationMessage}</ValidationMsg>
        </Row>
      )}
    </div>
  );
};

ButtonField.defaultProps = {
  buttonLabel: 'Default',
  onClick: val => console.log('click', val),
  validationMessage: 'Invalid.',
};

export default ButtonField;
