import React from 'react';
import { member } from '../../constants/routes';
import Item from '../admin/shared/Item';
import DisplayName from '../admin/shared/DisplayName';
import Date from '../admin/shared/Date';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 2 1 200px;
`;

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 100px;
`;

const StyledItem = styled(Item)`
  padding: 12px;
`;

const ResourceItem = withRouter(({ resource, match }) => {
  const { categoryId } = match.params;

  return (
    <StyledItem>
      <LeftWrapper>
        <DisplayName
          style={{ width: 'auto' }}
          to={`${member.MEMBER_HOME}/view/${categoryId}/${resource.id}`}
        >
          {resource.displayName || 'No Display Name'}
        </DisplayName>
      </LeftWrapper>
      <RightWrapper>
        <Date date={resource.date} />
      </RightWrapper>
    </StyledItem>
  );
});

export default ResourceItem;
