import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components/macro';
import { GroupsContext } from '../../../contexts/groups';

const GroupWrapper = styled.div`
  text-align: right;
  font-weight: 400;
  font-style: italic;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 14px;
  box-sizing: border-box;
`;
const GroupString = ({ groups }) => {
  const { byId } = useContext(GroupsContext);

  const [groupStr, setGroupStr] = useState('No Groups Assigned');

  useEffect(() => {
    if (groups && groups.length) {
      setGroupStr(
        groups
          .map(g => {
            // If the group is a reference, look up the info by the id's from the
            // GroupsProvider
            if (g.hasOwnProperty('firestore') && byId[g.id]) {
              return byId[g.id].displayName;
            }
            return g.displayName;
          })
          .join(', '),
      );
    }
  }, [byId, groups]);

  return <GroupWrapper>{groupStr}</GroupWrapper>;
};

export default GroupString;
