import React, { useState, useEffect, useContext } from 'react';
import Header from '../../shared/Header2';
import Canvas from '../shared/Canvas';
import CheckRow from '../shared/CheckRow';
import Row from '../shared/Row';
import TextInput from '../../shared/TextInput';
import Label from '../../shared/Label';
import CheckboxItem from '../../shared/CheckboxItem';
import Button from '../../shared/Button';
import { PreferencesContext } from '../../../contexts/preferences';
import { FirebaseContext } from '../../../contexts/firebase';
import Spinner from '../../shared/Spinner';
import { admin } from '../../../constants/routes';
import { MessagesContext } from '../../../contexts/messages';
import GroupsCheckList from '../groups/GroupsCheckList';
import { UsersContext } from '../../../contexts/users';
import TrustorDropDown from './TrustorList';
import useUpdateFirebaseAuth from '../../../hooks/useUpdateFirebaseAuth';

const UserAdd = ({ history }) => {
  const { user, database } = useContext(FirebaseContext);
  const { apiHost } = useContext(PreferencesContext);
  const { addMessage } = useContext(MessagesContext);
  const { refreshUsers } = useContext(UsersContext);

  const [email, setEmail] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [options, setOptions] = useState({
    isActive: true,
    isAdmin: false,
  });
  const [groups, setGroups] = useState([]);
  const [phone, setPhone] = useState();
  const [valid, setValid] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [inviting, setInviting] = useState(false);
  const [trustor, setTrustor] = useState(null);
  const [sendInvite, setSendInvite] = useState(true);

  const updateFirebaseAuth = useUpdateFirebaseAuth();

  /**
   * Validate the form
   */
  useEffect(() => {
    const isValid = email.length > 0 && validEmail && displayName.length > 0;
    setValid(isValid);
  }, [email, validEmail, displayName]);

  /**
   * Invite the user
   */
  const inviteUser = async () => {
    const payload = {
      email,
      displayName,
      phoneNumber: phone,
      profile: options,
      groups: groups.map(g => g.id),
      sendInvite,
    };

    if (trustor) {
      payload.trustor = trustor.id;
    }

    console.debug('Create User Payload:', payload);

    setInviting(true);

    const idToken = await user.getIdToken();
    let res;
    try {
      res = await fetch(apiHost + '/admin/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `IdToken ${idToken}`,
        },
        body: JSON.stringify(payload),
      });
    } catch (e) {
      setInviting(false);
      console.error(e);
      return addMessage(['Error contacting the API.', e], 'error');
    }

    if (res.status > 400) {
      const message = await res.text();
      addMessage(['Unable to create user.', message], 'warn');
      setInviting(false);
      return;
    }

    const userId = await res.text();

    // save public information
    try {
      let payload = { displayName };
      if (trustor) {
        payload.trustor = trustor.ref;
      }

      console.debug('Public Payload', payload);
      await database.doc(`/users/${userId}/public/info`).set(payload);
    } catch (e) {
      addMessage('Unable to add public information.', 'warn');
    }

    // if the user is not active, disable the user account
    if (!options.isActive) {
      await updateFirebaseAuth(userId, 'disabled', false);
    }

    refreshUsers();

    setInviting(false);

    history.push(admin.USERS + '/' + userId);

    return addMessage('Successfully created user.');
  };

  return (
    <Canvas style={{ alignItems: 'flex-start' }}>
      <Row style={{ marginBottom: 45 }}>
        <Header>Create User</Header>
      </Row>

      <TextInput
        label="* Email:"
        type="email"
        placeholder="Required Field. Example: smith@example.com"
        validationMessage="Please enter a valid email address. Example: name@example.com"
        onDebounced={setEmail}
        onValidate={setValidEmail}
      />

      <TextInput
        label="* Display Name:"
        placeholder="Required Field. Example: John Smith"
        onDebounced={setDisplayName}
      />

      <TextInput
        label="Phone Number:"
        placeholder="Example: 123-456-7890"
        onDebounced={setPhone}
      />

      <TrustorDropDown onChange={setTrustor} />

      <Row style={{ marginBottom: 16 }}>
        <Label>Options:</Label>
      </Row>

      <CheckRow>
        <CheckboxItem
          label="Active"
          checked={options.isActive}
          onChange={checked => setOptions({ ...options, isActive: checked })}
        />
        <CheckboxItem
          label="Administrator"
          checked={options.isAdmin}
          onChange={checked => setOptions({ ...options, isAdmin: checked })}
        />
        <CheckboxItem
          label="Send Invitation Email"
          checked={sendInvite}
          onChange={checked => setSendInvite(checked)}
        />
      </CheckRow>

      <GroupsCheckList
        onChange={(group, checked) => {
          if (checked) {
            setGroups(prev => prev.concat([group]));
          } else {
            setGroups(prev => prev.filter(g => g.id !== group));
          }
        }}
      />

      {!inviting && (
        <Row>
          <Button invert onClick={() => history.push(admin.USERS)}>
            Cancel
          </Button>

          <Button disabled={!valid} onClick={inviteUser}>
            Add User
          </Button>

          {inviting && <Spinner />}
        </Row>
      )}
    </Canvas>
  );
};

export default UserAdd;
