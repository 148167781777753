import React, { useEffect, useContext } from 'react';
import { member } from '../../constants/routes';
import { FirebaseContext } from '../../contexts/firebase';
import { Paragraph } from '../shared/Paragraph';
import Header from '../shared/Header';
import { Wrapper, LogoWrapper, Img } from '../admin/Home';
import imgLogo from '../../assets/img/logoBio.png';

const NotAuthorized = ({ history, match }) => {
  const { profile } = useContext(FirebaseContext);
  /**
   * If the user becomes active, redirect to the member home
   */
  useEffect(() => {
    console.debug('Profile Updated', profile, match);
    if (profile.isActive && match.path === member.NOT_AUTHORIZED) {
      return history.push(member.MEMBER_HOME);
    }
  }, [history, match, profile]);

  return (
    <Wrapper>
      <LogoWrapper>
        <Img src={imgLogo} />
      </LogoWrapper>
      <Header style={{ marginBottom: 42 }}>Unauthorized</Header>

      <Paragraph>
        Unfortunately, you have not yet been granted access to the Reta Vault.
      </Paragraph>

      <Paragraph>
        If you believe this is an error, please contact the Reta Leadership Team
        Administrators to be granted access.
      </Paragraph>
    </Wrapper>
  );
};

export default NotAuthorized;
