// scrolling layout
export const pubScroll = {
  ROOT: '/',
  TRUSTORS: '/trustors',
  TEAM: '/leadership/team',
  TEAM_BIO: '/leadership/team/bio/:bioId',
  CONTACT_FORM: '/contact',
  PILLARS: '/pillars',
  PILLAR: '/pillars/:pillarId',
  LOGIN: '/login',
  LOGIN_DEMO: '/login_demo',
  POST_LOGIN: '/post-login',
  LOGOUT: '/logout',
};

export const pub = {
  HOME: '/public',
  OUR_MISSION: '/public/about/ourMission',
  OUR_HISTORY: '/public/about/ourHistory',
  OUR_HISTORY_CONT: '/public/about/ourHistoryCont',
  ADVANTAGE: '/public/advantage',
  MEMBER_ORGS: '/public/orgs',
  TRUSTOR_LIST: '/public/orgs/trustor-list',
  LEADERSHIP: '/public/leadership',
  CONTACT_US: '/public/contactUs',
  CONTACT_FORM: '/public/contact',
  TEAM: '/public/leadership/team',
  TEAM_BIO: '/public/leadership/team/bio/:bioId',
  LOGIN: '/public/login',
  LOGOUT: '/public/logout',
  HISTORY: '/public/history',
};

export const member = {
  MEMBER_HOME: '/member',
  MEMBER_VIEW: '/member/view/:categoryId/:resourceId',
  MEMBER_BIO_VIEW: '/member/view/bios/:bioId',
  PROFILE: '/member/profile',
  NOT_AUTHORIZED: '/member/not-authorized',
};

export const admin = {
  HOME: '/member/admin',

  USERS: '/member/admin/users',
  USER_ADD: '/member/admin/users/add',
  USER: '/member/admin/users/:userId',
  USER_DELETE: '/member/admin/users/:userId/delete',

  GROUPS: '/member/admin/groups',
  GROUP: '/member/admin/groups/:groupId',
  GROUP_DELETE: '/member/admin/groups/:groupId/delete',
  GROUP_ADD: '/member/admin/groups/add',

  CATEGORIES: '/member/admin/categories',
  CATEGORY: '/member/admin/categories/:categoryId',
  CATEGORY_DELETE: '/member/admin/categories/:categoryId/delete',
  CATEGORY_ADD: '/member/admin/categories/add',

  RESOURCES: '/member/admin/categories/:categoryId/resources',
  RESOURCE: '/member/admin/categories/:categoryId/resources/:resourceId',
  RESOURCE_DELETE:
    '/member/admin/categories/:categoryId/resources/:resourceId/delete',
  RESOURCE_ADD: '/member/admin/categories/:categoryId/resources/add',

  TRUSTORS: '/member/admin/trustors',
  TRUSTOR: '/member/admin/trustors/:trustorId',
  TRUSTOR_DELETE: '/member/admin/trustors/:trustorId/delete',
  TRUSTOR_ADD: '/member/admin/trustors/add',

  CAROUSELS: '/member/admin/carousel',
  CAROUSEL: '/member/admin/carousel/:carouselId',
  CAROUSEL_DELETE: '/member/admin/carousel/:carouselId/delete',
  CAROUSEL_ADD: '/member/admin/carousel/add',

  NEWSLETTERS: '/member/admin/newsletters',
  NEWSLETTER: '/member/admin/newsletters/:newsletterId',
  NEWSLETTER_DELETE: '/member/admin/newsletters/:newsletterId/delete',
  NEWSLETTER_ADD: '/member/admin/newsletters/add',

  CONTACTS: '/member/admin/contacts',

  BULLETINS: '/member/admin/bulletins',
  BULLETIN: '/member/admin/bulletins/:bulletinId',
  BULLETIN_DELETE: '/member/admin/bulletins/:bulletinId/delete',
  BULLETIN_ADD: '/member/admin/bulletins/add',
};

export const vault = {
  HOME: '/vault',
  VIEW: '/vault/view/:categoryId/:resourceId',
  NOT_AUTHORIZED: '/vault/not-authorized',

  admin: {
    HOME: '/vault/admin',

    CATEGORIES: '/vault/admin/categories',
    CATEGORY: '/vault/admin/categories/:categoryId',
    CATEGORY_DELETE: '/vault/admin/categories/:categoryId/delete',
    CATEGORY_ADD: '/vault/admin/categories/add',

    RESOURCES: '/vault/admin/categories/:categoryId/resources',
    RESOURCE: '/vault/admin/categories/:categoryId/resources/:resourceId',
    RESOURCE_DELETE:
      '/vault/admin/categories/:categoryId/resources/:resourceId/delete',
    RESOURCE_ADD: '/vault/admin/categories/:categoryId/resources/add',
  },
};
