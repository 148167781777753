import React from 'react';
import { vault } from '../../../constants/routes';
import styled from 'styled-components/macro';
import AdminItem from '../../admin/AdminItem';
import { MOBILE_WIDTH } from '../../../constants/dimensions';

const Wrapper = styled.div`
  /* flex: 0 1 210px; */
  width: 100%;
  height: auto;
  color: white;
  background-color: #495b5e;
  padding: 25px 35px;
  box-sizing: border-box;
  overflow-y: auto;

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    max-height: 210px;
  }
`;

const H3 = styled.div`
  font-family: Titillium;
  font-size: 27px;
  color: #e2ebe9;
  margin-bottom: 22px;
`;

const AdminMenu = () => {
  return (
    <Wrapper>
      <H3>Administrators</H3>

      <AdminItem to={vault.admin.CATEGORIES}>Categories</AdminItem>
    </Wrapper>
  );
};

export default AdminMenu;
