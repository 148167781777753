import React, { useState, useContext, useEffect } from 'react';
import Canvas from '../shared/Canvas';
import TextInput from '../../shared/TextInput';
import Label from '../../shared/Label';
import CheckboxItem from '../../shared/CheckboxItem';
import { FirebaseContext } from '../../../contexts/firebase';
import { MessagesContext } from '../../../contexts/messages';
import Header from '../../shared/Header2';
import CheckRow from '../shared/CheckRow';
import Spinner from '../../shared/Spinner';
import GroupsCheckList from '../groups/GroupsCheckList';
import Resources from '../resources/Resources';
import TextArea from '../../shared/TextArea';
import Delete from '../shared/Delete';
import styled from 'styled-components/macro';

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  margin-bottom: 24px;
`;

const CategoryUpdate = ({ match }) => {
  const { firebase, database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const [initialized, setInitialized] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [category, setCategory] = useState({});

  /**
   * Subscribe to the category
   */
  useEffect(() => {
    setFetching(true);
    const unsub = database
      .doc(`/categories/${match.params.categoryId}`)
      .onSnapshot(
        doc => {
          setFetching(false);
          setInitialized(true);

          if (!doc.exists) {
            addMessage('Unable to get category.', 'error');
            return;
          }

          setCategory(doc.data());
        },
        e => {
          console.error(e);
          addMessage('Unable to get category.', 'error');
          setFetching(false);
          setInitialized(true);
        },
      );

    return () => unsub && typeof unsub === 'function' && unsub();
  }, [addMessage, database, match.params.categoryId]);

  /**
   * Update the information in the user profile:w
   *
   * @param {string} path Firebase field to update
   * @param {*} value Value for the firebase field
   * @param {function} setSpinner enable or disable the spinner
   */
  const update = async (path, value, setSpinner) => {
    setSpinner(true);

    // if blank, remove the field
    if (typeof value !== 'boolean' && (!value || !value.length)) {
      value = firebase.firestore.FieldValue.delete();
    }

    try {
      await database
        .doc(`/categories/${match.params.categoryId}`)
        .update({ [path]: value });
      addMessage(`Successfully updated "${category.displayName}".`);
    } catch (e) {
      addMessage(`Unable to update "${category.displayName}".`, 'error');
    }

    setSpinner(false);
  };

  /**
   * Update Group status
   *
   * @param {object} group Information about the group
   * @param {boolean} checked is the group selected
   * @param {function} setSpinner enable or disable the spinner
   */
  const handleUpdateGroup = async (group, checked, setSpinner) => {
    setSpinner(true);

    const prevGroups = category.groups;
    console.debug('Prev Groups', category.groups);

    let updatedGroups = [];
    if (checked) {
      console.debug('Adding Group:', group);
      updatedGroups = prevGroups.concat([group.ref]);
    } else {
      console.debug('Removing Group:', group);
      updatedGroups = prevGroups.filter(g => g.id !== group.id);
    }

    console.debug('Updated Groups:', updatedGroups);
    await database
      .doc(`/categories/${match.params.categoryId}`)
      .update('groups', updatedGroups);

    addMessage(`Successfully update groups for "${category.displayName}".`);

    setSpinner(false);
  };

  const catExists = Object.keys(category).length > 0;

  return (
    <Canvas>
      <Row style={{ marginBottom: 45 }}>
        <Header>Update Category Information</Header>
        <Delete to={`${match.url}/delete`} />
      </Row>

      {fetching && (
        <Spinner size={20} message="Fetching category information..." />
      )}

      {initialized && !fetching && !catExists && (
        <div>Unable to locate category.</div>
      )}

      {initialized && !fetching && catExists && (
        <React.Fragment>
          {/* Display Name */}
          <TextInput
            label="Display Name:"
            placeholder="Example: Calendar"
            value={category.displayName}
            onConfirm={(v, setSpinner) => update('displayName', v, setSpinner)}
          />

          <TextArea
            label="Notes:"
            style={{ marginBottom: 42 }}
            onConfirm={(v, setSpinner) => update('notes', v, setSpinner)}
            placeholder="(optional)"
            value={category.notes}
          />

          {/* Options */}
          <Row style={{ marginBottom: 16 }}>
            <Label>Options:</Label>
          </Row>

          <CheckRow>
            <CheckboxItem
              label="Visible"
              checked={category.visible}
              onChange={(checked, setSpinner) =>
                update('visible', checked, setSpinner)
              }
            />
            <CheckboxItem
              label="Show Archive"
              checked={category.showArchive}
              onChange={(checked, setSpinner) =>
                update('showArchive', checked, setSpinner)
              }
            />
          </CheckRow>

          {/* Groups */}
          <GroupsCheckList
            selectedIds={category.groups && category.groups.map(g => g.id)}
            onChange={handleUpdateGroup}
          />

          {/* Resource List  */}
          <Resources categoryId={match.params.categoryId} />
        </React.Fragment>
      )}
    </Canvas>
  );
};

export default CategoryUpdate;
