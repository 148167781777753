import React, { useState, useContext, useEffect } from 'react';
import Canvas from '../shared/Canvas';
import TextInput from '../../shared/TextInput';
import { FirebaseContext } from '../../../contexts/firebase';
import { MessagesContext } from '../../../contexts/messages';
import Header from '../../shared/Header2';
import Spinner from '../../shared/Spinner';
import TextArea from '../../shared/TextArea';
import Delete from '../shared/Delete';
import styled from 'styled-components/macro';

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  margin-bottom: 24px;
`;

const GroupUpdate = ({ match }) => {
  const { groupId } = match.params;

  const { firebase, database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const [initialized, setInitialized] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [group, setGroup] = useState({});

  /**
   * Subscribe to the groups
   */
  useEffect(() => {
    setFetching(true);
    const unsub = database.doc(`/groups/${groupId}`).onSnapshot(
      doc => {
        setFetching(false);
        setInitialized(true);

        if (!doc.exists) {
          addMessage('Unable to get group.', 'error');
          return;
        }

        setGroup(doc.data());
      },
      e => {
        console.error(e);
        addMessage('Unable to get group.', 'error');
        setFetching(false);
        setInitialized(true);
      },
    );

    return () => unsub && typeof unsub === 'function' && unsub();
  }, [addMessage, database, groupId]);

  /**
   * Update the group information
   *
   * @param {string} path Firebase field to update
   * @param {*} value Value for the firebase field
   * @param {function} setSpinner enable or disable the spinner
   */
  const update = async (path, value, setSpinner) => {
    setSpinner(true);

    // if blank, remove the field
    if (typeof value !== 'boolean' && (!value || !value.length)) {
      value = firebase.firestore.FieldValue.delete();
    }

    try {
      await database.doc(`/groups/${groupId}`).update({ [path]: value });
      addMessage(`Successfully updated "${group.displayName}".`);
    } catch (e) {
      addMessage(`Unable to update "${group.displayName}".`, 'error');
    }

    setSpinner(false);
  };

  const groupExists = Object.keys(group).length > 0;

  return (
    <Canvas>
      <Row style={{ marginBottom: 45 }}>
        <Header>Update Group Information</Header>
        <Delete to={`${match.url}/delete`} />
      </Row>

      {(fetching || !group) && (
        <Spinner size={20} message="Fetching group information..." />
      )}

      {initialized && !fetching && !groupExists && (
        <div>Unable to locate group.</div>
      )}

      {initialized && !fetching && groupExists && (
        <React.Fragment>
          {/* Display Name */}
          <TextInput
            label="Display Name:"
            placeholder="Example: Calendar"
            value={group.displayName}
            onConfirm={(v, setSpinner) => update('displayName', v, setSpinner)}
          />

          <TextArea
            label="Notes:"
            style={{ marginBottom: 42 }}
            onConfirm={(v, setSpinner) => update('notes', v, setSpinner)}
            placeholder="Optional group notes"
            value={group.notes}
          />
        </React.Fragment>
      )}
    </Canvas>
  );
};

export default GroupUpdate;
