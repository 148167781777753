import React, { useState, useContext } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import imgAdd from '../../assets/img/item-add.svg';
import imgConfig from '../../assets/img/item-config.svg';
import imgDel from '../../assets/img/item-delete.svg';
import { admin, vault } from '../../constants/routes';
import { FirebaseContext } from '../../contexts/firebase';
import { useDrag, useDrop } from 'react-dnd';
import { Container, StyledLink } from './ResourceItem';
import arrayMove from 'array-move';
import Promise from 'bluebird';

const DragResource = styled(Container)`
  justify-content: space-between;
  position: relative;
  padding: 0;

  &.no-docs .placeholder {
    color: #9c8388;
    font-style: italic;
  }

  &:hover {
    background-color: #ececec;
  }
`;

const DropResource = styled.div`
  padding: 6px;
  box-sizing: border-box;
  border: 1px dashed rgba(0, 0, 0, 0);

  &.dragging {
    border-radius: 4px;
    border: 1px dashed #98b7bf;

    & a {
      color: #4a5a5e;
    }
  }

  &.over {
    background-color: #befffe;
    border-radius: 4px;
    border: 1px dashed #98b7bf;

    & a {
      color: #4a5a5e;
    }
  }
`;

const Left = styled.div`
  display: flex;
  flex: 1 1 100px;
`;

const Right = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  right: 0;
  height: 100%;
  text-align: right;
  background-color: #ececece0;
`;

const AdminLink = styled(Link)`
  padding-left: 4px;
  box-sizing: border-box;

  &:last-child {
    padding-right: 4px;
  }
`;

export const AdminItem = ({ src, alt, ...props }) => {
  return (
    <AdminLink {...props}>
      <img src={src} alt={alt} />
    </AdminLink>
  );
};

const ResourceItem = ({
  categoryId,
  resource,
  onDragging = () => {},
  order,
  allResources,
  isVault = false,
  ...props
}) => {
  const { profile } = useContext(FirebaseContext);

  const [showAdmin, setShowAdmin] = useState(false);

  const [{ opacity }, dragRef] = useDrag({
    item: { type: `RESOURCE_${categoryId}`, resource, order },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.25 : 1,
      // isDragging: setIsDragging(monitor.isDragging()),
    }),
  });

  const handleDrop = async item => {
    if (!allResources.length) {
      return;
    }
    await Promise.map(
      arrayMove(allResources, item.order, order),
      async (d, id) => {
        await d.ref.update({ order: id });
      },
    );
  };

  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: `RESOURCE_${categoryId}`,
    drop: handleDrop,
    collect: monitor => {
      const item = monitor.getItem();

      return {
        isOver:
          item &&
          item.resource &&
          item.resource.id !== resource.id &&
          monitor.isOver(),
        // highlight drop targets if it's not the current item being dragged
        canDrop:
          item &&
          item.resource &&
          item.resource.id !== resource.id &&
          monitor.canDrop(),
      };
    },
  });

  const handleShowAdmin = () => {
    if (isVault) {
      profile.vault?.isAdmin && setShowAdmin(true);
    } else {
      profile.isAdmin && setShowAdmin(true);
    }
  };

  const handleHideAdmin = () => setShowAdmin(false);

  let className = '';
  if (isOver) {
    className += 'over ';
  }
  if (canDrop) {
    className += 'dragging ';
  }

  return (
    <DragResource
      className={props.className}
      style={{ opacity }}
      ref={dragRef}
      onMouseEnter={handleShowAdmin}
      onMouseLeave={handleHideAdmin}
    >
      <DropResource className={className} ref={dropRef}>
        <Left>
          {props.to && (
            <StyledLink {...props}>{resource.displayName}</StyledLink>
          )}

          {!props.to && (
            <div className="placeholder">{resource.displayName}</div>
          )}
        </Left>

        {showAdmin && (
          <Right>
            <AdminItem
              src={imgAdd}
              alt="+"
              to={`${
                isVault ? vault.admin.CATEGORIES : admin.CATEGORIES
              }/${categoryId}/resources/add/${order + 1}`}
            />
            {resource.id && (
              <>
                <AdminItem
                  src={imgConfig}
                  alt="o"
                  to={`${
                    isVault ? vault.admin.CATEGORIES : admin.CATEGORIES
                  }/${categoryId}/resources/${resource.id}`}
                />
                <AdminItem
                  src={imgDel}
                  alt="x"
                  to={`${
                    isVault ? vault.admin.CATEGORIES : admin.CATEGORIES
                  }/${categoryId}/resources/${resource.id}/delete`}
                />
              </>
            )}
          </Right>
        )}
      </DropResource>
    </DragResource>
  );
};

export default ResourceItem;
