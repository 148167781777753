import React, { useState, useEffect } from 'react';
import { admin } from '../../../constants/routes';
import Delete from '../../shared/Delete';
import Item from '../shared/Item';
import DisplayName from '../shared/DisplayName';
import Date from '../shared/Date';
import { withRouter } from 'react-router-dom';
import Checkbox from '../../shared/Checkbox';
import imgAdd from '../../../assets/img/add-item.svg';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd';
import arrayMove from 'array-move';
import Promise from 'bluebird';

const DropZone = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 12px;
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 2 1 200px;
`;

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 100px;
`;

const ResourceItem = withRouter(({ resource, allResources, match, order }) => {
  const { categoryId } = match.params;

  const [isDragging, setIsDragging] = useState(false);
  const [classNames, setClassNames] = useState('');

  /**
   * Set the drag event
   */
  const [{ opacity }, dragRef] = useDrag({
    item: { type: `RESOURCE_ITEM`, resource, order },
    collect: monitor => {
      setIsDragging(monitor.isDragging());

      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
      };
    },
  });

  /**
   * Setup the drop event
   */
  const handleDrop = async item => {
    await Promise.map(
      arrayMove(allResources, item.order, order),
      async (d, id) => {
        await d.ref.update({ order: id });
      },
    );
  };

  const [{ isOver }, dropRef] = useDrop({
    accept: `RESOURCE_ITEM`,
    drop: handleDrop,
    collect: monitor => {
      return {
        isOver: monitor.isOver(),
      };
    },
  });

  /**
   * Assemble the class names
   */
  useEffect(() => {
    let classes = '';
    if (isOver) {
      classes += 'over ';
    }

    if (isDragging) {
      classes += 'dragging ';
    }

    setClassNames(classes);
  }, [isDragging, isOver]);

  return (
    <Item className={classNames} ref={dragRef} style={{ opacity }}>
      <DropZone ref={dropRef} className={classNames}>
        <LeftWrapper>
          <Checkbox style={{ marginRight: 12 }} checked={resource.archive} />
          <DisplayName
            style={{ width: 'auto' }}
            to={`${admin.CATEGORIES}/${categoryId}/resources/${resource.id}`}
          >
            {resource.displayName || 'No Display Name'}
          </DisplayName>
        </LeftWrapper>
        <RightWrapper>
          <Date date={resource.date} />
          <Link
            to={`${admin.CATEGORIES}/${categoryId}/resources/add/${order || 1}`}
          >
            <img src={imgAdd} alt="+" />
          </Link>
          <Delete
            to={`${admin.CATEGORIES}/${categoryId}/resources/${resource.id}/delete`}
            style={{ marginLeft: 12 }}
          />
        </RightWrapper>
      </DropZone>
    </Item>
  );
});

export default ResourceItem;
