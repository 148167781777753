import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { admin } from '../../constants/routes';
import NoMatch from '../shared/NoMatch';
import Users from './users/Users';
import UserAdd from './users/UserAdd';
import UserUpdate from './users/UserUpdate';
import UserDelete from './users/UserDelete';
import Groups from './groups/Groups';
import GroupAdd from './groups/GroupAdd';
import GroupUpdate from './groups/GroupUpdate';
import GroupDelete from './groups/GroupDelete';
import Categories from './categories/Categories';
import CategoryAdd from './categories/CategoryAdd';
import CategoryUpdate from './categories/CategoryUpdate';
import ResourceAdd from './resources/ResourceAdd';
import ResourceUpdate from './resources/ResourceUpdate';
import ResourceDelete from './resources/ResourceDelete';
import CategoryDelete from './categories/CategoryDelete';
import UsersProvider from '../../contexts/users';
import Trustors from './trustors/Trustors';
import TrustorAdd from './trustors/TrustorAdd';
import TrustorDelete from './trustors/TrustorDelete';
import TrustorUpdate from './trustors/TrustorUpdate';
import Carousel from './carousel/Carousel';
import CarouselAdd from './carousel/CarouselAdd';
import CarouselUpdate from './carousel/CarouselUpdate';
import CarouselDelete from './carousel/CarouselDelete';
import NewsletterAdd from './newsletter/NewsletterAdd';
import NewsletterDelete from './newsletter/NewsletterDelete';
import Newsletters from './newsletter/Newsletters';
import NewsletterUpdate from './newsletter/NewsletterUpdate';
import BulletinAdd from './bulletins/BulletinAdd';
import BulletinsDelete from './bulletins/BulletinDelete';
import Bulletins from './bulletins/Bulletins';
import BulletinUpdate from './bulletins/BulletinUpdate';
import Contacts from './contacts/Contacts';

const Routes = () => {
  return (
    <Switch>
      <Route exact path={admin.HOME}>
        <Redirect to={admin.CATEGORIES} />
      </Route>
      {/* Categories */}
      <Route exact path={admin.CATEGORIES} component={Categories} />
      <Route exact path={admin.CATEGORY_ADD} component={CategoryAdd} />
      <Route
        exact
        path={`${admin.CATEGORY_ADD}/:orderId`}
        component={CategoryAdd}
      />
      <Route exact path={admin.CATEGORY} component={CategoryUpdate} />
      <Route exact path={admin.CATEGORY_DELETE} component={CategoryDelete} />
      {/* Resources */}
      <Route exact path={admin.RESOURCE_ADD} component={ResourceAdd} />
      <Route
        exact
        path={`${admin.RESOURCE_ADD}/:orderId`}
        component={ResourceAdd}
      />
      <Route exact path={admin.RESOURCE} component={ResourceUpdate} />
      <Route exact path={admin.RESOURCE_DELETE} component={ResourceDelete} />
      {/* Groups */}
      <Route exact path={admin.GROUPS} component={Groups} />
      <Route exact path={admin.GROUP_ADD} component={GroupAdd} />
      <Route exact path={admin.GROUP} component={GroupUpdate} />
      <Route exact path={admin.GROUP_DELETE} component={GroupDelete} />
      {/* Trustors */}
      <Route exact path={admin.TRUSTORS} component={Trustors} />
      <Route exact path={admin.TRUSTOR_ADD} component={TrustorAdd} />
      <Route exact path={admin.TRUSTOR} component={TrustorUpdate} />
      <Route exact path={admin.TRUSTOR_DELETE} component={TrustorDelete} />
      {/* Carousel */}
      <Route exact path={admin.CAROUSELS} component={Carousel} />
      <Route exact path={admin.CAROUSEL_ADD} component={CarouselAdd} />
      <Route exact path={admin.CAROUSEL} component={CarouselUpdate} />
      <Route exact path={admin.CAROUSEL_DELETE} component={CarouselDelete} />
      {/* Newsletter */}
      <Route exact path={admin.NEWSLETTERS} component={Newsletters} />
      <Route exact path={admin.NEWSLETTER_ADD} component={NewsletterAdd} />
      <Route exact path={admin.NEWSLETTER} component={NewsletterUpdate} />
      <Route
        exact
        path={admin.NEWSLETTER_DELETE}
        component={NewsletterDelete}
      />

      {/* Bulletins */}
      <Route exact path={admin.BULLETINS}>
        <Bulletins />
      </Route>
      <Route exact path={admin.BULLETIN_ADD}>
        <BulletinAdd />
      </Route>
      <Route exact path={admin.BULLETIN}>
        <BulletinUpdate />
      </Route>
      <Route exact path={admin.BULLETIN_DELETE}>
        <BulletinsDelete />
      </Route>

      {/* Users */}
      <UsersProvider>
        <Switch>
          <Route exact path={admin.USERS} component={Users} />
          <Route exact path={admin.USER_ADD} component={UserAdd} />
          <Route exact path={admin.USER} component={UserUpdate} />
          <Route exact path={admin.USER_DELETE} component={UserDelete} />

          <Route exact path={admin.CONTACTS} component={Contacts} />
        </Switch>
      </UsersProvider>

      <Route path="*" component={NoMatch} />
    </Switch>
  );
};

export default Routes;
