import React from 'react';
import { admin } from '../../../constants/routes';
import Delete from '../../shared/Delete';
import GroupString from '../groups/GroupString';
import Item from '../shared/Item';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';

const StyledItem = styled(Item)`
  padding: 12px;
  box-sizing: border-box;
  color: #495b5e;

  &.disabled {
    color: white !important;
    background-color: #c5ccca !important;
    font-style: italic !important;
  }
`;

const LeftWrapper = styled.div`
  flex: 2 1 200px;
  cursor: pointer;
`;

const DisplayName = styled.div`
  font-size: 14px;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Email = styled.div`
  font-weight: light;
  font-size: 12px;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 200px;
  overflow: hidden;

  & > .delete {
    flex: 0 0 32px;
  }
`;

const UserItem = ({ user }) => {
  const history = useHistory();

  return (
    <StyledItem
      className={!user.profile || user.profile.isActive ? '' : 'disabled'}
    >
      <LeftWrapper onClick={() => history.push(admin.USERS + '/' + user.uid)}>
        {user.displayName && (
          <DisplayName style={{ lineHeight: 1.6 }}>
            {user.displayName}{' '}
          </DisplayName>
        )}
        <Email>{user.email || user.phoneNumber}</Email>
      </LeftWrapper>
      <RightWrapper>
        {user.profile && !user.profile.isActive && (
          <DisplayName>INACTIVE</DisplayName>
        )}
        {user.profile && user.profile.isActive && (
          <GroupString
            groups={user.profile.groups}
            style={{ paddingRight: 0 }}
          />
        )}
        <div className="delete">
          <Delete
            to={admin.USERS + '/' + user.uid + '/delete'}
            style={{ marginLeft: 12 }}
          />
        </div>
      </RightWrapper>
    </StyledItem>
  );
};

export default UserItem;
