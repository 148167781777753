import React, { useState, useContext, useEffect, useRef } from 'react';
import Header from '../shared/Header';
import { Paragraph } from '../shared/Paragraph';
import styled from 'styled-components/macro';
import ButtonField from '../shared/ButtonField';
import { FirebaseContext } from '../../contexts/firebase';
import { MessagesContext } from '../../contexts/messages';
import useLocalStorage from '../../hooks/useLocalStorage';
import Spinner from '../shared/Spinner';
import { useHistory } from 'react-router';
import { pubScroll } from '../../constants/routes.js';
import { Wrap } from '../../views/ScrollingLanding';
import { Toolbar } from '@material-ui/core';

const H4 = styled.div`
  font-family: Roboto;
  font-weight: bold;
  font-size: 1rem;
  margin: 12px 0;
  font-size: 18px;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Login = () => {
  const { isAuthenticated, auth } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);
  const history = useHistory();

  const [loggingIn, setLogginIn] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [signInEmail, setSignInEmail] = useLocalStorage('signInEmail');
  const [showVerification, setShowVerification] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(true);

  const ref = useRef();

  /**
   * if all ready authenticated, redirect to the member area
   */
  useEffect(() => {
    if (isAuthenticated) {
      history.push('/member');
    }
  }, [history, isAuthenticated]);

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be whitelisted in the Firebase Console.
    url: window.location.origin + pubScroll.POST_LOGIN,
    handleCodeInApp: true,
    iOS: {
      bundleId: 'org.retatrust.ios',
    },
    android: {
      packageName: 'org.retatrust.android',
      installApp: true,
      minimumVersion: '12',
    },
    dynamicLinkDomain:
      (process.env.NODE_ENV === 'development' &&
        process.env.REACT_APP_DYNAMIC_LINK) ||
      'retatrust.page.link',
  };

  const signInByEmail = async email => {
    setSignInEmail(email);
    setLogginIn(true);
    try {
      await auth.sendSignInLinkToEmail(email, actionCodeSettings);
      setCheckEmail(true);
    } catch (e) {
      console.error(e);
      addMessage(
        'There was a problem trying to login by email.  Check the console for additional information',
        'error',
      );
    }
    setLogginIn(false);
  };

  /**
   * Initiate SMS sign in
   * https://firebase.google.com/docs/auth/web/phone-auth
   */
  const signInByPhone = async phone => {
    setLogginIn(true);

    // remove miscellaneous characters from phone number
    phone = phone.replace(/[-\s()]/g, '');

    // create an element for the recaptcha
    const elem = document.createElement('div');
    elem.setAttribute('id', 'recaptcha');
    ref.current.appendChild(elem);

    // Recpatcha Verifier
    const applicationVerifier = new auth.RecaptchaVerifier(elem, {
      size: 'invisible',
      callback: async response => {
        console.log('reCAPTCHA solved', response);
      },
    });

    try {
      const confirmationResult = await auth.signInWithPhoneNumber(
        phone,
        applicationVerifier,
      );
      setConfirmationResult(confirmationResult);
      console.log('CONFIRMATION RESULT', confirmationResult);
    } catch (e) {
      console.error(e);
      addMessage(
        'There was a problem signing in by phone.  Check the console for more information.',
        'error',
      );
    }
    setLogginIn(false);
    setShowVerification(true);
    applicationVerifier.clear();
    ref.current.removeChild(elem);
  };

  /**
   * Submit SMS verification code
   */
  const submitVerificationCode = async code => {
    setLogginIn(true);
    try {
      await confirmationResult.confirm(code);
    } catch (e) {
      console.error(e);
      addMessage('Unable to verify the code.  Please enter it again.', 'warn');
      setLogginIn(false);
    }
  };

  return (
    <Wrap>
      <Toolbar />

      <div style={{ fontSize: 16, maxWidth: 700 }} ref={ref}>
        <Header style={{ marginBottom: 42, marginTop: 32 }}>
          Reta Leadership Login
        </Header>

        {loggingIn && (
          <Row>
            <Spinner />
          </Row>
        )}

        {/* Login form */}
        {!loggingIn && !checkEmail && !showVerification && (
          <React.Fragment>
            <H4>Login by email</H4>
            <Paragraph>
              To access private communications for the leadership team, please
              provide your email address. An email will be sent to you with a
              link to login.
            </Paragraph>
            <ButtonField
              type="email"
              style={{
                input: {
                  backgroundColor: '#e4e9f1',
                  border: '1px solid #526366',
                  minHeight: 48,
                },
                button: {
                  fontFamily: 'Roboto',
                  backgroundColor: '#2a407f',
                  minHeight: 48,
                },
              }}
              buttonLabel="Send Email"
              placeholder="Enter Email"
              validationMessage="Please enter a valid email address. Example: name@example.com"
              onClick={email => signInByEmail(email)}
            />

            <H4>or by SMS Text Message</H4>
            <Paragraph>
              If you have a phone number associated with your account, enter it
              below and we’ll send you a text message to login.{' '}
              <b>Standard text messaging rates apply</b>.
            </Paragraph>

            <ButtonField
              type="phone"
              style={{
                input: {
                  backgroundColor: '#e4e9f1',
                  border: '1px solid #526366',
                  minHeight: 48,
                },
                button: {
                  fontFamily: 'Roboto',
                  backgroundColor: '#2a407f',
                  minHeight: 48,
                },
              }}
              placeholder="Enter Phone Number"
              buttonLabel="Send Text"
              validationMessage="A valid phone number is required.  Example: +1 123-456-7890."
              onClick={phone => signInByPhone(phone)}
            />
          </React.Fragment>
        )}

        {/* Check email notification */}
        {checkEmail && (
          <Paragraph>
            An email has been sent to <b>{signInEmail}</b> with a link to
            complete the sign in process. Follow the instructions in the email
            to proceed.
          </Paragraph>
        )}

        {/* SMS Verification */}
        {showVerification && !loggingIn && (
          <React.Fragment>
            <Paragraph>
              You should receive a text message shortly. Please enter the 6
              digit verification code below.
            </Paragraph>

            <ButtonField
              type="verification-code"
              placeholder="Enter the 6 digit verification code"
              buttonLabel="Send Verification"
              validationMessage="Enter the verification code.  It should be at least six digits."
              onClick={code => submitVerificationCode(code)}
            />
          </React.Fragment>
        )}

        <div>
          <H4>Need help?</H4>
          <Paragraph style={{ margin: 0 }}>
            Call: <b>1.877.303.7382</b>
          </Paragraph>
          <Paragraph style={{ marginBottom: 12 }}>
            (Monday thru Friday 8:30am to 8:00pm ET)
          </Paragraph>
          <Paragraph style={{ margin: 0 }}>
            Email:&nbsp;
            <b>
              <a
                href="mailto:service@RetaEnroll.org"
                style={{ color: '#4d626a' }}
              >
                service@RetaEnroll.org
              </a>
            </b>
          </Paragraph>
        </div>
      </div>
    </Wrap>
  );
};

export default Login;
