import React, { useState, useEffect, useContext } from 'react';
import Header from '../../shared/Header2';
import Canvas from '../shared/Canvas';
import Row from '../shared/Row';
import TextInput from '../../shared/TextInput';
import TextArea from '../../shared/TextArea';
import Button from '../../shared/Button';
import { FirebaseContext } from '../../../contexts/firebase';
import Spinner from '../../shared/Spinner';
import { admin } from '../../../constants/routes';
import { MessagesContext } from '../../../contexts/messages';

const GroupAdd = ({ history }) => {
  const { database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const [displayName, setDisplayName] = useState('');
  const [valid, setValid] = useState(false);
  const [adding, setAdding] = useState(false);
  const [notes, setNotes] = useState('');

  /**
   * Validate the form
   */
  useEffect(() => {
    const isValid = displayName && displayName.length > 0;
    setValid(isValid);
  }, [displayName]);

  /**
   * Add the group
   */
  const handleAddGroup = async () => {
    setAdding(true);
    const data = { displayName };
    if (notes) {
      data.notes = notes;
    }
    try {
      await database.collection('/groups').doc().set(data);

      addMessage(`Successfully added group "${displayName}"`);
      setAdding(false);
      history.push(admin.GROUPS);
    } catch (e) {
      console.error(e);
      addMessage(`Unable to add group "${displayName}"`, 'error');
      setAdding(false);
    }
  };

  return (
    <Canvas>
      <Row style={{ marginBottom: 45 }}>
        <Header>Create Group</Header>
      </Row>

      <TextInput
        label="* Display Name:"
        placeholder="Required Field. Example: Finance Committee"
        onDebounced={setDisplayName}
      />

      <TextArea
        label="Notes:"
        style={{ marginBottom: 42 }}
        onDebounced={setNotes}
      />

      {!adding && (
        <Row>
          <Button invert onClick={() => history.push(admin.GROUPS)}>
            Cancel
          </Button>
          <Button disabled={!valid} onClick={handleAddGroup}>
            Add Group
          </Button>
        </Row>
      )}

      {adding && <Spinner />}
    </Canvas>
  );
};

export default GroupAdd;
