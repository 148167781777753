import React, { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from '../../../../contexts/firebase';
import { MessagesContext } from '../../../../contexts/messages';
import styled from 'styled-components/macro';
import ResourceItem from './ResourceItem';
import Spinner from '../../../shared/Spinner';
import HTML5Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

const Resource = styled.div`
  display: flex;
  align-items: center;
`;

const List = styled(Resource)`
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const ResourceList = ({ categoryId, filter = '' }) => {
  const { database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const [fetching, setFetching] = useState(false);
  const [resources, setResources] = useState([]);
  const [filteredResources, setFilteredResources] = useState([]);

  /**
   * Subscribe to the Resource List
   */
  useEffect(() => {
    let unsub;
    (async () => {
      setFetching(true);

      const col = await database
        .doc(`/vault_categories/${categoryId}`)
        .collection('resources')
        .orderBy('order', 'asc')
        .get();

      // bail out if it's an empty collection
      if (col.empty) {
        setFetching(false);
        return;
      }

      unsub = database
        .doc(`/vault_categories/${categoryId}`)
        .collection('resources')
        .onSnapshot(
          docs => {
            const resources = docs.docs
              .map(d => ({
                ...d.data(),
                id: d.id,
                ref: d.ref,
              }))
              .sort((a, b) => a.order - b.order);
            setResources(resources);
            setFetching(false);
          },
          e => {
            console.error(e);
            addMessage('Unable to fetch resource list', 'error');
            setFetching(false);
          },
        );
    })();

    return () => unsub && typeof unsub === 'function' && unsub();
  }, [addMessage, database, categoryId]);

  /**
   * Filter the resources list based on the search field
   */
  useEffect(() => {
    if (!filter && !resources.length) {
      return;
    }

    if (!filter) {
      return setFilteredResources(resources);
    }

    const regex = new RegExp(filter, 'i');

    const filtered = resources.filter(
      g => regex.test(g.displayName) || regex.test(g.email),
    );

    setFilteredResources(filtered);
  }, [resources, filter]);

  return (
    <List>
      {fetching && <Spinner />}

      {!fetching && (!filteredResources || !filteredResources.length) && (
        <div>No results found.</div>
      )}

      {!fetching && filteredResources && (
        <DndProvider backend={HTML5Backend}>
          {filteredResources
            .sort((a, b) => a.order - b.order)
            .map((resource, index) => (
              <ResourceItem
                key={index}
                order={index}
                resource={resource}
                allResources={resources}
              />
            ))}
        </DndProvider>
      )}
    </List>
  );
};

export default ResourceList;
