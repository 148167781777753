import React, { useState, useContext, useEffect } from 'react';
import Canvas from '../shared/Canvas';
import TextInput from '../../shared/TextInput';
import Label from '../../shared/Label';
import CheckboxItem from '../../shared/CheckboxItem';
import { FirebaseContext } from '../../../contexts/firebase';
import { MessagesContext } from '../../../contexts/messages';
import Header from '../../shared/Header2';
import CheckRow from '../shared/CheckRow';
import Spinner from '../../shared/Spinner';
import UploadFile from '../../shared/UploadFile';
import imgUser from '../../../assets/img/user.svg';
import styled from 'styled-components/macro';
import GroupsCheckList from '../groups/GroupsCheckList';
import TextArea from '../../shared/TextArea';
import Delete from '../shared/Delete';
import Bio from './Bio';
import TrustorDropDown from './TrustorList';
import { MOBILE_WIDTH } from '../../../constants/dimensions';
import useUpdateFirebaseAuth from '../../../hooks/useUpdateFirebaseAuth';
import { PreferencesContext } from '../../../contexts/preferences';
import Button from '../../shared/Button';
import Add from '../../shared/Add';
import { admin } from '../../../constants/routes';

export const ProfileImg = styled.div`
  /* width: 256px;
  height: 256px;
  border-radius: 128px; */
  width: 128px;
  height: 128px;
  border-radius: 64px;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: contain !important;
  background-position: center;
  background-color: #d8e6e8;
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }

  &.has-image {
    background-size: cover;
  }

  @media (max-width: ${MOBILE_WIDTH}px) {
    margin-bottom: 1.6em;
  }
`;

const Center = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  margin-bottom: 24px;
`;

export const ProfileRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
`;

const UserUpdate = ({ match }) => {
  const { userId } = match.params;

  const { user, firebase, database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);
  const { apiHost } = useContext(PreferencesContext);

  const [fetchedProfile, setFetchedProfile] = useState(false);
  const [ready, setReady] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [sendingInvite, setSendingInvite] = useState(false);

  const updateFirebaseAuth = useUpdateFirebaseAuth();

  /**
   * Subscribe to user profile changes
   */
  useEffect(() => {
    const docRef = database.doc(`/users/${userId}`);
    const unsub = docRef.onSnapshot(
      doc => {
        if (!doc.exists) {
          addMessage('Unable to locate user.', 'warn');
          return;
        }

        setSelectedUser(doc.data());
        setFetchedProfile(true);
      },
      e => {
        console.error(e);
        addMessage('Unable to get profile update.', 'error');
      },
    );

    return () => unsub();
  }, [addMessage, database, match, userId]);

  /**
   * Set `ready` when all data is fetched
   */
  useEffect(() => {
    if (fetchedProfile) {
      setReady(true);
    }
  }, [fetchedProfile]);

  /**
   * Update the information in the user profile
   *
   * @param {string} path Firebase field to update
   * @param {*} value Value for the firebase field
   * @param {function} setSpinner enable or disable the spinner
   */
  const updateProfile = async (path, value, setSpinner) => {
    setSpinner && setSpinner(true);

    // if blank, remove the field
    if (typeof value !== 'boolean' && (!value || !value.length)) {
      value = firebase.firestore.FieldValue.delete();
    }

    try {
      await database.doc(`/users/${userId}`).update({ [path]: value });
      addMessage(`Successfully updated "${selectedUser.displayName}".`);
    } catch (e) {
      addMessage(`Unable to update "${selectedUser.displayName}".`, 'error');
    }

    setSpinner && setSpinner(false);
  };

  const updatePublicInfo = async (path, value, setSpinner) => {
    setSpinner && setSpinner(true);

    // if blank, remove the field
    if (
      typeof value !== 'boolean' &&
      typeof value !== 'object' &&
      (!value || !value.length)
    ) {
      value = firebase.firestore.FieldValue.delete();
    }

    const docRef = database.doc(`/users/${userId}/public/info`);
    const doc = await docRef.get();

    // if the document doesn't exist create it
    if (!doc.exists) {
      try {
        await docRef.set({ [path]: value });
        addMessage(
          `Successfully added public info for "${selectedUser.displayName}".`,
        );
      } catch (e) {
        console.error(e);
        addMessage(
          `Unable to add public info for "${selectedUser.displayName}".`,
          'error',
        );
      }
    } else {
      // otherwise update the document
      try {
        await docRef.update({ [path]: value });
        addMessage(
          `Successfully updated public info for "${selectedUser.displayName}".`,
        );
      } catch (e) {
        console.error(e);
        addMessage(
          `Unable to update public info for "${selectedUser.displayName}".`,
          'error',
        );
      }
    }

    setSpinner && setSpinner(false);
  };

  /**
   * Updates the Firebase authentication information for a user
   *
   * @param {string} path firebase field to update
   * @param {*} value value for the firebase field
   * @param {function} setSpinner enable or disable the spinner
   */
  const updateAuth = async (path, value, setSpinner) => {
    setSpinner && setSpinner(true);

    updateFirebaseAuth(userId, path, value);

    // if blank, remove the field
    if (typeof value !== 'boolean' && (!value || !value.length)) {
      value = firebase.firestore.FieldValue.delete();
    }

    try {
      await database.doc(`/users/${userId}`).update({ [path]: value });
      addMessage(`Successfully updated "${selectedUser.displayName}".`);
    } catch (e) {
      console.error(e);
      addMessage(`Unable to update "${selectedUser.displayName}".`, 'error');
    }

    setSpinner && setSpinner(false);
  };

  /**
   * Update Group status for a user
   *
   * @param {object} group Information about the group
   * @param {boolean} checked is the group selected
   * @param {function} setSpinner enable or disable the spinner
   */
  const handleUpdateGroup = async (group, checked, setSpinner) => {
    setSpinner(true);

    const prevGroups = selectedUser.groups;
    console.debug('Prev Groups', selectedUser.groups);

    let updatedGroups = [];
    if (checked) {
      console.debug('Adding Group:', group);
      updatedGroups = prevGroups.concat([group.ref]);
    } else {
      console.debug('Removing Group:', group);
      updatedGroups = prevGroups.filter(g => g.id !== group.id);
    }

    console.debug('Updated Groups:', updatedGroups);
    await database.doc(`/users/${userId}`).update('groups', updatedGroups);

    setSpinner(false);
  };

  /**
   * Handle trustor updates
   *
   * @param {*} trustor
   * @param {*} setSpinner
   */
  const handleTrustor = async (trustor, setSpinner) => {
    setSpinner(true);

    // if blank, remove the field
    if (!trustor) {
      trustor = {
        ref: firebase.firestore.FieldValue.delete(),
        displayName: firebase.firestore.FieldValue.delete(),
      };
    }

    try {
      await database.doc(`/users/${userId}`).update({ trustor: trustor.ref });
      addMessage(`Successfully updated "${selectedUser.displayName}".`);
    } catch (e) {
      addMessage(`Unable to update "${selectedUser.displayName}".`, 'error');
    }

    // update the public info with the trustor
    await updatePublicInfo('trustor', trustor.ref);

    setSpinner(false);
  };

  const sendInvite = async () => {
    setSendingInvite(true);

    const idToken = await user.getIdToken();

    try {
      console.debug(`Sending invite to "${selectedUser.email}"...`);
      const res = await fetch(`${apiHost}/admin/users/${userId}/invite`, {
        method: 'POST',
        headers: { Authorization: `IdToken ${idToken}` },
      });
      const text = await res.text();
      console.debug('Response:', text);
      addMessage(`Invite sent to "${selectedUser.email}".`);
    } catch (e) {
      console.error(e);
      addMessage(`Unable to send invite to "${selectedUser.email}".`, 'error');
    }

    setSendingInvite(false);
  };

  return (
    <Canvas style={{ alignItems: 'flex-start' }}>
      <Row style={{ marginBottom: 45 }}>
        <Header>Update User Information</Header>
        <Add style={{ marginLeft: 22 }} to={admin.USERS + '/add'} />
        {ready && selectedUser && <Delete to={`${match.url}/delete`} />}
      </Row>

      {!ready && (
        <Center>
          <Spinner size={20} message="Fetching user information..." />
        </Center>
      )}

      {ready && !selectedUser && <div>Unable to locate user.</div>}

      {ready && selectedUser && (
        <React.Fragment>
          {/* Profile Picture */}
          <ProfileRow>
            <ProfileImg
              className={
                selectedUser.photo && selectedUser.photo.url ? 'has-image' : ''
              }
              src={
                selectedUser.photo && selectedUser.photo.url
                  ? selectedUser.photo.url
                  : imgUser
              }
            />
            <UploadFile
              label="Profile Image:"
              dest={{
                gsPath: `/users/${userId}/images/profile.{epoch}.orig.{ext}`,
                firestorePath: `/users/${userId}`,
                firestoreField: 'photo.originalUrl',
              }}
              resize={{
                resize: '256>',
                gsPath: `/users/${userId}/images/profile.{epoch}.png`,
                firestorePath: JSON.stringify([
                  `/users/${userId}`,
                  `/users/${userId}/public/info`,
                ]),
                firestoreField: 'photo.url',
              }}
            />
          </ProfileRow>

          {/* Email */}
          <Row style={{ alignItems: 'center', marginBottom: 0 }}>
            <TextInput
              style={{ flex: '1 1 150px' }}
              value={selectedUser.email}
              label="Email:"
              type="email"
              placeholder="Example: user@example.com"
              validationMessage="Please enter a valid email address. Example: name@example.com"
              onConfirm={(v, setSpinner) => updateAuth('email', v, setSpinner)}
            />

            {sendingInvite && <Spinner size={15} />}

            {!sendingInvite && (
              <Button
                style={{ flex: '0 0 130px', marginLeft: 32 }}
                onClick={sendInvite}
              >
                Send Invite
              </Button>
            )}
          </Row>

          {/* Display Name */}
          <TextInput
            label="Display Name:"
            placeholder="Example: John Smith"
            value={selectedUser.displayName}
            onConfirm={async (v, setSpinner) => {
              setSpinner(true);
              await updateAuth('displayName', v);
              await updatePublicInfo('displayName', v);
              setSpinner(false);
            }}
          />

          {/* Trustor */}
          <TrustorDropDown
            defaultValue={selectedUser.trustor ? selectedUser.trustor.id : null}
            onChange={handleTrustor}
          />

          {/* Title */}
          <TextInput
            label="Title:"
            placeholder="Title at Trustor or AJG"
            value={selectedUser.title}
            onConfirm={async (v, setSpinner) => {
              setSpinner(true);
              await updateProfile('title', v);
              await updatePublicInfo('title', v);
              setSpinner(false);
            }}
          />

          {/* Reta Title */}
          <TextInput
            label="Reta Title:"
            placeholder="Title within the Reta organization"
            value={selectedUser.retaTitle}
            onConfirm={async (v, setSpinner) => {
              setSpinner(true);
              await updateProfile('retaTitle', v);
              await updatePublicInfo('retaTitle', v);
              setSpinner(false);
            }}
          />

          {/* Phone Number */}
          <TextInput
            label="Phone Number:"
            value={selectedUser.phoneNumber}
            placeholder="Example: +1 123-456-7890"
            validationMessage="A valid phone number is required.  Example: +1 123-456-7890."
            onConfirm={(v, setSpinner) =>
              updateAuth('phoneNumber', v.replace(/[-\s()]/g, ''), setSpinner)
            }
          />

          {/* Notes */}
          <TextArea
            label="Notes:"
            style={{ marginBottom: 42 }}
            onConfirm={(v, setSpinner) => updateProfile('notes', v, setSpinner)}
            placeholder="(optional)"
            value={selectedUser.notes}
          />

          {/* Options */}
          <Row style={{ marginBottom: 16 }}>
            <Label>Options:</Label>
          </Row>

          <CheckRow>
            <CheckboxItem
              label="Active"
              checked={selectedUser.isActive}
              onChange={(checked, setSpinner) =>
                updateProfile('isActive', checked, setSpinner)
              }
            />
            <CheckboxItem
              label="Administrator"
              checked={selectedUser.isAdmin}
              onChange={(checked, setSpinner) =>
                updateProfile('isAdmin', checked, setSpinner)
              }
            />
          </CheckRow>

          {/* Contact Options */}
          <Row style={{ marginBottom: 16 }}>
            <Label>Contact Options:</Label>
          </Row>

          <CheckboxItem
            label="Show in Reta Leadership"
            checked={
              selectedUser.leadershipList
                ? selectedUser.leadershipList.show
                : false
            }
            onChange={(checked, setSpinner) =>
              updateProfile('leadershipList.show', checked, setSpinner)
            }
          />
          <CheckboxItem
            label="Show in Executive Leadership"
            checked={selectedUser.execList ? selectedUser.execList.show : false}
            onChange={(checked, setSpinner) =>
              updateProfile('execList.show', checked, setSpinner)
            }
          />
          <CheckboxItem
            label="Show in Leadership Partners"
            checked={
              selectedUser.partnerList ? selectedUser.partnerList.show : false
            }
            onChange={(checked, setSpinner) =>
              updateProfile('partnerList.show', checked, setSpinner)
            }
          />
          <CheckboxItem
            label="Show in Reta Board of Trustees"
            checked={
              selectedUser.boardList ? selectedUser.boardList.show : false
            }
            onChange={(checked, setSpinner) =>
              updateProfile('boardList.show', checked, setSpinner)
            }
          />
          <CheckboxItem
            label="Show in Reta Governance Committee Members"
            checked={
              selectedUser.outsideList ? selectedUser.outsideList.show : false
            }
            onChange={(checked, setSpinner) =>
              updateProfile('outsideList.show', checked, setSpinner)
            }
          />

          {/* Groups */}
          <GroupsCheckList
            selectedIds={
              selectedUser.groups && selectedUser.groups.map(g => g.id)
            }
            onChange={handleUpdateGroup}
          />

          {/* Biography */}
          <Bio
            userId={userId}
            displayName={selectedUser.displayName}
            title={selectedUser.title}
            trustor={selectedUser.trustor}
            photo={selectedUser.photo && selectedUser.photo.url}
          />
        </React.Fragment>
      )}
    </Canvas>
  );
};

export default UserUpdate;
