import React, { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from '../../../contexts/firebase';
import { MessagesContext } from '../../../contexts/messages';
import styled from 'styled-components/macro';
import ContactsItem from './ContactsItem';
import Spinner from '../../shared/Spinner';
import HTML5Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import Promise from 'bluebird';

const Doc = styled.div`
  display: flex;
  align-items: center;
`;

const List = styled(Doc)`
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const ContactsBoardList = ({ filter }) => {
  const { database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const [fetched, setFetched] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);

  /**
   * Subscribe to the Doc list
   */
  useEffect(() => {
    setFetched(false);

    const unsub = database
      .collection('/users')
      .where('boardList.show', '==', true)
      .onSnapshot(
        async docs => {
          const contacts = await Promise.map(docs.docs, async d => {
            const data = d.data();

            let trustor;
            if (data.trustor) {
              trustor = await data.trustor.get();
            }

            return {
              ...data,
              id: d.id,
              ref: d.ref,
              trustor: trustor ? trustor.data() : null,
            };
          });

          const sorted = contacts.sort(
            (a, b) => a.boardList.order - b.boardList.order,
          );

          setContacts(sorted);
          setFetched(true);
        },
        e => {
          console.error(e);
          addMessage('Unable to fetch contacts list', 'error');
          setFetched(true);
        },
      );

    return unsub;
  }, [addMessage, database]);

  /**
   * Filter the contacts list based on the search field
   */
  useEffect(() => {
    if (!filter && !contacts.length) {
      return;
    }

    if (!filter) {
      return setFilteredContacts(contacts);
    }

    const regex = new RegExp(filter, 'i');

    const filtered = contacts.filter(
      g => regex.test(g.displayName) || regex.test(g.email),
    );

    setFilteredContacts(filtered);
  }, [filter, contacts]);

  return (
    <List>
      {!fetched && <Spinner />}

      {fetched && (!filteredContacts || !filteredContacts.length) && (
        <div>No results found.</div>
      )}

      {filteredContacts && (
        <DndProvider backend={HTML5Backend}>
          {filteredContacts.map((contact, index) => (
            <ContactsItem
              key={index}
              order={index}
              orderField="boardList.order"
              contact={contact}
              contacts={contacts}
              dndType="BOARD_LIST_ITEM"
            />
          ))}
        </DndProvider>
      )}
    </List>
  );
};

ContactsBoardList.defaultProps = {
  filter: '',
};

export default ContactsBoardList;
