const config = {
  apiKey:
    (process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_FB_API_KEY) ||
    'AIzaSyBAAfegoLcXRrvyNd6T3909ugx8h2SDsFc',
  authDomain:
    (process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_FB_AUTH_DOMAIN) ||
    'retatrust-org.firebaseapp.com',
  databaseURL:
    (process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_FB_DB_URL) ||
    'https://retatrust-org.firebaseio.com',
  projectId:
    (process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_FB_PROJECT_ID) ||
    'retatrust-org',
  storageBucket:
    (process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_FB_BUCKET) ||
    'retatrust-org.appspot.com',
  messagingSenderId:
    (process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_FB_MSG_SENDER_ID) ||
    '519176442999',
  appId:
    (process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_FB_APP_ID) ||
    '1:519176442999:web:ffe576ae9554561dd86272',
};

export default config;
