import { Switch, Route } from 'react-router-dom';
import Pillar1 from './pillar1/Pillar1';
import Pillar2 from './pillar2/Pillar2';
import Pillar3 from './pillar3/Pillar3';
import Pillar4 from './pillar4/Pillar4';
import Pillar5 from './pillar5/Pillar5';
import Pillar6 from './pillar6/Pillar6';
import { pubScroll as routes } from '../../../constants/routes';
import styled from 'styled-components/macro';
import imgCircles from './circles.png';
import Back from '../../v2/Back';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 34px;

  & .circles {
    position: absolute;
    top: 200px;
    z-index: -1;

    & img {
      width: 900px;
    }
  }

  & .pillar {
    position: relative;
    width: 100%;
  }
`;

const Pillars = () => {
  return (
    <Wrapper>
      <div className="circles">
        <img src={imgCircles} alt="circles" />
      </div>

      <div className="pillar">
        <Back to={`${routes.ROOT}#reta-advantage`} />

        <Switch>
          <Route exact path={`${routes.PILLARS}/1`}>
            <Pillar1 />
          </Route>
          <Route exact path={`${routes.PILLARS}/2`}>
            <Pillar2 />
          </Route>
          <Route exact path={`${routes.PILLARS}/3`}>
            <Pillar3 />
          </Route>
          <Route exact path={`${routes.PILLARS}/4`}>
            <Pillar4 />
          </Route>
          <Route exact path={`${routes.PILLARS}/5`}>
            <Pillar5 />
          </Route>
          <Route exact path={`${routes.PILLARS}/6`}>
            <Pillar6 />
          </Route>
        </Switch>
      </div>
    </Wrapper>
  );
};

export default Pillars;
