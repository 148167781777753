import React, { useState } from 'react';
import Header from '../../../shared/Header2';
import Search from '../../../shared/Search';
import Add from '../../../shared/Add';
import { vault } from '../../../../constants/routes';
import ContentList from './CategoryList';
import Canvas from '../../../admin/shared/Canvas';
import Row from '../../../admin/shared/Row';
import Group from '../../../admin/shared/Group';

const Categories = () => {
  const [filter, setFilter] = useState(null);

  return (
    <Canvas>
      <Row style={{ marginBottom: 45 }}>
        <Group>
          <Header>Vault Categories</Header>
          <Add
            style={{ marginLeft: 27 }}
            to={vault.admin.CATEGORIES + '/add'}
          />
        </Group>
        <Search onChange={val => setFilter(val)} />
      </Row>

      <ContentList filter={filter} />
    </Canvas>
  );
};

export default Categories;
