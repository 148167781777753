import React, { useState, useEffect, useContext } from 'react';
import Header from '../../shared/Header2';
import Canvas from '../shared/Canvas';
import Row from '../shared/Row';
import TextInput from '../../shared/TextInput';
import TextArea from '../../shared/TextArea';
import Button from '../../shared/Button';
import { FirebaseContext } from '../../../contexts/firebase';
import Spinner from '../../shared/Spinner';
import { admin } from '../../../constants/routes';
import { MessagesContext } from '../../../contexts/messages';
import maps from '@google/maps';
import { PreferencesContext } from '../../../contexts/preferences';

const TrustorAdd = ({ history }) => {
  const { database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);
  const { googleMapsApiKey } = useContext(PreferencesContext);

  const [displayName, setDisplayName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [valid, setValid] = useState(false);
  const [adding, setAdding] = useState(false);
  const [notes, setNotes] = useState('');
  const [latlong, setLatLong] = useState();

  /**
   * Validate the form
   */
  useEffect(() => {
    const isValid = displayName.length > 0 && state.length > 0;
    setValid(isValid);
  }, [displayName, state, zipCode]);

  /**
   * Get the geolocation when the form updates
   */
  useEffect(() => {
    let sendAddress = '';

    if (address) {
      sendAddress += address + ', ';
    }

    if (city) {
      sendAddress += city + ', ';
    }

    if (state) {
      sendAddress += state + ', ';
    }

    if (zipCode) {
      sendAddress += zipCode;
    }

    if (sendAddress.length <= 0) {
      return;
    }

    maps
      .createClient({
        key: googleMapsApiKey,
      })
      .geocode(
        {
          address: sendAddress,
        },
        (err, response) => {
          if (err) {
            console.error(err);
            return;
          }
          console.debug('Geocode Results:', response.json.results[0]);
          const latlng = response.json.results[0].geometry.location;
          setLatLong(latlng);
        },
      );
  }, [address, city, googleMapsApiKey, state, zipCode]);

  /**
   * Handle adding the trustor
   */
  const handleAddTrustor = async () => {
    setAdding(true);
    let data = { displayName, state, lat: latlong.lat, lng: latlong.lng };

    if (address) {
      data.address = address;
    }

    if (city) {
      data.city = city;
    }

    if (zipCode) {
      data.zipCode = zipCode;
    }

    if (notes) {
      data.notes = notes;
    }

    try {
      const ref = await database.collection('/trustors').add(data);

      addMessage(`Successfully added trustor "${displayName}"`);
      setAdding(false);
      history.push(admin.TRUSTORS + '/' + ref.id);
    } catch (e) {
      console.error(e);
      addMessage(`Unable to add trustor "${displayName}"`, 'error');
      setAdding(false);
    }
  };

  return (
    <Canvas style={{ alignItems: 'flex-start' }}>
      <Row style={{ marginBottom: 45 }}>
        <Header>Create Trustor</Header>
      </Row>

      <TextInput
        label="* Display Name:"
        placeholder="Required Field. Example: Finance Committee"
        onDebounced={setDisplayName}
      />

      <TextInput
        label="Address:"
        placeholder="Example: 1123 State St."
        onDebounced={setAddress}
      />

      <Row>
        <TextInput
          style={{ flex: '1 1 200px', width: 'auto', marginRight: 16 }}
          label="City:"
          placeholder="Example: San Francisco"
          onDebounced={setCity}
        />

        <TextInput
          style={{ flex: '1 1 50px', width: 'auto' }}
          label="* State:"
          placeholder="Required. Ex: CA or California"
          onDebounced={setState}
        />
      </Row>

      <TextInput
        style={{ flex: '1 1 100px', width: 'auto' }}
        label="Zip Code:"
        placeholder="Example: 94101"
        onDebounced={setZipCode}
      />

      <TextArea
        label="Notes:"
        style={{ marginBottom: 42 }}
        onDebounced={setNotes}
      />

      {!adding && (
        <Row>
          <Button invert onClick={() => history.push(admin.GROUPS)}>
            Cancel
          </Button>
          <Button disabled={!valid} onClick={handleAddTrustor}>
            Add Trustor
          </Button>
        </Row>
      )}

      {adding && <Spinner />}
    </Canvas>
  );
};

export default TrustorAdd;
