import { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import AppBar from '../components/appBar/AppBar';
import { useLocation } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { pubScroll as routes } from '../constants/routes';
import Login from '../components/mainScrolling/Login';
import PostLogin from '../components/mainScrolling/PostLogin';
import Logout from '../components/mainScrolling/Logout';
import Root from '../components/v2/Root';
import DemoLogin from '../components/mainScrolling/LoginDemo';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  background-color: white;
  min-height: 100vh;

  & > .center {
    position: relative;
    width: 100%;
    max-width: 1440px;
    // box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    //   0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  }
`;

export const Wrap = styled.div`
  padding: 0 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ScrollingLanding = () => {
  const topRef = useRef();
  const location = useLocation();

  /**
   * Scroll element into view when location hash changes
   */
  useEffect(() => {
    if (location.hash) {
      const el = document.querySelector(location.hash);
      if (el) el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  /**
   * We need to remove the `height` on the canvas element, but it's still
   * needed for the old landing.  Remove at a later time.
   */
  useEffect(() => {
    const el = document.querySelector('.canvas');
    el.style.height = '100%';

    return () => {
      el.style.height = '';
    };
  }, []);

  return (
    <Wrapper id="home" ref={topRef}>
      <div className="center">
        <AppBar />

        <Switch>
          <Route path={routes.LOGIN}>
            <Login />
          </Route>

          <Route path={routes.LOGIN_DEMO}>
            <DemoLogin />
          </Route>

          <Route exact path={routes.POST_LOGIN}>
            <PostLogin />
          </Route>

          <Route exact path={routes.LOGOUT}>
            <Logout />
          </Route>

          <Route path={routes.ROOT}>
            <Root />
          </Route>
        </Switch>
      </div>
    </Wrapper>
  );
};

export default ScrollingLanding;
