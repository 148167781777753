import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

const DisplayName = styled(Link)`
  font-size: 14px;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #495b5e;

  &:visited {
    color: inherit;
  }
`;

export default DisplayName;
