import React, { useEffect, useContext, useReducer } from 'react';
import { FirebaseContext } from './firebase';
import { MessagesContext } from './messages';

export const TrustorsContext = React.createContext([]);

export const TrustorsProvider = ({ children }) => {
  const { database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const [state, dispatch] = useReducer(
    // reducing function
    (state, action) => {
      console.debug('Action:', action);
      console.debug('Current State:', state);
      let nextState;

      switch (action.type) {
        case 'UPDATE_TRUSTORS':
          nextState = {
            ...state,
            trustors: action.payload.trustors,
            count: action.payload.trustors.length,
            byId: action.payload.trustors.reduce((acc, g) => {
              acc[g.id] = g;
              return acc;
            }, {}),
          };
          break;

        case 'FETCHING':
          nextState = { ...state, fetching: true };
          break;

        case 'FETCHED':
          nextState = { ...state, fetching: false };
          break;

        default:
          nextState = { ...state };
          break;
      }

      console.debug('Next State:', state);
      return nextState;
    },
    // initial state
    { trustors: [], byId: {}, fetching: false },
  );

  /**
   * Subscribe to the Trustor list
   */
  useEffect(() => {
    if (!database) {
      return;
    }

    dispatch({ type: 'FETCHING' });

    const unsub = database
      .collection('/trustors')
      .orderBy('displayName', 'asc')
      .onSnapshot(
        docs => {
          const trustors = docs.docs.map(d => ({
            ...d.data(),
            id: d.id,
            ref: d.ref,
          }));
          dispatch({ type: 'UPDATE_TRUSTORS', payload: { trustors } });
          dispatch({ type: 'FETCHED' });
        },
        e => {
          console.error(e);
          addMessage('Unable to fetch trustor list', 'error');
          dispatch({ type: 'FETCHED' });
        },
      );

    return unsub;
  }, [addMessage, database]);

  return (
    <TrustorsContext.Provider value={state}>
      {children}
    </TrustorsContext.Provider>
  );
};

export default TrustorsProvider;
