import React, { useRef, useState, useEffect } from 'react';
import imgFile from '../../assets/img/iconFile.svg';
import styled from 'styled-components/macro';
import Button from './Button';
import TextInput from './TextInput';
import Label from './Label';
import { Container as InputContainer, BtnContainer, Img } from './TextInput';
import imgOk from '../../assets/img/ok.svg';
import imgCancel from '../../assets/img/cancel.svg';
import Spinner from './Spinner';

const Container = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 24px;
  box-sizing: border-box;
`;

const StyledInputContainer = styled(InputContainer)`
  align-items: flex-end;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Filename = styled.div`
  font-family: Roboto;
  font-size: 14px;
  color: #495b5e;
  margin: 0 8px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Preview = ({ src, ...props }) => {
  return (
    <div>
      <img alt="Preview" {...props} />
      <TextInput>{src}</TextInput>
    </div>
  );
};

const SelectFile = ({
  label,
  type,
  selectLabel,
  defaultValue,
  onSelectedFile,
  onConfirm,
}) => {
  const ref = useRef();
  const formRef = useRef();

  const [file, setFile] = useState();
  const [showSpinner, setSpinner] = useState(false);
  const [key, setKey] = useState(0);
  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    onSelectedFile(file);
  }, [file, onSelectedFile]);

  /**
   * If we receive an onConfirm function, enable the confirmation behavior.
   */
  useEffect(() => {
    if (onConfirm && typeof onConfirm === 'function') {
      setConfirm(true);
    }
  }, [onConfirm]);

  const handleCancel = () => {
    setFile(null);
    setKey(prev => prev + 1);
  };

  const handleOk = async () => {
    await onConfirm(file, setSpinner);
    setKey(prev => prev + 1);
    setFile(null);
  };

  return (
    <StyledInputContainer>
      <Container>
        <Row>
          <Label>{label}</Label>
        </Row>
        <Row>
          {type === 'image' && defaultValue && <Preview src={defaultValue} />}
          <form ref={formRef}>
            <input
              style={{ display: 'none' }}
              ref={ref}
              type="file"
              onChange={e => setFile(e.target.files[0])}
              key={key}
            />
          </form>

          <Img src={imgFile} alt="File Icon" style={{ marginRight: 8 }} />
          {file && file.name && <Filename>{file.name}</Filename>}
          <Button
            invert
            onClick={() => ref.current.click()}
            style={{ marginLeft: 8, minWidth: 115 }}
          >
            {selectLabel}
          </Button>

          {confirm && (
            <React.Fragment>
              {showSpinner && (
                <BtnContainer style={{ height: 'auto', top: 0 }}>
                  <Spinner size={15} />
                </BtnContainer>
              )}

              {!showSpinner && file && (
                <BtnContainer style={{ height: 'auto', top: 0 }}>
                  <Img src={imgCancel} onClick={handleCancel} />
                  <Img src={imgOk} onClick={handleOk} />
                </BtnContainer>
              )}
            </React.Fragment>
          )}
        </Row>
      </Container>
    </StyledInputContainer>
  );
};

SelectFile.defaultProps = {
  label: 'Select File:',
  type: 'file',
  selectLabel: 'Select File',
  onSelectedFile: file => console.debug('onSelectedFile:', file),
};

export default SelectFile;
