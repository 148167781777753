import React, { useEffect, useContext, useState } from 'react';
import ft from 'feature-toggle';
import { FirebaseContext } from './firebase';

export const FeaturesContext = React.createContext();

export const FeaturesProvider = ({ children }) => {
  const { database } = useContext(FirebaseContext);

  const [features, setFeatures] = useState({ active: () => {}, init: false });

  useEffect(() => {
    if (!database) return;

    const unsub = database
      .collection('features')
      .doc('retatrust.org')
      .onSnapshot(doc => {
        const data = doc.data();
        console.debug('Features:', data);
        if (!data) {
          return;
        }
        const dbFeatures = Object.keys(data).filter(key => data[key]);
        console.debug('Enabled Features:', dbFeatures);
        const feats = ft(dbFeatures);
        feats.init = true;
        setFeatures(feats);
      });

    return unsub;
  }, [database]);

  return (
    <FeaturesContext.Provider value={features}>
      {children}
    </FeaturesContext.Provider>
  );
};

export default FeaturesProvider;
