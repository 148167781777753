import { Header } from '../shared/Header';
import { Paragraph } from '../shared/newShared/Paragraph';
import { Toolbar } from '@material-ui/core';
import { Content } from './RetaAdvantage';
import imgProposal from './requestProposal.png';

const RequestProposal = () => {
  return (
    <Content id="request-proposal">
      <Toolbar />

      <Header>Request a Proposal</Header>

      <Paragraph>
        If you’d like a proposal for your Catholic organization, or for more
        information, click anywhere on the image above to open the digital
        brochure. To request a proposal, go to the last page of the brochure and
        click on the link. You will receive your proposal within five business
        days.
      </Paragraph>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <a
          href="https://www.myenroll.com/web_projects/RetaTrustProposalWelcome/index.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={imgProposal} alt="Request Proposal" />
        </a>
      </div>
    </Content>
  );
};

export default RequestProposal;
