export const COLORS = {
  RED: '#951a2f',
  BLUE: '#475a62',
  YELLOW: '#fdca50',
  CAROUSEL_BG: '#d8e6e8',
  LEFT_SIDE_HEADER: '#4d626a',
  LEFT_SIDE_TEXT: '#51666e',
  TEXT: '#4d626a',
  RETA_ACCESS_BG: '#bad1cf',
  MENU_AREA_BG: '#e2ebe9',
};

/**
 * Uniform Drop Shadow
 */
export const shadow = '0 8px 40px 0 rgba(20, 0, 0, .3)';

export const shadowStyle = {
  WebkitBoxShadow: shadow,
  MozBoxShadow: shadow,
  boxShadow: shadow,
};
