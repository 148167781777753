import React, { useState, useEffect } from 'react';
import Header from '../shared/Header';
import { Paragraph } from '../shared/Paragraph';
import styled from 'styled-components/macro';
import Spinner from '../shared/Spinner';
import { Wrap } from '../../views/ScrollingLanding';
import Button from '../shared/Button';
import TextInput from '../shared/TextInput';
import CheckboxItem from '../shared/CheckboxItem';
import { COLORS } from '../../constants/colors';
import { Toolbar } from '@material-ui/core';

const H4 = styled.div`
  font-family: Roboto;
  font-weight: bold;
  font-size: 1rem;
  margin: 12px 0;
  font-size: 18px;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const DemoLogin = () => {
  const [username, setUsername] = useState('');
  const [remember, setRemember] = useState(false);
  const [loggingIn, setLogginIn] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);

  const handleLogin = () => {
    setLogginIn(true);
    setFail(false);
    setSuccess(false);

    setTimeout(() => {
      if (/bill|bzabit/i.test(username)) {
        setSuccess(true);
      } else {
        setFail(true);
      }

      setLogginIn(false);
    }, 500);
  };

  useEffect(() => {
    let id;
    if (success) {
      id = setTimeout(() => {
        window.location.href =
          'https://rbc-ihc.retatrust.org/?id=5bb87112802d009f67dcb7ce32a7ae29';
      }, 4000);
    }
    return () => clearTimeout(id);
  }, [success]);

  return (
    <Wrap>
      <Toolbar />

      <div style={{ fontSize: 16, width: '100%', maxWidth: 500 }}>
        {!fail && !success && (
          <Header style={{ marginBottom: 42, marginTop: 32 }}>
            Reta Member Login
          </Header>
        )}

        {fail && !success && (
          <>
            <Header style={{ marginBottom: 42, marginTop: 32 }}>
              Your Login was Unsuccessful
            </Header>

            <H4 style={{ color: COLORS.RED, fontWeight: 'normal' }}>
              Please try again or call or email for help
            </H4>
          </>
        )}

        {success && !fail && (
          <>
            <Header style={{ marginBottom: 42, marginTop: 32 }}>
              Your Login was Successful
            </Header>

            <H4>
              Heading to your personalized Reta Benefits Center (RBC) and
              RetaEnroll.
            </H4>

            <Row>
              <Spinner />
            </Row>
          </>
        )}

        {loggingIn && (
          <Row>
            <Spinner />
          </Row>
        )}

        {/* Login form */}
        {!loggingIn && !success && (
          <>
            <TextInput
              placeholder="Username"
              onDebounced={txt => setUsername(txt)}
              inputStyle={{
                backgroundColor: '#e4e9f1',
                border: '1px solid #526366',
                minHeight: 48,
              }}
            />
            <TextInput
              type="password"
              placeholder="Password"
              inputStyle={{
                backgroundColor: '#e4e9f1',
                border: '1px solid #526366',
                minHeight: 48,
              }}
            />

            <CheckboxItem
              label={<H4>Remember me</H4>}
              onChange={val => setRemember(val)}
              checked={remember}
            />

            <Row style={{ justifyContent: 'flex-end' }}>
              <Button
                style={{
                  fontFamily: 'Roboto',
                  backgroundColor: '#2a407f',
                  width: 150,
                  minHeight: 48,
                }}
                onClick={handleLogin}
              >
                Login
              </Button>
            </Row>

            <H4>Forgot username or password</H4>

            <Row style={{ justifyContent: 'flex-end' }}>
              <Button
                style={{
                  fontFamily: 'Roboto',
                  backgroundColor: '#2a407f',
                  width: 150,
                  minHeight: 48,
                }}
              >
                First time users
              </Button>
            </Row>
          </>
        )}
        <div>
          <H4>Need help?</H4>
          <Paragraph style={{ margin: 0 }}>
            Call: <b>1.877.303.7382</b>
          </Paragraph>
          <Paragraph style={{ marginBottom: 12 }}>
            (Monday thru Friday 8:30am to 8:00pm ET)
          </Paragraph>
          <Paragraph style={{ margin: 0 }}>
            Email:&nbsp;
            <b>
              <a
                href="mailto:service@RetaEnroll.org"
                style={{ color: '#4d626a' }}
              >
                service@RetaEnroll.org
              </a>
            </b>
          </Paragraph>
        </div>
      </div>
    </Wrap>
  );
};

export default DemoLogin;
