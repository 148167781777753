import imgPillar from './RETA.org.PILLAR.V.BUTTON.png';
import Paragraph from '../../../shared/newShared/Paragraph';
import Pillar from '../Pillar';
import imgLong from './long.png';
import imgWillhite from './willhite.png';
import imgMcGowan from '../pillar4/RETA.org.KATHRYNE.MCGOWAN.png';
import Video from '../Video';
import Header from '../../../shared/Header';
import useSetTitle from '../../../../hooks/useSetTitle';

const Pillar2 = () => {
  useSetTitle('Pillar V');

  return (
    <Pillar imgSrc={imgPillar} imgAlt="Pillar V">
      <Paragraph>
        When we speak of wellness and cost management the two are very much
        connected. As members are truly engaged and self-motivated to stay
        healthy, the resulting positive outcomes translate to healthier and more
        productive lifestyles for membership. That means less healthcare
        utilization and a way to control premiums for our Trustors and their
        employees.{' '}
      </Paragraph>

      <Video
        poster={imgWillhite}
        float="right"
        style={{ paddingLeft: 25 }}
        videoUrl="https://assets.retatrust.org/2021/210421.1/Willhite+P5.mp4"
      >
        Jo Wilhite — Chief Administrative Officer, Archdiocese of Portland OR
      </Video>

      <Paragraph>
        For over two decades, Reta continues to provide and promote well-being
        and preventive medicine to its members. And that has proven to have a
        cascading effect that benefits everyone. These wellness and health
        intervention programs encourage self-accountability for staying healthy
        with proven results that directly translate into healthier and more
        productive members and a healthy way to manage healthcare costs for all.
      </Paragraph>

      <Paragraph>
        For these very good reasons, Reta is committed to offering health and
        wellness self-help programs that assist, encourage and incentivize
        members to be well and stay well. Reta wellness certainly evolved
        considerably over the years. What originated with a paper health risk
        assessment, morphed into the comprehensive integrated programs that we
        offer today. And with all the questions that went unanswered about the
        pandemic, one thing is for certain. We recognized early on how
        “sheltering in place” proved to impact how members received
        non-emergency care with a major migration to telemedicine for both
        medical and mental health appointments as an additional pathway to
        access wellness and preventative medicine.
      </Paragraph>

      <Header>The trend is virtual medicine and integrated wellness</Header>

      <Paragraph>
        We anticipate this trend to continue. In fact, Reta will be urging
        members who are hesitant to return to a traditional medical setting to
        get their preventive annual physicals and non-urgent appointments
        virtually.
      </Paragraph>

      <Paragraph>
        So Reta explored new options for our wellness programs. Options that
        could be directly tailored to complement the medical condition and
        treatment history for individual members while keeping premiums down for
        our Trustors and their employees. And for the 2021-22 plan year the Reta
        Board of Trustees made the decision to integrate our new wellness
        programs with our medical plan carriers. The reasoning was sound.
        Medical providers understand the total health of their members and can
        be in a better position to facilitate multipoint techniques to engage
        members in well-being initiatives while promoting preventative medicine
        and condition management.
      </Paragraph>

      <Video
        poster={imgLong}
        float="right"
        style={{ paddingLeft: 25 }}
        videoUrl="https://assets.retatrust.org/2021/210421.1/Long+P5.mp4"
      >
        Troy Long, MD — Kaiser Permanente
      </Video>

      <Paragraph>
        Certainly wellness and preventive programs promote a healthier
        lifestyle. But Reta also has found value in programs designed for
        members who have… or are at risk of having chronic conditions. The goal
        is to help them to help themselves to make sure they are receiving
        clinically appropriate care to stabilize their condition and to avoid
        serious complications.
      </Paragraph>

      <Paragraph>
        And by integrating our wellness, disease management and cost management
        programs with our healthcare providers, we are able to provide Reta
        members with a single point of coordinated care through the Blue Shield
        Wellvolution program and Workforce Health through Kaiser Permanente.
        These programs support Reta’s overall objectives to improve the health
        of our members, increase access to care in both traditional and
        non-traditional settings while reducing the costs of chronic conditions.
        For more on Wellvolution through Blue Shield click{' '}
        <a href="https://rbc-ihc.retatrust.org/?id=ab9b2b45c5c95e3c00b106993c1d5804&ft=onlyNetworks#medical-blue_shield_ca">
          here
        </a>
        . To learn more on Workforce Health through Kaiser, click{' '}
        <a href="https://rbc-ihc.retatrust.org/?id=e7e142497f5cdba9b8dad86d36473e9d&ft=onlyNetworks">
          here
        </a>
        .
      </Paragraph>

      <Header>Pillar V summary and closing</Header>

      <Paragraph>
        By looking at our combined wellness, disease management and healthcare
        through a single lens, we can best reach our goal to map our services to
        the health issues our members have. And that means providing the right
        programs, available at the right time and place, to meet members where
        they are along the path of their own health journey.
      </Paragraph>

      <Video
        poster={imgMcGowan}
        float="right"
        style={{ paddingLeft: 25 }}
        videoUrl="https://assets.retatrust.org/2021/210421.1/McGowan+P5.mp4"
      >
        Kathryne McGowan — CEO, Reta Trust
      </Video>

      <Paragraph>
        At the end of the day, Trustors can be confident that their employees
        will continue to have programs to encourage and assist them to attain,
        and maintain, a healthier lifestyle. There will never be a worry about
        getting help during times of healthcare and healing care needs. As
        always, Reta will be there.
      </Paragraph>
    </Pillar>
  );
};

export default Pillar2;
