import imgPillar from './RETA.org.PILLAR.III.BUTTON.png';
import Paragraph from '../../../shared/newShared/Paragraph';
import Pillar from '../Pillar';
import imgCaldwell from './RETA.org.BRUCE.CALDWELL.png';
import imgHagen from './RETA.org.CHRIS.HAGEN.png';
import imgMcGowan from './RETA.org.KATHRYNE.MCGOWAN.png';
import Video from '../Video';
import Header from '../../../shared/Header';
import useSetTitle from '../../../../hooks/useSetTitle';

const Pillar3 = () => {
  useSetTitle('Pillar III');

  return (
    <Pillar imgSrc={imgPillar} imgAlt="Pillar III">
      <Paragraph>
        In this chapter dedicated to Pillar III, we will showcase how Reta
        aligns benefits and providers to support our pledge to deliver{' '}
        <b>quality</b> healthcare benefits that are most <b>affordable</b> and{' '}
        <b>accessible</b> to our members. And because benefit plans and plan
        providers change constantly, aligning benefits and providers to meet
        Reta’s high standards is an on-going process that never ends.
      </Paragraph>

      <Video
        poster={imgCaldwell}
        float="right"
        style={{ paddingLeft: 25 }}
        videoUrl="https://assets.retatrust.org/2021/210421.1/Caldwell+P3.mp4"
      >
        Bruce Caldwell — Regional President of Gallagher Benefit Services
      </Video>

      <Paragraph>
        And our vigilance to follow our founding promise to provide the highest
        quality of healthcare at the lowest cost has never missed a beat. In
        fact, these times have pushed us even harder… to be even more aggressive
        in accomplishing this mission. With the challenging times impacting our
        Trustors, we took a <i>time out…</i> to take a long, hard look at our
        benefit plans and our plan providers. The purpose was to assure we are
        offering the best quality of healthcare… with the widest network access…
        at the lowest possible costs.
      </Paragraph>

      <Paragraph>
        We didn’t have a crystal ball to see what the future would bring. So we
        assembled a workgroup of Reta Trustors along with our committee members,
        Trustees and advisory partners to help us make some important decisions.{' '}
      </Paragraph>

      <Header>
        A realignment of benefits and providers to support our promise
      </Header>

      <Paragraph>
        As an ongoing process, Reta has regular dialogue with all of our vendors
        about improving contract terms and conditions. And to be vigilant as
        stewards of Reta’s healthcare plans, we are also constantly testing the
        waters to reconfirm that the provider partners we have chosen are still
        the most appropriate in supporting our pledge of affordability, access
        and quality in the healthcare benefits we offer.{' '}
      </Paragraph>

      <Video
        poster={imgHagen}
        float="right"
        style={{ paddingLeft: 25 }}
        videoUrl="https://assets.retatrust.org/2021/210421.1/Hagen+P3.mp4"
      >
        Chris Hagen — Director of HR. Roman Catholic Diocese of Manchester
      </Video>

      <Paragraph>
        So we took a hard look at our medical and pharmacy carriers with defined
        goals and outcomes in mind to reduce administrative costs… and minimize
        member disruption.
      </Paragraph>

      <Paragraph>
        In mid-June of 2020, Reta initiated a request for proposal to our
        incumbent medical plan carriers and to other potential carriers and
        third-party administrators. The focus of our request was prioritized
        into four areas.
      </Paragraph>

      <Paragraph>
        After an in-depth evaluation process, Reta’s Board of Trustees approved
        Blue Shield as Reta’s new PPO along with a plan to change Reta’s current
        medical carrier lineup to include only Blue Shield and Kaiser Permanente
        effective July 1, 2021. The decision to go with Blue Shield was based on
        the quality and variety of their healthcare plans and services, their
        national provider network that is a 99.8% match to our current networks
        and to achieve significant cost savings that would benefit both Trustors
        and their employees. In addition, Blue Shield is offering a number of
        programs including a wellness program, a digital health platform,
        enhanced customer support and a concierge model for priests.
      </Paragraph>

      <Paragraph>
        The Reta Board of Trustees also confirmed CVS Caremark as Reta’s new
        Pharmacy Benefit Manager. This evaluation process for selecting our new
        medical and pharmacy vendors is a great example of how Reta’s governance
        in action works. Members of the Trustor Work Group, the Reta Health
        Benefits Planning Committee, the Board of Trustees and the Reta Team
        worked together to make these changes happen to the benefit of our
        member Trustors and their employees.
      </Paragraph>

      <Paragraph>
        <ul>
          <li>
            Trustors saw a significant reduction in their contributions for the
            2021—2022 plan year.
          </li>
          <li>
            The transition to CVS will be integrated with the change to Blue
            Shield. This means Reta members will carry one ID card to use for
            both medical and prescriptions.
          </li>
          <li>
            CVS brings a nationwide network of pharmacies that includes
            Walgreens, Walmart, local pharmacies and CVS stores.
          </li>
          <li>
            With the integration of CVS and Blue Shield, Trustors and members
            will realize the benefits of simplified administration.
          </li>
        </ul>
      </Paragraph>

      <Header>Pillar III summary and closing</Header>

      <Video
        poster={imgMcGowan}
        float="right"
        style={{ paddingLeft: 25 }}
        videoUrl="https://assets.retatrust.org/2021/210421.1/McGowan+P3.mp4"
      >
        Kathryne McGowan — CEO, Reta Trust
      </Video>

      <Paragraph>
        The year 2020 was a busy time for Reta for other reasons than the
        pandemic as we went through a scrutinous process to evaluate,
        consolidate and integrate healthcare benefits, wellness programs and the
        management of our pharmacy benefits. These efforts to reshape Reta for
        the 021/2022 plan year underscores Reta’s third Pillar promise to align
        benefits and providers with our pledge to provide affordability, access
        and quality healthcare benefits to our Trustors and members across
        America
      </Paragraph>
    </Pillar>
  );
};

export default Pillar3;
