import React, { useState, useEffect, useContext, useCallback } from 'react';
import { FirebaseContext } from './firebase';
import { MessagesContext } from './messages';
import { PreferencesContext } from './preferences';

export const UsersContext = React.createContext([]);

export const UsersProvider = ({ children }) => {
  const { user, database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);
  const { apiHost } = useContext(PreferencesContext);

  const [users, setUsers] = useState([]);
  const [byEmail, setByEmail] = useState({});
  const [byId, setById] = useState({});
  const [fetching, setFetching] = useState({});

  const [value, setValue] = useState({
    fetching: false,
    users: [],
    refreshUsers: () => {},
    byId: {},
    byEmail: {},
  });

  /**
   * Group the users when we get the user list
   */
  useEffect(() => {
    if (!users.length) {
      return;
    }

    setByEmail(
      users.reduce((acc, user) => {
        acc[user.email] = user;
        return acc;
      }, {}),
    );

    setById(
      users.reduce((acc, user) => {
        acc[user.uid] = user;
        return acc;
      }, {}),
    );
  }, [users]);

  /**
   * Get the users
   */
  const refreshUsers = useCallback(() => {
    (async () => {
      setFetching(true);
      setUsers([]);

      const idToken = await user.getIdToken();
      let users;
      try {
        console.debug('Fetching users.');
        const res = await fetch(`${apiHost}/admin/users`, {
          headers: { Authorization: `IdToken ${idToken}` },
        });
        const json = await res.json();
        users = json.users;
      } catch (e) {
        console.error(e);
        setFetching(false);
        return addMessage('Unable to get user via API', 'error');
      }

      // TODO: Could use the GroupContext to get the group list
      users = await Promise.all(
        users.map(async user => {
          try {
            const doc = await database.doc(`/users/${user.uid}`).get();
            const profile = doc.data();
            return { ...user, profile };
          } catch (e) {
            console.error(e);
            setFetching(false);
            return addMessage('Unable to get groups via API', 'error');
          }
        }),
      );

      setUsers(users);
      setFetching(false);
    })();
  }, [addMessage, apiHost, database, user]);

  /**
   *  get the refresh users list on mount
   */
  useEffect(() => {
    refreshUsers();
  }, [refreshUsers]);

  /**
   * Update the context value when the state updates
   */
  useEffect(() => {
    setValue(prev => ({ ...prev, users }));
  }, [users]);

  useEffect(() => {
    setValue(prev => ({ ...prev, byEmail }));
  }, [byEmail]);

  useEffect(() => {
    setValue(prev => ({ ...prev, byId }));
  }, [byId]);

  useEffect(() => {
    setValue(prev => ({ ...prev, fetching }));
  }, [fetching]);

  // add the `refreshUsers` function to the context value
  useEffect(() => {
    setValue(prev => ({ ...prev, refreshUsers }));
  }, [refreshUsers]);

  return (
    <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
  );
};

export default UsersProvider;
