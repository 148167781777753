import React, { useContext } from 'react';
import { admin } from '../../constants/routes';
import styled from 'styled-components/macro';
import AdminItem from './AdminItem';
import { MOBILE_WIDTH } from '../../constants/dimensions';
import { FeaturesContext } from '../../contexts/features';

const Wrapper = styled.div`
  /* flex: 0 1 210px; */
  width: 100%;
  height: auto;
  color: white;
  background-color: #495b5e;
  padding: 25px 35px;
  box-sizing: border-box;
  overflow-y: auto;

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    max-height: 210px;
  }
`;

const H3 = styled.div`
  font-family: Titillium;
  font-size: 27px;
  color: #e2ebe9;
  margin-bottom: 22px;
`;

const AdminMenu = () => {
  const features = useContext(FeaturesContext);

  return (
    <Wrapper>
      <H3>Administrators</H3>

      <AdminItem to={admin.CATEGORIES}>Categories</AdminItem>
      <AdminItem to={admin.GROUPS}>Groups</AdminItem>
      <AdminItem to={admin.USERS}>Users</AdminItem>
      <AdminItem to={admin.TRUSTORS}>Trustors</AdminItem>
      <AdminItem to={admin.CAROUSELS}>Video Carousel</AdminItem>
      {features.active('adminContactList') && (
        <AdminItem to={admin.CONTACTS}>Contacts</AdminItem>
      )}
      <AdminItem to={admin.NEWSLETTERS}>Newsletters</AdminItem>
      {features.active('bulletinBoard') && (
        <AdminItem to={admin.BULLETINS} docs="bulletinBoard">
          Bulletin Board
        </AdminItem>
      )}
    </Wrapper>
  );
};

export default AdminMenu;
