import imgPillar from './RETA.org.PILLAR.II.BUTTON.png';
import Paragraph from '../../../shared/newShared/Paragraph';
import Pillar from '../Pillar';
import imgThomas from './RETA.org.ERIC.THOMAS.png';
import imgRome from './RETA.org.ANDREA.HUCKABA.ROME.png';
import imgMcGowan from './RETA.org.KATHRYNE.MCGOWAN.png';
import Video from '../Video';
import Header from '../../../shared/Header';
import useSetTitle from '../../../../hooks/useSetTitle';

const Pillar2 = () => {
  useSetTitle('Pillar II');

  return (
    <Pillar imgSrc={imgPillar} imgAlt="Pillar II">
      <Paragraph>
        In this chapter on Reta’s Pillar II we will showcase Reta’s commitment
        to how we underwrite and price healthcare benefits by reflecting on the
        impact the 2020 pandemic had on our Trustors. This will bring an actual
        example of how our collaborative approach to underwriting and pricing,
        that is both <b>responsive</b>… and <b>responsible</b>. The year 2020,
        more than any other in Reta’s history, truly demonstrates Reta’s
        collaboration with Reta’s Board of Trustees, the Trust’s management team
        and our Trustors in <i>standing next to our Trustors</i> in their time
        of need.
      </Paragraph>

      <Video
        poster={imgThomas}
        float="right"
        style={{ paddingLeft: 25 }}
        videoUrl="https://assets.retatrust.org/2021/210421.1/Thomas+P2.mp4"
      >
        Eric Thomas — Partner at Armanino
      </Video>

      <Paragraph>
        The end result was the development and implementation of a range of
        creative approaches to funding the Trust’s claims and expenses. For the
        first half of the 2019 plan year our actuarial claims projections were
        on course. And the estimates for the July 2020 renewal based on emerging
        claims were also favorable, trending towards a modest increase
        adjustment in premiums averaging 3%.
      </Paragraph>

      <Paragraph>
        But the coronavirus pandemic hit, and it impacted not only the Trust’s
        operations and financial projections, but it dramatically altered our
        Trustors’ ability to maintain employee benefit plans, fund premiums and
        preserve a fully active workforce. The structure of the Trust allowed it
        to be <b>responsive</b> to the needs of Trustors while being{' '}
        <b>responsible</b> with the assets of the Trust to assure funds are
        available to pay health claims over the long term.
      </Paragraph>

      <Paragraph>
        Standing next to you is our way of saying that Reta will continually
        assess what we can do to respond to Trustors’ needs. And when the
        worldwide pandemic came out of nowhere, the Reta Board of Trustees moved
        quickly to respond to dioceses and Catholic organizations when they
        needed it most.
      </Paragraph>

      <Header>
        Standing next to our Trustor organizations when they needed it most
      </Header>

      <Paragraph>
        From May through September of 2020, Reta granted Trustors an 18-23%
        reduction in monthly premium costs, accelerated premium credits and
        waived a portion of administrative fees. Reta also continues to offer
        100% coverage to members for any testing, doctor visits, hospitalization
        and treatment related to the Coronavirus.
      </Paragraph>

      <Video
        poster={imgRome}
        float="right"
        style={{ paddingLeft: 25 }}
        videoUrl="https://assets.retatrust.org/2021/210421.1/Rome+P2.mp4"
      >
        Andrea Huckaba Rome — Vice President of Lewis & Ellis
      </Video>

      <Paragraph>
        Reta was able to accomplish this for a few reasons. Reta was
        experiencing an actual savings over our year-to-date projected costs. We
        unbundled Wellness and made it an opt-in program. Plus we postponed
        non-essential benefit programs. With our flexibility as a self-governed
        healthcare trust, we were able to pass these savings on to our member
        Trustors in short order.
      </Paragraph>

      <Paragraph>
        As our Trustors know, Reta’s premium rates are set by estimating future
        health costs based on the actual experience of incurred claims over the
        prior two years. For larger Trustors, their own claims are used in these
        projections, while the rates for small groups are based on an adjustment
        tied to the trend of the entire Trust combined.
      </Paragraph>

      <Paragraph>
        Under normal circumstances, it’s fairly easy for actuaries to predict
        what actual claims will be by looking at the experience of Reta’s large,
        covered population. However, the first six months of 2020 were anything
        but normal. So we asked our actuaries from Lewis & Ellis what benchmarks
        they would use in determining premium rates for our 2021-22 plan year
        with the unprecedented impact of the pandemic.
      </Paragraph>

      <Header>Pillar II summary and closing</Header>

      <Paragraph>
        <i>Standing next to our Trustors</i> is our commitment. What it means to
        us is to continually assess what Reta can do to respond to Trustors
        needs… during good times and especially in challenging times like these.
      </Paragraph>

      <Video
        poster={imgMcGowan}
        float="right"
        style={{ paddingLeft: 25 }}
        videoUrl="https://assets.retatrust.org/2021/210421.1/McGowan+P2.mp4"
      >
        Kathryne McGowan — CEO, Reta Trust
      </Video>

      <Paragraph>
        Of course, we take our fiduciary responsibilities to Trustors, members
        and the Reta Trust very seriously. That means first we listen and try to
        anticipate what our Trustors need from us. Then we consider how we can
        respond without undue cost shifting to members. And thirdly, we need to
        make sure our response doesn’t impact the financial strength of the Reta
        Trust. That means being both responsive and responsible.
      </Paragraph>
    </Pillar>
  );
};

export default Pillar2;
