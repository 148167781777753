import React, { useContext, useEffect } from 'react';
import { FirebaseContext } from '../../contexts/firebase';
import { pubScroll, vault } from '../../constants/routes';
import Spinner from '../shared/Spinner';
import Fullscreen from '../shared/Fullscreen';
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import Profile from '../member/Profile';
import AdminMenu from './admin/AdminMenu';
import Routes from './Routes';
import Resources from './Resources';
import {
  Canvas,
  Sidebar,
  SideContent,
  Content,
  BackToReta,
} from '../member/Home';

const Home = () => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const { isAuthenticated, initialized, profile } = useContext(FirebaseContext);

  useEffect(() => {
    if (!initialized) {
      return;
    }

    if (!isAuthenticated) {
      history.push({
        pathname: pubScroll.LOGIN,
        search: `?next=${encodeURIComponent(
          location.pathname + location.search,
        )}`,
      });
    }
  }, [
    history,
    initialized,
    isAuthenticated,
    location.pathname,
    location.search,
  ]);

  /**
   * If not an active user, redirect to the unauthorized message
   */
  useEffect(() => {
    if (profile && !profile.vault?.isActive) {
      return history.push(vault.NOT_AUTHORIZED);
    }
  }, [history, profile]);

  if (!isAuthenticated || !profile) {
    return (
      <Fullscreen>
        <Spinner />
      </Fullscreen>
    );
  }

  return (
    <Canvas>
      <Sidebar>
        <SideContent>{profile.vault?.isActive && <Resources />}</SideContent>

        {profile.vault?.isActive && profile.vault?.isAdmin && (
          <Route path={`${match.path}/admin`} component={AdminMenu} />
        )}

        <Profile vault />
      </Sidebar>

      <Content>
        <Routes />
      </Content>

      <BackToReta />
    </Canvas>
  );
};

export default Home;
