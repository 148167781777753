import React, { useContext } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { MessagesContext } from '../../contexts/messages';

const CloseWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
`;

const Close = ({ id }) => {
  const { removeMessage } = useContext(MessagesContext);

  return <CloseWrapper onClick={() => removeMessage(id)}>X</CloseWrapper>;
};

const MessageWrapper = styled.div`
  max-width: 300px;
  min-width: 150px;
  max-height: 300px;
  overflow-y: auto;
  padding: 12px;
  padding-right: 28px;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 1.2;
  margin-top: 4px;
  color: ${props => {
    switch (props.type) {
      case 'error':
        return 'red';
      case 'warn':
        return '#c37e00';
      default:
        return 'green';
    }
  }};
  background-color: ${props => {
    switch (props.type) {
      case 'error':
        return 'rgba(255, 202, 198, 0.8)';
      case 'warn':
        return 'rgba(255, 233, 203, 0.8)';
      default:
        return 'rgba(211, 255, 205, 0.8)';
    }
  }};
  border-top: 6px solid
    ${props => {
      switch (props.type) {
        case 'error':
          return 'red';
        case 'warn':
          return 'orange';
        default:
          return 'green';
      }
    }};
  position: relative;

  & > p {
    margin-bottom: 6px;
  }

  & > p:last-child {
    margin-bottom: 0;
  }
`;

const progress = keyframes`
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
`;

const ProgressBar = styled.div`
  position: absolute;
  opacity: 0.5;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: ${props => {
    switch (props.type) {
      case 'error':
        return 'red';
      case 'warn':
        return 'orange';
      default:
        return 'green';
    }
  }};
  animation: ${progress} ${props => props.duration / 1000}s linear;
`;

export const Message = ({ id, type, children, expires }) => {
  return (
    <MessageWrapper type={type}>
      <Close id={id} />

      <ProgressBar type={type} duration={expires} />

      {children}
    </MessageWrapper>
  );
};
