import React, { useContext } from 'react';
import { COLORS } from '../../constants/colors';
import { Link } from 'react-router-dom';
import { pub, pubScroll } from '../../constants/routes';
import { FeaturesContext } from '../../contexts/features';

const Contact = ({
  name,
  title,
  location,
  cv,
  id,
  showContactForm,
  ...props
}) => {
  const features = useContext(FeaturesContext);

  return (
    <div
      style={{
        fontSize: 12,
        lineHeight: '16px',
        marginBottom: 10,
        ...props.style,
      }}
    >
      {name && (
        <div
          style={{
            fontWeight: 'bold',
            color: COLORS.RED,
          }}
        >
          {name}
        </div>
      )}
      {title && (
        <div
          style={{
            fontWeight: 'bold',
            color: COLORS.BLUE,
          }}
        >
          {title}
        </div>
      )}
      {console.log('LOC', location)}
      {location && (
        <div
          style={{
            color: COLORS.BLUE,
          }}
        >
          {location}
        </div>
      )}
      {features.active('contactForm') && showContactForm && (
        <Link
          style={{
            color: COLORS.RED,
          }}
          to={`${
            features.active('scrollingLayout')
              ? pubScroll.CONTACT_FORM
              : pub.CONTACT_FORM
          }/${id}#content`}
        >
          (Send Message)
        </Link>
      )}
    </div>
  );
};

export default Contact;
