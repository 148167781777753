import React, { useEffect, useContext, useState, useReducer } from 'react';
import styled from 'styled-components/macro';
import Header from '../shared/Header2';
import Search from '../shared/Search';
import { FirebaseContext } from '../../contexts/firebase';
import AdminCategoryItem from './AdminCategoryItem';
import { MessagesContext } from '../../contexts/messages';
import CheckboxItem from '../shared/CheckboxItem';
import CategoryItem from './CategoryItem';
import HTML5Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { member } from '../../constants/routes';
import { Link } from 'react-router-dom';
import BiosList from './ResourceBios';

const Container = styled.div`
  position: relative;
  padding: 30px 22px;
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
`;

const StyledHeader = styled(Header)`
  margin-bottom: 28px;
`;

const Toggle = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

const Resources = () => {
  const { profile, database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const [categories, setCategories] = useState([]);
  const [filter, setFilter] = useState();
  const [showAdmin, setShowAdmin] = useState(profile.isAdmin);

  const [categoriesByGroup, dispatchCategoriesByGroup] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'add':
          return {
            ...state,
            [action.id]: action.docs,
          };
        default:
          throw new Error();
      }
    },
    {},
  );

  /**
   * Get the categories for each group the user is assigned to.
   */
  useEffect(() => {
    const unsubs = [];
    profile.groups.forEach(group => {
      unsubs.push(
        database
          .collection('categories')
          .where('visible', '==', true)
          .where('groups', 'array-contains', group)
          .onSnapshot(
            query => {
              if (query.empty) {
                return;
              }
              dispatchCategoriesByGroup({
                type: 'add',
                id: group.id,
                docs: query.docs,
              });
            },
            err => {
              console.error(err);
              addMessage(
                'Unable to get categories for resources list.',
                'error',
              );
            },
          ),
      );
    });

    return () => {
      unsubs && unsubs.length && unsubs.forEach(fn => fn());
    };
  }, [addMessage, database, profile]);

  /**
   * Pull together a single, sorted list of categories
   */
  useEffect(() => {
    const byCategoryId = Object.keys(categoriesByGroup)
      // reduce to a single array
      .reduce((acc, item) => {
        return acc.concat(categoriesByGroup[item]);
      }, [])
      // order by category id
      .reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});

    // reduce to a single array of categories
    const categories = Object.keys(byCategoryId)
      .reduce((acc, item) => {
        return acc.concat(byCategoryId[item]);
      }, [])
      .map(d => ({ ...d.data(), id: d.id, ref: d.ref }))
      .sort((a, b) => a.order - b.order);

    setCategories(categories);
  }, [categoriesByGroup]);

  return (
    <Container>
      <Link to={member.MEMBER_HOME}>
        <StyledHeader>Resources</StyledHeader>
      </Link>

      <Search placeholder="Filter Resources" onChange={setFilter} />

      {/* Admin Category List */}
      {showAdmin && (
        <DndProvider backend={HTML5Backend}>
          {categories.map((cat, idx) => (
            <AdminCategoryItem
              key={cat.id}
              category={cat}
              filter={filter}
              order={idx}
              allCategories={categories}
            />
          ))}
        </DndProvider>
      )}

      {/* Non Admin View */}
      {!showAdmin &&
        categories.map((cat, idx) => (
          <CategoryItem key={cat.id} category={cat} filter={filter} />
        ))}

      <BiosList />

      {/* Show the admin toggle if isAdmin */}
      {profile.isAdmin && (
        <Toggle>
          <CheckboxItem
            style={{ marginBottom: 0 }}
            label="Show Admin View"
            checked={showAdmin}
            onChange={checked => setShowAdmin(checked)}
          />
        </Toggle>
      )}
    </Container>
  );
};

export default Resources;
