import React from 'react';
import { COLORS } from '../../constants/colors';
import { Text } from './Text';

export const Header = ({ children, style, ...props }) => {
  return (
    <div
      style={{
        width: '100%',
        textAlign: 'center',
        marginTop: 80,
        marginBottom: 24,
      }}
      {...props}
    >
      <Text
        style={{
          color: COLORS.LEFT_SIDE_HEADER,
          fontFamily: 'Titillium Web',
          fontSize: 27,
          fontWeight: 300,
          letterSpacing: '0.03em',
          ...style,
        }}
      >
        {children}
      </Text>
    </div>
  );
};
