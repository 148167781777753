import React, { useState, useContext, useEffect } from 'react';
import Canvas from '../shared/Canvas';
import TextInput from '../../shared/TextInput';
import Label from '../../shared/Label';
import CheckboxItem from '../../shared/CheckboxItem';
import { FirebaseContext } from '../../../contexts/firebase';
import { MessagesContext } from '../../../contexts/messages';
import Header from '../../shared/Header2';
import CheckRow from '../shared/CheckRow';
import Spinner from '../../shared/Spinner';
import TextArea from '../../shared/TextArea';
import Delete from '../shared/Delete';
import styled from 'styled-components/macro';
import { useParams, useRouteMatch } from 'react-router-dom';
import TrustorDropDown from '../users/TrustorList';
import { Group } from '../resources/ResourceAdd';
import UploadFile from '../../shared/UploadFile';
import Poster from '../../carousel/Poster';
import { VideoModal } from '../../carousel/VideoModal';

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  margin-bottom: 24px;
`;

const CarouselUpdate = () => {
  const { firebase, database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const { carouselId } = useParams();
  const match = useRouteMatch();

  const [showVideo, setShowVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [carousel, setCarousel] = useState({});
  const [validUrl, setValidUrl] = useState(false);
  const [validThumbnailUrl, setValidThumbnailUrl] = useState(false);
  const [trustorName, setTrustorName] = useState();

  /**
   * Subscribe to the carousel
   */
  useEffect(() => {
    setFetching(true);
    const unsub = database.doc(`/carousel/${carouselId}`).onSnapshot(
      doc => {
        setFetching(false);
        setInitialized(true);

        if (!doc.exists) {
          addMessage('Unable to get carousel.', 'error');
          return;
        }

        setCarousel(doc.data());
      },
      e => {
        console.error(e);
        addMessage('Unable to get carousel.', 'error');
        setFetching(false);
        setInitialized(true);
      },
    );

    return () => unsub && typeof unsub === 'function' && unsub();
  }, [addMessage, database, carouselId]);

  /**
   * Get Trustor Info
   */
  useEffect(() => {
    if (!carousel || !carousel.trustor) return;

    if (typeof carousel.trustor === 'string') {
      setTrustorName(carousel.trustor);
    } else if (carousel.trustor.id) {
      (async () => {
        const doc = await carousel.trustor.get();
        setTrustorName(doc.data().displayName);
      })();
    }
  }, [carousel]);

  /**
   * Update the information in the database
   *
   * @param {string} path Firebase field to update
   * @param {*} value Value for the firebase field
   * @param {function} setSpinner enable or disable the spinner
   */
  const update = async (path, value, setSpinner) => {
    setSpinner && setSpinner(true);

    // if blank, remove the field
    if (typeof value !== 'boolean' && (!value || !value.length)) {
      value = firebase.firestore.FieldValue.delete();
    }

    try {
      await database.doc(`/carousel/${carouselId}`).update({ [path]: value });
      addMessage(`Successfully updated "${carousel.name}".`);
    } catch (e) {
      addMessage(`Unable to update "${carousel.name}".`, 'error');
    }

    setSpinner && setSpinner(false);
  };

  const handleTrustor = async (trustor, setSpinner) => {
    setSpinner(true);

    // if blank, remove the field
    if (!trustor) {
      trustor = {
        ref: firebase.firestore.FieldValue.delete(),
        displayName: firebase.firestore.FieldValue.delete(),
      };
    }

    try {
      await database
        .doc(`/carousel/${carouselId}`)
        .update({ trustor: trustor.ref });
      addMessage(`Successfully updated "${carousel.name}".`);
    } catch (e) {
      addMessage(`Unable to update "${carousel.name}".`, 'error');
    }

    setSpinner(false);
  };

  const exists = Object.keys(carousel).length > 0;

  return (
    <Canvas>
      <Row style={{ marginBottom: 45 }}>
        <Header>Update Carousel Information</Header>
        <Delete to={`${match.url}/delete`} />
      </Row>

      {fetching && (
        <Spinner size={20} message="Fetching carousel information..." />
      )}

      {initialized && !fetching && !exists && (
        <div>Unable to locate carousel.</div>
      )}

      {initialized && !fetching && exists && (
        <React.Fragment>
          {/* Preview */}
          <Poster
            item={{
              ...carousel,
              thumbnail: carousel.thumbnailUrl,
              video: carousel.videoUrl,
              title: [carousel.name, carousel.title, trustorName],
            }}
            onClickPlay={url => {
              setShowVideo(true);
              setVideoUrl(url);
            }}
          />

          {/* Video Modal */}
          {showVideo && (
            <VideoModal
              videoUrl={videoUrl}
              onHideModal={() => setShowVideo(false)}
            />
          )}

          {/* Display Name */}
          <TextInput
            label="Name:"
            placeholder="Example: Bob Smith"
            value={carousel.name}
            onConfirm={(v, setSpinner) => update('name', v, setSpinner)}
          />

          <TextInput
            label="Title:"
            placeholder="Example: CFO"
            value={carousel.title}
            onConfirm={(v, setSpinner) => update('title', v, setSpinner)}
          />

          <Group>
            <Label className="label">* Trustor Info</Label>

            <TrustorDropDown
              defaultValue={
                carousel.trustor && carousel.trustor.id
                  ? carousel.trustor.id
                  : 'NONE'
              }
              onChange={handleTrustor}
            />

            <div className="or">
              <div>or</div>
            </div>

            <TextInput
              label="Custom Info:"
              placeholder="Custom Trustor Information"
              value={
                typeof carousel.trustor === 'string' ? carousel.trustor : ''
              }
              onConfirm={(v, setSpinner) => update('trustor', v, setSpinner)}
            />
          </Group>

          {/* Thumbnail */}

          <Group>
            <Label className="label">* Thumbnail File or URL</Label>

            <UploadFile
              style={{ alignItems: 'flex-start' }}
              label="Thumbnail:"
              dest={{
                gsPath: `/carousel/${carouselId}/thumbnail.png`,
                firestorePath: `/carousel/${carouselId}`,
                firestoreField: 'thumbnailUrl',
              }}
              resize={{
                resize: '269>',
                gsPath: `/carousel/${carouselId}/thumbnail.png`,
                firestorePath: JSON.stringify([`/carousel/${carouselId}`]),
                firestoreField: 'thumbnailUrl',
              }}
            />

            <div className="or">
              <div>or</div>
            </div>

            <TextInput
              label="URL:"
              type="url"
              placeholder="Exampe: https://example.com"
              value={carousel.thumbnailUrl}
              onValidate={setValidThumbnailUrl}
              onConfirm={async (v, setSpinner) => {
                if (!validThumbnailUrl) {
                  return;
                }
                await update('thumbnailUrl', v, setSpinner);
              }}
            />
          </Group>

          {/* Update File or URL */}
          <Group>
            <Label className="label">* File or URL</Label>

            <UploadFile
              label="Video:"
              dest={{
                gsPath: `/carousel/${carouselId}/{basename}.{ext}`,
                firestorePath: `/carousel/${carouselId}`,
                firestoreField: 'videoUrl',
              }}
              onUpload={async url => {
                console.log(url);
                await update('videoUrl', url);
              }}
            />

            <div className="or">
              <div>or</div>
            </div>

            <TextInput
              label="URL:"
              type="url"
              placeholder="Exampe: https://example.com"
              value={carousel.videoUrl}
              onValidate={setValidUrl}
              onConfirm={async (v, setSpinner) => {
                if (!validUrl) {
                  return;
                }
                await update('videoUrl', v, setSpinner);
              }}
            />
          </Group>

          {/* Notes */}
          <TextArea
            label="Notes:"
            style={{ marginBottom: 42 }}
            onConfirm={(v, setSpinner) => update('notes', v, setSpinner)}
            placeholder="(optional)"
            value={carousel.notes}
          />

          {/* Options */}
          <Row style={{ marginBottom: 16 }}>
            <Label>Options:</Label>
          </Row>

          <CheckRow>
            <CheckboxItem
              label="Active"
              checked={carousel.active}
              onChange={(checked, setSpinner) =>
                update('active', checked, setSpinner)
              }
            />
          </CheckRow>
        </React.Fragment>
      )}
    </Canvas>
  );
};

export default CarouselUpdate;
