import React from 'react';
import imgDelete from '../../../assets/img/delete-header.svg';
import { Link } from 'react-router-dom';

const Delete = ({ to }) => {
  return (
    <Link to={to} style={{ paddingLeft: 16 }}>
      <img src={imgDelete} alt="X" />
    </Link>
  );
};

export default Delete;
