import React, { useContext, useState, useRef } from 'react';
import { FirebaseContext } from '../../contexts/firebase';
import { MessagesContext } from '../../contexts/messages';
import Header from '../shared/Header2';
import { ProfileImg, ProfileRow } from '../admin/users/UserUpdate';
import { Wrapper } from '../admin/Home';
import UploadFile from '../shared/UploadFile';
import TextInput from '../shared/TextInput';
import imgUser from '../../assets/img/user.svg';
import styled from 'styled-components/macro';
import ButtonField from '../shared/ButtonField';
import Paragraph from '../shared/Paragraph';

const StyledHeader = styled(Header)`
  margin-bottom: 42px;
`;

const ProfileUpdate = () => {
  const { firebase, database, user, profile } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const [showVerification, setShowVerification] = useState(false);
  const [verifyId, setVerifyId] = useState();

  const ref = useRef();

  /**
   * Update the information in the user profile
   *
   * @param {string} path Firebase field to update
   * @param {*} value Value for the firebase field
   * @param {function} setSpinner enable or disable the spinner
   */
  const updateProfile = async (path, value, setSpinner) => {
    setSpinner && setSpinner(true);

    try {
      await database.doc(`/users/${user.uid}`).update({ [path]: value });
      addMessage(`Successfully updated "${profile.displayName}".`);
    } catch (e) {
      addMessage(`Unable to update "${profile.displayName}".`, 'error');
    }

    setSpinner && setSpinner(false);
  };

  /**
   * Update a authentication information with the new value.
   *
   * @param {string} path path to firebase field to update.
   * @param {*} value new value for the firebase field.
   * @param {function} setSpinner function to set the spinner state for the input field.
   */
  const updateAuth = async (path, value, setSpinner) => {
    setSpinner && setSpinner(true);
    await firebase.auth().currentUser.updateProfile({ [path]: value });
    setSpinner && setSpinner(false);
  };

  /**
   * Update the email.
   *
   * @param {string} value new email
   * @param {function} setSpinner
   */
  const updateEmail = async (value, setSpinner) => {
    setSpinner(true);
    try {
      await firebase.auth().currentUser.updateEmail(value);
      addMessage('Successfully updated email.');
    } catch (e) {
      console.error(e);
      switch (e.code) {
        case 'auth/invalid-email':
          addMessage('Invalid Email.', 'warn');
          break;
        case 'auth/email-already-in-use':
          addMessage('Email already in use.', 'warn');
          break;
        case 'auth/requires-recent-login':
          addMessage('You will need to re-login to change email.', 'warn');
          break;
        default:
          addMessage('Unable to update email.', 'error');
      }
    }
    setSpinner(false);
  };

  /**
   * First step in the phone number update process, send a verification code.
   *
   * @param {string} phone A valid phone number. Ex: +14155551234
   * @param {function} setSpinner A function to set the state of the input field spinner
   */
  const updatePhone = async (phone, setSpinner) => {
    setSpinner(true);

    // remove miscellaneous characters from phone number
    phone = phone.replace(/[-\s()]/g, '');

    // create an element for the recaptcha
    const elem = document.createElement('div');
    elem.setAttribute('id', 'recaptcha');
    ref.current.appendChild(elem);

    // Recpatcha Verifier
    const applicationVerifier = new firebase.auth.RecaptchaVerifier(elem, {
      size: 'invisible',
      callback: async response => {
        console.log('reCAPTCHA solved', response);
      },
    });

    // Start the verification process
    try {
      const provider = new firebase.auth.PhoneAuthProvider();
      const verificationId = await provider.verifyPhoneNumber(
        phone,
        applicationVerifier,
      );
      setVerifyId(verificationId);
    } catch (e) {
      console.error(e);
      addMessage(['Unable to send verification code.', e.code], 'warn');
      setSpinner(false);
      applicationVerifier.clear();
      ref.current.removeChild(elem);
      return;
    }

    setSpinner(false);
    applicationVerifier.clear();
    ref.current.removeChild(elem);
    setShowVerification(true);
  };

  /**
   * Second step, submit the verification code.
   */
  const submitVerificationCode = async verificationCode => {
    const phoneCredentials = firebase.auth.PhoneAuthProvider.credential(
      verifyId,
      verificationCode,
    );

    try {
      await firebase.auth().currentUser.updatePhoneNumber(phoneCredentials);
      addMessage('Successfully updated phone number.');
      setShowVerification(false);
    } catch (e) {
      console.error(e);
      switch (e.code) {
        case 'auth/invalid-verification-code':
          addMessage('Invalid verification code.', 'warn');
          break;
        case 'auth/invalid-verification-id':
          addMessage('Invalid verification id.', 'warn');
          break;
        default:
          addMessage('Unable to update phone number.', 'error');
          setShowVerification(false);
          break;
      }
    }
  };

  return (
    <Wrapper ref={ref}>
      <StyledHeader>Update Profile</StyledHeader>

      {/* Profile Picture */}
      <ProfileRow>
        <ProfileImg
          className={profile.photo && profile.photo.url ? 'has-image' : ''}
          src={profile.photo && profile.photo.url ? profile.photo.url : imgUser}
        />
        <UploadFile
          label="Profile Image:"
          dest={{
            gsPath: `/users/${user.uid}/images/profile.{epoch}.orig.{ext}`,
            firestorePath: `/users/${user.uid}`,
            firestoreField: 'photo.originalUrl',
          }}
          resize={{
            resize: '256>',
            gsPath: `/users/${user.uid}/images/profile.{epoch}.png`,
            firestorePath: JSON.stringify([`/users/${user.uid}`]),
            firestoreField: 'photo.url',
          }}
        />
      </ProfileRow>

      {/* Display Name */}
      <TextInput
        label="Display Name:"
        placeholder="Example: John Smith"
        value={user.displayName || profile.displayName || ''}
        onConfirm={async (v, setSpinner) => {
          setSpinner(true);
          await updateAuth('displayName', v);
          await updateProfile('displayName', v);
          setSpinner(false);
        }}
      />

      {/* Email */}
      <TextInput
        value={user.email || ''}
        label="Email:"
        type="email"
        placeholder="Example: smith@example.com"
        validationMessage="Please enter a valid email address. Example: name@example.com"
        onConfirm={(v, setSpinner) => updateEmail(v, setSpinner)}
      />

      {/* Phone Number */}
      {!showVerification && (
        <TextInput
          label="Phone Number:"
          type="phone"
          value={user.phoneNumber || ''}
          placeholder="Example: +1 123-456-7890"
          validationMessage="A valid phone number is required.  Example: +1 123-456-7890."
          onConfirm={(v, setSpinner) => updatePhone(v, setSpinner)}
        />
      )}

      {/* SMS Verification */}
      {showVerification && (
        <React.Fragment>
          <Paragraph>
            You should receive a text message shortly. Please enter the 6 digit
            verification code below.
          </Paragraph>

          <ButtonField
            type="verification-code"
            placeholder="Enter the 6 digit verification code"
            buttonLabel="Send Verification"
            validationMessage="Enter the verification code.  It should be at least six digits."
            onClick={code => submitVerificationCode(code)}
          />
        </React.Fragment>
      )}

      {/* Title */}
      <TextInput
        label="Title:"
        value={profile.title}
        onConfirm={(v, setSpinner) => updateProfile('title', v, setSpinner)}
      />
    </Wrapper>
  );
};

export default ProfileUpdate;
