import React from 'react';
import { COLORS } from '../../constants/colors';
import styled, { keyframes } from 'styled-components/macro';

const rotateplane = keyframes`
  0% { 
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  } 50% { 
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  } 100% { 
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
`;

const Widget = styled.div`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  background-color: ${props => (props.color ? props.color : COLORS.RED)};
  animation: ${rotateplane} 1.2s infinite ease-in-out;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Message = styled.div`
  font-family: Roboto;
  font-size: 14px;
  color: #495b5e;
  margin-left: 16px;
`;

export const Spinner = ({ message, ...props }) => {
  return (
    <Container>
      <Widget {...props} />
      {message && <Message>{message}</Message>}
    </Container>
  );
};

Spinner.defaultProps = {
  size: 30,
};

export default Spinner;
