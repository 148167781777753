import React, { useState, useEffect, useContext } from 'react';
import Header from '../../shared/Header2';
import Canvas from '../shared/Canvas';
import Row from '../shared/Row';
import TextInput from '../../shared/TextInput';
import TextArea from '../../shared/TextArea';
import Button from '../../shared/Button';
import { FirebaseContext } from '../../../contexts/firebase';
import Spinner from '../../shared/Spinner';
import { admin } from '../../../constants/routes';
import { MessagesContext } from '../../../contexts/messages';
import GroupsCheckList from '../groups/GroupsCheckList';
import Label from '../../shared/Label';
import CheckboxItem from '../../shared/CheckboxItem';
import CheckRow from '../shared/CheckRow';

const CategoryAdd = ({ match, history }) => {
  const { database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const [displayName, setDisplayName] = useState('');
  const [valid, setValid] = useState(false);
  const [adding, setAdding] = useState(false);
  const [notes, setNotes] = useState('');
  const [groups, setGroups] = useState([]);
  const [options, setOptions] = useState({
    visible: true,
    showArchive: false,
  });

  /**
   * Validate the form
   */
  useEffect(() => {
    const isValid = displayName && displayName.length > 0;
    setValid(isValid);
  }, [displayName]);

  /**
   * Add the order from the URL
   */
  useEffect(() => {
    setOptions(prev => ({
      ...prev,
      order: match.params.orderId ? parseInt(match.params.orderId) : 0,
    }));
  }, [match.params.orderId]);

  /**
   * Add the category
   */
  const handlAdd = async () => {
    setAdding(true);
    const data = { displayName, groups: groups.map(g => g.ref), ...options };
    if (notes) {
      data.notes = notes;
    }
    try {
      const docRef = await database.collection('/categories').add(data);

      setAdding(false);
      history.push(`${admin.CATEGORIES}/${docRef.id}`);
      addMessage(`Successfully added category "${displayName}"`);
    } catch (e) {
      console.error(e);
      addMessage(`Unable to add category "${displayName}"`, 'error');
      setAdding(false);
    }
  };

  return (
    <Canvas>
      <Row style={{ marginBottom: 45 }}>
        <Header>Create Category</Header>
      </Row>
      <TextInput
        label="* Display Name:"
        placeholder="Required Field. Example: Finance Committee"
        onDebounced={setDisplayName}
      />
      <TextArea
        label="Notes:"
        style={{ marginBottom: 42 }}
        onDebounced={setNotes}
      />

      {/* Options */}
      <Row style={{ marginBottom: 16 }}>
        <Label>Options:</Label>
      </Row>
      <CheckRow>
        <CheckboxItem
          label="Visible"
          checked={options.visible}
          onChange={checked => setOptions({ ...options, visible: checked })}
        />
        <CheckboxItem
          label="Show Archive"
          checked={options.showArchive}
          onChange={checked => setOptions({ ...options, showArchive: checked })}
        />
      </CheckRow>

      {/* Groups */}
      <GroupsCheckList
        onChange={(group, checked) => {
          if (checked) {
            setGroups(prev => prev.concat([group]));
          } else {
            setGroups(prev => prev.filter(g => g.id !== group));
          }
        }}
      />
      {!adding && (
        <Row>
          <Button invert onClick={() => history.push(admin.CATEGORIES)}>
            Cancel
          </Button>
          <Button disabled={!valid} onClick={handlAdd}>
            Add Category
          </Button>
        </Row>
      )}
      {adding && <Spinner />}
    </Canvas>
  );
};

export default CategoryAdd;
