import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { vault } from '../../../constants/routes';
import NoMatch from '../../shared/NoMatch';
import Categories from './categories/Categories';
import CategoryAdd from './categories/CategoryAdd';
import CategoryUpdate from './categories/CategoryUpdate';
import CategoryDelete from './categories/CategoryDelete';
import ResourceAdd from './resources/ResourceAdd';
import ResourceUpdate from './resources/ResourceUpdate';
import ResourceDelete from './resources/ResourceDelete';

const Routes = () => {
  return (
    <Switch>
      <Route exact path={vault.admin.HOME}>
        <Redirect to={vault.admin.CATEGORIES} />
      </Route>

      {/* Categories */}
      <Route exact path={vault.admin.CATEGORIES} component={Categories} />
      <Route exact path={vault.admin.CATEGORY_ADD} component={CategoryAdd} />
      <Route
        exact
        path={`${vault.admin.CATEGORY_ADD}/:orderId`}
        component={CategoryAdd}
      />
      <Route exact path={vault.admin.CATEGORY} component={CategoryUpdate} />
      <Route
        exact
        path={vault.admin.CATEGORY_DELETE}
        component={CategoryDelete}
      />

      {/* Resources */}
      <Route exact path={vault.admin.RESOURCE_ADD} component={ResourceAdd} />
      <Route
        exact
        path={`${vault.admin.RESOURCE_ADD}/:orderId`}
        component={ResourceAdd}
      />
      <Route exact path={vault.admin.RESOURCE} component={ResourceUpdate} />
      <Route
        exact
        path={vault.admin.RESOURCE_DELETE}
        component={ResourceDelete}
      />

      <Route path="*" component={NoMatch} />
    </Switch>
  );
};

export default Routes;
