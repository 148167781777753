import React, { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from '../../../contexts/firebase';
import { MessagesContext } from '../../../contexts/messages';
import styled from 'styled-components/macro';
import BulletinItem from './BulletinItem';
import Spinner from '../../shared/Spinner';
import HTML5Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import Paragraph from '../../shared/Paragraph';

const Doc = styled.div`
  display: flex;
  align-items: center;
`;

const List = styled(Doc)`
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const BulletinList = ({ filter = '' }) => {
  const { database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const [fetched, setFetched] = useState(false);
  const [allBulletins, setAllBulletins] = useState([]);
  const [, setArchived] = useState([]);
  const [, setCurrent] = useState([]);
  const [filteredBulletins, setFilteredBulletins] = useState([]);

  /**
   * Subscribe to the Doc list
   */
  useEffect(() => {
    setFetched(false);

    const unsub = database
      .collection('/bulletins')
      .orderBy('order', 'asc')
      .onSnapshot(
        docs => {
          const bulletins = docs.docs
            .map(d => ({
              ...d.data(),
              id: d.id,
              ref: d.ref,
            }))
            .sort((a, b) => a.order - b.order);

          setAllBulletins(bulletins);
          setFetched(true);
        },
        e => {
          console.error(e);
          addMessage('Unable to fetch bulletin list', 'error');
          setFetched(true);
        },
      );

    return () => unsub && typeof unsub === 'function' && unsub();
  }, [addMessage, database]);

  /**
   * Filter the bulletin list based on the search field
   */
  useEffect(() => {
    if (!filter && !allBulletins.length) {
      return;
    }

    if (!filter) {
      return setFilteredBulletins(allBulletins);
    }

    const regex = new RegExp(filter, 'i');

    const filtered = allBulletins.filter(
      g => regex.test(g.title) || regex.test(g.slug),
    );

    setFilteredBulletins(filtered);
  }, [filter, allBulletins]);

  /**
   * Group into current & archived
   */
  useEffect(() => {
    const { current, archived } = filteredBulletins.reduce(
      (a, c) => {
        if (c.archived) {
          a.archived.push(c);
        } else {
          a.current.push(c);
        }

        return a;
      },
      { current: [], archived: [] },
    );

    setCurrent(current);
    setArchived(archived);
  }, [filteredBulletins]);

  const toggleArchive = async bulletin => {
    await database
      .doc(`bulletins/${bulletin.id}`)
      .update({ archived: !bulletin.archived });
  };

  return (
    <List>
      {!fetched && <Spinner />}

      {fetched && (!filteredBulletins || !filteredBulletins.length) && (
        <div>No bulletins found.</div>
      )}

      {filteredBulletins.length > 0 && (
        <Paragraph>
          <DndProvider backend={HTML5Backend}>
            {filteredBulletins.map((bulletin, index) => (
              <BulletinItem
                key={bulletin.id}
                order={index}
                bulletin={bulletin}
                allBulletins={allBulletins}
                onArchive={() => toggleArchive(bulletin)}
              />
            ))}
          </DndProvider>
        </Paragraph>
      )}
    </List>
  );
};

export default BulletinList;
