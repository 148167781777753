import React, { useState, useEffect, useContext } from 'react';
import Header from '../../shared/Header2';
import Canvas from '../shared/Canvas';
import Row from '../shared/Row';
import TextInput from '../../shared/TextInput';
import TextArea from '../../shared/TextArea';
import Button from '../../shared/Button';
import { FirebaseContext } from '../../../contexts/firebase';
import Spinner from '../../shared/Spinner';
import { admin } from '../../../constants/routes';
import { MessagesContext } from '../../../contexts/messages';
import Label from '../../shared/Label';
import CheckboxItem from '../../shared/CheckboxItem';
import CheckRow from '../shared/CheckRow';
import SelectFile from '../../shared/SelectFile';
import { Group } from '../resources/ResourceAdd';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import queryString from 'query-string';

const WideRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const CenterRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Percentage = styled.div`
  color: #951a2f;
  font-family: Titillium Web;
  letter-spacing: 0.03em;
`;

const NewsletterAdd = () => {
  const { firebase, database, storage } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const history = useHistory();
  const { search } = useLocation();

  const [displayName, setDisplayName] = useState();
  const [file, setFile] = useState();
  const [validFile, setValidFile] = useState(false);
  const [fileUrl, setFileUrl] = useState();
  const [validUrl, setValidUrl] = useState(false);
  const [notes, setNotes] = useState();
  const [isActive, setIsActive] = useState(true);
  const [order, setOrder] = useState(0);

  const [percentage, setPercentage] = useState(0);
  const [valid, setValid] = useState(false);
  const [adding, setAdding] = useState(false);

  useEffect(() => {
    const qs = queryString.parse(window.location.search);

    if (qs.order) {
      setOrder(parseInt(qs.order));
      return;
    }

    (async () => {
      const newsletters = await database.collection('/newsletters').get();
      setOrder(newsletters.size + 1);
    })();
  }, [database, search]);

  /**
   * Validate the form
   */
  useEffect(() => {
    const isValid =
      displayName &&
      displayName.length > 0 &&
      ((file !== undefined && validFile) ||
        (fileUrl && fileUrl.length > 0 && validUrl));
    setValid(isValid);
  }, [displayName, file, fileUrl, validUrl, validFile]);

  /**
   * Validate the file input
   */
  useEffect(() => {
    if (file && file.constructor === File) {
      setValidFile(true);
    } else {
      setValidFile(false);
    }
  }, [file]);

  /**
   * Add the category
   */
  const handlAdd = async () => {
    setAdding(true);

    const data = { active: isActive, displayName, order };

    if (notes) {
      data.notes = notes;
    }

    console.debug('Adding newsletter item', data);

    try {
      const docRef = await database.collection('/newsletters').add(data);

      let url = fileUrl;
      if (validFile) {
        url = await uploadFile(docRef.id, file);
      }

      // update db with video files
      await docRef.update({ url });

      setAdding(false);
      history.push(admin.NEWSLETTERS + '/' + docRef.id);
      addMessage(`Successfully added newsletter "${displayName}"`);
    } catch (e) {
      console.error(e);
      addMessage(`Unable to add newsletter "${displayName}"`, 'error');
      setAdding(false);
    }
  };

  const uploadFile = async (newsletterId, file) => {
    const gsPath = `/newsletters/${newsletterId}/${file.name}`;

    await new Promise((resolve, reject) => {
      const uploadTask = storage.child(gsPath).put(file, {
        customMetadata: {
          firestorePath: `/newsletters/${newsletterId}`,
          firestoreField: 'url',
        },
      });

      const unsub = uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        // next callback
        snapshot => {
          const percent =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setPercentage(percent);
        },
        // error callback
        error => {
          console.error('Upload Error:', error);
          addMessage('There was an error uploading the file.', 'error');
          reject();
        },
        // complete
        () => {
          unsub();
          resolve();
        },
      );
    });

    const origUrl = await storage.child(gsPath).getDownloadURL();
    return origUrl;
  };

  return (
    <Canvas style={{ alignItems: 'flex-start' }}>
      <Row style={{ marginBottom: 45 }}>
        <Header>Add Newsletter</Header>
      </Row>

      {/* Title */}
      <TextInput
        label="* Display Name:"
        placeholder="Required Field. Example: Bishop Newsletter #5"
        onDebounced={setDisplayName}
      />

      {/* File */}
      <Group>
        <Label className="label">* File or URL</Label>

        <WideRow>
          <SelectFile label="File to Upload:" onSelectedFile={setFile} />
          {percentage > 0 && <Percentage>{Math.round(percentage)}%</Percentage>}
        </WideRow>

        <div className="or">
          <div>or</div>
        </div>

        <TextInput
          label="URL:"
          type="url"
          validationMessage="A valid URL is required.  Exampe: https://example.com"
          placeholder="Exampe: https://example.com/newsletter.pdf"
          onValidate={setValidUrl}
          onDebounced={setFileUrl}
        />
      </Group>

      {/* Notes */}
      <TextArea
        label="Notes:"
        style={{ marginBottom: 42 }}
        onDebounced={setNotes}
      />

      {/* Options */}
      <Row style={{ marginBottom: 16 }}>
        <Label>Options:</Label>
      </Row>
      <CheckRow>
        <CheckboxItem
          label="Active"
          checked={isActive}
          onChange={checked => setIsActive(checked)}
        />
      </CheckRow>

      {!adding && (
        <Row>
          <Button invert onClick={() => history.push(admin.CATEGORIES)}>
            Cancel
          </Button>
          <Button disabled={!valid} onClick={handlAdd}>
            Add Newsletter
          </Button>
        </Row>
      )}

      {adding && (
        <CenterRow>
          <Spinner />
        </CenterRow>
      )}
    </Canvas>
  );
};

export default NewsletterAdd;
