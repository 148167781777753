import React, { useRef, useState, useContext } from 'react';
import imgFile from '../../assets/img/iconFile.svg';
import styled from 'styled-components/macro';
import Button from '../shared/Button';
import Spinner from '../shared/Spinner';
import TextInput from '../shared/TextInput';
import Label from './Label';
import { FirebaseContext } from '../../contexts/firebase';
import { MessagesContext } from '../../contexts/messages';

const Container = styled.div`
  margin-bottom: 16px;
  box-sizing: border-box;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Img = styled.img`
  margin-right: 16px;
`;

const Filename = styled.div`
  font-family: Roboto;
  font-size: 14px;
  color: #495b5e;
  margin-right: 16px;
  box-sizing: border-box;
`;

const Preview = ({ src, ...props }) => {
  return (
    <div>
      <img alt="Preview" {...props} />
      <TextInput>{src}</TextInput>
    </div>
  );
};

const UploadFile = ({
  label,
  type,
  selectLabel,
  uploadLabel,
  defaultValue,
  dest,
  resize,
  onUpload,
}) => {
  const { firebase, storage } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const [file, setFile] = useState();
  const [uploading, setUploading] = useState(false);
  const [key, setKey] = useState(0);
  const [percentage, setPercentage] = useState(0);

  const ref = useRef();

  const upload = async () => {
    const [result, basename, ext] = file.name.match(/(.+)(?:\.)([^.]+)$/);
    if (!result) {
      return addMessage(
        `Unable to parse file information from "${file.name}"`,
        'warn',
      );
    }
    const epoch = new Date().getTime();
    const gsPath = dest.gsPath
      .replace(/\{basename\}/g, basename)
      .replace(/\{epoch\}/g, epoch)
      .replace(/\{ext\}/g, ext);

    let metadata = {};
    if (resize) {
      metadata = {
        ...resize,
        gsPath: resize.gsPath
          .replace(/\{basename\}/g, basename)
          .replace(/\{epoch\}/g, epoch)
          .replace(/\{ext\}/g, ext),
      };
    }

    setUploading(true);
    console.debug('Uploading to:', gsPath);

    await new Promise((resolve, reject) => {
      const uploadTask = storage.child(gsPath).put(file, {
        customMetadata: metadata,
      });
      const unsub = uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        // next callback
        snapshot => {
          const percent =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setPercentage(percent);
        },
        // error callback
        error => {
          console.error('Upload Error:', error);
          addMessage('There was an error uploading the file.', 'error');
          reject();
        },
        // complete
        () => {
          unsub();
          resolve();
        },
      );
    });

    const origUrl = await storage.child(gsPath).getDownloadURL();
    console.debug('URL', origUrl);
    onUpload(origUrl, gsPath);
    setUploading(false);

    setKey(prev => prev + 1);
  };

  return (
    <Container>
      <Row>
        <Label>{label}</Label>
      </Row>
      <Row>
        {type === 'image' && defaultValue && <Preview src={defaultValue} />}

        <input
          style={{ display: 'none' }}
          ref={ref}
          type="file"
          key={key}
          onChange={e => setFile(e.target.files[0])}
        />

        {uploading && (
          <Spinner
            size={16}
            message={`Uploading... ${Math.round(percentage)}%`}
          />
        )}

        {file && !uploading && (
          <React.Fragment>
            <Img src={imgFile} alt="File Icon" />
            <Filename>{file.name}</Filename>
            <Button onClick={upload}>{uploadLabel}</Button>
          </React.Fragment>
        )}

        {!file && !uploading && (
          <React.Fragment>
            <Img src={imgFile} alt="File Icon" />
            <Button invert onClick={() => ref.current.click()}>
              {selectLabel}
            </Button>
          </React.Fragment>
        )}
      </Row>
    </Container>
  );
};

UploadFile.defaultProps = {
  label: 'Upload File:',
  type: 'file',
  selectLabel: 'Select File',
  uploadLabel: 'Upload File',
  metadata: {},
  onUpload: () => console.log('upload'),
};

export default UploadFile;
