import { useEffect, useRef } from 'react';
import imgArrow from './arrow.png';
import imgLogo from './retaLogo.png';
import ScrollToHash from './ScrollToHash';
import styled from 'styled-components/macro';
import imgBg from './background.jpg';
import Carousel from '../carousel/Carousel';
import Footer from '../footer/Footer';
import Welcome from '../mainScrolling/Welcome';
import RetaAdvantage from '../mainScrolling/RetaAdvantage';
import backToTopImage from '../../assets/img/backToTopImage.png';
import MemberOrgs from '../mainScrolling/MemberOrgs';
import TrustorList from '../memberOrgs/TrustorList';
import { Leadership } from '../mainScrolling/Leadership';
import Team from '../leadership/Team';
import ViewBio from '../member/ViewBio';
import { Switch, Route } from 'react-router-dom';
import { TABLET_WIDTH } from '../../constants/dimensions';
import { useHistory } from 'react-router';
import { pubScroll as routes } from '../../constants/routes';
import ContactForm from '../../views/ContactForm';
import { SmallEmployer } from '../mainScrolling/SmallEmployer';
import RequestProposal from '../mainScrolling/RequestProposal';
import Pillars from '../mainScrolling/pillars/Pillars';
import Back from './Back';
import { Toolbar } from '@material-ui/core';

const Fullscreen = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 64px);
  background-image: url(${imgBg});
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: top;
  background-size: cover;
  z-index: 1;

  & > .center {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;

    & .title {
      padding-top: 32px;
      font-family: Roboto Condensed;
      font-size: 34px;
      color: white;
      line-height: 1.2;

      & > div {
        text-align: center;
      }
    }
  }

  & > .details {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 32px;
    position: absolute;
    bottom: 32px;
    color: #fdca50;
    font-weight: bold;
    font-size: 18px;

    @media (max-width: ${TABLET_WIDTH}px) {
      font-size: 0.8em !important;

      & > div:last-child {
        text-align: right;
      }
    }
  }

  & > .scroll-to {
    position: absolute;
    bottom: 32px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 1001;

    & img {
      cursor: pointer;
    }
  }
`;

const Content = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  min-height: 100vh;

  & > .routes {
    position: relative;
    width: 100%;
  }

  & > .side {
    position: relative;
    flex: 0 0 365px;
    // z-index: -1;
    background-color: white;
    padding-left: 20px;
    box-sizing: border-box;

    @media (max-width: 865px) {
      display: none;
    }

    & > .carousel {
      position: relative;
      width: 345px;
      z-index: 1001;

      & .scroll {
        height: 100vh;
        overflow-y: auto;
      }

      & .background {
        margin-top: 18px;
        background-color: #e2e2ef !important;
        border-radius: 10px;
        overflow: hidden;
      }

      & .wrapper {
        padding: 0;

        & .header {
          margin: 10px 0 !important;

          & span {
            font-weight: inherit !important;
          }
        }

        & .description span {
          font-size: 16px !important;
          line-height: 1.6 !important;
        }

        & .title {
          margin-top: 16px !important;

          span {
            font-size: 12px !important;
          }
        }
      }
    }
  }

  & > .bottom {
    position: absolute;
    bottom: 0;
    left: 6px;
    transform: scale(0.8);

    @media (max-width: ${TABLET_WIDTH}px) {
      display: none;
    }
  }

  & > main {
    flex: 1 1 350px;
    overflow-y: auto;
    background-color: white;
    @media (max-width: 865px) {
      padding: 0px 15px 0px 15px;
    }
  }
`;

const Img = styled.img`
  width: auto;
  height: auto;
  cursor: pointer;
  transform: scale(0.9);
`;

const Root = () => {
  const contentRef = useRef();
  const carouselRef = useRef();
  const scrollRef = useRef();
  const footerRef = useRef();
  const history = useHistory();

  /**
   * Fix the carousel to the top when we get past the fullscreen landing image
   */
  useEffect(() => {
    const listener = e => {
      const el = carouselRef.current;
      if (el) {
        if (window.scrollY + 64 >= window.innerHeight) {
          el.style.position = 'fixed';
          el.style.top = '64px';
        } else {
          el.style.position = 'relative';
          el.style.top = '0px';
        }
      }

      // calculate height of scroll area
      const footer = footerRef.current;

      if (footerRef.current && scrollRef.current) {
        const footerDims = footer.getBoundingClientRect();

        if (window.innerHeight > footerDims.top) {
          scrollRef.current.style.height = `${footerDims.top - 64}px`;
        } else {
          scrollRef.current.style.height = `100vh`;
        }
      }
    };
    window.addEventListener('scroll', listener);
    listener();

    return () => window.removeEventListener('scroll', listener);
  }, []);

  const handleScrollTo = reference => {
    reference?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <>
      <Toolbar />

      <Fullscreen>
        <div className="center">
          <div>
            <img src={imgLogo} alt="Reta Logo" />
          </div>

          <div className="title">
            <div>Quality health plans.</div>
            <div>Catholic values.</div>
          </div>
        </div>

        <div className="details">
          <div>
            <div>Photo by Jason Savage</div>
          </div>
          <div>
            <div>Cathedral of St. Helena</div>
            <div>Diocese of Helena Montana</div>
            <div>A Reta Member Trustor</div>
          </div>
        </div>

        <div className="scroll-to">
          <img
            src={imgArrow}
            onClick={() => {
              handleScrollTo(contentRef);
              history.push({
                pathname: routes.ROOT,
                hash: 'welcome',
                search: history.location.search,
              });
            }}
            alt="V"
          />
        </div>
      </Fullscreen>

      <Content ref={contentRef} id="main">
        <div className="side">
          <div className="carousel" ref={carouselRef}>
            <div className="scroll" ref={scrollRef}>
              <div className="background">
                <Carousel />
              </div>
            </div>
          </div>
        </div>

        <div className="routes" id="content">
          <Toolbar />

          <Switch>
            <Route exact path={routes.ROOT}>
              <ScrollToHash />

              <main style={{ width: '100%', position: 'relative' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'relative',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      position: 'relative',
                      width: '100%',
                    }}
                  >
                    <Welcome />
                    <RetaAdvantage />
                    <MemberOrgs />
                    <Leadership />
                    <SmallEmployer />
                    <RequestProposal />
                  </div>
                </div>
              </main>
            </Route>

            <Route path={routes.TRUSTORS}>
              <ScrollToHash />

              <div
                style={{
                  width: '100%',
                  padding: 32,
                  paddingTop: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'relative',
                }}
              >
                <Back to={`${routes.ROOT}#member-orgs`} />
                <TrustorList />
              </div>
            </Route>

            <Route path={routes.TEAM}>
              <ScrollToHash />

              <div
                style={{
                  width: '100%',
                  padding: 32,
                  paddingTop: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'relative',
                }}
              >
                <Back to={`${routes.ROOT}#leadership`} />
                <Team />
              </div>
            </Route>

            <Route path={routes.TEAM_BIO}>
              <ScrollToHash />

              <div
                style={{
                  width: '100%',
                  padding: 32,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <ViewBio />
              </div>
            </Route>

            <Route path={routes.PILLARS}>
              <ScrollToHash />
              <Pillars />
            </Route>

            <Route path={`${routes.CONTACT_FORM}/:id`}>
              <ScrollToHash />
              <div
                style={{
                  width: '100%',
                  padding: 32,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <ContactForm />
              </div>
            </Route>

            <Route>
              <div
                style={{
                  width: '100%',
                  padding: 32,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Unable to find requested content
              </div>
            </Route>
          </Switch>
        </div>

        <div className="bottom">
          <Img
            src={backToTopImage}
            onClick={() => {
              handleScrollTo(document.querySelector('#home'));
              history.push({
                pathname: routes.ROOT,
                hash: '',
                search: history.location.search,
              });
            }}
            alt="Back to top button"
          />
        </div>
      </Content>

      <Footer ref={footerRef} />
    </>
  );
};

export default Root;
