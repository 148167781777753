import React, { useState, useEffect } from 'react';

export const PreferencesContext = React.createContext({
  showNav: true,
  fullscreen: false,
  showCarousel: true,
  googleMapsApiKey: 'AIzaSyDLCoBRfmYqEODQpdtj29TTjaqoz0W3dNY',
  apiHost: '',
});

export const PreferencesProvider = ({ children }) => {
  const [value, setValue] = useState({
    apiHost: '',
    setApiHost: val => setValue(prev => ({ ...prev, apiHost: val })),
    showCarousel: !/showCarousel=(?:0|false)/.test(window.location.search),
    setShowCarousel: val => setValue(prev => ({ ...prev, showCarousel: val })),
    showLandingLogo: true,
    setShowLandingLogo: val =>
      setValue(prev => ({ ...prev, showLandingLogo: val })),
    showNav: !/showNav=(?:0|false)/.test(window.location.search),
    setShowNav: val => setValue(prev => ({ ...prev, showNav: val })),
    fullscreen: /fullscreen=(?:1|true)/.test(window.location.search),
    googleMapsApiKey: 'AIzaSyDLCoBRfmYqEODQpdtj29TTjaqoz0W3dNY',
  });

  useEffect(() => {
    const fetchApiHost = async () => {
      const res = await fetch(window.location.origin + '/apiHost');
      const _apiHost = await res.text();
      value.setApiHost(_apiHost);
      console.debug('apiHost:', _apiHost);
    };

    fetchApiHost();
    // eslint-disable-next-line
  }, []);

  return (
    <PreferencesContext.Provider value={value}>
      {children}
    </PreferencesContext.Provider>
  );
};
