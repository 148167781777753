import React, { useState, useEffect } from 'react';
import { Img } from '../admin/Home';
import imgLogo from '../../assets/img/logoBio.png';
import Header from '../shared/Header';
import styled from 'styled-components/macro';
import Slideshow from '../member/Slideshow';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div {
    background-color: #d8e6e8;
    padding: 24px;
    box-sizing: border-box;
    border: 1px solid #adbdbf;
    box-shadow: 0px 5px 15px 0 rgba(20, 0, 0, 0.2);
    z-index: 1000;
    opacity: 0.9;
  }
`;

const MemberHome = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    (async () => {
      const res = await fetch(
        `https://ihc.48s.io/api/clients/reta/orgs/images`,
      );
      const json = await res.json();
      setImages(json);
    })();
  }, []);

  return (
    <Wrapper>
      <Slideshow images={images} />
      <Img style={{ position: 'absolute', top: 24, left: 24 }} src={imgLogo} />

      <Container>
        <div>
          <Header style={{ width: 'auto', marginBottom: 24 }}>
            Welcome to the Reta Vault.
          </Header>
          <div>To get started, please select an item from the left.</div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default MemberHome;
