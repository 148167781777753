import styled from 'styled-components/macro';

const ValidationMsg = styled.div`
  width: 100%;
  color: #da7608;
  font-family: Roboto;
  font-style: italic;
  font-size: 12px;
  line-height: ${props => (props.noValidationMessage ? '1.6em' : '1')};
  box-sizing: border-box;
  margin-top: ${props => (props.noValidationMessage ? 8 : 4)}px;

  &.focus {
    margin-top: ${props => (props.noValidationMessage ? 6 : 2)}px;
  }
`;

export default ValidationMsg;
