import React from 'react';
import { POSTER_WIDTH } from '../../constants/dimensions';
import { PlayButton } from './PlayButton';
import { Title } from './Title';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${POSTER_WIDTH}px;
  align-items: center;
`;

const TitleArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 36px;
  margin-bottom: 36px;
  align-items: center;
`;

const PosterArea = styled.div`
  display: flex;
  justify-content: center;
`;

const PosterItem = styled.div`
  width: ${POSTER_WIDTH}px;
  height: 283px;
  flex: 0 0 ${POSTER_WIDTH}px;
  background-color: black;
  background-image: url('${props => props.item.thumbnail}');
  background-size: cover;
  background-position: center;
`;

export const Poster = ({ item, onClickPlay = () => {} }) => {
  return (
    <Wrapper>
      <PosterArea className="poster">
        {/* Video Poster */}
        <PosterItem item={item} />
      </PosterArea>

      <PlayButton
        className="play-button"
        url={item.video}
        onClickPlay={onClickPlay}
      />

      {/* Title */}
      <TitleArea className="title">
        {item.title.map((title, i) => (
          <Title key={i}>{title}</Title>
        ))}
      </TitleArea>
    </Wrapper>
  );
};

export default Poster;
