import React, { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from '../../../contexts/firebase';
import { MessagesContext } from '../../../contexts/messages';
import styled from 'styled-components/macro';
import GroupItem from './GroupItem';
import Spinner from '../../shared/Spinner';

const Group = styled.div`
  display: flex;
  align-items: center;
`;

const List = styled(Group)`
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const GroupList = ({ filter }) => {
  const { database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const [fetched, setFetched] = useState(false);
  const [groups, setGroups] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);

  /**
   * Subscribe to the Group list
   */
  useEffect(() => {
    setFetched(false);

    const unsub = database.collection('/groups').onSnapshot(
      docs => {
        const groups = docs.docs.map(d => ({
          ...d.data(),
          id: d.id,
          ref: d.ref,
        }));
        setGroups(groups);
        setFetched(true);
      },
      e => {
        console.error(e);
        addMessage('Unable to fetch group list', 'error');
        setFetched(true);
      },
    );

    return () => unsub && typeof unsub === 'function' && unsub();
  }, [addMessage, database]);

  /**
   * Filter the group list based on the search field
   */
  useEffect(() => {
    if (!filter && !groups.length) {
      return;
    }

    if (!filter) {
      return setFilteredGroups(groups);
    }

    const regex = new RegExp(filter, 'i');

    const filtered = groups.filter(
      g => regex.test(g.displayName) || regex.test(g.email),
    );

    setFilteredGroups(filtered);
  }, [filter, groups]);

  return (
    <List>
      {!fetched && <Spinner />}

      {fetched && (!filteredGroups || !filteredGroups.length) && (
        <div>No results found.</div>
      )}

      {filteredGroups &&
        filteredGroups.map((group, index) => (
          <GroupItem key={index} group={group} />
        ))}
    </List>
  );
};

GroupList.defaultProps = {
  filter: '',
};

export default GroupList;
