import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import imgDelete from '../../assets/img/delete-item.svg';

const Wrapper = styled(Link)`
  width: auto;
  height: auto;
  cursor: pointer;
`;

const Delete = props => {
  return (
    <Wrapper {...props}>
      <img src={imgDelete} alt="X" />
    </Wrapper>
  );
};

export default Delete;
