import React, { useContext } from 'react';
import Label from '../../shared/Label';
import CheckboxItem from '../../shared/CheckboxItem';
import CheckRow from '../shared/CheckRow';
import Row from '../shared/Row';
import Spinner from '../../shared/Spinner';
import { GroupsContext } from '../../../contexts/groups';

const GroupsCheckList = ({ onChange, selectedIds = [] }) => {
  const { groups, fetching } = useContext(GroupsContext);

  return (
    <React.Fragment>
      <Row style={{ marginBottom: 16 }}>
        <Label>Allow Access To Groups:</Label>
      </Row>

      {fetching && <Spinner />}

      {!fetching && !groups.length && <div>No groups found.</div>}

      {!fetching && groups.length > 0 && (
        <CheckRow style={{ marginBottom: 47 }}>
          {groups.map((group, index) => (
            <CheckboxItem
              label={group.displayName}
              key={index}
              checked={selectedIds.includes(group.id)}
              onChange={(checked, setSpinner) =>
                onChange(group, checked, setSpinner)
              }
            />
          ))}
        </CheckRow>
      )}
    </React.Fragment>
  );
};

export default GroupsCheckList;
