import React from 'react';
import { Text } from './Text';

export const Paragraph = props => {
  return (
    <div
      style={{
        width: '100%',
        marginBottom: 30,
        lineHeight: '2em',
        ...props.style,
      }}
    >
      <Text>{props.children}</Text>
    </div>
  );
};

export default Paragraph;
