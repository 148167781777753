import React, { useState } from 'react';
import Label from './Label';
import styled from 'styled-components/macro';
import Spinner from './Spinner';

const Wrapper = styled.div`
  max-width: 100%;
  margin-bottom: 24px;
`;

const CustomSelect = styled.div`
  & select {
    height: 30px;
    color: #495b5e;
    background-color: #e2ebe9;
    border: 1px solid #88a4a4;
    border-radius: 2px;
    padding: 0 10px;
    box-sizing: border-box;
    font-family: Roboto;
    font-size: 14px;
    outline: none;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Dropdown = ({
  label,
  defaultValue,
  options = [],
  onChange = () => {},
}) => {
  const [updating, setUpdating] = useState(false);

  const handleChange = e => onChange(e.target.value, setUpdating);

  return (
    <Wrapper>
      <Label>{label}</Label>
      <Row>
        <CustomSelect>
          <select defaultValue={defaultValue} onChange={handleChange}>
            {options.map((o, idx) => (
              <option key={idx} value={o.value}>
                {o.name}
              </option>
            ))}
          </select>
        </CustomSelect>

        {updating && <Spinner style={{ marginLeft: 12 }} size={15} />}
      </Row>
    </Wrapper>
  );
};

export default Dropdown;
