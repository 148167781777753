import React from 'react';
import { COLORS } from '../../../constants/colors';

export const TextNew = props => {
  return (
    <span
      style={{
        color: COLORS.TEXT,
        fontSize: 19,
        letterSpacing: '0.02em',
        ...props.style,
      }}
    >
      {props.children}
    </span>
  );
};
