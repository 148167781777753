import React, { useState } from 'react';
import Header from '../../shared/Header2';
import Search from '../../shared/Search';
import ContactsLeadershipList from './ContactsLeadershipList';
import ContactsBoardList from './ContactsBoardList';
import ContactsOutsideList from './ContactsOutsideList';
import Canvas from '../shared/Canvas';
import Row from '../shared/Row';
import Group from '../shared/Group';
import { Title, TeamWrapper } from '../../leadership/Team';
import styled from 'styled-components/macro';

const Column = styled.div`
  flex-direction: column;
  flex: 1 0 100px;
  margin: 0 12px;
`;

const Contacts = () => {
  const [filter, setFilter] = useState(null);

  return (
    <Canvas>
      <Row style={{ marginBottom: 45 }}>
        <Group>
          <Header>Contacts</Header>
        </Group>
        <Search onChange={val => setFilter(val)} />
      </Row>

      <TeamWrapper>
        <Column>
          <Title>Reta Board of Trustees</Title>
          <ContactsBoardList filter={filter} />
        </Column>

        <Column>
          <Title>Reta Leadership</Title>
          <ContactsLeadershipList filter={filter} />
        </Column>

        <Column>
          <Title>Reta Governance Committee Members</Title>
          <ContactsOutsideList filter={filter} />
        </Column>
      </TeamWrapper>
    </Canvas>
  );
};

export default Contacts;
