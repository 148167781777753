import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Checkbox from '@material-ui/core/Checkbox';
import { useContext, useEffect, useState } from 'react';
import { TrustorsContext } from '../../../contexts/trustors';
import styled from 'styled-components/macro';
// import { styled } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: 400,
    overflowY: 'auto',
    paddingTop: 0,
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    backgroundColor: 'inherit',
  },
}));

const StyledList = styled(List)``;

const StyledListSubheader = styled(ListSubheader)`
  background-color: blue;
`;

const TrustorSelector = ({ selected = [], label, onChange = () => {} }) => {
  const classes = useStyles();

  const { trustors } = useContext(TrustorsContext);
  const [filtered, setFiltered] = useState(trustors);
  useEffect(() => {
    setFiltered(trustors);
  }, [trustors]);

  const [search, setSearch] = useState('');
  const handleSearch = e => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (!search.length) {
      return setFiltered(trustors);
    }

    const regex = new RegExp(search, 'ig');
    return setFiltered(
      trustors.filter(trustor => regex.test(trustor.displayName)),
    );
  }, [search, trustors]);

  // TODO: add search
  return (
    <div>
      <ListSubheader>{label}</ListSubheader>
      <StyledList className={classes.root}>
        <StyledListSubheader className={classes.header}>
          <FormControl fullWidth>
            <InputLabel htmlFor="search-adornment">Search</InputLabel>
            <Input
              id="search-adornment"
              onChange={handleSearch}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              variant="filled"
            />
          </FormControl>
        </StyledListSubheader>

        {filtered.map(trustor => {
          return (
            <ListItem
              key={trustor.id}
              dense
              button
              onClick={e => onChange(e.target.name)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selected.includes(trustor.shortName)}
                  name={trustor.shortName}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText id={trustor.id} primary={trustor.displayName} />
            </ListItem>
          );
        })}
      </StyledList>
    </div>
  );
};

export default TrustorSelector;
