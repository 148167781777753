import React, { useContext, useReducer, useEffect } from 'react';
import Header from '../../shared/Header2';
import Editor from '../../shared/Editor';
import styled from 'styled-components/macro';
import Button from '../../shared/Button';
import Spinner from '../../shared/Spinner';
import { MessagesContext } from '../../../contexts/messages';

const Container = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 24px;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 16px;
`;

export const EditorStyle = styled.div`
  & h1 {
    color: #495b5e;
    font-family: Titillium Web;
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    box-sizing: border-box;
    line-height: 1.6;
    margin: 32px 0;

    &:first-child {
      margin-top: 0;
    }
  }

  & h2 {
    font-size: 18px;
    line-height: 1.6;
    color: #951a2f;
    font-family: Titillium Web;
    box-sizing: border-box;
    margin-top: 22px;
  }

  & h3 {
    font-family: Roboto;
    font-weight: bold;
    font-size: 17px;
    line-height: 1.6;
    color: #495b5e;
    margin-bottom: 6px;
    box-sizing: border-box;
  }

  & p,
  .unstyled-body {
    font-size: 16px;
    line-height: 1.6;
    margin: 0.8em 0;
    box-sizing: border-box;
  }

  & ul {
    list-style: none;
    list-style-position: outside;
    margin-bottom: 0.8em;

    & li {
      padding-left: 20px;
      text-indent: -20px;
      margin-bottom: 12px;
      line-height: 1.6em;

      &::before {
        content: '+';
        font-weight: bold;
        color: #951a2f;
        padding: 0 6px;
        line-height: 1.6em;
      }
    }

    & .public-DraftStyleDefault-depth1::before,
    & .public-DraftStyleDefault-depth2::before,
    & .public-DraftStyleDefault-depth3::before,
    & .public-DraftStyleDefault-depth4::before {
      content: '-';
    }

    & ul {
      padding-left: 20px;
      margin-bottom: 0;

      & li {
        &::before {
          content: '-';
        }
      }
    }
  }

  & ol {
    list-style: none;
    counter-reset: li;
    margin-bottom: 0.8em;

    & li::before {
      content: counter(li) '.';
      color: #951a2f;
      display: inline-block;
      padding: 0 6px;
      line-height: 1.6em;
    }

    & li {
      counter-increment: li;
    }
  }
`;

const CustomEditor = ({
  style,
  header,
  raw,
  html,
  handleSave,
  handleChange = () => {},
  handleCancel,
}) => {
  const { addMessage } = useContext(MessagesContext);

  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'LOAD':
          return {
            ...state,
            raw: action.payload.raw ? JSON.parse(action.payload.raw) : null,
            cached: action.payload.raw ? JSON.parse(action.payload.raw) : null,
            html: action.payload.html,
            modified: false,
          };

        case 'UPDATE':
          return {
            ...state,
            raw: action.payload.raw,
            html: action.payload.html,
            modified: true,
          };

        case 'SAVING':
          return {
            ...state,
            saving: true,
          };

        case 'SAVED':
          return {
            ...state,
            cached: { ...state.raw },
            modified: false,
            saving: false,
          };

        default:
          return { ...state };
      }
    },
    // initial state
    {
      raw: null,
      cached: null,
      html: null,
      modified: false,
      saving: false,
    },
  );

  /**
   * Load the editor
   */
  useEffect(() => {
    if (raw || html) {
      dispatch({ type: 'LOAD', payload: { raw, html } });
    }
  }, [raw, html]);

  /**
   * Reset local editor changes
   */
  const onCancel = async () => {
    dispatch({ type: 'LOAD', payload: { raw, html } });
    await handleCancel();
  };

  const onSave = async () => {
    dispatch({ type: 'SAVING' });
    await handleSave({ raw: state.raw, html: state.html });
    dispatch({ type: 'SAVED' });
    addMessage('Saved successfully.');
  };

  const onChange = async (raw, html) => {
    dispatch({ type: 'UPDATE', payload: { raw, html } });
    await handleChange({ raw, html });
  };

  return (
    <Container>
      {header && <Header style={{ marginBottom: 24 }}>{header}</Header>}

      <EditorStyle>
        <Editor style={style} defaultValue={state.cached} onChange={onChange} />
      </EditorStyle>

      {(handleSave || handleCancel) && (
        <Row style={{ justifyContent: 'space-between', marginTop: 16 }}>
          {handleCancel && (
            <Button invert onClick={onCancel}>
              Cancel
            </Button>
          )}

          {handleSave && !state.saving && (
            <Button onClick={onSave} disabled={!state.modified}>
              Save
            </Button>
          )}

          {handleSave && state.saving && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                minWidth: 100,
              }}
            >
              <Spinner size={15} />
            </div>
          )}
        </Row>
      )}
    </Container>
  );
};

export default CustomEditor;
