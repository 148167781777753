import React, { useEffect, useContext } from 'react';
import { Header } from '../shared/Header';
import { Paragraph } from '../shared/newShared/Paragraph';
import { Flexbox } from '../shared/Flexbox';
import { Link } from 'react-router-dom';
import { COLORS } from '../../constants/colors';
import styled from 'styled-components/macro';
import { PreferencesContext } from '../../contexts/preferences';
import { pubScroll as routes } from '../../constants/routes';
import { TrustorsContext } from '../../contexts/trustors';
import { Toolbar } from '@material-ui/core';
import { Content, Strap } from './RetaAdvantage';
/**
 * Register a function that the google maps api will callback after checking
 * the key
 */
window.initMap = () => {
  const map = new window.google.maps.Map(
    document.getElementById('map_canvas'),
    {
      center: { lat: 41.850033, lng: -87.6500523 },
      zoom: 3,
    },
  );

  const bounds = new window.google.maps.LatLngBounds();

  window.trustorData
    .map(t => ({ ...t, name: t.displayName }))
    .forEach(t => {
      if (!t.lat || !t.lng) return;

      const marker = new window.google.maps.Marker({
        map: map,
        position: { lat: t.lat, lng: t.lng },
        title: t.name,
      });

      bounds.extend(marker.position);

      const infowindow = new window.google.maps.InfoWindow({
        content: t.name,
      });

      marker.addListener('click', function () {
        infowindow.open(map, marker);
      });
    });

  map.fitBounds(bounds);
};

const MapWrapper = styled.div`
  width: 415px;
  height: 500px;

  @media (max-width: 600px) {
    width: 350px;
  }
`;

const MemberOrgs = () => {
  const { googleMapsApiKey } = useContext(PreferencesContext);
  const { trustors, count } = useContext(TrustorsContext);
  window.trustorData = trustors; // for use in the callback function

  useEffect(() => {
    if (!trustors) {
      return;
    }

    /**
     * Load Google Maps
     */
    const scriptElem = document.createElement('script');
    scriptElem.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&callback=initMap`;
    scriptElem.async = true;
    document.body.appendChild(scriptElem);

    return () => {
      document.body.removeChild(scriptElem);
      delete window.google.maps;
    };
  }, [googleMapsApiKey, trustors]);

  return (
    <Content id="member-orgs">
      <Toolbar />

      <Strap>Serving Trustors Nationwide</Strap>

      <Header>Member Organizations</Header>

      <Paragraph>
        Reta currently serves {count} Roman Catholic dioceses, archdioceses,
        religious communities, and other institutions across the United States.
        These Trustors help coordinate delivery of our services to their member
        organizations.
      </Paragraph>

      <Paragraph>
        For a complete list of member organizations, click&nbsp;
        <Link
          to={routes.TRUSTORS + '#content'}
          style={{
            color: COLORS.RED,
          }}
        >
          here.
        </Link>
      </Paragraph>

      <Flexbox
        style={{
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <MapWrapper id="map_canvas" />
      </Flexbox>
    </Content>
  );
};

export default MemberOrgs;
