import React, { useContext, useEffect, useState } from 'react';
import { COLORS } from '../../constants/colors';
import { Header } from '../shared/Header';
import styled from 'styled-components/macro';
import Contact from './Contact';
import { PreferencesContext } from '../../contexts/preferences';
import Spinner from '../shared/Spinner';

export const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${COLORS.BLUE};
  margin-bottom: 24px;
  border-bottom: 1px solid ${COLORS.RED};

  @media (max-width: 800px) {
    font-size: 16px;
    margin-top: 24px;
  }
`;

export const TeamWrapper = styled.div`
  width: 100%;

  @media (max-width: 800px) {
    display: block;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  margin-bottom: 16px;
`;

export let Team = () => {
  const { apiHost, setShowCarousel } = useContext(PreferencesContext);
  const [team, setTeam] = useState({
    execs: [],
    board: [],
    outside: [],
    partner: [],
  });
  const [init, setInit] = useState(false);

  useEffect(() => setShowCarousel(false), [setShowCarousel]);

  useEffect(() => {
    if (!apiHost) {
      return;
    }

    (async () => {
      const res = await fetch(`${apiHost}/team`);
      const json = await res.json();
      setTeam(json);
      setInit(true);
    })();
  }, [apiHost]);

  const locationName = contact => {
    let out = contact.location;

    if (contact.retaTitle && contact.title) {
      out = `${contact.title}, ${out}`;
    }

    return out;
  };

  return (
    <div
      style={{
        width: '100%',
        boxSizing: 'border-box',
      }}
    >
      <Header
        style={{
          marginBottom: 38,
        }}
      >
        Reta Leadership Team
      </Header>

      {!init && (
        <Row>
          <Spinner />
        </Row>
      )}

      {init && (
        <TeamWrapper>
          <div>
            <Title>Reta Board of Trustees</Title>

            <Columns>
              {team.board.map((e, i) => (
                <Contact
                  key={i}
                  name={e.displayName}
                  title={e.retaTitle || e.title}
                  location={locationName(e)}
                  cv={e.bioPath}
                  id={e.id}
                />
              ))}
            </Columns>
          </div>

          <div>
            <Title>Reta Leadership</Title>

            <Columns>
              {team.leadership.map((e, i) => {
                return (
                  <Contact
                    key={i}
                    name={e.displayName}
                    title={e.retaTitle || e.title}
                    location={locationName(e)}
                    showContactForm={e.showContactForm || false}
                    id={e.id}
                  />
                );
              })}
            </Columns>
          </div>

          <div>
            <Title> Reta Governance Committee Members</Title>

            <Columns>
              {team.outside.map((e, i) => (
                <Contact
                  key={i}
                  name={e.displayName}
                  title={e.retaTitle || e.title}
                  location={locationName(e)}
                  cv={e.bioPath}
                  id={e.id}
                />
              ))}
            </Columns>
          </div>
        </TeamWrapper>
      )}
    </div>
  );
};

export default Team;
