import React from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  justify-content: center;
  align-items: center;
  z-index: 1001;
  padding: 80;
  box-sizing: border-box;
`;

export const VideoModal = ({ videoUrl, onHideModal }) => {
  return (
    <Wrapper onClick={onHideModal}>
      <ReactPlayer
        width={720}
        height="auto"
        url={videoUrl}
        playing={true}
        controls={true}
        volume={1}
        onEnded={() => setTimeout(onHideModal, 500)}
      />
    </Wrapper>
  );
};
