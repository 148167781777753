import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import Spinner from '../components/shared/Spinner';
import { useContext } from 'react';
import { PreferencesContext } from '../contexts/preferences';
import { StyledInput as TextInput } from '../components/shared/TextInput';
import { StyledTextArea as TextArea } from '../components/shared/TextArea';
import Label from '../components/shared/Label';
import Button from '../components/shared/Button';
import Paragraph from '../components/shared/Paragraph';
import Header from '../components/shared/Header';
import { COLORS } from '../constants/colors';
import { useParams } from 'react-router-dom';

const Wrap = styled.div`
  position: relative;
  width: 100%;

  & .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  & form {
    position: relative;
    width: 100%;
    max-width: 400px;

    & > div {
      position: relative;
      width: 100%;
      margin: 18px 0;

      & > textarea {
        min-height: 200px;
      }
    }
  }

  & .btn-row {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 32px 0;
  }
`;

const Error = styled.div`
  color: ${COLORS.RED};
  font-style: italic;
  font-size: 0.8em;
  margin-top: 6px;
`;

const ContactUs = () => {
  const { apiHost } = useContext(PreferencesContext);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    document.title = 'The Reta Trust: Contact Us';
  }, []);

  const { register, handleSubmit, errors, reset } = useForm({
    mode: 'onBlur',
    submitFocusError: true,
  });

  const onSubmit = async data => {
    setSending(true);
    console.log(data);

    try {
      const res = await fetch(`${apiHost}/contact`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        cors: false,
        body: JSON.stringify({
          ...data,
          id,
        }),
      });

      if (res.status >= 400) {
        throw new Error('Invalid response received.', res.status);
      }

      reset();
      setError(false);
      setSent(true);
    } catch (e) {
      console.error(e);
      setError(true);
    }
    setSending(false);
  };

  return (
    <Wrap>
      <div>
        <Header>Contact</Header>

        <Paragraph>
          Please submit your contact information with a brief message and we'll
          get back to you shortly.
        </Paragraph>

        <div className="center">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Label>Full name: *</Label>
              <TextInput
                placeholder="required"
                name="fullname"
                ref={register({ required: true, maxLength: 80 })}
              />
              {errors.fullname && <Error>A name is required.</Error>}
            </div>

            <div>
              <Label>Email: *</Label>
              <TextInput
                placeholder="required"
                name="email"
                ref={register({ required: true, maxLength: 80 })}
              />
              {errors.email?.type === 'required' && (
                <Error>An email is required.</Error>
              )}
              {errors.email?.type === 'pattern' && (
                <Error>A valid email is required.</Error>
              )}
            </div>

            <div>
              <Label>Company:</Label>
              <TextInput name="company" ref={register()} />
            </div>

            <div>
              <Label>Phone Number:</Label>
              <TextInput name="phone" ref={register()} />
            </div>

            <div>
              <Label>Message: *</Label>
              <TextArea
                name="message"
                placeholder="required"
                ref={register({ required: true })}
              />
            </div>
            {errors.message && <Error>A message is required.</Error>}

            <div className="btn-row">
              {!sending && <Button type="submit">Submit</Button>}
              {sending && <Spinner />}
            </div>
          </form>
        </div>

        {sent && (
          <p>
            Your message has been successfully sent. Someone will be in touch
            with you shortly.
          </p>
        )}

        {error && (
          <p>
            <Error>
              Unfortunately, we were unable to send your request. Please try
              again shortly.
            </Error>
          </p>
        )}
      </div>
    </Wrap>
  );
};

export default ContactUs;
