import React, { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from '../../../contexts/firebase';
import { MessagesContext } from '../../../contexts/messages';
import styled from 'styled-components/macro';
import NewsletterItem from './NewsletterItem';
import Spinner from '../../shared/Spinner';
import HTML5Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

const Doc = styled.div`
  display: flex;
  align-items: center;
`;

const List = styled(Doc)`
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const NewsletterList = ({ filter = '' }) => {
  const { database } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  const [fetched, setFetched] = useState(false);
  const [allNewsletters, setAllNewsletters] = useState([]);
  const [filteredNewsletters, setFilteredNewsletters] = useState([]);

  /**
   * Subscribe to the Doc list
   */
  useEffect(() => {
    setFetched(false);

    const unsub = database
      .collection('/newsletters')
      .orderBy('order', 'asc')
      .onSnapshot(
        docs => {
          const newsletters = docs.docs
            .map(d => ({
              ...d.data(),
              id: d.id,
              ref: d.ref,
            }))
            .sort((a, b) => a.order - b.order);

          setAllNewsletters(newsletters);
          setFetched(true);
        },
        e => {
          console.error(e);
          addMessage('Unable to fetch newsletter list', 'error');
          setFetched(true);
        },
      );

    return () => unsub && typeof unsub === 'function' && unsub();
  }, [addMessage, database]);

  /**
   * Filter the newsletter list based on the search field
   */
  useEffect(() => {
    if (!filter && !allNewsletters.length) {
      return;
    }

    if (!filter) {
      return setFilteredNewsletters(allNewsletters);
    }

    const regex = new RegExp(filter, 'i');

    const filtered = allNewsletters.filter(
      g => regex.test(g.displayName) || regex.test(g.email),
    );

    setFilteredNewsletters(filtered);
  }, [filter, allNewsletters]);

  return (
    <List>
      {!fetched && <Spinner />}

      {fetched && (!filteredNewsletters || !filteredNewsletters.length) && (
        <div>No results found.</div>
      )}

      {filteredNewsletters && (
        <DndProvider backend={HTML5Backend}>
          {filteredNewsletters.map((newsletter, index) => (
            <NewsletterItem
              key={index}
              order={index}
              newsletter={newsletter}
              allNewsletters={allNewsletters}
            />
          ))}
        </DndProvider>
      )}
    </List>
  );
};

export default NewsletterList;
