import { useContext } from 'react';
import { PreferencesContext } from '../contexts/preferences';
import { FirebaseContext } from '../contexts/firebase';
import { MessagesContext } from '../contexts/messages';

/**
 * Updates the Firebase authentication information for a user
 *
 *
 * @return {function}
 * @param {string} userId firebase user id
 * @param {string} path firebase field to update
 * @param {*} value value for the firebase field
 * @param {function} setSpinner enable or disable the spinner
 */
const useUpdateFirebaseAuth = () => {
  const { apiHost } = useContext(PreferencesContext);
  const { user } = useContext(FirebaseContext);
  const { addMessage } = useContext(MessagesContext);

  return async (userId, path, value, setSpinner) => {
    if (setSpinner) {
      setSpinner(true);
    }

    let res;
    try {
      const idToken = await user.getIdToken();
      res = await fetch(`${apiHost}/admin/users/${userId}`, {
        method: 'PATCH',
        headers: {
          Authorization: `IdToken ${idToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify([
          {
            path,
            value,
          },
        ]),
      });
    } catch (e) {
      console.error(e);
      addMessage(`Unable to contact API.`, 'error');
    }

    if (res.status >= 400) {
      console.error(res);
      addMessage(
        [
          'Received an error when updating the authentication information.',
          'What a little while and try again.',
        ],
        'error',
      );
      return;
    }

    if (setSpinner) {
      setSpinner(false);
    }
  };
};

export default useUpdateFirebaseAuth;
