import imgPillar from './RETA.org.PILLAR.VI.BUTTON.png';
import Paragraph from '../../../shared/newShared/Paragraph';
import Pillar from '../Pillar';
import imgWillhite from '../pillar5/willhite.png';
import imgMcNamara from './RETA.org.TOM.MCNAMARA.png';
import imgBongiovanni from './bongiovanni.png';
import imgSilva from './silva.png';
import imgPassarello from './passarello.png';
import imgGovern from './retaTrustGovernance.png';
import Video from '../Video';
import Header from '../../../shared/Header';
import styled from 'styled-components/macro';
import useSetTitle from '../../../../hooks/useSetTitle';

const Emphasis = styled.span`
  color: #992131;
  font-weight: bold;
`;

const Clear = styled.div`
  clear: both;
`;

const Header2 = styled.div`
  color: #992131;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.03em;
  box-sizing: border-box;
  margin-top: 24px;
  margin-bottom: 12px;
`;

const Pillar2 = () => {
  useSetTitle('Pillar VI');

  return (
    <Pillar imgSrc={imgPillar} imgAlt="Pillar VI">
      <Paragraph>
        Reta’s Trust Agreement requires Trustors to elect Trustees, or Board
        Members, from its member organizations. Each Trustor representative has
        votes in proportion to the number of members enrolled for medical
        coverage.
      </Paragraph>

      <Paragraph>
        Trustees meet quarterly and at Reta’s annual Trustor and Strategic
        Planning meetings to monitor and assess the ongoing status of claims and
        the Trust’s financial assets. That means Trustees have the final word in
        the decisions that are made to support the best interests of all
        Trustors who also offer input in making those decisions. Over the years,
        Reta Trustees of participating dioceses and religious orders have been
        finance officers, human resource managers, clergy and women of religious
        orders.{' '}
      </Paragraph>

      <Paragraph>
        The Board of Trustees also has the important job of managing the assets
        of the Trust. These assets come from contributions made by Trustors that
        are held as reserves to fund the cost of covered members healthcare
        expenses.
      </Paragraph>

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          margin: '24px 0',
        }}
      >
        <div>
          <img src={imgGovern} alt="Governance" />
        </div>
      </div>

      <Paragraph>
        Decisions on ways to manage plan costs, vendors, asset investments and
        legal questions are often judged in the interest of diocesan
        constituents and with policy direction from their bishops. Consequently,
        the Reta Trust directly reflects the beliefs and the teachings of the
        Roman Catholic Church.
      </Paragraph>

      <Paragraph>
        Reta’s individual Trustors are well represented by the current slate of
        Trustees from dioceses representing 60% of the covered membership. That
        means that with this member representation, Reta maintains close ties
        with the issues that are of most concern to Catholic employers. That
        includes the health and wellbeing of its clergy, religious and lay
        staff, and healthcare budgets of Catholic organizations.
      </Paragraph>

      <Paragraph>
        Opportunities for Trustors to participate as Trustees and as committee
        members are frequently available to those who are interested in having a
        role in Reta’s governance structure.
      </Paragraph>

      <Header>Reta’s five governance committees</Header>

      <Paragraph>
        Reta’s Board of Trustees rely on a series of governance committees that
        provides input to the board on all aspects of Reta. These five
        committees represent an important component of Reta’s governance
        structure: The Audit Committee, the Finance & Investment Committee, the
        Health Benefits Planning Committee, the Managed Growth Committee and the
        Ethics & Appeals Committee.
      </Paragraph>

      <Paragraph>
        These committees are made up of Reta members who represent a variety of
        Reta Trustors. And busy working behind the scenes are those dedicated
        Trustor members who are heading up these committees who are also members
        of the Board of Trustees and operating officers of their individual
        dioceses.
      </Paragraph>

      <Clear />

      <Header2>Audit Committee</Header2>

      <Video
        poster={imgBongiovanni}
        float="right"
        style={{ paddingLeft: 25 }}
        videoUrl="https://assets.retatrust.org/2021/210421.1/Bongiovanni_P6.mp4"
      >
        Paul Bongiovanni — Reta Audit Committee CFO, Diocese of Oakland CA
      </Video>

      <Paragraph>
        Reta’s Audit Committee uses a delegated model to manage the Trust with
        different entities doing actuarial, accounting and claims processing.
        Paul Bongiovanni is the chair of this Reta committee, the CFO of the
        Diocese of Oakland, California and a member of the Reta Board of
        Trustees since 2008. Paul offers a brief summary of how this committee
        operates.
      </Paragraph>

      <Clear />

      <Header2>Finance & Investment Committee</Header2>

      <Video
        poster={imgSilva}
        float="right"
        style={{ paddingLeft: 25 }}
        videoUrl="https://assets.retatrust.org/2021/210421.1/Silva_P6.mp4"
      >
        Deacon Aruna Silva — Reta Finance & Investment Committee CFO, Diocese of
        Las Vegas NV
      </Video>

      <Paragraph>
        This Reta committee has the responsibility to ensure that the costs for
        operating Reta are appropriate. Deacon Aruna Silva heads up this Reta
        committee. He is also the CFO of the Diocese of Las Vegas and a member
        of the Reta Board of Trustees since 2015.
      </Paragraph>

      <Clear />

      <Header2>Health Benefits Planning Committee</Header2>

      <Video
        poster={imgWillhite}
        float="right"
        style={{ paddingLeft: 25 }}
        videoUrl="https://assets.retatrust.org/2021/210421.1/Willhite+P6.mp4"
      >
        Jo Willhite — Reta Health Benefits Planning Committee CAO, Archdiocese
        of Portland OR
      </Video>

      <Paragraph>
        This Reta committee is responsible for looking to the future to
        anticipate and ensure that Reta remains at the forefront of offering the
        healthcare benefits that are at the highest levels of quality and access
        for our members. Jo Willhite is the chair of this committee. Jo is the
        Chief Administrative Officer for the Archdiocese of Portland in Oregon
        and has also served as a Trustee on the Reta Board since 2015. Before
        taking on the role as chair of this committee, Jo headed up Reta’s
        Managed Growth Committee and speaks about how the two intersect.
      </Paragraph>

      <Clear />

      <Header2>Managed Growth Committee</Header2>

      <Video
        poster={imgMcNamara}
        float="right"
        style={{ paddingLeft: 25 }}
        videoUrl="https://assets.retatrust.org/2021/210421.1/McNamara+P6.mp4"
      >
        Tom McNamara — CFO, Diocese of Sacramento CA
      </Video>

      <Paragraph>
        The primary purpose of this committee is to promote healthy growth for
        the Reta Trust which brings several advantages to all Trustor members.
        One advantage of growing the Trust in size is Reta’s ability to leverage
        its purchasing power to negotiate better rates with vendors and carriers
        while reducing costs to Trustors. Heading up this committee is Tom
        McNamara who is the CFO of the Diocese of Sacramento and has also served
        as a Trustee on the Reta board since 2018. Tom offers a short summary of
        the biggest advantages of this program for Trustors.
      </Paragraph>

      <Clear />

      <Header2>Ethics & Appeals Committee</Header2>

      <Video
        poster={imgPassarello}
        float="right"
        style={{ paddingLeft: 25 }}
        videoUrl="https://assets.retatrust.org/2021/210421.1/Passarello_P6.mp4"
      >
        Joe Passarello — Reta Ethics & Appeals Committee CFO, Archdiocese of San
        Francisco CA
      </Video>

      <Paragraph>
        The Ethics & Appeals Committee assists the Trustees with guidance and
        oversite of the ethical practices of the Trust including but not limited
        to the implementation and review of the “Ethical and Religious
        Directives” and appeals from participants.
      </Paragraph>

      <Paragraph>
        Joe Passarello is the chair of Reta’s Ethics & Appeals Committee, is the
        CFO for the Archdiocese of San Francisco and has also served as a
        Trustee on Reta’s Board since 2018
      </Paragraph>

      <Header>Reta’s governance is all about you</Header>

      <Paragraph>
        Reta continues to grow and change over the years. But one thing has
        remained constant. We have always relied on our Trustor members to
        provide the Board with both guidance and insights involving how we
        operate and the decisions that need to be made.
      </Paragraph>

      <Paragraph>
        <i>So. If you are a Reta member...</i>
      </Paragraph>

      <Paragraph>
        <div>
          <Emphasis>Q.</Emphasis> Who has the last word when it comes to
          governing all matters Reta?
        </div>
        <div>
          <Emphasis>A.</Emphasis>{' '}
          <Emphasis
            style={{ display: 'inline-block', transform: 'rotate(180deg)' }}
          >
            You do!
          </Emphasis>
        </div>
      </Paragraph>

      <Paragraph>
        <div>
          <Emphasis>Q.</Emphasis> And when it comes to providing healthcare
          benefits, who is looking out for the best interests of Reta members?
        </div>
        <div>
          <Emphasis>A.</Emphasis>{' '}
          <Emphasis
            style={{ display: 'inline-block', transform: 'rotate(180deg)' }}
          >
            Reta Members!
          </Emphasis>
        </div>
      </Paragraph>
    </Pillar>
  );
};

export default Pillar2;
