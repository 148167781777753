import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import MemberHome from './components/member/Home';
import VaultHome from './components/vault/Home';
import PostLogin from './components/login/PostLogin';
import { useContext } from 'react';
import { FeaturesContext } from './contexts/features';
import ScrollingLanding from './views/ScrollingLanding';
import Documents from './views/Documents.jsx';
import History from './views/History.jsx';
import { pub, pubScroll } from './constants/routes';

const Routes = () => {
  const features = useContext(FeaturesContext);

  return (
    <Router>
      <Switch>
        <Route path="/member">
          <MemberHome />
        </Route>

        <Route path="/vault">
          <VaultHome />
        </Route>

        <Route exact path="/post-login">
          <PostLogin />
        </Route>

        <Route exact path={pub.HISTORY}>
          <History />
        </Route>

        {/* Documents*/}
        <Route path="/docs/:docName">
          <Documents />
        </Route>

        {/* Don't load the root routes if the feature flags haven't been loaded */}
        {features.init && (
          <>
            <Route path={pubScroll.ROOT}>
              <ScrollingLanding />
            </Route>

            <Route path={pub.HOME}>
              <Redirect to={pubScroll.ROOT} />
            </Route>
          </>
        )}
      </Switch>
    </Router>
  );
};

export default Routes;
