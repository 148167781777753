import React, { useContext, useEffect } from 'react';
import { Switch, Route, withRouter } from 'react-router';
import NotAuthorized from './NotAuthorized';
import NoMatch from '../shared/NoMatch';
import { FirebaseContext } from '../../contexts/firebase';
import { vault } from '../../constants/routes';
import AdminHome from './admin/Home';
import View from '../member/View';
import CategoryArchive from '../member/CategoryArchive';
import VaultHome from './VaultHome';

export const AdminRoute = withRouter(({ history, ...props }) => {
  const { profile } = useContext(FirebaseContext);

  useEffect(() => {
    if (!profile) {
      return;
    }

    if (!profile.vault?.isActive || !profile.vault?.isAdmin) {
      console.warn('Not active or admin.');
      history.push(vault.HOME);
    }
  }, [history, profile]);

  return <Route {...props} />;
});

const Routes = ({ match }) => {
  return (
    <Switch>
      <Route exact path={vault.HOME} component={VaultHome} />
      <Route
        exact
        path={`${vault.HOME}/view/:categoryId/archive`}
        component={CategoryArchive}
      />
      <Route exact path={vault.VIEW} render={() => <View isVault />} />
      <Route path={`${match.path}/not-authorized`} component={NotAuthorized} />

      <AdminRoute path={`${match.path}/admin`} component={AdminHome} />

      <Route path="*" component={NoMatch} />
    </Switch>
  );
};

export default withRouter(Routes);
