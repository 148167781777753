import React, { useState, useEffect, useContext } from 'react';
import imgCross from '../../assets/img/cross.svg';
import styled from 'styled-components/macro';
import { member, vault } from '../../constants/routes';
import AdminResourceItem from './AdminResourceItem';
import { StyledLink } from './ResourceItem';
import imgAdd from '../../assets/img/cat-add.svg';
import imgConfig from '../../assets/img/cat-config.svg';
import imgDel from '../../assets/img/cat-delete.svg';
import { admin } from '../../constants/routes';
import { FirebaseContext } from '../../contexts/firebase';
import { AdminItem } from './AdminResourceItem';
import HTML5Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import {
  Container,
  Category,
  CategoryName,
  Docs,
  Archive,
} from './CategoryItem';
import { useDrag, useDrop } from 'react-dnd';
import arrayMove from 'array-move';
import Promise from 'bluebird';

const AdminCategory = styled(Category)`
  justify-content: space-between;
  position: relative;
  border: 1px dashed rgba(0, 0, 0, 0);

  &:hover {
    background-color: #ececec;
  }

  &.over {
    background-color: #befffe;
    border-radius: 4px;
    border: 1px dashed #98b7bf;

    & a {
      color: #4a5a5e;
    }
  }

  &.dragging {
    border-radius: 4px;
    border: 1px dashed #98b7bf;

    & a {
      color: #4a5a5e;
    }
  }
`;

const Left = styled.div`
  display: flex;
  flex: 1 1 100px;
`;

const Right = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  right: 0;
  height: 100%;
  background-color: #ececece0;
`;

const CategoryItem = ({
  category,
  filter,
  order,
  allCategories,
  isVault = false,
}) => {
  const { profile } = useContext(FirebaseContext);

  const [showAdmin, setShowAdmin] = useState(false);
  const [docSnapshots, setDocSnapshots] = useState([]);
  const [docs, setDocs] = useState([]);
  const [regex, setRegEx] = useState(new RegExp('.', 'g'));

  const [{ opacity }, dragRef] = useDrag({
    item: { type: `CATEGORY`, category, order },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.25 : 1,
      // isDragging: setIsDragging(monitor.isDragging()),
    }),
  });

  const handleDrop = async item => {
    if (item.id === category.id) {
      console.log('SKIP');
      return;
    }

    await Promise.map(
      arrayMove(allCategories, item.order, order),
      async (d, id) => {
        await d.ref.update({ order: id });
      },
    );
  };

  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: `CATEGORY`,
    drop: handleDrop,
    collect: monitor => {
      const item = monitor.getItem();

      return {
        isOver:
          item &&
          item.category &&
          item.category.id !== category.id &&
          monitor.isOver(),
        // highlight drop targets if it's not the current item being dragged
        canDrop:
          item &&
          item.category &&
          item.category.id !== category.id &&
          monitor.canDrop(),
      };
    },
  });

  let className = '';
  if (isOver) {
    className += 'over ';
  }
  if (canDrop) {
    className += 'dragging ';
  }

  /**
   * Build a new regexp when the filter value changes
   */
  useEffect(() => {
    if (!filter || !filter.length) {
      setRegEx(new RegExp('.', 'g'));
    }
    setRegEx(new RegExp(filter, 'gi'));
  }, [filter]);

  /**
   * Get the active resources
   */
  useEffect(() => {
    const unsub = category.ref
      .collection(isVault ? 'resources' : 'documents')
      .where('archive', '==', false)
      .onSnapshot(docs => {
        if (docs.empty) {
          return;
        }
        setDocSnapshots(docs.docs);
      });

    return () => unsub && unsub();
  }, [category, isVault]);

  /**
   * Get the resources information
   */
  useEffect(() => {
    setDocs(
      docSnapshots
        .map(doc => {
          const data = doc.data();
          return { ...data, id: doc.id, ref: doc.ref };
        })
        .filter(d => regex.test(d.displayName))
        .sort((a, b) => a.order - b.order),
    );
  }, [docSnapshots, regex]);

  const handleShowAdmin = () => {
    if (isVault) {
      profile.vault?.isAdmin && setShowAdmin(true);
    } else {
      profile.isAdmin && setShowAdmin(true);
    }
  };

  const handleHideAdmin = () => setShowAdmin(false);

  return (
    <Container style={{ opacity }} ref={dragRef}>
      {/* Category */}
      <AdminCategory
        ref={dropRef}
        className={className}
        onMouseEnter={handleShowAdmin}
        onMouseLeave={handleHideAdmin}
      >
        <Left>
          <div>
            <img src={imgCross} alt="+" />
          </div>
          <CategoryName>{category.displayName}</CategoryName>
        </Left>

        {showAdmin && (
          <Right>
            <AdminItem
              src={imgAdd}
              alt="+"
              to={`${isVault ? vault.admin.CATEGORIES : admin.CATEGORIES}/add/${
                order + 1
              }`}
            />
            <AdminItem
              src={imgConfig}
              alt="o"
              to={`${isVault ? vault.admin.CATEGORIES : admin.CATEGORIES}/${
                category.id
              }`}
            />
            <AdminItem
              src={imgDel}
              alt="x"
              to={`${isVault ? vault.admin.CATEGORIES : admin.CATEGORIES}/${
                category.id
              }/delete`}
            />
          </Right>
        )}
      </AdminCategory>

      <DndProvider backend={HTML5Backend}>
        {/* Resources */}
        <Docs>
          {!docs.length && (
            <AdminResourceItem
              isVault={isVault}
              className={'no-docs'}
              categoryId={category.id}
              resource={{ displayName: 'No resources available' }}
              order={0}
              allResources={[]}
            />
          )}
          {docs.map((doc, idx) => {
            return (
              <AdminResourceItem
                isVault={isVault}
                key={doc.id}
                categoryId={category.id}
                to={`${isVault ? vault.HOME : member.MEMBER_HOME}/view/${
                  category.id
                }/${doc.id}`}
                resource={doc}
                order={idx}
                allResources={docs}
              />
            );
          })}

          {/* Archive */}
          {category.showArchive && (
            <Archive>
              <StyledLink
                to={`${isVault ? vault.HOME : member.MEMBER_HOME}/view/${
                  category.id
                }/archive`}
              >
                Archive...
              </StyledLink>
            </Archive>
          )}
        </Docs>
      </DndProvider>
    </Container>
  );
};

export default CategoryItem;
