import styled from 'styled-components/macro';

const Canvas = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
`;

export default Canvas;
